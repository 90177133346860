import React from 'react'
import { useDispatch } from 'react-redux'

import { WebSocketContext } from '../services/WebSocket'
import Constants from '../constants/constants'
import useStoreSubscription from '../hooks/useStoreSubscription'
import addKeyMouseClickListener from '../hooks/desktopListener'
import { onValueChangeByHandle, onValuesChangeInMask } from '../store/actions/store_actions'
import { getPxWidthByLetters, textWithMnemonic } from '../utils/screen'
import { renderMenuItemChildrens } from './Navbar'
import BSTooltip from './BSTooltip'
import constants from '../constants/constants'
import { getElementData } from './desktop_server/elementMethods'
import { getEmptyRowProp } from '../utils/actions'

const { SpecialId, InternalElementState, MenuActions, WebSocketActions } = Constants
const { SET_VALUE_REQUEST, INTERRUPT, SET_ATTRIBUTE, GET_SCREEN } = WebSocketActions
const { MENU_ACTION } = Constants.SetValueRequest
const { SELECT_RECORD, SELECT_ALL, DESELECT_ALL, CLOSE_PROGRAM, CLOSE_TAB } = MenuActions

export function hideAllSubmenus() {
    //console.log("HIDE ALL SUBMENUS")
    let elements = document.querySelectorAll('[id^=submenu_]')
    elements.forEach(element => {
        //console.log("nastavuju", element)
        element.style["display"] = "none"
    })
}

export function hideAllDropdowns() {
    let elements = document.querySelectorAll('[id^=dropdown_menu_]')
    //console.log("HIDE ALL DROPDOWNS", elements)
    elements.forEach(element => {
        element.classList.remove("show")
    })
}

/**
 * Polozka navbar menu - zaznam, upravy...
 * @param {*} props
 * @returns
 */
export default function NavbarItem(props) {
    const navbarItemRef = React.useRef()
    const dispatch = useDispatch()

    const ws_context = React.useContext(WebSocketContext)
    let { navbarItem, firstColWidth, secondColWidth, calledFrom } = props
    const { createMessage, createRequest, sendMessage, sendMessageImmediately } = ws_context

    let currentFocusId, ws, desktopSessionMask, desktopMenu, messageContainer, currentTabId, currentMaskID
    useStoreSubscription(store => {
        currentFocusId = store.ws.focusId
        ws = store.ws
        currentTabId = store.ws.currentTabId
        currentMaskID = store.ws.currentMaskID
        if (store.desktopSession[currentTabId] !== undefined)
            desktopSessionMask = store.desktopSession[currentTabId][currentMaskID]
        desktopMenu = store.desktopMenu
        messageContainer = store.app.messageContainer
    })

    let id = "navdrop_" + navbarItem.parentHandle + "_li_" + navbarItem.handle + "_" + calledFrom

    function doClick(event) {
        //console.log("doClick() NAVBAR", navbarItem.handle, MenuActions.INTERRUPT)

        //preruseni
        if (navbarItem.handle === MenuActions.INTERRUPT) {
            sendMessageImmediately(createMessage(WebSocketActions.INTERRUPT, createRequest({ action: INTERRUPT, focus: ws.focusId })), INTERRUPT)
            return
        }

        //close tab/mask se resi v APP.js - klient si resi close_tab i close_program sam, neni to ovladano zes strany serveru
        if (navbarItem.handle === CLOSE_PROGRAM || navbarItem.handle === CLOSE_TAB)
            return

        //console.log("event", event.key)
        if (!(event instanceof MouseEvent) &&
            !(event instanceof KeyboardEvent &&
                event.key === "Enter")) {

            return
        }

        //neni koncovy prvek, ma dalsi potomky
        if (navbarItem.childrens.length > 0) {
            event.preventDefault()
            event.stopPropagation()

            let id = "submenu_" + navbarItem.parentHandle + "_li_" + navbarItem.handle + "_submenu_" + calledFrom
            let navbarItemElement = document.getElementById(id)
            if (navbarItemElement.style["display"] === "block") {
                navbarItemElement.style.setProperty("display", "none")
                navbarItemElement.style.setProperty("left", "100%")
                //console.log("NASTAVUJU HIDE", props)
            } else {
                //console.log("NASTAVUJU SHOW", props)
                navbarItemElement.style.setProperty("display", "block")
                navbarItemElement.style.setProperty("left", "100%")
                navbarItemElement.style.setProperty("transform", "translate(0,0)")
            }

            return
        }

        let callback
        const sendedValue = getElementData(currentFocusId).value
        const sendedTabId = currentTabId
        const sendedMaskid = currentMaskID
        const sendedElementId = currentFocusId

        function addBrowseRowId() {
            let focusedElement = desktopSessionMask[currentFocusId]
            if ((navbarItem.handle === SELECT_RECORD
                || navbarItem.handle === SELECT_ALL
                || navbarItem.handle === DESELECT_ALL) && focusedElement.componentName === 'BrowseElement') {
                callback = response => {
                    let checkboxHandle
                    focusedElement.childIds.forEach(handle => {
                        if (desktopSessionMask[handle].data.specialId === SpecialId.CHECKBOX)
                            checkboxHandle = handle
                    })

                    if (checkboxHandle !== undefined) {
                        let internalState
                        if (navbarItem.handle === SELECT_RECORD)
                            internalState = InternalElementState.PARTLY_CHECKED
                        else if (navbarItem.handle === SELECT_ALL)
                            internalState = InternalElementState.CHECKED
                        else if (navbarItem.handle === DESELECT_ALL)
                            internalState = InternalElementState.UNCHECKED

                        dispatch(onValueChangeByHandle(checkboxHandle, "internalState", internalState))
                    }
                }
                return { rowId: focusedElement.data.rowId }
            } else {
                callback = response => {
                    if (sendedValue !== null)
                        dispatch(onValuesChangeInMask(sendedElementId, sendedTabId, sendedMaskid, { value: sendedValue }))
                }
            }
        }

        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: currentFocusId,
            newFocus: navbarItem.handle,
            action: MENU_ACTION,
            value: sendedValue,
            ...addBrowseRowId()
        })), callback)

        //schovani dropdown a submenus
        hideAllSubmenus()
        hideAllDropdowns()
    }


    addKeyMouseClickListener(navbarItemRef, (e) => {
        //console.log("E", e.srcElement.id)
        //console.log(navbarItem)
        if (!e.srcElement.id?.startsWith("star_input") && !e.srcElement.id.startsWith("star_checkbox"))
            doClick(e)
        else {
            if (e.srcElement.id?.startsWith("star_input"))
                e.stopPropagation()
        }
    })

    let disabled = (navbarItem.sensitivity) ? "" : " disabled"
    let styleNoPadding = { padding: '0rem .3rem 0rem .3rem' }

    /*pocita se pouze s jednou podurovni - jak je tomu na strane progresu*/
    function renderDescendants(parentId) {
        let longestWord = 1
        let longestAbb = 1
        for (var key of Object.keys(desktopMenu)) {
            if (desktopMenu[key].subType === "RULE") continue
            if (desktopMenu[key].parentHandle !== parentId) continue
            longestWord = Math.max(desktopMenu[key].label.length, longestWord)
            if (desktopMenu[key].accelerator !== undefined)
                longestAbb = Math.max(desktopMenu[key].accelerator.length, longestAbb)
        }

        let firstColWidth = getPxWidthByLetters(longestWord, true) / 2
        let secondColWidth = getPxWidthByLetters(longestAbb, true)

        if (navbarItem.childrens.length > 0) {
            let id = "submenu_" + navbarItem.parentHandle + "_li_" + navbarItem.handle + "_submenu_" + calledFrom
            return <ul id={id} className="submenu dropdown-menu" style={{
                "--bs-dropdown-min-width": firstColWidth + secondColWidth + "px",
                "--bs-dropdown-link-active-bg": "none",
                display: "none"
            }}>
                {renderMenuItemChildrens(navbarItem, desktopMenu, props.mnemonic, props.inRecordAction)}
            </ul>
        }
    }

    //normal     color: #495057;

    //var(--bs-nav-link-color);
    //disabled color: #adb5bd; pointer-events: none;
    let dropDownItemStyle = {
        ...styleNoPadding,
        textDecoration: "none"
    }

    //var(--bs-nav-link-disabled-color);
    if (!navbarItem.sensitivity) {
        dropDownItemStyle = {
            ...styleNoPadding,
            pointerEvents: "none",
            textDecoration: "none"
        }
    }

    function formatShortcuts() {
        let shortcuts = []
        let classname = "badge text-bg-light"
        let style = { fontWeight: 'normal', '--bs-badge-padding-x': '0em' }

        function margin() {
            if (shortcuts.length > 0) {
                return " ms-2"
            }
            return ""
        }

        if (navbarItem.accelerator !== undefined && navbarItem.accelerator !== "")
            shortcuts.push(<span key={"ac1"} className={classname + margin()} style={style}> {navbarItem.accelerator}</span>)
        if (navbarItem.accelerator2 !== undefined && navbarItem.accelerator2 !== "")
            shortcuts.push(<span key={"ac2"} className={classname + margin()} style={style}> {navbarItem.accelerator2}</span>)
        if (navbarItem.accelerator3 !== undefined && navbarItem.accelerator3 !== "")
            shortcuts.push(<span key={"ac3"} className={classname + margin()} style={style}> {navbarItem.accelerator3}</span>)

        return shortcuts

        //KDB
        /*let formattedShortcuts = []
        let shortcut = []
    
        if (navbarItem.accelerator) {
            shortcut = navbarItem.accelerator?.split("+")
            shortcut.forEach((key, index) => {
                if (index > 0)
                    formattedShortcuts.push("+")
                formattedShortcuts.push(<kbd key={navbarItem.handle + "_" + index} className="kbd">{key}</kbd>)
            })
        }
    
        return formattedShortcuts*/
    }

    let dropDownToggle
    if (navbarItem.childrens.length > 0)
        dropDownToggle = { ["data-bs-toggle"]: "dropdown" }

    function hasFunctionAncestor() {
        //console.log("hasFunctionAncestor", navbarItem.parentHandle)
        if (navbarItem.parentHandle === MenuActions.FUNCTIONS)
            return true

        if (desktopMenu[navbarItem.parentHandle]?.parentHandle)
            return true

        return false
    }

    function Favourites() {
        //console.log("inRecordAction", inRecordAction)
        /*if (inRecordAction !== undefined)
            return null*/

        if (hasFunctionAncestor()) {
            if (navbarItem.sensitivity && navbarItem.childrens.length === 0) {
                let isFavourite = navbarItem.favorite
                if (isFavourite === undefined)
                    isFavourite = false

                function onStarClick(e) {
                    e.stopPropagation()
                    navbarItemRef.current.focus()

                    //console.log("onStarClick", e)
                    sendMessage(createMessage(SET_ATTRIBUTE, createRequest({
                        navbarItemHandle: navbarItem.handle,
                        favorite: !isFavourite
                    })), response => {
                        response.data.forEach(resp => {
                            if (resp.OkResponse)
                                sendMessage(createMessage(GET_SCREEN, createRequest({})))
                        })
                    })
                }

                return <div className="star_checkbox">
                    <BSTooltip id={"star_favorite_" + calledFrom + "_" + navbarItem.handle} tooltiptext={isFavourite ? messageContainer[constants.MessageContainer.REMOVE_FROM_FAVORITES] : messageContainer[constants.MessageContainer.ADD_2_FAVORITES]} >
                        <input id={"star_input_" + calledFrom + "_" + navbarItem.handle} className="star fs-4 me-1" type="checkbox" checked={isFavourite} readOnly tabIndex={1} />
                        <label htmlFor={"star_input_" + calledFrom + "_" + navbarItem.handle}><i className={`star-icon ${isFavourite ? 'fas' : 'far'} fa-star`} id="star_checkbox" onClick={onStarClick}></i></label>
                    </BSTooltip >
                </div >
            } else
                return <div className='me-3'></div>
        }
    }

    let gridTemplateColumns = '3fr 1fr'

    return <li>
        <a
            id={id}
            key={id}
            tabIndex="1"
            ref={navbarItemRef}
            className={"dropdown-item p0 m0 " + disabled}
            {...dropDownToggle}
            style={{
                ...dropDownItemStyle,
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns,
                ...styleNoPadding
            }}
            href="#">
            <div id={"nav_div_2_" + navbarItem.handle}>
                <div style={{ display: 'flex' }}>
                    <Favourites />

                    <BSTooltip key={"tooltip_" + id} tooltiptext={navbarItem.tooltip}>
                        {textWithMnemonic(navbarItem.label, props.mnemonic, true)}
                    </BSTooltip>
                </div>
            </div>
            <div key={"nav_a_2_" + navbarItem.handle}>
                {formatShortcuts()}
                {(navbarItem.childrens.length > 0) ? <p>&#707;</p> : ""}
            </div>

        </a>

        {renderDescendants(navbarItem.handle)}
    </li >

    //Pokus o prevedeni na styled componentes - nefunguje v tmavem rezimu - implementovano v adminkit.dropdown.scss, bylo by nutne vytvorit novy style
    //dojde ke konfliktu "li.display: grid" a v css je "li.display.block"
    return <DropDownItemStyledLi key={"nav_i_" + navbarItem.handle} ref={navbarItemRef} className={"dropdown-item p0 m0" + disabled} gridTemplateColumns={"2fr 1fr"}>
        <DropDownItemStyledA className={"dropdown-item " + disabled} href="#">
            {textWithMnemonic(navbarItem.label, props.mnemonic)}
        </DropDownItemStyledA>
        <DropDownItemStyledA className={"dropdown-item " + disabled} href="#">
            {textWithMnemonic(navbarItem.accelerator, props.mnemonic)}
        </DropDownItemStyledA>
    </ DropDownItemStyledLi >
}
