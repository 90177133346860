//vzor jak muze i vypadat drag and drop grid stack- https://gridstackjs.com/

import React from 'react'
import { connect, useDispatch, useStore } from 'react-redux'
import constants from '../../constants/constants'
import { WebSocketContext } from '../../services/WebSocket'
import { setDashboards } from '../../store/actions/ws_actions'
import { LISTENER_TYPE, addListener } from '../../hooks/desktopListener'
import Widget from './Widget'
import { pxToRem } from '../../utils/screen'

const Dashboard = props => {
    const { dashboard, dashboardIndex, isSmallScreen } = props
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context
    const dashboardRef = React.useRef()
    const dragItemIndex = React.useRef()
    const dragWidget = React.useRef()
    const targetWidget = React.useRef()
    const switched = React.useRef()
    const [switchingState, setSwitchingState] = React.useState()

    React.useEffect(() => {
        if (dashboard.widgets === undefined || dashboard?.widgets?.length === 0)
            getWidgets()
    }, [])

    function getWidgets() {
        sendMessage(createMessage(constants.WebSocketActions.GET_WIDGETS, createRequest({
            dashboardIndex: dashboardIndex,
            dashboardId: dashboard.id
        })))
    }

    function fillTo4() {
        let numberOfTiles = 4
        if (dashboardRef.current) {
            let dashboardRect = dashboardRef.current?.getBoundingClientRect()
            let dashboardWidth = dashboardRect?.width
            numberOfTiles = Math.floor(pxToRem(dashboardWidth) / 22)
        }

        let width = 0
        dashboard?.widgets?.forEach(widget => width += widget.width)

        let numberOfEmptyTiles = width % numberOfTiles
        if (numberOfEmptyTiles > 0)
            numberOfEmptyTiles = numberOfTiles - (numberOfEmptyTiles)

        let emptyTiles = []
        for (let index = 0; index < numberOfEmptyTiles; index++) {
            emptyTiles.push(<div></div>)
        }

        return emptyTiles
    }

    //console.log("render -> dashboard", dashboard?.widgets)
    //console.log("Dashboard", switchingState)
    return <div
        ref={dashboardRef}
        style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(22rem, 1fr))", gap: "1rem",
            gridAutoRows: "11rem",
            gridAutoFlow: "dense"
        }}>
        {
            dashboard?.widgets?.map((widget, widgetIndex) => {
                //console.log("WIDGET", widget)
                return <React.Fragment key={"draggable_grid_item_" + widgetIndex}>
                    <Widget
                        dashboard={dashboard}
                        dashboardIndex={dashboardIndex}
                        isSmallScreen={isSmallScreen}
                        widget={widget}
                        widgetIndex={widgetIndex}
                        dragItemIndex={dragItemIndex}
                        dragWidget={dragWidget}
                        targetWidget={targetWidget}
                        switched={switched}
                        switchingState={switchingState}
                        setSwitchingState={setSwitchingState} />
                </React.Fragment>
            })}

        {fillTo4()}
    </div >
}

const Dashboards = props => {
    const { dashboards } = props
    const [isSmallScreen, setSmallScreen] = React.useState(checkIsSmallScreen())
    const ws_context = React.useContext(WebSocketContext)
    const dispatch = useDispatch()

    const { createMessage, createRequest, sendMessage } = ws_context
    const dashboardsItems = dashboards?.dashboards
    const selectedDashboardId = dashboards?.selectedDashboardId
    const dashboardIndex = dashboardsItems?.findIndex(dashboard => dashboard.id === selectedDashboardId)
    const ACTION_SELECT_DASHBOARD = 1

    React.useEffect(() => {
        const controller = new AbortController()

        window.addEventListener(LISTENER_TYPE.RESIZE, e => {
            setSmallScreen(checkIsSmallScreen())

        }, { signal: controller.signal })

        return () => {
            controller.abort()
        }
    }, [])

    function checkIsSmallScreen() {
        return window.innerWidth <= 768
    }

    if (dashboards === undefined)
        return


    function displayText(dashboard) {
        if (dashboard.id === selectedDashboardId)
            return <strong>{dashboard.title}</strong>

        return dashboard.title
    }
    //console.log("Dashboards => render()", dashboards, dashboardIndex)
    return <div>
        <nav className="nav nav-pills flex-column flex-sm-row">
            {dashboardsItems.map((dashboard, index) => {
                if (selectedDashboardId === dashboard.id)
                    return <div key={"dashboard_" + index} style={{ display: "flex", alignItems: 'center' }}>
                        <span className="pe-1">
                            <h3>
                                <strong>
                                    {dashboard.title}
                                </strong>
                            </h3>
                        </span>
                        <div className="dropdown">
                            <a className="dropdown-toggle dropdown-icon-button" href="#" data-bs-toggle="dropdown" tabIndex={-1}>
                                <i className="fas fa-chevron-down fa-xs" />
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end">
                                {dashboardsItems
                                    //.filter(dashboard => selectedDashboardId !== dashboard.id)
                                    .map((dashboard, index) => {
                                        return <li key={"dashboards_option_" + index}><a className={"dropdown-item"} href="#" onClick={e => {
                                            if (dashboard.id !== selectedDashboardId)
                                                sendMessage(createMessage(constants.WebSocketActions.SET_DASHBOARD, createRequest({
                                                    action: ACTION_SELECT_DASHBOARD,
                                                    dashboardId: dashboard.id
                                                })), response => {
                                                    //nastaveni id dashboard + smazani widgets - budou nacteny znovu
                                                    dispatch(setDashboards({
                                                        ...dashboards,
                                                        selectedDashboardId: dashboard.id,

                                                        dashboards: dashboards.dashboards.map(arrayDashboard => {
                                                            if (arrayDashboard.id === dashboard.id)
                                                                return {
                                                                    ...arrayDashboard,
                                                                    widgets: []
                                                                }

                                                            return arrayDashboard
                                                        })
                                                    }))
                                                })
                                        }}>{displayText(dashboard)}</a></li>
                                    })}
                            </ul>
                        </div>
                    </div>
                //return <a className={"flex-sm-fill text-sm-center nav-link" + (selectedDashboardId === dashboard.id ? " active" : "")} aria-current="page" href="#">{dashboard.title}</a>
            })}
        </nav>
        <Dashboard key={"dashboard_" + selectedDashboardId} dashboard={dashboardsItems[dashboardIndex]} dashboardIndex={dashboardIndex} isSmallScreen={isSmallScreen} />
    </div>
}

function mapStateToProps(state, ownProps) {
    return {
        dashboards: state.dashboards
    }
}

export default connect(mapStateToProps)(Dashboards)
