import { useEffect } from 'react'
import { useStore } from 'react-redux'

export default function useStoreSubscription(fillValues2Variables) {
    const store = useStore()

    //INIT VALUES
    fillValues2Variables(store.getState())

    useEffect(() => {
        const unsubscribeStore = store.subscribe(() => {
            //UPDATE VALUES
            fillValues2Variables(store.getState())
        })

        return () => {
            unsubscribeStore()
        }
    })
}
