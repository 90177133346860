import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import constants from '../constants/constants'
import { addListener, LISTENER_TYPE } from '../hooks/desktopListener'
import { WebSocketContext } from '../services/WebSocket'
import { logRender, logRenderProps } from '../utils/logging'
import BSTooltip from './BSTooltip'
import { getStore } from '..'
import { Dropdown } from 'bootstrap/dist/js/bootstrap.bundle.min.js'
import FavoriteSettingsDialog from './dialogs/FavoriteSettingsDialog'
import { findIconKey } from './dialogs/IconsDialog'
import styles from '../constants/styles'
import { getEmptyRowProp } from '../utils/actions'

export const SHOW_SETTINGS = "SHOW_SETTINGS"
const { CONTEXT_MENU, CLICK } = LISTENER_TYPE

function FavoriteFunctions(props) {
    const { currentFocusId, favorites, dispatch } = props
    const dragItem = React.useRef()
    const dragItemOver = React.useRef()
    const [dividerIndex, setDividerIndex] = React.useState(false)

    logRender("FavoriteFunctions")
    logRenderProps()
    //console.log("FavoriteFunctions", favorites)
    return <div className="d-flex gap-1">
        {favorites?.map((menuItem, index) => {
            //console.log("menuitem", menuItem)
            return <Fragment key={"frag_f_b_" + index}>
                <FavouriteButton
                    key={"f_b_" + menuItem.handle}
                    menuItem={menuItem}
                    index={index}
                    currentFocusId={currentFocusId}
                    dragItem={dragItem}
                    dragItemOver={dragItemOver}
                    favorites={favorites}
                    dispatch={dispatch}
                    setDividerIndex={setDividerIndex} />
                {(dividerIndex === index) ? <div style={{ height: '1', borderLeft: '2px dashed #aaa' }} /> : null}

            </Fragment>
        })}
    </div >
}

function FavouriteButton(props) {
    const btnFavourite = React.useRef()
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context

    const [isDialogVisible, setDialogVisibility] = React.useState(false)

    const messageContainer = getStore().getState().app.messageContainer
    const { menuItem, index, currentFocusId, dragItem, dragItemOver, favorites, dispatch, setDividerIndex } = props
    let dropDownFavourite
    React.useEffect(() => {
        dropDownFavourite = new Dropdown(btnFavourite.current)

        return () => {
            dropDownFavourite.dispose()
        }
    })

    addListener(LISTENER_TYPE.CLICK, btnFavourite, e => {
        sendMessage(createMessage(constants.WebSocketActions.SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: currentFocusId,
            newFocus: menuItem.handle,
            action: constants.SetValueRequest.MENU_ACTION,
        })))

        dropDownFavourite.hide()
    })

    addListener(CONTEXT_MENU, btnFavourite, e => {
        e.preventDefault()
        dropDownFavourite.show()
    })

    function addGap() {
        if (menuItem.favFAUnicode !== undefined && menuItem.favFAUnicode !== "" && menuItem.favUserDescription !== undefined && menuItem.favUserDescription !== "")
            return " me-2"

        return ""
    }


    logRender("FavoriteSettingsDialog")
    logRenderProps(props)
    return <React.Fragment>
        <BSTooltip key={"tooltip_f_b" + menuItem.handle} tooltiptext={menuItem.tooltip} shortcuts={[menuItem.accelerator, menuItem.accelerator2]}>
            <button
                key={"btn_settings_" + menuItem.handle}
                id={"btn_settings_" + menuItem.handle}
                ref={btnFavourite}
                type="button"
                disabled={!menuItem.sensitivity}
                className="btn btn-primary"
                style={styles.buttonStyle}
                onDragOver={e => {
                    //hide stop icon
                    e.preventDefault()
                }}
                onDragStart={e => {
                    dragItem.current = index
                    //console.log("onDragStart", index)                    
                }}
                onDragEnter={e => {
                    dragItemOver.current = index
                    //console.log("onDragEnter", index)
                    setDividerIndex(index)
                }}
                onDragEnd={e => {
                    let dragNavbarItem = favorites[dragItem.current]
                    let toSwitchNavbarItem = favorites[dragItemOver.current]

                    /*console.log("favorites", favorites)
                    console.log(dragItem.current, "presouvam", dragNavbarItem)
                    console.log(dragItemOver.current, "za", toSwitchNavbarItem)*/

                    favorites[dragItem.current] = toSwitchNavbarItem
                    favorites[dragItemOver.current] = dragNavbarItem

                    //console.log("favorites", favorites)
                    let favOrder
                    favorites.forEach(item => {
                        if (favOrder === undefined) {
                            favOrder = item.handle
                        } else
                            favOrder = favOrder + String.fromCharCode(1) + item.handle
                    })

                    sendMessage(createMessage(constants.WebSocketActions.SET_ATTRIBUTE, createRequest({
                        navbarItemHandle: constants.MenuActions.FUNCTIONS,
                        favOrder: favOrder
                    })), response => {
                        response.data.forEach(resp => {
                            if (resp.OkResponse)
                                sendMessage(createMessage(constants.WebSocketActions.GET_SCREEN, createRequest({})))
                        })
                    })

                    dragItem.current = null
                    dragItemOver.current = null

                    setDividerIndex(-1)
                }}
                draggable={true}
                data-bs-toggle="dropdown">
                <span><i className={"fas fa-" + findIconKey(menuItem.favFAUnicode) + addGap()}></i>{menuItem.favUserDescription}</span>
            </button>
            <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={e => {
                    setDialogVisibility(true)
                }} href="#">{messageContainer[constants.MessageContainer.SETTINGS]}</a></li>
                <li><a className="dropdown-item" onClick={e => {
                    sendMessage(createMessage(constants.WebSocketActions.SET_ATTRIBUTE, createRequest({
                        navbarItemHandle: menuItem.handle,
                        favorite: false
                    })), response => {
                        response.data.forEach(resp => {
                            if (resp.OkResponse)
                                sendMessage(createMessage(constants.WebSocketActions.GET_SCREEN, createRequest({})))
                        })
                    })
                }} href="#">{messageContainer[constants.MessageContainer.REMOVE]}</a></li>
            </ul>
        </BSTooltip>
        <FavoriteSettingsDialog menuItem={menuItem} isDialogVisible={isDialogVisible} setDialogVisibility={setDialogVisibility} messageContainer={messageContainer} />
    </React.Fragment >
}

function mapStateToProps(state, ownProps) {
    //console.log("map state to props", state, ownProps)
    return {
        favorites: getFavoritesMenuItems(state),
        desktopMenu: state.desktopMenu,
        currentFocusId: state.ws.focusId
    }
}

export function getFavoritesMenuItems(state) {
    let desktopMenu = state.desktopMenu
    let favOrder = desktopMenu[constants.MenuActions.FUNCTIONS]?.favOrder?.split(String.fromCharCode(1))
    if (favOrder === undefined)
        favOrder = []

    let favorites = []
    favOrder.forEach(handle => {
        if (desktopMenu[handle] !== undefined)
            favorites.push(desktopMenu[handle])
    })
    return favorites
}

function areEqual(prevProps, nextProps) {
    return (JSON.stringify(prevProps.desktopMenu) === JSON.stringify(nextProps.desktopMenu) && prevProps.currentFocusId === nextProps.currentFocusId)
}

export default connect(mapStateToProps)(React.memo(FavoriteFunctions, areEqual))
//export default connect(mapStateToProps)(FavoriteFunctions)