import React from 'react';
import { connect } from 'react-redux'

import { addListener, LISTENER_TYPE } from '../hooks/desktopListener'
import ModalDialogElement from '../components/desktop_server/ModalDialogElement'
import { getPxWidthByLetters, getWidthInChars } from '../utils/screen'
import constants from '../constants/constants'
import { ROW_HEIGHT } from './browse/Browse';

export function renderChildrens2Screen(props) {
    const { childIds, renderChild, desktopSessionMask, previousElement } = props

    //console.log("renderChildrens2Screen")
    //console.log(props)

    if (childIds === undefined)
        return ""

    /*obrazovka lze vykreslit jako:
        -   maska
        -   downframe(tabulkove zobrazeni)*/
    if (props.data.downFrame)
        return renderAsDownFrame()
    else
        return renderAsMask()

    function findNextElement(id) {
        let element = desktopSessionMask[id]
        let nextElementId
        childIds.forEach(childId => {
            let nextElement = desktopSessionMask[childId]

            if (nextElement.data.y === element.data.y && nextElement.data.x > element.data.x) {
                if (nextElementId === undefined)
                    nextElementId = nextElement.id

                if (nextElementId && nextElement.data.x < desktopSessionMask[nextElementId].data.x)
                    nextElementId = nextElement.id
            }
        })

        return desktopSessionMask[nextElementId]
    }

    function renderAsMask() {
        //console.log("render as mask")
        //mnemonica
        const frmRef = React.useRef(null)
        addListener(LISTENER_TYPE.KEYDOWN, frmRef, event => {
            let maskElement = desktopSessionMask[props.id]
            let childIds = maskElement.childIds
            childIds.forEach(elementId => {
                let element = desktopSessionMask[elementId]
                let caption = element.data.caption

                if (event.type === LISTENER_TYPE.KEYDOWN && caption && caption.indexOf("&") > -1) {
                    let letterIndex = caption.indexOf("&") + 1
                    let fuctionalKey = caption.substring(letterIndex, letterIndex + 1).toLowerCase()
                    if (event.key === fuctionalKey
                        && (element.data.specialId === constants.SpecialId.BUTTON_MODAL_YES || element.data.specialId === constants.SpecialId.BUTTON_MODAL_NO)) {
                        document.getElementById(elementId)?.click()
                    }
                }
            })
        })

        let frmWidth = props.data.width
        let isDialog = props.componentName === "DialogElement" || props.componentName === "ModalDialogElement"
        if (!isDialog)
            isDialog = desktopSessionMask[props.data.parentHandle].componentName === "DialogElement" || desktopSessionMask[props.data.parentHandle].componentName === "ModalDialogElement"

        let hasFrame = (isDialog || (props.componentName === "FrameElement" && props.data.box))
        if (hasFrame)
            frmWidth = frmWidth - 1

        //console.log("mask", props, parent)
        let maskStyle = {
            display: "grid",
            gap: "0.3rem",
            minWidth: getWidthInChars(props.data.width, isDialog),
            gridTemplateColumns: "repeat(" + (frmWidth - 1) + ", 1fr)"
        }

        return <div id={"mask_" + props.id} tabIndex="0" ref={frmRef} className="no_outline" style={maskStyle}>
            {
                childIds.map((val, i) => {

                    let element = desktopSessionMask[val]
                    if (element.data.visible === false)
                        return

                    let y = element.data.y
                    let x = element.data.x
                    let width = element.data.width
                    let height = element.data.height

                    //zvetseni poli mensich jak sirka 3 jednotky                
                    let widthCorrection = (x + width)
                    if ((widthCorrection - x) < 3 && element.componentName !== "LabelElement") {
                        let nextElement = findNextElement(element.id)
                        let space = 3

                        //console.log("MENSI JAK TRI", x, width, nextElement)
                        if (nextElement) {
                            let availableSpace = nextElement.data.x - (x)
                            //console.log(nextElement.data.x + " > " + x, element, nextElement, "avail space", availableSpace)
                            if (availableSpace < 3) {
                                space = availableSpace
                                //console.log("SPACE", space)
                            }
                        }

                        widthCorrection = x + space
                    }

                    let minWidth = "1rem"
                    if (element.componentName === "InputFieldElement")
                        minWidth = "1.5rem"
                    if (element.data.dataType === "LOGICAL")
                        minWidth = "2.5rem" //1.5rem se nevesla cela hodnota "Ano" na tri jednotky gridu v dialogu
                    if (element.componentName === "LabelElement")
                        minWidth = undefined

                    if (element.componentName === "RadioSetElement")
                        height++

                    let gridArea = y + " / " + x + " / " + (y + height - 1) + " / " + widthCorrection
                    return <div id={"element_" + element.id} style={{
                        gridArea: gridArea,
                        minWidth: minWidth,
                        marginBottom: "0px",
                        width: "100%"
                    }} key={element.id}>
                        {renderChild(element.id, previousElement)}
                    </div>
                })
            }
            {renderEmptyRows()}
        </div>
    }

    function renderEmptyRows() {
        //PRAZDNE RADKY
        let emptyRows = []
        let yAxis = new Set()

        if (childIds.length > 1)
            childIds.forEach(value => {
                let element = desktopSessionMask[value]
                //console.log("ELEMENT", element)
                if (element.data.visible && element.data.specialId === undefined) {
                    if (element.data.height > 1) {
                        for (let index = element.data.y; index < (element.data.y + element.data.height); index++) {
                            yAxis.add(index)
                        }
                        yAxis.add(element.data.y + element.data.height - 1)
                    } else
                        yAxis.add(element.data.y)
                }

            })

        let yAxisArray = Array.from(yAxis).sort(function (a, b) { return a > b ? 1 : -1 })

        //console.log("yAxisArray", yAxisArray)

        //console.log("yAxisArray", yAxisArray)
        if (yAxisArray.length > 0) {
            let prevVal = yAxisArray[0]
            yAxisArray.forEach(value => {
                //todo child height == ...
                //console.log("VALUE", value, "PREF VAL", prevVal, "(" + (value - prevVal) + ")")
                if (value - prevVal > 1) {
                    let gridArea = prevVal + 1 + "/" + 1 + "/" + value + "/" + (props.data.width - 1)
                    let elementStyle = {
                        gridArea: gridArea,
                        height: '1rem'
                    }
                    emptyRows.push(<div id={"empty_element_y" + value} key={"empty_element_y" + value} style={elementStyle}></div >)
                }
                prevVal = value
            })
        }

        //console.log("EMPTY ROWS", emptyRows)
        return emptyRows
    }

    function renderAsDownFrame() {
        //console.log("renderAsDownFrame")
        let header = []
        let rows = {}
        let dialogs = []

        childIds.forEach((val) => {
            let element = desktopSessionMask[val]
            if (element.componentName === "InputFieldElement") {
                //console.log("ELEMENT: ", element)

                let textAlign = "left"
                //zarovnani decimal a integer do prava
                if (element.data.dataType === "DECIMAL" || element.data.dataType === "INTEGER")
                    textAlign = "right"

                //HLAVICKA
                if (element.data.downFrameLine === undefined || element.data.downFrameLine === 1) {
                    header.push({
                        label: element.data.label,
                        width: getPxWidthByLetters(element.data.width),
                        textAlign: textAlign
                    })
                }

                //RADKY
                let rowItems = []
                if (rows[element.data.downFrameLine] != undefined)
                    rowItems = rows[element.data.downFrameLine]

                if (element.data.visible === true)
                    rows = {
                        ...rows,
                        [element.data.downFrameLine]: [
                            ...rowItems,
                            element.data.value
                        ]
                    }
            } else if (element.componentName === "ModalDialogElement") {
                //13.13.1 - funckce "nacteni ze souboru" - > Axigon -> nacist zobrazi Modal dialog a uvnitr dialog
                dialogs.push(<ModalDialogElement key={"md" + element.id} {...element} renderChild={renderChild} />)
            }
        })

        /*console.log("RENDER AS DOWN FRAME")
        console.log("HEADER", header)
        console.log("ROWS", rows)*/

        return (
            <div>
                <table id={"downFrame_" + props.id} className="table table-sm table-striped" style={{ marginBottom: 0 }}>
                    <thead style={{ position: 'unset', boxShadow: 'none' }}>
                        <tr className='downframe_header'>{header.map((val, index) => <th scope="col" key={"h" + index} style={{ width: val.width, textAlign: val.textAlign }}>{val.label === "&Null;" ? "" : val.label}</th>)}</tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </table>
                {dialogs}
            </div>)


        function renderRows() {
            let frameRows = [];
            for (let key in rows) {
                /*let hasColVal = false

                rows[key].map(col => {
                    if (col !== "")
                        hasColVal = true
                })*/

                //if (hasColVal) {
                frameRows.push(<tr key={"row" + key} style={{ borderStyle: 'hidden', height: ROW_HEIGHT + "px" }}>{rows[key].map((col, index) => {
                    //console.log("header: ", header, index)

                    let style = { verticalAlign: top }
                    if (header != undefined && header[index] != undefined)
                        style = { width: header[index].width, textAlign: header[index].textAlign }

                    //style={{ width: header[index].width }}
                    return <td key={"col" + index} style={style}>{col}</td>
                })}
                </tr>)
                //}
            }
            return frameRows
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        desktopSessionMask: state.desktopSession[state.ws.currentTabId][state.ws.currentMaskID],
    }
}

export default connect(mapStateToProps)(renderChildrens2Screen)