
export function emptyBrowse(state, action) {
    const { currentTabId, currentMaskID } = state.ws;
    return ({
        ...state,
        desktopSession: {
            ...state.desktopSession,
            [state.ws.currentTabId]: {
                ...state.desktopSession[currentTabId],
                [currentMaskID]: {
                    ...state.desktopSession[currentTabId][currentMaskID],
                    [action.id]: {
                        ...state.desktopSession[currentTabId][currentMaskID][action.id],
                        data: {
                            ...state.desktopSession[currentTabId][currentMaskID][action.id].data,
                            refresh: false,
                            browseData: []
                        }
                    }
                }
            }
        }
    })
}

export function setBrowseData(state, action, wsAction) {
    /*console.log("setBrowseData", action, state)
    console.log("ACTION", wsAction)
    console.log("ACTION", action.browseData)*/

    const { currentTabId, currentMaskID } = state.ws

    return {
        ...state,
        desktopSession: {
            ...state.desktopSession,
            [state.ws.currentTabId]: {
                ...state.desktopSession[currentTabId],
                [currentMaskID]: {
                    ...state.desktopSession[currentTabId][currentMaskID],
                    [action.browseHandle]: {
                        ...state.desktopSession[currentTabId][currentMaskID][action.browseHandle],
                        data: {
                            ...state.desktopSession[currentTabId][currentMaskID][action.browseHandle].data,
                            refresh: false,
                            ...action.borders,
                            browseData: [
                                ...action.browseData
                            ],
                            scrollOptions: {
                                ...action.scrollOptions
                            }
                        }
                    }
                }
            }
        }
    }
}

/*export function setBrowseCellValue(state, action) {
    const { currentTabId, currentMaskID } = state.ws

    return {
        ...state,
        desktopSession: {
            ...state.desktopSession,
            [state.ws.currentTabId]: {
                ...state.desktopSession[currentTabId],
                [currentMaskID]: {
                    ...state.desktopSession[currentTabId][currentMaskID],
                    [action.browseHandle]: {
                        ...state.desktopSession[currentTabId][currentMaskID][action.browseHandle],
                        data: {
                            ...state.desktopSession[currentTabId][currentMaskID][action.browseHandle].data,


                        }
                    }
                }
            }
        }
    }
}*/

export function resizeBrowseColumn(state, action) {
    //console.log("resizeBrowseColumn", action, state)
    const { currentTabId, currentMaskID } = state.ws

    return ({
        ...state,
        desktopSession: {
            ...state.desktopSession,
            [state.ws.currentTabId]: {
                ...state.desktopSession[currentTabId],
                [currentMaskID]: {
                    ...state.desktopSession[currentTabId][currentMaskID],
                    [action.columnHandle]: {
                        ...state.desktopSession[currentTabId][currentMaskID][action.columnHandle],
                        data: {
                            ...state.desktopSession[currentTabId][currentMaskID][action.columnHandle].data,
                            inTableWidth: action.newColWidth
                        }
                    }
                }
            }
        }
    })
}