import React from 'react'
import { useDispatch } from 'react-redux'

import { onValueChange } from '../store/actions/store_actions'
import useStoreSubscription from '../hooks/useStoreSubscription'
import { LISTENER_TYPE } from '../hooks/desktopListener'
import constants from '../constants/constants'

export const ATTRIBUTE_ROOT_FREE_SPACE = "root_free_space"

export function getSessionHeight() {
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
    const MIN_HEIGHT = 20
    let height

    height = (document.getElementById("root")?.offsetHeight / rootFontSize - 13)
    if (height > MIN_HEIGHT)
        return height

    return MIN_HEIGHT
}

export default function SessionScreen(props) {
    const { tabId, maskId, sessionHeight } = props
    const dispatch = useDispatch()

    let storeFreeSpace
    useStoreSubscription(store => {
        if (tabId && maskId) {
            if (store.desktopSession[tabId] && store.desktopSession[tabId][maskId])
                storeFreeSpace = store.desktopSession[tabId][maskId][0]?.data?.[ATTRIBUTE_ROOT_FREE_SPACE]
        }
    })

    function setSessionScreenHeight() {
        let newHeight = getSessionHeight()

        if (newHeight !== sessionHeight) {
            //console.log("### setSessionScreenHeight", newHeight, sessionHeight)
            dispatch(onValueChange(constants.InternalElementState.SESSION_HEIGHT, getSessionHeight()))
        }
    }

    React.useEffect(() => {
        const controller = new AbortController()

        let timeout
        function handleScreenHeightChange() {
            setSessionScreenHeight()

        }

        function reduceResizing() {
            clearTimeout(timeout)
            timeout = setTimeout(handleScreenHeightChange, 200)
        }

        window.addEventListener(LISTENER_TYPE.RESIZE, reduceResizing, { signal: controller.signal })

        return () => {
            controller.abort()
        }
    })

    React.useEffect(() => {
        //console.log('tabid', tabId, 'maskid', maskId)
        setSessionScreenHeight()

    })

    let style = {}
    if (props.hasframe2render)
        style = {
            padding: "0rem 1rem 1rem",
            minHeight: sessionHeight + "rem"
        }

    //console.log("SessionScreen -> render()", props, style)
    return (
        <div id={constants.ElementsIds.SESSION_SCREEN} style={style}>
            {props.children}
        </div>
    )
}