import { logDebug, logDoFocus } from './utils/logging'
import { getStore } from './index'

export const ACTION_ESCAPE_NAVBAR = "ESCAPE_NAVBAR"
export const ACTION_MOVE_IN_NAVBAR = "MOVE_IN_NAVBAR"
export const ACTION_CLICK_OUTSIDE_OF_NAVBAR = "CLICK_OUTSIDE_OF_NAVBAR"

export const FOCUS_AREA_SESSION = "SESSION"
export const FOCUS_AREA_NAVIGATION = "NAVIGATION"
export const FOCUS_AREA_SIDEBAR = "SIDEBAR"

/**
 * Vraci oblast stranky dle "relatedTarget" primarne z udalosti "focusout"
 * - vyuziva se pro zjisteni oblasti focusu jeste pred samotnym focusem na element
 * - App.js - pokud je oblast session, tak provede focus na komponentu
 * @param {event.relatedTarget} relatedTargetArea 
 */
export function getRelatedTargetFocusArea(relatedTarget) {
    //    console.log("getRelatedTargetFocusArea", relatedTarget)
    if (relatedTarget === null)
        return null

    let elMain = document.getElementById("main_session")
    if (elMain !== undefined && elMain !== null && elMain.contains(relatedTarget))
        return FOCUS_AREA_SESSION
    if (hasDialogFocus())
        return FOCUS_AREA_SESSION

    let elNavigation = document.getElementById("navigation")
    if (elNavigation !== undefined && elNavigation !== null && elNavigation.contains(relatedTarget))
        return FOCUS_AREA_NAVIGATION

    let elSidebar = document.getElementById("sidebar")
    if (elSidebar !== undefined && elSidebar !== null && elSidebar.contains(relatedTarget))
        return FOCUS_AREA_SIDEBAR

    //DIALOG ZATIM NEBYL ASI POTREBA

    logDebug("Related target patri do nezname oblasti", relatedTarget)

    return FOCUS_AREA_SESSION
}

/***********/
/* SESSION */
/***********/
export function focus2SessionMain() {
    focusElement("main_session", "focus2SessionMain()")
}

export function isElemenInSession(element) {
    let elMain = document.getElementById("main_session")
    if (elMain !== undefined && elMain !== null)
        return elMain.contains(element)

    return false
}

export function hasSessionFocus() {
    let store = getStore().getState()
    let hasSessionFocus = false

    // vyjimka kalkulator 
    if (document.getElementById("rootCalculator")?.contains(document.activeElement)) 
        return false

    let elMain = document.getElementById("main_session")
    if (elMain !== undefined && elMain !== null)
        hasSessionFocus = elMain.contains(document.activeElement)

    //console.log("hasDialogFocus", hasDialogFocus())
    if (hasDialogFocus())
        return hasSessionFocus = true

    if (document.activeElement.tagName === "BODY") {
        //console.log("FOCUS AREA", store.focusArea)
        if (store.focusArea === FOCUS_AREA_SESSION)
            hasSessionFocus = true
    }

    if (store.focusArea === null)
        hasSessionFocus = true

    if (Object.keys(getStore().getState().desktopSession).length === 1)
        hasSessionFocus = false

    return hasSessionFocus
}

/***********/
/*  DIALOG */
/***********/
export function hasDialogFocus() {
    let elModalDialog = document.getElementById("modal_dialog")
    if (elModalDialog)
        return elModalDialog.contains(document.activeElement)

    return false
}

/***************/
/*  NAVIGATION */
/***************/
/**
 * Vraci zda ma navigacni lista focus dle elementu nebo document.activeElement pokud neni vyplnen
 * @param {*} element pokud neni vyplnen, pouzije se document.activeElement
 * @returns 
 */
export function hasNavigationFocus(element) {
    let elNavigation = document.getElementById("navigation")
    if (element === undefined || element === null)
        element = document.activeElement

    if (elNavigation)
        return elNavigation.contains(element)

    if (document.activeElement.tagName === "BODY") {
        let store = getStore().getState()
        if (store.focusArea === FOCUS_AREA_NAVIGATION)
            return true
    }

    return false
}

/***********/
/*  NAVBAR */
/***********/
export function hasNavbarFocus() {
    let elNavBar = document.getElementById("navbar_menu_items")
    if (elNavBar !== undefined && elNavBar !== null)
        return elNavBar.contains(document.activeElement)

    return false
}

/***********/
/* SIDEBAR */
/***********/
export function focus2Sidebar(exception) {
    if (!hasSidebarFocus())
        focusElement("sidebar", "focus2Sidebar()", exception)
}

export function hasSidebarFocus() {
    let elSidebar = document.getElementById("sidebar")
    if (elSidebar !== undefined && elSidebar !== null)
        return elSidebar.contains(document.activeElement)

    if (document.activeElement.tagName === "BODY") {
        let store = getStore().getState()
        if (store.focusArea === FOCUS_AREA_SIDEBAR)
            return true
    }

    return false
}

/***********/
/*ELEMENTS */
/***********/
/**
 * Provede focus na html element s logem
 * @param {*} element 
 * @param {*} calledFrom 
 */
export function focusHtmlElement(element, calledFrom) {
    if (element) {
        element.focus()
        logDoFocus(element, calledFrom)
    }
}

function getSelectedElement() {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        console.log("RANGE", range)
        return range.commonAncestorContainer.parentElement;
    }
    return null;
}

/*
Provede focus na konkretni komponentu dle handlu
- RadioSetElement - focus se nastavuje na input
*/
export function focusElement(id, calledFrom, exception) {
    //console.log("--> focusElement " + id, calledFrom, document.activeElement)
    let store = getStore().getState()
    let ws = store.ws

    let desktopTab, desktopMask, storeElement
    let compoundElementId = id
    desktopTab = store.desktopSession[ws.currentTabId]
    if (desktopTab)
        desktopMask = desktopTab[ws.currentMaskID]
    if (desktopMask)
        storeElement = desktopMask[id]

    if (storeElement) {
        if (storeElement.componentName === "RadioSetElement")
            compoundElementId = storeElement.id + ";" + storeElement.data.value

        //SelectionElement - focus se nastavuje na element select a ne option
    }

    let element = document.getElementById(compoundElementId)

    //console.log("hasNavbarFocus", hasNavbarFocus(), exception)
    if (element !== undefined
        && element !== null
        && element !== document.activeElement
        && (!hasNavbarFocus()
            || (hasNavbarFocus() && exception === ACTION_ESCAPE_NAVBAR) //vyjimka po vykresleni app ma prednost navbar || pri zmaknuti escape a navbar ma focus, tak se provede focus na aktualniho elementu v session
            || (hasNavbarFocus() && exception === ACTION_MOVE_IN_NAVBAR) // vyjimka pro pohyb sipkama v navbaru      
            || (hasNavbarFocus() && exception === ACTION_CLICK_OUTSIDE_OF_NAVBAR) //vyjimka ctr + 1/2/3 -> focus menu/oblibnene/historie
        )) {

        /*bez timeoutu nefunguje focus po zavreni dialogu   
        focus() not working:
            - use this function after appending to parent
            - if console is selected, after refreshing page, element will not gain focus, so select (click on) the webpage while testing*/
        const executerId = setTimeout(function () {
            /*const selection = window.getSelection()
            if (selection && selection.toString().length > 0) {
                //pokud je oznacen text, neprovadi se focus kvuli firefox nefunguje vybrani textu
                console.log("getSelectedElement", getSelectedElement())
                return
            } else
                element.focus()*/
            element.focus()
            clearTimeout(executerId)
        }, 0)

        //console.log("focused to ", element)
        //oznac cele input pole pri skoku na input pomoci tab
        if (element.tagName.toLowerCase() === 'input') {
            element.select()
            //console.log("@DEBUG SELECT")
        }


        logDoFocus(id, calledFrom)
    }
}

/**
 * @deprecated
 */
export function doFocus(focusId, storeFocusedElement, eventElement, event) {
    //console.log("DO FOCUS", focusId)
    //FOCUS - pri kliku se nastavi na aktualni komponentu ve storu
    let focus2Id = focusId

    //table row functions
    if (event !== undefined && event.target.id === "record_actions_functions_btn")
        return

    if (storeFocusedElement?.componentName === "RadioSetElement")
        focus2Id = storeFocusedElement.id + ";" + storeFocusedElement.data.value /*Nelze hledat podle elementId: RadioSetElement*/

    //focus by zavrel nabidku options v ComboBoxElement
    if (eventElement?.componentName !== "ComboBoxElement")
        focusElement(focus2Id, "App.handleUserInput() not combox")
}
