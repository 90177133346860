import React from 'react';
import { connect, useDispatch } from 'react-redux'
import { textWithMnemonic } from '../utils/screen'

import { disableOtherListeners, existSystemErrorDialog, isEntryable } from '../app_actions'
import { hasNavigationFocus, hasSessionFocus } from '../focus_actions'
import Constants from '../constants/constants'
import { WebSocketContext } from '../services/WebSocket'
import { logRender, logRenderProps } from '../utils/logging'
import { getPxWidthByLetters } from '../utils/screen'
import useStoreSubscription from '../hooks/useStoreSubscription'
import { onValueChange, onValuesChangeInMask } from '../store/actions/store_actions'
import NavbarItem, { hideAllSubmenus, hideAllDropdowns } from './NavbarItem'
import { focusElement, focusHtmlElement, hasDialogFocus, focus2SessionMain, ACTION_ESCAPE_NAVBAR, ACTION_MOVE_IN_NAVBAR } from '../focus_actions'
import { getStore } from '../index'
import { getElementData } from './desktop_server/elementMethods'

import { addListener, LISTENER_TYPE } from '../hooks/desktopListener'
import { Dropdown } from 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { findElementByPartialId } from '../App'
import { RESISIZE_BROWSE_INPUT } from './browse/BrowseBottom'
import { getEmptyRowProp } from '../utils/actions'

const { KEYDOWN, KEYUP, CLICK } = LISTENER_TYPE

const { SET_VALUE_REQUEST } = Constants.WebSocketActions
const { MENU_ACTION, MENU_ACTION_OFFER } = Constants.SetValueRequest
const { CLOSE_PROGRAM, CLOSE_TAB, FUNCTIONS, EDIT, PROGRAM, HELP, DISPLAY, TOOLS, SELECT_ALL, DESELECT_ALL, ROOT_RECORD } = Constants.MenuActions

export function getMenuItems(parentHandle) {
    let desktopMenu = getStore().getState().desktopMenu
    let menuItems = []

    for (var key of Object.keys(desktopMenu)) {
        let menuItem = { ...desktopMenu[key] }

        if (menuItem.parentHandle == parentHandle) {
            if (
                menuItem.handle !== PROGRAM
                //menuItem.handle !== FUNCTIONS
                //menuItem.handle !== HELP
                //&& menuItem.handle !== PROGRAM
                //&& menuItem.handle !== DISPLAY
                //&& menuItem.handle !== TOOLS
            )
                if (menuItem.handle !== EDIT)
                    menuItems.push(menuItem)
        }
    }

    return menuItems
}

function getEventKey(event) {
    if (event.key === "AltGraph")
        return "Alt"

    return event.key
}

export function handleNavbarEvents(event) {
    //console.log("handleNavbarEvents", event)

    //pri systemove chybe se klavesove zkratky je zakazano spoustet
    if (existSystemErrorDialog())
        return

    let store = getStore().getState()
    let ws = store.ws

    let isDropdown = event.target.id.includes("navdrop")

    if (event.type === "keydown") {
        let childrenIndex = 0
        let ids = event.target.id.split("_")
        let dropDownId = parseInt(ids[1])
        if (isDropdown) {
            //id="navdrop_1_li_2"
            //console.log("IDS", ids)
            childrenIndex = store.desktopMenu[dropDownId].childrens.findIndex(children => children.id == parseInt(ids[3]))
        }

        const DIRECTION_UP = "UP"
        const DIRECTION_DOWN = "DOWN"
        function getDropdownItem(childrenIndex, direction) {
            if (direction === DIRECTION_UP)
                childrenIndex--
            else if (direction === DIRECTION_DOWN)
                childrenIndex++

            if (store.desktopMenu[store.desktopMenu[dropDownId].childrens[childrenIndex].id].subType === "RULE") {
                return getDropdownItem(childrenIndex, direction)
            } else {
                let newId = store.desktopMenu[dropDownId].childrens[childrenIndex].id
                let key = ids[0] + "_" + ids[1] + "_li_" + newId
                return {
                    id: newId,
                    element: document.getElementById(key),
                }
            }
        }

        switch (getEventKey(event)) {
            case "Enter":
                //a href                
                if (!isDropdown) {
                    //a href
                    //event.preventDefault()
                    //event.stopPropagation()

                    let ahref = document.getElementById("dropdown_" + dropDownId)
                    ahref.click()

                    ids[0] = "navdrop"
                    let elementItem = getDropdownItem(-1, DIRECTION_DOWN)
                    if (elementItem.element !== null) {
                        //event.preventDefault()
                        //event.stopPropagation()

                        focusElement(elementItem.element.id, "Navbar.handleNavbarEvents(Enter)", ACTION_MOVE_IN_NAVBAR)
                    }
                }
                break
            case "Escape":
                //jedna se o rootovy element nabaru(Zaznam/Funkce/...), tak se provede focus do session
                if (event.srcElement?.id?.includes("dropdown_"))
                    focusElement(ws.focusId, "Navbar.handleNavbarEvents(Escape)", ACTION_ESCAPE_NAVBAR)
                break
            case "Alt":
                //console.log("LET CANCEL MNEMONIC", store.mnemonic, document.activeElement)
                /*if (store.mnemonic) {
                    document.activeElement.blur()
                    focusElement(ws.focusId, "Navbar.handleNavbarEvents(Alt)", ACTION_ESCAPE_NAVBAR)
                }*/
                break
            /*case "ArrowUp":
                let index = store.desktopMenu[dropDownId].childrens.findIndex(children => children.id == parseInt(ids[3]))

                event.preventDefault()
                event.stopPropagation()

                if (index === 0) {
                    event.preventDefault()
                    event.stopPropagation()
                    focusElement("dropdown_" + dropDownId, "Navbar.handleNavbarEvents(ArrowUp)", ACTION_MOVE_IN_NAVBAR)
                    let ahref = document.getElementById("dropdown_" + dropDownId)
                    ahref.click()
                } else {
                    let elementItem = getDropdownItem(childrenIndex, DIRECTION_UP)
                    if (elementItem.element !== null) {
                        event.preventDefault()
                        event.stopPropagation()

                        //focusElement(elementItem.element.id, "Navbar.handleNavbarEvents(ArrowUp)", ACTION_MOVE_IN_NAVBAR)
                        //elementItem.element.focus()
                    }
                }
                break
            case "ArrowDown":
                if (isDropdown) {
                    let elementItem
                    elementItem = getDropdownItem(childrenIndex, DIRECTION_DOWN)

                    if (elementItem.element !== null) {
                        event.preventDefault()
                        event.stopPropagation()

                        elementItem.element.focus()
                    }
                } else {
                    //a href
                    event.preventDefault()
                    event.stopPropagation()

                    let ahref = document.getElementById("dropdown_" + dropDownId)
                    ahref.click()

                    ids[0] = "navdrop"
                    let elementItem = getDropdownItem(-1, DIRECTION_DOWN)
                    if (elementItem.element !== null) {
                        event.preventDefault()
                        event.stopPropagation()

                        elementItem.element.focus()
                    }
                }
                break*/
            case "ArrowRight":
                if (isDropdown) {
                    let elementItem = getDropdownItem(childrenIndex)
                    let childrens = store.desktopMenu[elementItem.id].childrens
                    if (childrens.length > 0) {
                        document.getElementById("submenu_" + elementItem.id).style["display"] = "block"

                        let newId = store.desktopMenu[elementItem.id].childrens[0].id
                        let key = ids[0] + "_" + elementItem.id + "_li_" + newId
                        focusElement(key, "Navbar.handleNavbarEvents(ArrowRight)", ACTION_MOVE_IN_NAVBAR)
                    }
                } else {
                    let menuItems = getMenuItems()
                    let newIndex = menuItems.findIndex(item => { return item.handle > dropDownId })
                    if (newIndex > -1) {
                        focusElement("dropdown_" + menuItems[newIndex].handle, "Navbar.handleNavbarEvents(ArrowRight)", ACTION_MOVE_IN_NAVBAR)

                        let toDel = document.getElementById("dropdown_menu_" + dropDownId)
                        new Dropdown(toDel)?.hide()

                        menuItemParent = undefined
                    }
                }

                break
            case "ArrowLeft":
                if (isDropdown) {
                    let element = document.getElementById("submenu_" + dropDownId)
                    if (element !== null)
                        element.style["display"] = "none"

                    focusElement("navdrop_" + store.desktopMenu[dropDownId].parentHandle + "_li_" + dropDownId, "navbar - left", ACTION_MOVE_IN_NAVBAR)
                } else {
                    // root a href
                    let menuItems = getMenuItems().reverse()
                    let newIndex = menuItems.findIndex(item => { return item.handle < dropDownId })
                    if (newIndex > -1) {
                        focusElement("dropdown_" + menuItems[newIndex].handle, "Navbar.handleNavbarEvents(ArrowLeft)", ACTION_MOVE_IN_NAVBAR)


                        let toDel = document.getElementById("dropdown_menu_" + dropDownId)
                        new Dropdown(toDel)?.hide()

                        menuItemParent = undefined
                    }
                }
                break
        }
    }
}

export function renderMenuItemChildrens(parent, desktopMenu, mnemonic, calledFrom) {
    let subMenus = []

    if (parent?.childrens === undefined)
        return

    //vykresleni
    parent.childrens.forEach(submenu => {
        for (var key of Object.keys(desktopMenu)) {
            let menuItem = desktopMenu[key]
            if (menuItem.handle === submenu.id) {
                if (menuItem.subType === "RULE") {
                    subMenus.push(<li key={"nav_i_" + menuItem.handle}><hr style={{ margin: '0rem' }} /></li>)
                } else
                    subMenus.push(<NavbarItem
                        key={"navbar_item_" + menuItem.handle}
                        navbarItem={menuItem}
                        mnemonic={mnemonic}
                        calledFrom={calledFrom} />)
            }
        }
    })

    return subMenus
}

let menuItemParent = undefined
//Horní menu pro spuštěný program - Záznam,Úpravy,Funkce,Zobrazení,Nástroje,Program,Nápověda
function Navbar(props) {
    const dispatch = useDispatch()

    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context
    const { desktopMenu, mnemonic, } = props
    const [altWithCharacterPressed, setAltWithCharacterPressed] = React.useState(false)
    let rootMenuItems = getMenuItems()
    const dropdownsRef = React.useRef(rootMenuItems.map(() => React.createRef()))

    let currentFocusId, desktopSession, currentTabId, currentMaskID
    useStoreSubscription(store => {
        currentFocusId = store.ws.focusId
        desktopSession = store.desktopSession
        currentTabId = store.ws.currentTabId
        currentMaskID = store.ws.currentMaskID
    })

    addListener(KEYDOWN, document, handleKeyDown)
    addListener(KEYUP, document, handleKeysUp)

    const navbarRef = React.useRef()
    React.useEffect(() => {
        const controller = new AbortController()

        function focusout(e) {
            //navbar nema focus
            if (navbarRef.current && !navbarRef.current.contains(e.relatedTarget)) {
                dispatch(onValueChange("mnemonic", false))
            }
        }
        navbarRef.current.addEventListener("focusout", focusout, { signal: controller.signal })


        function showDropdownListener(e) {
            menuItemParent = e.target.id.replace("dropdown_", "")
        }
        function hideDropdownListener(e) {
            hideAllSubmenus()
        }

        dropdownsRef.current.forEach(ref => {
            ref.current.addEventListener("show.bs.dropdown", showDropdownListener, { signal: controller.signal })
            ref.current.addEventListener("hide.bs.dropdown", hideDropdownListener, { signal: controller.signal })
        })

        return () => {
            controller.abort()
        }
    })

    function handleKeysUp(event) {
        //console.log("KEYUP", event)
        //console.log("pressed", altWithCharacterPressed)
        let eventKey = getEventKey(event)

        if (eventKey === "Escape")
            menuItemParent = undefined

        if (hasSessionFocus()) {
            if (altWithCharacterPressed === false && (eventKey === "Alt")) {
                //console.log("MNEMONIC TRUE")

                //zapnuti mnemonic
                event.preventDefault()

                if (mnemonic === false) {
                    dispatch(onValueChange("mnemonic", true))
                    focusElement("dropdown_1", "alt mnemonica hide all dropdowns")
                } else {
                    document.activeElement.blur()
                    focusElement(currentFocusId, "Navbar.handleNavbarEvents(Alt)", ACTION_ESCAPE_NAVBAR)
                    hideAllDropdowns()
                    hideAllSubmenus()
                }

                //zavrenid dropdown spodni menu funkce
                let bottomFunctionsDropdown = document.getElementById("record_actions_functions_btn")
                if (bottomFunctionsDropdown)
                    new Dropdown(bottomFunctionsDropdown).hide()
            }
        }

        if (event.code === "AltLeft" || event.code === "AltRight")
            setAltWithCharacterPressed(false)
    }


    function handleKeyDown(event) {
        //console.log("KEYDOWN", event)
        //return
        let pressedKey = getEventKey(event)
        let shortcut = ""

        if (pressedKey === "Escape") {
            if (document.getElementById("navbar_menu_items")?.contains(document.activeElement))
                return

            menuItemParent = undefined
        }

        //osetreni backspace
        if (isEntryable(event) && event.key === "Backspace")
            return

        //oblibene funkce dialog
        let favoriteSettingsDialog = findElementByPartialId("favorite_settings_dialog")
        if (favoriteSettingsDialog !== null) {
            //napr. zakaz spusteni navbar funkci pomoci klaves
            //disableOtherListeners(event, "App -> handleUserInput() -> favoriteSettingsDialog")
            return
        }

        //zmena browse sizeperpage                
        if (event.target.id === RESISIZE_BROWSE_INPUT)
            return

        //vyber ikony
        let dialogIcons = findElementByPartialId("icons_dialog")
        if (dialogIcons !== null) {
            //napr. zakaz spusteni navbar funkci pomoci klaves
            //disableOtherListeners(event, "App -> handleUserInput() -> dialogIcons")
            return
        }

        //console.log("ma focus",hasSessionFocus())
        if (hasSessionFocus()) {
            //mnemonica
            if (mnemonic) {
                //console.log("-----------> PARENT", menuItemParent)
                let elements = []
                let navbarMenuItems = getMenuItems(menuItemParent)

                //console.log('navbarMenuItems', navbarMenuItems)
                navbarMenuItems.forEach(menuItem => {
                    //console.log("menu item", menuItem)
                    if (menuItem.label) {
                        let letterIndex = menuItem.label.indexOf("&") + 1
                        let fuctionalKey = menuItem.label.substring(letterIndex, letterIndex + 1).toLowerCase()

                        //console.log("FUNCTIONAL KEY", fuctionalKey, event.key)
                        if (pressedKey === fuctionalKey) {
                            let key
                            if (menuItem.parentHandle === undefined) {
                                key = "dropdown_" + menuItem.handle
                                menuItemParent = menuItem.handle
                            } else {
                                key = "navdrop_" + menuItem.parentHandle + "_li_" + menuItem.handle + "_navbar"
                                menuItemParent = menuItem.parentHandle
                            }

                            let item2Click = document.getElementById(key)
                            elements.push(item2Click)
                        }
                    }
                })

                //navdrop_22_li_101
                //console.log("elements", elements)
                if (elements.length === 1) {
                    //console.log("CLICK")
                    elements[0].click()
                    if (elements[0].id.indexOf("navdrop_") > -1)
                        document.activeElement.blur()
                } else {
                    let focusedPosition = -1
                    elements.forEach((element, position) => {
                        if (element === document.activeElement) {
                            //console.log(position, elements.length)
                            if (position === elements.length - 1)
                                focusedPosition = -1
                            else
                                focusedPosition = position
                        }
                    })
                    focusedPosition++
                    //console.log("focused position", focusedPosition)
                    focusHtmlElement(elements[focusedPosition])
                }

                //console.log("ACTIVE ELEMENT", document.activeElement.id)
            } else
                menuItemParent = undefined

            //prevod specialni znaku
            switch (pressedKey) {
                case "/":
                    pressedKey = "Num /"
                    //pressedKey = "DIVIDE"                    
                    break
                case "*":
                    //pressedKey = "MULTIPLY"
                    pressedKey = "Num *"
                    break
                case "-":
                    //pressedKey = "SUBTRACT"
                    pressedKey = "Num -"
                    break
                case "+":
                    //pressedKey = "ADD"
                    pressedKey = "Num +"
                    break
                case "=":
                    pressedKey = "EQUALS"
                    break
                case " ":
                    pressedKey = "Mezerník"
                    break
                /*case "":
                    pressedKey = ""*/
            }

            //lokalni menu
            if (event.code === "NumpadDecimal")
                pressedKey = "Num ."
            //pressedKey = "DECIMAL"

            //Pro cisla 0 - 9 pridam slovo "num" pred cislo, protoze accelerator pro novy, menit, duplikovat... jsou ve tvaru: "Num "+<cislo>
            //console.log("menu item parent", !menuItemParent, event.altKey, pressedKey.match(/^[0-9]$/))
            if (event.altKey === false && pressedKey.match(/^[0-9]$/))
                pressedKey = "Num " + pressedKey

            //console.log("pressedKey: ", pressedKey, "alt: ", event.altKey, "ctrl: ", event.ctrlKey, "shift: ", event.shiftKey)
            //skladni shift, alt
            if (event.shiftKey && pressedKey !== "Shift") {
                shortcut = "Shift+"
            }
            if (event.altKey && pressedKey !== "Alt") {
                shortcut += "Alt+"
                setAltWithCharacterPressed(true)
            }
            if (event.ctrlKey && pressedKey !== "Ctrl") {
                shortcut += "Ctrl+"
            }

            //bez shift,alt,ctrl
            if (!event.altKey && !event.shiftKey & !event.ctrlKey)
                shortcut = pressedKey
            else
                shortcut += pressedKey.toUpperCase()

            if (shortcut !== "")
                findMenuItemAndExecute(shortcut, event)
        }
    }

    function findMenuItem(shortcut) {
        function find(accelerator) {
            if (accelerator === undefined)
                return

            let pressed = shortcut?.split("+")
            if (pressed === undefined)
                return

            if (pressed.length !== accelerator.length)
                return

            let exist = undefined

            for (let i = 0; i < pressed.length; i++) {
                let text = pressed[i]
                if (accelerator.includes(text) === false) {
                    exist = false
                    break
                } else
                    exist = true

            }
            return exist
        }

        let letterMenuItem
        for (var key of Object.keys(desktopMenu)) {
            let menuItem = desktopMenu[key]
            //console.log("KEY", key, menuItem)
            if (!menuItem.sensitivity) continue
            if (find(menuItem.accelerator?.split("+")) || find(menuItem.accelerator2?.split("+")) || find(menuItem.accelerator3?.split("+")))
                letterMenuItem = menuItem
        }

        //console.log("letterMenuItem", letterMenuItem)
        return letterMenuItem
    }

    function findMenuItemAndExecute(shortcut, event) {
        //console.log("findMenuItemAndExecute", shortcut, event)
        //console.log("menuItemParent", menuItemParent)
        let letterMenuItem = findMenuItem(shortcut)
        //console.log("-----> letterMenuItem", letterMenuItem)

        //console.log("LETTER MENU ITEM", letterMenuItem.handle, FUNCTIONS)
        if (letterMenuItem !== undefined) {
            if (letterMenuItem.handle === FUNCTIONS) {
                // zobrazeni funkci, v desktopu existuje specialni komponenta pro zobrazeni funkci
                //let functions2Click = document.getElementById("dropdown_" + FUNCTIONS)
                //functions2Click?.click()

                //spodni tlacitko
                let btn = document.getElementById("record_actions_functions_btn")
                if (btn) {
                    //console.log("click")
                    btn.click()
                    //btn.focus()
                    return
                }
            } else if (letterMenuItem.handle === SELECT_ALL) {
                let key = "navdrop_" + desktopMenu[ROOT_RECORD].handle + "_li_" + SELECT_ALL
                let fceElement = document.getElementById(key)
                if (fceElement !== null) {
                    disableOtherListeners(event, "Navbar.findMenuItemAndExecute()")
                    fceElement.click()
                }

            } else if (letterMenuItem.handle === DESELECT_ALL) {
                let key = "navdrop_" + desktopMenu[ROOT_RECORD].handle + "_li_" + DESELECT_ALL
                let fceElement = document.getElementById(key)
                if (fceElement !== null) {
                    disableOtherListeners(event, "Navbar.findMenuItemAndExecute()")
                    fceElement.click()
                }
            } else {
                if (letterMenuItem.handle == CLOSE_PROGRAM) {

                    //pokud je focus napr. v hledani, dialog se nesmi zavrit
                    if ((event.key === "Escape" || event.key === "Backspace") && hasNavigationFocus(event.srcElement))
                        return

                    //editor element nesmi zavirat program
                    let focusedElement = getElementData(event.target.id)
                    if (focusedElement?.storeElement?.componentName === "EditorElement") {
                        let parentElement = getElementData(focusedElement.storeElement.data.parentHandle)?.storeElement
                        let stornoButtonHandle = parentElement?.data?.stornoButtonHandle
                        let stornoButton = document.getElementById(stornoButtonHandle)
                        if (stornoButton !== undefined && stornoButton !== null) {
                            stornoButton.focus() //spusti onBlur pro ulozeni hodnoty do storu
                            stornoButton.click()
                        } else
                            document.getElementById(Constants.CloseButtons.CLOSE_FRAME_BTN)?.click()
                    } else
                        document.getElementById(Constants.CloseButtons.CLOSE_FRAME_BTN)?.click()
                    return
                } else if (letterMenuItem.handle == CLOSE_TAB) {
                    document.getElementById(Constants.CloseButtons.CLOSE_TAB_BTN)?.click()
                    return
                }

                //pokud s zmackne alt + [0-9], tak se provede zpozdeni pozadavku, aby se nevložil znak do vstupniho pole noveho framu
                let delayedTime = 0
                if (event.altKey && getEventKey(event).match(/^[0-9]$/))
                    delayedTime = 100

                disableOtherListeners(event, "Navbar.findMenuItemAndExecute()")

                const executerId = setTimeout(() => {
                    if (ws_context.getRequestsQueue().size() === 0) {
                        const sendedValue = getElementData(currentFocusId).value
                        const sendedTabId = currentTabId
                        const sendedMaskid = currentMaskID
                        const sendedElementId = currentFocusId
                        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                            ...getEmptyRowProp(),
                            handle: currentFocusId,
                            newFocus: letterMenuItem.handle,
                            value: sendedValue,
                            action: MENU_ACTION,
                        })), response => {
                            if (sendedValue !== null)
                                dispatch(onValuesChangeInMask(sendedElementId, sendedTabId, sendedMaskid, { value: sendedValue }))

                        })
                        clearTimeout(executerId)
                    }
                }, delayedTime)

                //schovani dropdownu
                hideAllDropdowns()
                //zruseni focusu v navbaru
                document.activeElement.blur()
            }
        }
    }

    /* napr. menuItem "Úpravy" se ridi ze strany klienta a jsou zobrazeny vzdy */
    function isMenuAvailable() {
        //console.log("rootMenuItems", rootMenuItems)
        if (rootMenuItems == undefined)
            return false

        return true
    }

    logRender("Navbar")
    logRenderProps(props)
    //console.log("Navbar -> render()", props)
    //console.log("isMenuAvailable()", isMenuAvailable())

    if (!isMenuAvailable())
        return null

    let longestWord = 1
    let longestAbb = 1
    for (var key of Object.keys(desktopMenu)) {
        if (desktopMenu[key].subType === "RULE") continue
        longestWord = Math.max(desktopMenu[key].label.length, longestWord)
        if (desktopMenu[key].accelerator !== undefined)
            longestAbb = Math.max(desktopMenu[key].accelerator.length, longestAbb)
    }

    let firstColWidth = getPxWidthByLetters(longestWord, true) / 2
    let secondColWidth = getPxWidthByLetters(longestAbb, true)

    return <nav id="navbar_menu_items" ref={navbarRef} className='navbar navbar-expand webtop-dropdown-items btn-group' style={{ display: 'flex', padding: 0, boxShadow: "0 0 0" }}>
        {rootMenuItems.map((item, i) => {
            let rootKey = "ni_" + i
            let disabled = !item.sensitivity ? "disabled" : ""
            //console.log(item.label, item.sensitivity, disabled)
            return (
                <ul className="navbar-nav" key={rootKey}>
                    <a
                        id={"dropdown_" + item.handle}
                        ref={dropdownsRef.current[i]}
                        tabIndex="1"
                        key={"navbar_a_" + i}
                        className={"nav-link m_navlink " + disabled}
                        style={{
                            margin: '0.5rem 1rem 0.5rem 0rem',
                            '--bs-nav-link-padding-y': '0rem'
                        }}
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside">
                        {textWithMnemonic(item.label, props.mnemonic, true)}
                    </a>
                    <ul id={"dropdown_menu_" + item.handle} className="dropdown-menu" style={{ "--bs-dropdown-min-width": firstColWidth + secondColWidth + "px", left: 'auto', "--bs-dropdown-link-active-bg": "none" }}>
                        {renderMenuItemChildrens(item, desktopMenu, mnemonic, "navbar")}
                    </ul>
                </ul>
            )
        })}
    </nav >
}

function mapStateToProps(state, ownProps) {
    /*console.log("mapStateToProps-------------")
    console.log("state", state)
    console.log("ownProps", ownProps)
    console.log("desktopMenu ===========", (state.desktopMenu === ownProps.desktopMenu))*/
    return {
        desktopMenu: state.desktopMenu,
        mnemonic: state.mnemonic,
    }
}

function areEqual(prevProps, nextProps) {
    /*console.log("NAVBAR are equal")
    console.log("prevProps", prevProps)
    console.log("nextProps", nextProps)*/
    //return (prevProps.desktopMenu === nextProps.desktopMenu)
    return (JSON.stringify(prevProps.desktopMenu) === JSON.stringify(nextProps.desktopMenu)
        && prevProps.mnemonic === nextProps.mnemonic)
}

//export default connect(mapStateToProps)(Navbar)
export default connect(mapStateToProps)(React.memo(Navbar, areEqual))
