import React, { useRef } from 'react'

import Icon from './Icon'
import InputFieldElement, { handleBrowseInputFieldElement } from '../../components/desktop_server/InputFieldElement'
import Constants from '../../constants/constants'
import { logRender, logRenderProps } from '../../utils/logging'
import { getEmptyRowProp } from '../../utils/actions'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import { WebSocketContext } from '../../services/WebSocket'
import { addListener, addListenerWithRefHandler, LISTENER_TYPE } from '../../hooks/desktopListener'
import { onValueChangeByHandle } from '../../store/actions/store_actions'
import { browseAction, KEY_ENTER } from './browseAction'
const { CLICK, DOUBLE_CLICK, CONTEXT_MENU } = LISTENER_TYPE;

import { CM_BROWSE } from '../ContextMenu'
import { ROW_HEIGHT, SETTINGS_PADDING } from './Browse'
import { isEditable } from './browseAction'
import ElementWithListener from '../ElementWithListener'
import { getEventElement, isLightTheme } from '../../app_actions'
import BSTooltip from '../BSTooltip'
import { getBootstrapVariables, getThemeVariables } from '../../theme/getThemeVariables'
import { func } from 'prop-types'
const { InternalElementState } = Constants
const { SET_VALUE_REQUEST, SET_BROWSE_CELL_VALUE_REQUEST } = Constants.WebSocketActions
const { DEFAULT_ACTION, FIELD_DETAIL_ACTION, EVENT_ENTRY } = Constants.SetValueRequest


export function Badge({ badgeValues, badgeTooltips, badgeColors, value }) {
    let elementValue = value
    if (badgeValues) {
        let index = badgeValues.findIndex(badgeValue => badgeValue === value)
        if (index > -1) {
            let badge
            let badgeColor = badgeColors[index]
            let badgeTooltip = badgeTooltips[index]

            switch (badgeColor) {
                case "Red":
                    badge = <span className="badge text-bg-danger">{value}</span>
                    break
                case "Yellow":
                    badge = <span className="badge text-bg-warning">{value}</span>
                    break
                case "Green":
                    badge = <span className="badge text-bg-success">{value}</span>
                    break
                default:
                    <span className="badge text-bg-primary">{value}</span>
                    break
            }

            elementValue = <BSTooltip tooltiptext={badgeTooltip}>
                {badge}
            </BSTooltip>
        }
    }
    return elementValue
}

function BrowseRow(props) {
    const checkboxRef = useRef()
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context

    const { SpecialId } = Constants
    const { CHOOSE_ROW_NO_MOVE } = Constants.SetValueRequest
    const { CHOOSE_BROWSE_ROW } = Constants.WebSocketActions
    const { row, selRowRef, columsWidth, isRowHighlighted, selectBrowseRow, displayContextMenu,
        browse, columnsVisibility } = props

    const rowRef = React.useRef()

    let data, browseData, childIds, handle
    if (browse !== undefined) {
        data = browse.data
        browseData = data.browseData
        childIds = browse.childIds
        handle = browse.id
    }

    React.useEffect(() => {
        if (row.rowId === data.rowId)
            selRowRef.current = rowRef.current
    })

    addListenerWithRefHandler(CLICK, rowRef, e => {
        if (e.target.nodeName === "A")
            e.preventDefault()

        clickOnRow(e, (row) => {
            //callback externi url (ares, ...s)
            if (e.target.nodeName === "A") {
                //e.preventDefault()
                let inputFieldElementId = e.target.id?.split("_")[1]
                let url = desktopSessionMask[inputFieldElementId]?.data?.URL
                window.open(url, '_blank')
            }
        })
    })

    addListenerWithRefHandler(DOUBLE_CLICK, rowRef, e => {
        if (!e.srcElement.id.startsWith("browse_cell_text"))
            browseAction(KEY_ENTER, e, ws_context, handle)
    })
    addListenerWithRefHandler(CONTEXT_MENU, rowRef, rowContextMenu)

    let desktopSessionMask, ws, currentFocusId, messageContainer, parentElement
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
        messageContainer = store.app.messageContainer
        if (store.desktopSession[browse.tabId]) {
            desktopSessionMask = store.desktopSession[browse.tabId][browse.maskId]
            if (desktopSessionMask)
                parentElement = desktopSessionMask[browse.data?.parentHandle]
        }
    })

    //kliknuti na zaskrtavaci checkbox pro oznaceni radku mysi
    //neposouvase na dalsi radek -  nelze pouzit funkci oznacit
    function clickOnCheckBoxMarkRow(e) {
        if (data.sensitivity) {
            e.stopPropagation() //aby se neaktivoval listener na radku
            sendMessage(createMessage(CHOOSE_BROWSE_ROW, createRequest({
                handle: currentFocusId,
                //newFocus: SELECT_RECORD,
                action: CHOOSE_ROW_NO_MOVE,
                rowId: row.rowId,
                browseRowId: data.rowId
            })), response => {
                let focusedElement = desktopSessionMask[currentFocusId]
                let checkboxHandle
                focusedElement.childIds.forEach(handle => {
                    if (desktopSessionMask[handle].data.specialId === SpecialId.CHECKBOX)
                        checkboxHandle = handle
                })
            })
        }
    }

    function doEntry(newFocus, callback) {
        /*sendMessage(createMessage(SET_BROWSE_CELL_VALUE_REQUEST, createRequest({
            handle: currentFocusId,
            newFocus: newFocus,
            keyEvent: 'Entry'
        })), callback)*/

        sendMessage(createMessage(SET_BROWSE_CELL_VALUE_REQUEST, createRequest({
            handle: currentFocusId,
            keyEvent: 'Entry'
        })), () => {
            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                ...getEmptyRowProp(),
                handle: currentFocusId,
                newFocus: newFocus,
                action: EVENT_ENTRY
            })), callback)
        })


    }

    //bezne kliknuti na radek
    function clickOnRow(event, callback) {
        if (data.sensitivity && parentElement.data.sensitivity) {
            function doCallback(row) {
                if (callback !== undefined)
                    callback(row)
            }

            function doClickRow() {
                if (row.rowId !== data.rowId) {
                    selectBrowseRow(row, () => {
                        if (isEditable(event)) {
                            //console.log("do entry")
                            doEntry(getEventElement(event).id, callback)
                        } else
                            doCallback(row)
                    })
                } else {

                    //kdyz klikam v ramci vybraneho radku
                    if (callback instanceof Function) {
                        //console.log("nejakej callback")
                        if (isEditable(event)) {
                            doCallback()
                        } else {
                            doCallback()
                        }
                    }
                }
            }

            if (isEditable(event))
                if (row.rowId !== data.rowId) {
                    //bunka na jinem radku
                    handleBrowseInputFieldElement(event, ws_context, doClickRow)
                } else {
                    //bunka na stejnem radku
                    let newFocus = getEventElement(event).id
                    if (newFocus !== currentFocusId)
                        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                            ...getEmptyRowProp(),
                            handle: currentFocusId,
                            newFocus: newFocus,
                            action: EVENT_ENTRY
                        })), callback)
                    else
                        doCallback()
                }
            else
                doClickRow()
        }
    }

    //otevreni kontextoveho menu nad radkem
    function rowContextMenu(e) {
        if (data.sensitivity) {

            //zobrazeni defaultniho kontextoveho menu pro odkaz a href
            if (e.target.tagName === "A")
                return

            if (e.target.className !== "inputURL")
                e.preventDefault()

            if (row.rowId !== data.rowId) {
                //posunuti pri kliku na prvni/posledni radek v browsu
                const i = browseData.findIndex((brow) => brow.rowId === row.rowId);

                if (i === 0)
                    selectBrowseRow(row, () => displayContextMenu(e, CM_BROWSE))
                else
                    selectBrowseRow(row, () => displayContextMenu(e, CM_BROWSE))
            } else {
                displayContextMenu(e, CM_BROWSE)
            }
        }
    }

    logRender("BrowseRow ")
    logRenderProps(props)
    //console.log('render() -> BrowseRow ', props)
    //console.log('%c render() -> BrowseRow ' + row.columns.map(val => val.value).join(",") + " " + row.rowId, 'color: #34eb65')

    let backgroundColor = props.backgroundColor !== undefined ? props.backgroundColor : ""

    let isRowChecked = false
    row.columns.forEach(column => {
        let columnHeader = desktopSessionMask[column.handle].data
        if (columnHeader.specialId === SpecialId.CHECKBOX && column.value === "*") {
            isRowChecked = true
            backgroundColor = getBootstrapVariables().yellow300
        }
    })

    if (isRowHighlighted) { //override pro OZNACENY radek
        backgroundColor = isLightTheme() ? getBootstrapVariables().yellow400 : getBootstrapVariables().yellow600

        if (props.hasFocus === false)
            backgroundColor = getBootstrapVariables().gray400
    }

    //console.log("---> render browse row hasFocus", props.hasFocus)
    return <tr
        style={{
            height: ROW_HEIGHT + "px",
            backgroundColor: backgroundColor
        }}
        ref={rowRef}
        key={row.rowId}
        id={row.rowId}>
        {((row, isLightTheme) => {
            //RENDER COLUMNS
            let rowColumns = []
            //console.log("RENDER COLUMNS", row)
            rowColumns.push(row.columns.map((column, index) => {
                //console.log(index, column)
                /*console.log("childIds", childIds)
                console.log("desktopSessionMask", desktopSessionMask)
                console.log("desktopSessionMask[childIds[index]]", desktopSessionMask[childIds[index]])
                console.log("desktopSessionMask", desktopSessionMask[column.handle].data.dataType)*/
                let textAlign = "left"
                let val = column.value
                if (val === Constants.Values.ERROR_VALUE)
                    val = "X"
                let columnHeader = desktopSessionMask[column.handle].data

                //zarovnani decimal a integer do prava
                if (desktopSessionMask[column.handle].data.dataType === "DECIMAL" || desktopSessionMask[column.handle].data.dataType === "INTEGER")
                    textAlign = "right"

                if (columnsVisibility[index]) {
                    //IKONA
                    if (columnHeader.browseListColumnIconID != undefined && val !== "") {
                        //ids={columnHeader.browseListColumnIconID} values={columnHeader.browseListColumnValue} tooltips={columnHeader.browseListColumnIconTooltip}
                        if (columnHeader.browseListColumnValue.includes(val)) {
                            val = <Icon id={index + "_" + column.handle} value={val} columnHeader={columnHeader} />
                        }
                    }

                    //CHECKBOX
                    let rowSelection = undefined
                    if (columnHeader.specialId === SpecialId.CHECKBOX) {
                        //console.log("COLUMN HEADER: ", columnHeader)
                        let checked = false

                        //console.log("VAL: " + val + " preval: " + column.preValue, column)
                        if (val === "*" || column.preValue === "*") {
                            checked = true
                            val = undefined
                        }

                        //console.log("CHECKED", checked)

                        rowSelection = <ElementWithListener doAction={clickOnCheckBoxMarkRow} mouseListener={true}>
                            <input id={"mark_row_chck_" + row.rowId} tabIndex="-1" className="form-check-input" ref={checkboxRef}
                                type="checkbox" value="" checked={checked} onChange={(e) => { checkboxRef.current.blur() }} />
                        </ElementWithListener>
                        textAlign = "left"
                    }

                    function isEditableCell() {
                        return isRowHighlighted && desktopSessionMask[column.handle].data.readOnly === false
                    }

                    function renderValue() {

                        if (isEditableCell()) {
                            //EDITABLE CELL
                            let inputProps = {
                                ...desktopSessionMask[column.handle],
                                data: {
                                    ...desktopSessionMask[column.handle].data,
                                    value: val
                                }
                            }

                            return <InputFieldElement key={"inp" + column.handle} {...inputProps} />
                        } else {
                            if (desktopSessionMask[column.handle].data.URL != undefined) {
                                return <a
                                    id={"col_" + column.handle}
                                    href={desktopSessionMask[column.handle].data.URL}
                                    title={desktopSessionMask[column.handle].data.URLTooltip}
                                    style={{
                                        display: "inline-block"
                                    }}
                                >{val}</a>
                            } else {
                                let elementValue = val
                                let badgeValues = desktopSessionMask[column.handle].data.badgeValue?.split("\u0001")
                                if (badgeValues)
                                    elementValue = <Badge
                                        value={val}
                                        badgeValues={badgeValues}
                                        badgeTooltips={desktopSessionMask[column.handle].data.badgeTooltip?.split("\u0001")}
                                        badgeColors={desktopSessionMask[column.handle].data.badgeColor?.split("\u0001")} />

                                /*
                                let badgeValues = desktopSessionMask[column.handle].data.badgeValue?.split("\u0001")
                                if (badgeValues) {
                                    let index = badgeValues.findIndex(badgeValue => badgeValue === val)
                                    if (index > -1) {
                                        let badge
                                        let badgeTooltips = desktopSessionMask[column.handle].data.badgeTooltip?.split("\u0001")
                                        let badgeColors = desktopSessionMask[column.handle].data.badgeColor?.split("\u0001")
                                        let badgeColor = badgeColors[index]
                                        let badgeTooltip = badgeTooltips[index]
 
                                        switch (badgeColor) {
                                            case "Red":
                                                badge = <span class="badge text-bg-danger">{val}</span>
                                                break
                                            case "Yellow":
                                                badge = <span class="badge text-bg-warning">{val}</span>
                                                break
                                            case "Green":
                                                badge = <span class="badge text-bg-success">{val}</span>
                                                break
                                            default:
                                                <span class="badge text-bg-primary">{val}</span>
                                                break
                                        }
 
                                        elementValue = <BSTooltip tooltiptext={badgeTooltip}>
                                            {badge}
                                        </BSTooltip>
                                    }
                                }*/

                                return <React.Fragment>{elementValue} {rowSelection}</React.Fragment>
                            }
                        }
                    }

                    let paddingRight = '.2rem'
                    let paddingLeft
                    if (columnHeader.specialId === SpecialId.CHECKBOX) {
                        paddingRight = '.8rem'
                        paddingLeft = '18.5px'
                    }

                    let textColor = (props.backgroundColor !== undefined || isLightTheme) ? "#212D3C" : ""
                    if ((isRowHighlighted || isRowChecked) && !isLightTheme) textColor = "black" //override pro OZNACENY radek pro dark theme

                    /*let pinCol = "", boxShadow, background = backgroundColor
                    if (index === 0) {
            pinCol = "fixed-td"
                        boxShadow = 'rgba(0, 0, 0, 0.2) -4px 0px 8px -6px inset'
        if (!isRowHighlighted && !isRowChecked)
        background = getThemeVariables().cardBodyColor
                    }*/

                    return (<td id={"b_col_" + index + "_" + column.handle}
                        rowid={row.rowId}
                        key={"column" + index}
                        style={{
                            textAlign: textAlign,
                            color: textColor,
                            paddingRight: paddingRight,
                            paddingLeft: paddingLeft,
                            boxSizing: "content-box",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            position: "relative"
                        }}>
                        {(() => {
                            if (columnHeader.fieldDetailAction)
                                return <ElementWithListener
                                    style={{ display: "inline-block", paddingLeft: "0px" }}
                                    mouseListener={true}
                                    outerHtmlClassName="inputURL"
                                    children={val}
                                    title={messageContainer[Constants.MessageContainer.DETAIL]}
                                    doAction={(e) => {
                                        clickOnRow(e, (row) => {
                                            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                                                ...getEmptyRowProp(),
                                                handle: currentFocusId,
                                                newFocus: column.handle,
                                                action: FIELD_DETAIL_ACTION
                                            })))
                                        })
                                    }} />

                            /*if (columnHeader.fieldDetailAction)
                                return <React.Fragment>
                                    <BSTooltip tooltiptext={messageContainer[Constants.MessageContainer.DETAIL]}>
                                        <ElementWithListener
                                            mouseListener={true}
                                            outerHtmlClassName="green-triangle"
                                            doAction={(e) => {
                                                clickOnRow(e, () => {
                                                    sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                                                        ...getEmptyRowProp(),
                                                        handle: currentFocusId,
                                                        newFocus: column.handle,
                                                        action: FIELD_DETAIL_ACTION
                                                    })))
                                                })
                                            }} />
                                    </BSTooltip>
                                    {renderValue()}
                                </React.Fragment>*/

                            let wrapStyle = {}
                            if (!isEditableCell())
                                wrapStyle = { display: "inline-block" }

                            if (column.value === Constants.Values.ERROR_VALUE)
                                wrapStyle = {
                                    ...wrapStyle,
                                    color: 'red',
                                    fontWeight: 'bold'
                                }

                            return <div id={"browse_cell_text_" + column.handle} style={wrapStyle}>
                                {renderValue()}</div>
                        })()}

                    </td >)
                }
            }))

            return rowColumns
        })(row, isLightTheme())}
    </tr >
}


function isColumnsWidthEqual(first, second) {
    let areEqual = true
    first.forEach((element, index) => {
        if (element.colWidthPx !== second[index].colWidthPx) {
            areEqual = false;
            return
        }
    });
    return areEqual;

}
function areEqual(prevProps, nextProps) {
    let prevRow = prevProps.row.columns.map(val => val.value).join(",")
    let nextRow = nextProps.row.columns.map(val => val.value).join(",")

    //console.log("prevProps: ", prevProps)
    //console.log("nextProps: ", nextProps)
    /*console.log("prevProps: ", prevRow, prevProps.isRowHighlighted)
    console.log("nextProps: ", nextRow, nextProps.isRowHighlighted)
    console.log("-------------------> ", (prevRow === nextRow), (prevProps.isRowHighlighted === nextProps.isRowHighlighted), nextProps.row)*/

    /*console.log("ARE EQUAL-----------------", prevRow === nextRow)
    console.log("prevProps: ", prevRow)
    console.log(prevProps)
    console.log("nextProps: ", nextRow)
    console.log(nextProps)*/

    //test visibility
    let breakTest = false
    prevProps.columnsVisibility.forEach((element, index) => {
        if (element !== nextProps.columnsVisibility[index]) {
            breakTest = true
            return
        }
    })

    if (breakTest)
        return false

    //test headers
    prevProps.columnsHeader.forEach((element, index) => {
        if (element.handle !== nextProps.columnsHeader[index].handle) {
            breakTest = true
            return
        }
    })

    //zmena editable column
    if (prevProps?.browse?.data?.rowId === prevProps?.row?.rowId) {
        if (prevProps.hasEditableColumn !== nextProps.hasEditableColumn)
            return false
    }

    if (breakTest)
        return false

    if (nextProps.isRowHighlighted && prevProps.hasFocus !== nextProps.hasFocus)
        return false

    if (prevRow === nextRow
        && prevProps.isRowHighlighted === nextProps.isRowHighlighted
        && nextProps.header.toString() === prevProps.header.toString()
        && isColumnsWidthEqual(prevProps.columsWidth, nextProps.columsWidth)
        && prevProps.browse.data.sensitivity === nextProps.browse.data.sensitivity
        && prevProps.browseRowColoredBackground === nextProps.browseRowColoredBackground)
        return true

    return false
}

export default React.memo(BrowseRow, areEqual)
