import React from 'react'
import Constants from '../constants/constants'
import { getStore } from '..';

export function getXScreenValue(x, width) {
    // pozicovani je od x:1,y:1
    x--;

    let frameWidth = Constants.screenWidth;
    if (width != undefined)
        frameWidth = width

    let unit = "%"

    //console.log("FRAME WIDHT getXScreenValue(): " + x + " / " + frameWidth + " 100 " + unit)
    return x / frameWidth * 100 + unit
}

export function getXFrameValue(x, screenWidth) {
    // pozicovani je od x:1,y:1
    x--;

    let unit = "%";

    return x / screenWidth * 100 + unit;
}

export function getYScreenValue(y, height) {
    // pozicovani je od x:1,y:1
    y--;

    let frameHeight = Constants.screenHeight;
    if (height != undefined)
        frameHeight = height;

    let unit = "%";

    return y / frameHeight * 100 + unit;
}

/**
 * odstrani '&' z labelu
 * pokud je 'underLineVisibility === true', tak se podtrhne znak na pozici kde puvodne byl znak '&'
 * @param {*} label 
 * @param {*} underLineVisibility boolean
 * @param {*} firstLetterAmend boolean true -> pokud neni znak '&' obsazen podtrhne se prvni znak
 * @returns 
 */
export function textWithMnemonic(label, underLineVisibility, firstLetterAmend) {
    if (label === undefined)
        return ""

    let ampIndex = label.indexOf("&")
    if (firstLetterAmend && ampIndex === -1)
        ampIndex = 0

    label = label.replace("&", "")

    if (underLineVisibility && ampIndex > -1) {
        if (ampIndex === 0)
            return <><u>{label.substring(ampIndex, 1)}</u>{label.substring(1)}</>
        else
            return <>{label.substring(0, ampIndex)}<u>{label.substring(ampIndex, ampIndex + 1)}</u>{label.substring(ampIndex + 1)}</>
    }
    return label
}

export function getPxWidthByLetters(numOfLetters, hidePxSufix) {

    const maxLetterWidth = getMaxLetterWidth()

    if (hidePxSufix)
        return numOfLetters * maxLetterWidth
    else
        return numOfLetters * maxLetterWidth + "px"
}

export function fitPxToChars() {
    return 15
}

export function getMaxLetterWidth() {
    return getTextWidth("W", "12pt arial");

    function getTextWidth(text, font) {
        var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"))
        var context = canvas.getContext("2d")
        context.font = font
        var metrics = context.measureText(text)
        return metrics.width
    }
}

export function getScrollbarWidth() {
    // Create the div
    var scrollDiv = document.createElement("div")
    scrollDiv.className = "scrollbar-measure"
    document.body.append(scrollDiv)

    // Get the scrollbar width
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth

    // Delete the div
    document.body.removeChild(scrollDiv)

    return scrollbarWidth
}

export function getWidthInChars(width, isDialog) {
    //console.log("getWidthInChars", width, isDialog)
    let correction = 0.8

    if (isDialog)
        correction = 0.3

    if (getStore().getState().smallScreen)
        correction = 0.1

    return width + width * correction + "ch"
}

export function remToPx(remValue) {
    // Get the root font size in pixels
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)

    // Perform the conversion
    const pxValue = remValue * rootFontSize

    return pxValue
}

export function pxToRem(pxValue) {
    // Get the root font size in pixels
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)

    // Perform the conversion
    const remValue = pxValue / rootFontSize

    return remValue
}