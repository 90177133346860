import React from 'react'
import { useDispatch } from 'react-redux'

import { Dropdown } from 'bootstrap/dist/js/bootstrap.bundle.min.js'
import BreadCrumbs from './BreadCrumbs'
import { addListener } from '../hooks/desktopListener'
import { WebSocketContext } from '../services/WebSocket'
import Constants from '../constants/constants'
import { getStore } from '../index'
import { LISTENER_TYPE } from '../hooks/desktopListener'
import addKeyMouseClickListener from '../hooks/desktopListener'
import { getBootstrapVariables } from '../theme/getThemeVariables.js'
import { logRender, logRenderProps } from '../utils/logging'
import { focusElement, focus2SessionMain } from '../focus_actions'
import BSTooltip from './BSTooltip'
import { loadNavigatorDataFromRoot } from './MenuItem'
import { onValueChange } from '../store/actions/store_actions'

const { KEYDOWN, CLICK } = LISTENER_TYPE
const { SEARCH_QUERY, RUN_SEARCH_PROGRAM } = Constants.WebSocketActions

export default function SearchResults(props) {
    const ws_context = React.useContext(WebSocketContext)
    const { sendMessage, createMessage, createRequest } = ws_context

    let messageContainer = getStore().getState().app.messageContainer
    let searchMenuItem = getStore().getState().app.menuItems.SEARCH

    const [results, setResults] = React.useState([])
    const searchInputRef = React.useRef()
    const searchButtonRef = React.useRef()
    const dropdownRef = React.useRef()
    const id = "search_input"
    const dispatch = useDispatch()
    let searchDropdown

    React.useEffect(() => {
        searchDropdown = new Dropdown(dropdownRef.current)

        return () => {
            searchDropdown.dispose()
        }
    })

    React.useEffect(() => {
        const controller = new AbortController()
        //smaz stare vysledky kdyz se zavre dropdown
        document.getElementById(Constants.globalSearch).addEventListener('hide.bs.dropdown', e => setResults([]), { signal: controller.signal })

        return () => {
            controller.abort()
        }
    }, [])

    addListener(CLICK, searchButtonRef, e => {
        focusElement("search_input", "SearchResults")

        sendMessage(createMessage(SEARCH_QUERY, createRequest({
            query: searchInputRef.current.value,
            searchForPrograms: true,
            searchForMenuAndSubmenu: true
        })), response => {
            response.data.forEach(resp => {

                searchDropdown.show()

                let responseEntity = Object.keys(resp)[0]
                if (responseEntity === "SearchQueryResponse") {
                    if (resp["SearchQueryResponse"] === null) {
                        setResults([{
                            id: '4',
                            parentId: '0',
                            label: messageContainer["dialog.searchWholeDesktop.text.menuPath"].replace("&1", searchInputRef.current.value),
                            menuPath: '',
                            iconId: 0
                        }])
                    } else
                        setResults(resp["SearchQueryResponse"])
                }
            })
        })
    })

    addListener(KEYDOWN, searchInputRef, e => {
        if (e.key === 'Enter')
            searchButtonRef.current.click()
        else if (e.key === "ArrowDown" || e.key === "Tab") {
            //pri zmacknuti klavesy sipka dolu - provedeni focusu na prvni polozku dropdown
            let firstRow = document.getElementById("first_search_row")
            let children = firstRow.children[0]
            if (firstRow && children) {
                function cursorFocus(elem) {
                    var x = dropdownRef.current.scrollLeft, y = dropdownRef.current.scrollTop
                    elem.focus({
                        preventScroll: true,
                    })

                    dropdownRef.current.scrollTo(x, y)
                }

                const executerId = setTimeout(function () {
                    cursorFocus(children)
                    clearTimeout(executerId)
                }, 0)
            }
        }
    })

    addListener(KEYDOWN, document, e => {
        //skok na globalni vyhledavani
        if (e instanceof KeyboardEvent && e.ctrlKey && e.shiftKey && e.key == 'F') {
            //searchInputRef.current.click()
            focusElement("search_input", "SearchResults")
        }

        if (e.key === "Escape") {
            searchDropdown?.hide()
        }

        //tab uvnitr vysledku zavre dropdown
        if (e.key === "Tab" && e.delegateTarget?.id === "search_dropdown")
            searchDropdown?.hide()
    })

    function hideSearchDropdown() {
        if (searchDropdown)
            searchDropdown.hide()
    }

    function divider(result, i) {
        return <li key={"hr_li_" + result.id}>
            <hr className="dropdown-divider" style={{ margin: 0 }} key={"search_row_divider" + result.id} />
        </li>
    }

    //nefunguje tlacitko sipka dolu a musi se mackat tab
    //pri provedeni focusu po "change" focus provede ale neni videt cerny ramecek
    //todo udelat komponentu searchbar, aby nebyl problem s focusem, nyni jsou to dve komponenty input + dropdown
    logRender("SearchResults")
    logRenderProps(props)
    return <div id={Constants.globalSearch}>
        <div data-bs-toggle="dropdown">
            <BSTooltip tooltiptext={searchMenuItem.name} shortcuts={[searchMenuItem.shortcut]}>
                <div className="input-group input-group-navbar" style={{ minWidth: '100px' }}>
                    <input
                        id={id}
                        ref={searchInputRef}
                        className="form-control"
                        autoComplete="off"
                        aria-label="Search"
                        placeholder={messageContainer["dialog.searchWholeDesktop.text.label"]}
                        onFocus={e => {
                            //vysledky hledani se nezapisuji do related target
                            if (e?.relatedTarget?.id?.indexOf("search_result_row") === -1)
                                dispatch(onValueChange(Constants.InternalElementState.RELATED_TARGET_ID, e?.relatedTarget?.id))
                        }} />
                    <button id="btn_search_input" ref={searchButtonRef} className="btn" type="button" aria-expanded="false" tabIndex="-1" data-bs-toggle="dropdown">
                        <i className="align-middle fas fa-search" data-feather="search" />
                    </button>
                </div>
            </BSTooltip>
        </div>

        {(() => {
            return <ul id="search_dropdown" ref={dropdownRef} className="dropdown-menu overflow-auto dropdown-search-results" style={{ padding: 0, minWidth: 0 }}>
                {(() => {
                    let isFirstProgram = true
                    return results.map((result, i) => {
                        if (result.parentId === "0")
                            return (<React.Fragment key={"search_fragment_" + result.id}>
                                <li>
                                    <h6 key={"search_row_" + result.id} id={"dropdown_header_" + i} className="dropdown-header"
                                        style={{
                                            fontWeight: 500,
                                            fontSize: ".875rem",
                                            paddingLeft: "1rem"
                                        }}>
                                        {result.label}
                                    </h6>
                                </li>
                                {divider(result, i)}
                            </React.Fragment>)

                        let row = <React.Fragment key={"search_fragment_" + result.id}>
                            <SearchResult key={"search_row_" + result.id} result={result} isFirstProgram={isFirstProgram} hideSearchDropdown={hideSearchDropdown} />
                            {divider(result, i)}
                        </React.Fragment>

                        isFirstProgram = false
                        return row
                    })
                })()}
            </ul>
        })()}
    </div >
}

function SearchResult(props) {
    const { result, isFirstProgram, hideSearchDropdown } = props
    const ws_context = React.useContext(WebSocketContext)
    const { sendMessage, createMessage, createRequest } = ws_context
    const searchRowRef = React.useRef()

    const dispatch = useDispatch()

    addKeyMouseClickListener(searchRowRef, e => {
        if (!(e instanceof MouseEvent) &&
            !(e instanceof KeyboardEvent &&
                e.key === "Enter")) {
            return
        }

        hideSearchDropdown()
        if (result.iconId == 3) {
            loadNavigatorDataFromRoot(result.label.split(". ")[0], ws_context, dispatch, false)
        } else {
            sendMessage(createMessage(RUN_SEARCH_PROGRAM, createRequest({ rowId: result.id })), response => focus2SessionMain())
        }
        document.getElementById(Constants.globalSearch).click()
    })

    let icon = ""

    switch (result.iconId) {
        case 0: { //zakladni menu
            break
        }
        case 1: { //ikona programu
            icon = "fas fa-file-alt"
            break
        }
        case 2: { //oblibene
            icon = "fas fa-star"
            break
        }
        case 3: { //submenu ve vysledcich
            icon = "fas fa-bars"
            break
        }
        default: {
            break
        }
    }

    return (<li id={isFirstProgram ? "first_search_row" : ("next_search_row_" + result.id)}>
        <a tabIndex={-1} id={"search_result_row_" + result.id} className="dropdown-item" href="#" ref={searchRowRef} style={{ padding: ".2rem 0 .2rem 0" }}>
            <div className="d-flex flex-row g-0 align-items-center">
                <div className="me-3 ms-3">
                    <i className={icon} data-feather="alert-circle"></i>
                </div>
                <div>
                    <div className={(result.parentId == 0) ? "fw-bold" : "text-dark"}>{result.label}</div>
                    <div className="d-flex"><BreadCrumbs result={result} key={"breadcrumbs_" + result.id} menuPath={result.menuPath} /></div>
                </div>
            </div>
        </a>
    </li>)
}
