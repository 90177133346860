import React from 'react'

export default function WidgetListWithLabels(props) {
    const { data } = props
    const ttColumnData = data?.ttDWDColumnData

    return <div className="list-group card-text grid-card h-100">
        {ttColumnData?.map((row, index) => {
            return <div className="row row-cols-2" key={"row_info_" + index}>
                <div className="col-md-6 text-secondary">
                    {row.columnLabel}
                </div>
                <div className="col-md-6" style={{ whiteSpace: 'pre' }}>
                    {row.columnValue}
                </div>
            </div>
        })}
    </div>
}