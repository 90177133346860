import React from 'react'

import { logRender, logRenderProps } from '../../utils/logging'

import BrowseHeaderColumn from './BrowseHeaderColumn'
import { getThemeVariables } from '../../theme/getThemeVariables'

export default function BrowseHeader(props) {
    const { desktopSessionMask, columsWidth, desktopMenu, data, tableElementRef } = props

    logRender("BrowseHeader")
    logRenderProps(props)
    //console.log("BrowseHeader props", props)

    let columnOrder = data.browseColsSorted.split(",")
    //console.log("BrowseHeader columnOrder: ", columnOrder)
    //console.log("BrowseHeader columsWidth: ", columsWidth)

    return <thead id={"table_header_" + props.id} className='table_header thead-sticky'
        style={{ background: getThemeVariables().cardBodyColor }}>
        <tr className='tr'>
            {columnOrder.map((columnId, index) => {
                let hColumn = desktopSessionMask[columnId].data
                if ((hColumn?.browseColVisible || hColumn?.browseColVisible === undefined)) {
                    if (hColumn.visible) {
                        let hKey = "k-" + hColumn.label + "-" + hColumn.handle

                        return <BrowseHeaderColumn
                            browseId={props.id}
                            childIds={columnOrder}
                            hColumn={hColumn}
                            columsWidth={columsWidth}
                            key={hKey}
                            desktopMenu={desktopMenu}
                            tableElementRef={tableElementRef}
                            handleResize={props.handleResize}
                            sizePerPage={props.sizePerPage}
                            colPosition={index}
                            sortedByHandle={data.sortedByHandle}
                            desktopSessionMask={desktopSessionMask}
                            tabId={props.tabId}
                            maskId={props.maskId}
                            columnOrder={columnOrder}
                        />
                    }
                }
            })}
        </tr>
    </thead>


    /*
    import useStoreSubscription from '../../hooks/useStoreSubscription'
//import withContextMenu from '../withContextMenu'
import constants from '../../constants/constants'
import { WebSocketContext } from '../../services/WebSocket'
import { couldCallWS } from '../../App'
import { textWithMnemonic } from '../../utils/screen'

        const { DEFAULT_TABLE_COLUMNS_ORDER } = constants.MenuActions
        const { MENU_ACTION } = constants.SetValueRequest
        const { SET_VALUE_REQUEST } = constants.WebSocketActions
    
        const ws_context = React.useContext(WebSocketContext)
        const { createMessage, createRequest, sendMessage } = ws_context
    
    
    let ws, currentFocusId
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
    })
    
    function doMenuAction(action, event) {
        if (couldCallWS(ws_context))
            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                handle: currentFocusId,
                newFocus: action,
                action: MENU_ACTION
            })))
    }
    
        const Header = ({ onContextMenu }) => {
            return <thead id={"table_header_" + props.id} className='table_header' onContextMenu={onContextMenu}
                style={{ background: getThemeVariables().cardBodyColor }}>
                <tr className='tr'>
                    {columnOrder.map((columnId, index) => {
                        let hColumn = desktopSessionMask[columnId].data
                        if ((hColumn?.browseColVisible || hColumn?.browseColVisible === undefined)) {
                            if (hColumn.visible) {
                                let hKey = "k-" + hColumn.label + "-" + hColumn.handle
    
                                return <BrowseHeaderColumn
                                    browseId={props.id}
                                    childIds={columnOrder}
                                    hColumn={hColumn}
                                    columsWidth={columsWidth}
                                    key={hKey}
                                    desktopMenu={desktopMenu}
                                    tableElementRef={tableElementRef}
                                    handleResize={props.handleResize}
                                    colPosition={index}
                                    sortedByHandle={data.sortedByHandle}
                                />
                            }
                        }
                    })}
                </tr>
            </thead>
        }
    
        const tableColumnsOrder = desktopMenu[DEFAULT_TABLE_COLUMNS_ORDER]
    
        const HeaderWithContextMenu = withContextMenu([
            {
                key: 3,
                label: textWithMnemonic(tableColumnsOrder.label, false, true),
                onClick: e => {
                    if (couldCallWS(ws_context))
                        doMenuAction(DEFAULT_TABLE_COLUMNS_ORDER, e)
                }
            }], Header)
    
        return <HeaderWithContextMenu />*/
}