import { getStore } from '../../index.js'
import Constants from '../../constants/constants'
import { getEventElement } from '../../app_actions.js'
import { KEY_REQUEST } from '../../services/WebSocket.js'
import { getNumberOfRows } from './Browse.jsx'
import { disableOtherListeners } from '../../app_actions.js'
import { handleBrowseInputFieldElement } from '../desktop_server/InputFieldElement.jsx'
import { getElementData } from '../desktop_server/elementMethods.js'
import { onBrowseCellChanged } from '../../store/actions/store_actions.js'
import { action } from '../../utils/actions.js'
import { getEmptyRowProp } from '../../utils/actions.js'

export const KEY_HOME = "Home"
export const KEY_END = "End"
export const KEY_UP = "ArrowUp"
export const KEY_DOWN = "ArrowDown"
export const KEY_PAGE_UP = "PageUp"
export const KEY_PAGE_DOWN = "PageDown"
export const KEY_SPACE = " "
export const KEY_ENTER = "Enter"
export const KEY_ESCAPE = "Escape"
export const KEY_TAB = "Tab"

export const MOVE_UP = "up"
export const MOVE_DOWN = "down"

const { BRW_HOME, BRW_END, EVENT_ENTRY, DEFAULT_ACTION } = Constants.SetValueRequest
const { SET_VALUE_REQUEST, SELECT_BROWSE_ROW_REQUEST, SET_BROWSE_CELL_VALUE_REQUEST, GET_BROWSE_DATA_AND_SELECT_REQUEST } = Constants.WebSocketActions
const { SELECT_RECORD, ROOT_RECORD } = Constants.MenuActions

export function isEditable(event) {
    let element = getEventElement(event)
    return (element !== undefined && element.componentName === "InputFieldElement" && element.data.readOnly === false)
}

export function browseAction(action, event, ws_context, tabId) {
    //console.log("BROWSE ACTION", action)
    const { createMessage, createRequest, sendMessage, getRequestsQueue } = ws_context
    const store = getStore().getState()
    const ws = store.ws
    const desktopSessionMask = store.desktopSession[ws.currentTabId][ws.currentMaskID]
    const tableElement = store.desktopSession[ws.currentTabId][ws.currentMaskID][tabId]
    const parent = store.desktopSession[ws.currentTabId][ws.currentMaskID][tableElement.data.parentHandle]
    const desktopMenu = getStore().getState().desktopMenu

    const { data } = tableElement
    const { browseData, sizePerPage } = data

    function getSelectedIndex() {
        let i = 0
        if (browseData !== undefined)
            i = browseData.findIndex((row) => row.rowId === data.rowId)

        return i
    }

    /*OZNACENI RADKU BROWSU */
    function selectBrowseRow(newRow, callback) {
        //console.log(desktopSessionMask)

        //pridani informaci k editoru(po zmene radku se muze vracet ScreenDescription s EditorElementem, ktery neobsahuje sourceFileName, proto se musi predavat)
        //EditorElement ma pro vsechny radky stejny sourceFileName
        let editors = []
        for (const [key, value] of Object.entries(desktopSessionMask)) {
            if (value.componentName === "EditorElement")
                editors.push({ handle: value.data.handle, sourceFileName: value.data.sourceFileName })
        }

        //console.log("NEW ROW", newRow)
        let keyRequest = SELECT_BROWSE_ROW_REQUEST + "," + newRow.rowId

        if (getRequestsQueue().size() === 0) {
            sendMessage(createMessage(SELECT_BROWSE_ROW_REQUEST, createRequest({
                handle: tabId,
                rowId: newRow.rowId,
                editors
            }), { [KEY_REQUEST]: keyRequest }), callback)
        }
    }

    function doEntry(newFocus) {
        sendMessage(createMessage(SET_BROWSE_CELL_VALUE_REQUEST, createRequest({
            handle: ws.focusId,
            keyEvent: 'Entry'
        })), () => {
            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                ...getEmptyRowProp(),
                handle: ws.focusId,
                newFocus: newFocus,
                action: EVENT_ENTRY
            })))
        })
    }

    function pressEnter() {
        if (!isEditable(event) && tableElement.data.sensitivity) {
            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                ...getEmptyRowProp(),
                handle: ws.focusId,
                action: DEFAULT_ACTION
            })))
        } else
            handleBrowseInputFieldElement(event, ws_context)
    }

    function pressEnd() {
        if (parent?.data?.specialId === Constants.SpecialId.UCJE_SELECTION)
            return

        if (!(event instanceof MouseEvent) &&
            !(event instanceof KeyboardEvent && (
                event.key === "Enter" ||
                event.key === KEY_END))) {
            return
        }

        let htmlBrowseElement = document.getElementById(tabId)
        let scrollOptions = {
            verticalScrollPosition: htmlBrowseElement?.scrollHeight
        }

        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: ws.focusId,
            action: BRW_END,
            scrollOptions
        })))
    }

    function pressDown() {
        //console.log("press down", data)
        if (!(event instanceof MouseEvent) && !(event instanceof KeyboardEvent && event.key === KEY_DOWN))
            return

        function doPressDown() {
            //console.log("fce press down")
            let index = getSelectedIndex()
            let remains = browseData.length - index

            if (remains === 2 && !data.reachBottom) {
                // dva radky od konce se nacitaji nova data
                let rowId = browseData[browseData.length - 1].rowId
                if (rowId != undefined) {
                    let keyRequest = GET_BROWSE_DATA_AND_SELECT_REQUEST + "," + rowId
                    let reqData = {
                        handle: ws.focusId,
                        numRows: sizePerPage,
                        move: MOVE_DOWN,
                        rowId,
                        scrollOptions: {
                            horizontalScrollPosition: document.getElementById("browseScrollDiv")?.scrollLeft
                        }
                    }

                    sendMessage(createMessage(GET_BROWSE_DATA_AND_SELECT_REQUEST, createRequest(reqData), { [KEY_REQUEST]: keyRequest }), response => {
                        //console.log("is editable", isEditable(event))
                        if (isEditable(event))
                            doEntry(getEventElement(event).id)
                    })
                }
            } else if (remains > 1) {
                selectBrowseRow(browseData[index + 1], response => {
                    if (isEditable(event))
                        doEntry(getEventElement(event).id)

                })
            }
        }

        if (isEditable(event))
            handleBrowseInputFieldElement(event, ws_context, doPressDown)
        else
            doPressDown()
    }

    function pressUp() {
        if (!(event instanceof MouseEvent) && !(event instanceof KeyboardEvent && event.key === KEY_UP))
            return

        function doPressUp() {
            let index = getSelectedIndex()

            if (index === 1 && !data.reachTop) {
                let rowId = browseData[0].rowId
                if (rowId != undefined) {
                    let keyRequest = GET_BROWSE_DATA_AND_SELECT_REQUEST + "," + rowId
                    let reqData = {
                        handle: ws.focusId,
                        numRows: sizePerPage * -1,
                        move: MOVE_UP,
                        rowId,
                        scrollOptions: {
                            horizontalScrollPosition: document.getElementById("browseScrollDiv")?.scrollLeft
                        }
                    }

                    sendMessage(createMessage(GET_BROWSE_DATA_AND_SELECT_REQUEST, createRequest(reqData), { [KEY_REQUEST]: keyRequest }), response => {
                        if (isEditable(event))
                            doEntry(getEventElement(event).id)
                    })
                }
            } else if (index >= 1)
                selectBrowseRow(browseData[index - 1], response => {
                    if (isEditable(event))
                        doEntry(getEventElement(event).id)
                })
        }

        if (isEditable(event))
            handleBrowseInputFieldElement(event, ws_context, doPressUp)
        else
            doPressUp()
    }

    function pressPageUp() {
        if (!(event instanceof MouseEvent) && !(event instanceof KeyboardEvent && (event.key === "Enter" || event.key === KEY_PAGE_UP)))
            return

        function doPressPageUp() {
            let index = getSelectedIndex()
            let newIndex = index - sizePerPage
            if (newIndex < 0)
                newIndex = 0

            //console.log("pressPageUp: ", "newIndex: " + newIndex, "data.reachTop: " + data.reachTop)

            if (newIndex === 0 && !data.reachTop) {
                let rowId = browseData[0].rowId
                if (rowId != undefined) {
                    let keyRequest = GET_BROWSE_DATA_AND_SELECT_REQUEST + "," + rowId
                    let reqData = {
                        handle: ws.focusId,
                        numRows: sizePerPage * -1,
                        move: MOVE_UP,
                        rowId,
                        scrollOptions: {
                            horizontalScrollPosition: document.getElementById("browseScrollDiv")?.scrollLeft
                        }
                    }

                    sendMessage(createMessage(GET_BROWSE_DATA_AND_SELECT_REQUEST, createRequest(reqData), { [KEY_REQUEST]: keyRequest }), response => {
                        if (isEditable(event))
                            doEntry(getEventElement(event).id)
                    })
                }
            } else if (index != newIndex)
                selectBrowseRow(browseData[newIndex], response => {
                    if (isEditable(event))
                        doEntry(getEventElement(event).id)
                })
        }

        if (isEditable(event))
            handleBrowseInputFieldElement(event, ws_context, doPressPageUp)
        else
            doPressPageUp()
    }

    function pressPageDown() {
        if (!(event instanceof MouseEvent) && !(event instanceof KeyboardEvent && (event.key === "Enter" || event.key === KEY_PAGE_DOWN)))
            return

        function doPressPageDown() {
            let index = getSelectedIndex()
            let newIndex = parseInt(index) + parseInt(sizePerPage)
            if (newIndex >= browseData.length)
                newIndex = browseData.length - 1

            let remains = browseData.length - newIndex
            //console.log("PAGE DOWN -> remains: " + remains, "new index: " + newIndex)
            if (remains === 1 && !data.reachBottom) {
                let rowId = browseData[browseData.length - 1].rowId
                if (rowId != undefined) {
                    let keyRequest = GET_BROWSE_DATA_AND_SELECT_REQUEST + "," + rowId
                    let reqData = {
                        handle: ws.focusId,
                        numRows: sizePerPage,
                        move: MOVE_DOWN,
                        rowId,
                        scrollOptions: {
                            horizontalScrollPosition: document.getElementById("browseScrollDiv")?.scrollLeft
                        }
                    }

                    sendMessage(createMessage(GET_BROWSE_DATA_AND_SELECT_REQUEST, createRequest(reqData), { [KEY_REQUEST]: keyRequest }), response => {
                        if (isEditable(event))
                            doEntry(getEventElement(event).id)
                    })
                }
            } else if (index !== newIndex) {
                selectBrowseRow(browseData[newIndex], response => {
                    if (isEditable(event))
                        doEntry(getEventElement(event).id)
                })
            }
        }

        if (isEditable(event))
            handleBrowseInputFieldElement(event, ws_context, doPressPageDown)
        else
            doPressPageDown()
    }

    function pressHome() {
        //vyber ucetni jednotky preskoci akce
        if (parent?.data?.specialId === Constants.SpecialId.UCJE_SELECTION)
            return

        if (!(event instanceof MouseEvent) &&
            !(event instanceof KeyboardEvent && (
                event.key === "Enter" ||
                event.key === KEY_HOME))) {
            return
        }

        let scrollOptions = {
            verticalScrollPosition: 0
        }
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: ws.focusId,
            action: BRW_HOME,
            scrollOptions
        })))
    }

    let request
    switch (action) {
        case KEY_HOME:
            event.preventDefault()
            event.stopPropagation()
            pressHome()
            break
        case KEY_END:
            event.preventDefault()
            pressEnd()
            break
        case KEY_DOWN:
            event.preventDefault()
            pressDown()
            break
        case KEY_UP:
            event.preventDefault()
            pressUp()
            break
        case KEY_PAGE_UP:
            event.preventDefault()
            pressPageUp()
            break
        case KEY_PAGE_DOWN:
            event.preventDefault()
            pressPageDown()
            break
        case KEY_SPACE:
            //provede click na zaznam -> oznac()/odznac
            let key = "navdrop_" + desktopMenu[ROOT_RECORD].handle + "_li_" + SELECT_RECORD
            let fceElement = document.getElementById(key)
            if (fceElement !== null)
                fceElement.click()
            break
        case KEY_ENTER:
            pressEnter()
            break
        case KEY_ESCAPE:
            if (isEditable(event))
                handleBrowseInputFieldElement(event, ws_context)
            break
        case KEY_TAB:
            if (isEditable(event))
                handleBrowseInputFieldElement(event, ws_context)
            break
        default:
            break
    }
}