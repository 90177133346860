import { getStore } from '../index'

export function getSpecialIdElementData(specialId) {
    let { desktopSession } = getStore().getState()
    let data = ""
    Object.entries(desktopSession[0][0]).forEach(item => {
        item.forEach(i => {
            if (i.data != undefined && i.data.specialId === specialId) {
                data = i.data
                return
            }
        })
    })
    return data
}

export function getSpecialId(handle) {
    let store = getStore().getState()
    let ws = store.ws
    let desktopSessionMask = store.desktopSession[ws.currentTabId][ws.currentMaskID]
    return desktopSessionMask[handle]?.data?.specialId
}

export function getSpecialIdElement(specialId) {
    let { desktopSession } = getStore().getState()
    let data = "";
    Object.entries(desktopSession[0][0]).forEach(item => {
        item.forEach(i => {
            if (i.data != undefined && i.data.specialId === specialId) {
                data = i
                return
            }
        })
    })
    return data
}