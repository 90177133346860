import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../utils/logging'
import { addListener, addListenerWithRefHandler, LISTENER_TYPE } from '../hooks/desktopListener'
import { WebSocketContext } from '../services/WebSocket'
import { focus2SessionMain } from '../focus_actions'
import Constants from '../constants/constants'
import { MENU_HISTORY } from './SideMenu'
import ContextMenu from './ContextMenu'
import { auto } from '@popperjs/core'

function DesktopHistoryMenuRow({ rowName, menuItem, refContextMenu }) {
    const menuItemRef = React.useRef()
    const ws_context = React.useContext(WebSocketContext)

    const style = { border: 'none', padding: '.1rem' }

    addListenerWithRefHandler(LISTENER_TYPE.CLICK, menuItemRef, e => {
        if (!window.getSelection()?.toString())
            ws_context.sendMessage(ws_context.createMessage(Constants.WebSocketActions.EXECUTE_PROGRAM_NAVIGATOR_HISTORY, ws_context.createRequest({
                ...menuItem,
                event: Constants.MenuEvents.RUN
            })), response => focus2SessionMain())
    })

    addListener(LISTENER_TYPE.CONTEXT_MENU, menuItemRef, e => {
        e.preventDefault()

        let menuItemProps = {
            programName: menuItem.programName,
            programParameter: menuItem.programParameter,
            event: menuItem.event
        }

        refContextMenu.current.displayContextMenu(e, "menu", menuItemProps, MENU_HISTORY)
    })

    let enabled = menuItem.isAllowed ? "" : " isDisabled"

    return (
        <a ref={menuItemRef} href="#" className={"list-group-item list-group-item-action" + enabled} style={style}>
            {rowName}
        </a>
    );
}

function RecentOpen({ desktopHistoryMenu, messageContainer, popupHistory }) {
    const refContextMenu = React.useRef({})

    function renderRows() {
        return desktopHistoryMenu
            ? Object.values(desktopHistoryMenu).map((value, i) => {
                if (i > 9)
                    return
                return <DesktopHistoryMenuRow key={value.rowName} rowName={value.rowName} menuItem={value} refContextMenu={refContextMenu} />
            })
            : []
    }

    logRender('RecentOpen ')
    return (
        <div style={{ display: 'contents' }}>

            {/* <div className='lead' style={{ fontWeight: 400 }}>
                <i className="fas fa-clock me-2"></i>{messageContainer[Constants.MessageContainer.HISTORY]}
            </div>

    <hr style={{ margin: '.4rem' }} />*/}

            <ContextMenu ctxRef={refContextMenu} type={MENU_HISTORY} popupHistory={popupHistory} />
            <div className="list-group card-text overflow-auto grid-card h-100">{renderRows()}</div>
        </div>
    )
}

function mapStateToProps(state, ownProps) {
    return {
        desktopHistoryMenu: state.desktopHistoryMenu,
        messageContainer: state.app.messageContainer,
        popupHistory: state.popupHistory
    }
}

export default connect(mapStateToProps)(RecentOpen)