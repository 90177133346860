import React from 'react'
import { findIconKey } from '../dialogs/IconsDialog'
import { getBadgeColor } from './WidgetTable'

export default function ShortInfo(props) {
    const { data, widget } = props
    const { primaryText, secondaryDesc, secondaryValue, secondaryColor, iconName } = data

    //console.log("render -> ShortInfo()", props)
    return <div draggable="true" style={{ userSelect: "text" }}>
        <div style={{
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <h1 className="w-100">{primaryText}</h1>
            <div className="flex-shrink-1">
                <div className="col-auto">
                    <div className="stat text-primary">
                        <i className={"far fa-" + findIconKey(iconName) + " fa-2x"} />
                    </div>
                </div></div>
        </div>

        <div>
            <span className={"badge " + getBadgeColor(secondaryColor)}>
                {secondaryValue}
            </span>
            <span className="text-muted">{" " + secondaryDesc}</span>
        </div>
    </div>
}