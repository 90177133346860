/*redux konstanty*/
export const EMPTY_DESKTOP_STORE = "EMPTY_DESKTOP_STORE"
export const TREE_ITEMS = "TREE_ITEMS"
export const UPDATE_NAVIGATOR_MENU_ITEM = "UPDATE_NAVIGATOR_MENU_ITEM"
export const UPDATE_FAVORITES_MENU_ITEM = "UPDATE_FAVORITES_MENU_ITEM"
export const UPDATE_HISTORY_MENU_ITEM = "UPDATE_HISTORY_MENU_ITEM"
export const ADD_NAVIGATOR_MENU_ITEMS = "ADD_NAVIGATOR_MENU_ITEMS"
export const FAVORITES_ITEMS = "FAVORITES_ITEMS"
export const NAVIGATOR_POPUP = "NAVIGATOR_POPUP"
export const FAVORITE_POPUP = "FAVORITE_POPUP"
export const HISTORY_POPUP = "HISTORY_POPUP"
export const HISTORY_ITEMS = "HISTORY_ITEMS"
export const SET_CONNECTION_DATA = "SET_CONNECTION_DATA"
export const SET_RECONNECT_DATA = "SET_RECONNECT_DATA"
export const SET_FOCUS_ID = "SET_FOCUS_ID"
export const SET_DISCONNECT = "SET_DISCONNECT"
export const ADD_MESSAGE_DIALOG = "ADD_MESSAGE_DIALOG"
export const REMOVE_MESSAGE_DIALOG = "REMOVE_MESSAGE_DIALOG"
export const LOGIN = "LOGIN"
export const SET_BROWSE_DATA = "SET_BROWSE_DATA"
export const SET_BROWSE_DATA_AND_SELECT = "SET_BROWSE_DATA_AND_SELECT"
export const REMOVE_MASK = "REMOVE_MASK"
export const SET_DASHBOARDS = "SET_DASHBOARDS"
export const SET_WIDGETS = "SET_WIDGETS"
export const SET_WIDGET_DATA = "SET_WIDGET_DATA"
export const SET_ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
export const REMOVE_NOTIFICATIONS = "REMOVE_NOTIFICATIONS"
export const TRIGGER_BROWSE_GO = "TRIGGER_BROWSE_GO"

export const SET_NOTIFICATION_OPTIONS = "SET_NOTIFICATION_OPTIONS"

export const emptyDesktopStore = () => ({
    type: EMPTY_DESKTOP_STORE
})

export const wsUpdateNavigatorMenuItem = (data) => ({
    type: UPDATE_NAVIGATOR_MENU_ITEM,
    payload: data
})

export const wsUpdateHistoryMenuItem = (data) => ({
    type: UPDATE_HISTORY_MENU_ITEM,
    payload: data
})

export const wsUpdateFavoritesMenuItem = (data) => ({
    type: UPDATE_FAVORITES_MENU_ITEM,
    payload: data
})

export const wsSaveNavigatorMenuItems = (updatedMenu) => ({
    type: ADD_NAVIGATOR_MENU_ITEMS,
    updatedMenu: updatedMenu
})

export const wsSaveFavoritesItems = (items) => ({
    type: FAVORITES_ITEMS,
    items: items
})

export const wsSaveHistoryItems = (items) => ({
    type: HISTORY_ITEMS,
    items: items
})

export const wsSetNavigatorPopup = (items) => ({
    type: NAVIGATOR_POPUP,
    items: items
})

export const wsSetFavouritePopup = (items) => ({
    type: FAVORITE_POPUP,
    items: items
})

export const wsSetHistoryPopup = (items) => ({
    type: HISTORY_POPUP,
    items: items
})

export const setWSConnectionData = (data) => ({
    type: SET_CONNECTION_DATA,
    payload: data
})

export const setWSReconnectData = (data) => ({
    type: SET_RECONNECT_DATA,
    payload: data
})

export const setWSDisconnect = (data) => ({
    type: SET_DISCONNECT,
    payload: data
})

export const addMessageDialog = (data) => ({
    type: ADD_MESSAGE_DIALOG,
    payload: data
})

export const removeMessageDialog = index => ({
    type: REMOVE_MESSAGE_DIALOG,
    index: index
})

export const login = () => ({ type: LOGIN })

export const setWSFocusId = (data) => ({
    type: SET_FOCUS_ID,
    screenVariables: data
})

export const wsProcessScreenData = (data, menuItems, action, request, screenVariables) => ({
    type: TREE_ITEMS,
    payload: data,
    menuItems,
    action: action,
    request: request,
    screenVariables: screenVariables

    //tabId: tabId,
    //tabName: screenLabel,
    //maskId: maskId,
    //focusId: focusId,
    //dropContent: dropContent
})

export const setBrowseDataAction = (id, browseData, borders, scrollOptions) => {
    return {
        type: SET_BROWSE_DATA,
        browseHandle: id,
        browseData,
        borders,
        scrollOptions
    }
}

export const setBrowseDataAndSelect = (browseData, screenData, menuItems) => {
    return {
        type: SET_BROWSE_DATA_AND_SELECT,
        browseData: browseData,
        menuItems,
        ...screenData,
    }
}

export const setDashboards = (dashboards) => {
    return {
        type: SET_DASHBOARDS,
        payload: dashboards
    }
}

export const setWidgets = (payload) => {
    return {
        type: SET_WIDGETS,
        payload: payload
    }
}

export const addNotifications = (notifications, screenVariables) => {
    return {
        type: SET_ADD_NOTIFICATIONS,
        notifications: notifications,
        screenVariables: screenVariables
    }
}

export const setNotificationOptions = (requestId, options) => {
    return {
        type: SET_NOTIFICATION_OPTIONS,
        requestId: requestId,
        options: options
    }
}

export const removeNotification = requestId => ({
    type: REMOVE_NOTIFICATION,
    requestId: requestId
})

export const removeNotifications = () => ({
    type: REMOVE_NOTIFICATIONS,
})

export const setWidgetData = (dashboardIndex, dashboardId, widgetIndex, widgetId, widgetData) => {
    return {
        type: SET_WIDGET_DATA,
        dashboardIndex: dashboardIndex,
        dashboardId: dashboardId,
        widgetIndex: widgetIndex,
        widgetId: widgetId,
        widgetData: widgetData
    }
}

export const triggerBrowseGo = (focusId, doDelete) => ({
    type: TRIGGER_BROWSE_GO,
    browseId: focusId,
    doDelete: doDelete
})