import React from 'react'
import ReactDOM from 'react-dom'

import Styles from '../constants/styles'
import useDialogDrag from '../hooks/useDialogDrag'
import constants from '../constants/constants'
import { LISTENER_TYPE, addListener } from '../hooks/desktopListener'

export const CUSTOM_MODAL_DIALOG_ID = "custom_modal_dialog"

/**
 * 
 * @param assign2Component = {
 *      tabId: number,
 *      maskId: number,
 *      id: number
 * }
 */

const CustomModalDialog = ({ isOpen, onClose, children, iconHeader, textHeader, assign2Component, dialogX, dialogY, width }) => {
    const modalRoot = document.getElementById('page_body')
    const refDialog = React.useRef(null)
    const refHeaderDialog = React.useRef(null)
    const refBodytDialog = React.useRef(null)

    useDialogDrag(modalRoot, assign2Component, refDialog, refHeaderDialog, refBodytDialog, dialogX, dialogY)

    addListener(LISTENER_TYPE.KEYDOWN, document, e => {
        if (e.key === 'Escape')
            onClose()
    })

    if (!isOpen)
        return null

    return ReactDOM.createPortal(<div
        id={CUSTOM_MODAL_DIALOG_ID}
        className="modal-dialog"
        ref={refDialog}
        style={{
            ...Styles.dialogStyle,
            width: width,
            zIndex: constants.modalDialogZIndex
        }}
        aria-modal="true"
        data-bs-backdrop="false">
        <div className="modal-content blue_box" style={{
            ...Styles.dialogContent
        }}>
            <div className="modal-header p-3" ref={refHeaderDialog} style={{ cursor: 'move' }}>
                <div className='d-inline-flex'>
                    {iconHeader}
                    <h4 className="modal-title">{textHeader}</h4>
                </div>
                {<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />}
            </div>
            <div className="modal-body p-3" ref={refBodytDialog}>
                {children}
            </div>
        </div>
    </div>, modalRoot)
}

export default CustomModalDialog