import React, { useContext, useCallback, useEffect } from 'react';
import { connect } from 'react-redux'
import Constants from '../constants/constants'
import { textWithMnemonic } from '../utils/screen'
import { getEmptyRowProp } from '../utils/actions'
import { logRender, logRenderProps } from '../utils/logging'
import { WebSocketContext } from '../services/WebSocket'
import { getSpecialIdElementData } from '../store/storageMethods'
import SearchResults from './SearchResults'
import SettingsDropdown from './SettingsDropdown'
import { addListener } from '../hooks/desktopListener'
import useStoreSubscription from '../hooks/useStoreSubscription'
import { getRelatedTargetFocusArea } from '../focus_actions'
import { changeFocusAreaWithBrowseFocusLoss, onValueChange } from '../store/actions/store_actions'

import { LISTENER_TYPE } from '../hooks/desktopListener'
import constants from '../constants/constants'
import { removeNotification, removeNotifications } from '../store/actions/ws_actions';

import Calculator from './calculator/Calculator'

const { CLICK } = LISTENER_TYPE

const { CLOSE_SESSION, SET_VALUE_REQUEST } = Constants.WebSocketActions
const { CHANGE_USER, CHANGE_ACCOUNTING_UNIT, HELP, MAILS_NOTIFICATIONS, LOAD_MAILS } = Constants.MenuActions
const { MENU_ACTION } = Constants.SetValueRequest

export function getUserName() {
    let data = getSpecialIdElementData(Constants.SpecialId.USERNAME_HIDDEN_INPUT)
    if (data !== undefined && data.value !== undefined)
        return data.value.substring(0, data.value.indexOf("[")).trimRight()
}

function getUcje() {
    let data = getSpecialIdElementData(Constants.SpecialId.UCJE_HIDDEN_INPUT)
    if (data !== undefined)
        return data.value
}

//Horní lišta aplikace - hledani, notifikace, akce nad celou aplikací (Zmena uzivatele, Zmena ucetni jednotky, Zavrit sezeni)
function Navigation(props) {
    const refNavigation = React.useRef({})
    const loadMailRef = React.useRef()
    const mailNotificationRef = React.useRef()
    const changeUserRef = React.useRef()
    const changeAccountingUnitRef = React.useRef()
    const closeSessionRef = React.useRef()
    const collapseSidebarRef = React.useRef()

    const [calculatorVisibility, setCalculatorVisibility] = React.useState(false);

    const ws_context = useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context
    const { closeSessionMenuItem, changeUserMenuItem, changeAccounttingUnitMenuItem, loadMailsMenuItem,
        mailNotifiactionsMenuItem, notifications, dispatch } = props

    let currentFocusId
    useStoreSubscription(store => {
        currentFocusId = store.ws.focusId
    })

    React.useEffect(() => {
        const controller = new AbortController()

        function focusout(e) {
            //console.log("out", e)
            const leavingParent = !refNavigation.current?.contains(e.relatedTarget)
            if (leavingParent) {
                //console.log("FOCUS OUT", e)
                dispatch(changeFocusAreaWithBrowseFocusLoss(getRelatedTargetFocusArea(e.relatedTarget)))
            }
        }

        if (refNavigation.current && refNavigation.current !== null)
            refNavigation.current.addEventListener("focusout", focusout, { signal: controller.signal })

        return () => {
            controller.abort()
        }
    })

    addListener(CLICK, changeUserRef, doClickChangeUser)
    function doClickChangeUser() {
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: currentFocusId,
            newFocus: changeUserMenuItem.handle,
            action: MENU_ACTION
        })))
    }

    addListener(CLICK, changeAccountingUnitRef, doClickChangeAccountingUnit)
    function doClickChangeAccountingUnit() {
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: currentFocusId,
            newFocus: changeAccounttingUnitMenuItem.handle,
            action: MENU_ACTION
        })))
    }

    addListener(CLICK, closeSessionRef, e => { window.location.reload() })

    addListener(CLICK, collapseSidebarRef, doClickCollapseSidebar)
    function doClickCollapseSidebar() {
        const sidebarElement = document.getElementById("sidebar");
        sidebarElement.classList.toggle("collapsed");

        sidebarElement.addEventListener("transitionend", () => {
            window.dispatchEvent(new Event("resize"));
        })
    }

    addListener(CLICK, loadMailRef, e => {
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: currentFocusId,
            newFocus: loadMailsMenuItem.handle,
            action: MENU_ACTION
        })))
    })

    addListener(CLICK, mailNotificationRef, e => {
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: currentFocusId,
            newFocus: mailNotifiactionsMenuItem.handle,
            action: MENU_ACTION
        })))
    })

    addListener(CLICK, mailNotificationRef)

    function renderUserDropDown() {
        if (changeUserMenuItem == undefined || changeAccounttingUnitMenuItem == undefined)
            return ""

        return (
            <div className="nav-item dropdown" >
                <a className="nav-link dropdown-toggle webtop-dropdown-items" href="#" data-bs-toggle="dropdown" tabIndex={-1}>
                    <span className="pe-1"><i className="fas fa-user me-1" /><span className='text-dark'>{getUserName() + " (" + getUcje() + ")"}</span></span>
                </a>

                <ul className="dropdown-menu dropdown-menu-end">
                    <li><a className={"dropdown-item" + (changeUserMenuItem.sensitivity === false ? " disabled" : "")} href="#" ref={changeUserRef}>{textWithMnemonic(changeUserMenuItem.label, false)}</a></li>
                    <li><a className={"dropdown-item" + (changeAccounttingUnitMenuItem.sensitivity === false ? " disabled" : "")} href="#" ref={changeAccountingUnitRef}>{textWithMnemonic(changeAccounttingUnitMenuItem.label, false)}</a></li>
                    <div className="dropdown-divider" />
                    <li><a className="dropdown-item" href="#" ref={closeSessionRef}>{closeSessionMenuItem.name}</a></li>
                </ul>
            </div>)
    }

    function renderMessages() {
        return (<li className="nav-item dropdown">
            <a className="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown" tabIndex={-1}>
                <div className="position-relative">
                    <i className="fas fa-envelope" />
                </div>
            </a >
            <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-end"
                aria-labelledby="alertsDropdown">
                <li><a className="dropdown-item" ref={loadMailRef} href="#" >{textWithMnemonic(loadMailsMenuItem.label, false)}</a></li>
                <li><a className="dropdown-item" ref={mailNotificationRef} href="#">{textWithMnemonic(mailNotifiactionsMenuItem.label, false)}</a></li>
            </ul>
        </li>)
    }

    function Notification(props) {
        const { notification } = props
        const [timeBefore, setTimeBefore] = React.useState(formatTime(Date.now() - notification.timestamp))

        React.useEffect(() => {
            const action = setInterval(() => {
                setTimeBefore(formatTime(Date.now() - notification.timestamp))
            }, 1000 * 30)

            return () => { clearInterval(action) }
        }, [])

        function formatTime(milliseconds) {
            if (milliseconds < 0) {
                return 'Čas nelze zobrazit'
            }

            const seconds = Math.floor(milliseconds / 1000)
            const minutes = Math.floor(seconds / 60)
            const hours = Math.floor(minutes / 60)

            const remainingMinutes = minutes % 60
            const remainingSeconds = seconds % 60

            const formattedTime = []

            if (hours > 0) {
                formattedTime.push(`${hours} h`)
            }

            if (remainingMinutes > 0) {
                formattedTime.push(`${remainingMinutes} m`)
            }

            if (remainingSeconds > 0) {
                formattedTime.push(`${remainingSeconds} s`)
            }

            return formattedTime.length > 0 ? "před " + formattedTime.join(', ') : '0s'
        }

        let iconClassName
        switch (notification.type) {
            case 1:
                iconClassName = "fas fa-comment-alt text-primary"
                break
            case 2:
                iconClassName = "fas fa-check text-success"
                break
            case 3:
                iconClassName = "fas fa-bell text-warning"
                break
            case 4:
                iconClassName = "fas fa-exclamation-triangle text-danger"
                break
            default:
                break
        }

        return <a key={"notification_bell_" + notification.requestId} href="#" className="list-group-item">
            <div className="row g-0 align-items-center">
                <div className="col-2">
                    <i className={iconClassName} style={{ fontSize: "1.5rem" }}></i>
                </div>
                <div className="col-10">
                    {/*<div className="text-dark">Update completed</div>*/}
                    <div id={"dropdown_notification_" + notification.requestId} className="text-muted small mt-1" style={{ userSelect: 'all' }}>{notification.message}</div>
                    <div className="text-muted small mt-1" style={{ textAlign: "right" }}>{timeBefore}</div>
                </div>
            </div>
        </a>
    }

    function Notifications(props) {
        const refDropdown = React.useRef({})

        React.useEffect(() => {
            const controller = new AbortController()
            refDropdown.current.addEventListener("hide.bs.dropdown", e => {
                if (e.clickEvent?.srcElement?.id?.includes("dropdown_notification_")) {
                    e.preventDefault()
                    e.stopPropagation()
                }
            }, { signal: controller.signal })

            return () => {
                controller.abort()
            }
        }, [])

        return (<li ref={refDropdown} className="nav-item dropdown">
            <a className="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown" tabIndex={-1}>
                <div className="position-relative">
                    <i className="fas fa-bell" />
                    {/*<span className="indicator">4</span>*/}
                </div>
            </a>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                aria-labelledby="alertsDropdown"
                style={{
                    overflowY: "auto",
                    maxHeight: "30rem"
                }}>
                <div className="list-group">
                    {(() => {
                        if (notifications.length === 0)
                            return <div key={"notification_bell_no_items"} className="dropdown-menu-header">
                                0 Notifikací
                            </div>

                        return <React.Fragment>
                            <div key={"notification_bell_no_items"} className="dropdown-menu-header" onClick={e => {
                                dispatch(removeNotifications())
                            }}>
                                Smazat
                            </div>

                            {notifications.map(notification => <Notification
                                key={"notification_li" + notification.requestId}
                                notification={notification} />)}
                        </React.Fragment>


                    })()}
                </div>
            </div>
        </li>)
    }

    function ScreenSwitch() {
        if (!props.existActiveTab)
            return

        const dropdownItemStyle = {
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center'
        }

        const iconStyle = { fontSize: "1.5rem" }

        return <div className="dropdown">
            <a className="nav-icon dropdown-toggle webtop-dropdown-items" href="#" id="pages" data-bs-toggle="dropdown" tabIndex={-1}>
                <i className="fas fa-grip-vertical"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end" style={{ "--bs-dropdown-padding-y": 0 }}>
                {props.dashboardVisibility ? <li>
                    <div className="dropdown-item" style={dropdownItemStyle} onClick={e => {
                        dispatch(onValueChange(constants.InternalElementState.DASHBOARD_VISIBILITY, false))
                    }}>
                        <i className="fas fa-home" style={iconStyle}></i>
                        <div>Sezení</div>
                    </div>
                </li> : <li>
                    <div className="dropdown-item" style={dropdownItemStyle} onClick={e => {
                        dispatch(onValueChange(constants.InternalElementState.DASHBOARD_VISIBILITY, true))
                    }}>
                        <i className="fas fa-chart-line" style={iconStyle}></i>
                        <div>Dashboard</div>
                    </div>
                </li>}
            </ul>
        </div>
    }


  // smacknuta klavesa
  // const handleKeyDown = useCallback((event) => {
  //   const key = event.key.toUpperCase();
  //   if (key === 'K') setCalculatorVisibility(true);
  // });

  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [handleKeyDown]);

    function Calculations(props) {
      return (
        <div>
          <li 
            className="nav-item"
            >
            <a 
              className="nav-icon " 
              href="#" 
              id="calcGo" 
              tabIndex={-1}
              >
              <div 
                className="position-relative"
                onClick=
                  {
                    e => 
                      {
                        setCalculatorVisibility(true);
                      }
                  }
                >
                <i className="fas fa-calculator" />
              </div>
            </a>
          </li>

          {calculatorVisibility && (
            <Calculator
              id="navigationCalculator"
              setCalculatorVisibility={setCalculatorVisibility}
              inputValue="0"
              disableInsertButton={true}
              />
            )
          }
        </div>

      )        
    }


    logRender("Navigation")
    logRenderProps(props)
    return (
        <nav id={"navigation"} ref={refNavigation} className="navbar navbar-expand navbar-light navbar-bg" style={{
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
        }}>
            <a className="sidebar-toggle d-flex" ref={collapseSidebarRef}>
                <i className="hamburger align-self-center"></i>
            </a>

            <SearchResults key={"search_results"} />

            {/*<button onClick={e => {
                for (let i = 0; i < 100; i++) {
                    setTimeout(() => {
                        console.log("DO: ", i)
                        test("click")
                    }, i * 100); // i * 1000 gives us a pause of 1 second between each iteration
                }
            }}>test</button>*/}

            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">

                    <Calculations />

                    <ScreenSwitch />

                    <Notifications />

                    <SettingsDropdown />

                    {renderMessages()}

                    <div className='vr'></div>

                    {renderUserDropDown()}
                </ul >
            </div >
        </nav >
    );
}

function mapStateToProps(state) {
    return {
        changeUserMenuItem: state.desktopMenu[CHANGE_USER],
        changeAccounttingUnitMenuItem: state.desktopMenu[CHANGE_ACCOUNTING_UNIT],
        closeSessionMenuItem: state.app.menuItems[CLOSE_SESSION],
        loadMailsMenuItem: state.desktopMenu[LOAD_MAILS],
        mailNotifiactionsMenuItem: state.desktopMenu[MAILS_NOTIFICATIONS],
        notifications: state.notifications,
        existActiveTab: (Object.keys(state.desktopSession).length > 1),
        dashboardVisibility: state.dashboardVisibility
    }
}

export default connect(mapStateToProps)(Navigation)