import { original, produce } from 'immer'

import Constants from '../../constants/constants'
import constants from '../../constants/constants'

const { SpecialId, LocalStorageKeys, InternalElementState } = Constants
const { CLOSE_PROGRAM } = Constants.MenuActions

// oznac polozku navigator menu jako otevrenou nebo zavrenou - vykresli sipku u ni
export const markNavigatorMenuItemOpenImmer = produce((draft, navLabel) => {

    //oznaci vsechny menu items jako zavreny
    for (const [key, value] of Object.entries(original(draft))) {
        if (value.isOpen && (value.isMenu === false || value.isMenu === undefined)) //nebo ten program (value.isMenu === undefined) byl nejdříve spuštěný z globálního vyhledávání, tedy neměl všechno ve storu vyplněné jako klasicky spusteny program z navigatoru
            draft[key].isOpen = false
    }

    if (navLabel === "" || navLabel === undefined || navLabel === null)
        return draft

    //nezacina zacina cislem
    if (!navLabel.substring(0, 1).match(/^\d/))
        return draft

    let level = navLabel.substr(0, navLabel.indexOf('. '))
    if (draft[level] == undefined) {
        draft[level] = {
            isOpen: true
        }
    } else {
        draft[level].isOpen = true
    } return draft
})

export const processMenuItemsImmer = produce((draft, menuItems) => {
    //const startTime = performance.now()
    menuItems?.forEach(item => {
        Object.keys(item).forEach((componentName) => {
            //console.log("ITEM", item)
            if (componentName === "SubMenu" || componentName === "MenuItem") {

                let component = {
                    ...item[componentName],
                    childrens: []
                }
                //console.log(component)
                function acceleratorToKey() {
                    let updAccelerators = null
                    //pro menu item "zavrit (46)" - prevod accelerators
                    if (component.handle == CLOSE_PROGRAM) { //close program                    
                        if (component.accelerator == "Esc") updAccelerators = { ...updAccelerators, accelerator: "Escape" }
                        if (component.accelerator2 == "Esc") updAccelerators = { ...updAccelerators, accelerator2: "Escape" }
                        if (component.accelerator3 == "Esc") updAccelerators = { ...updAccelerators, accelerator3: "Escape" }

                        if (component.accelerator == "BackSpace") updAccelerators = { ...updAccelerators, accelerator: "Backspace" }
                        if (component.accelerator2 == "BackSpace") updAccelerators = { ...updAccelerators, accelerator2: "Backspace" }
                        if (component.accelerator3 == "BackSpace") updAccelerators = { ...updAccelerators, accelerator3: "Backspace" }
                    }

                    return updAccelerators
                }

                if (draft[component.handle] === undefined) {
                    //NEW
                    draft[component.handle] = {
                        ...component,
                        ...acceleratorToKey()
                    }
                } else {
                    if (component.label === "") {
                        //DELETE
                        delete draft[component.handle]
                    } else {
                        //UPDATE
                        draft[component.handle] = {
                            ...draft[component.handle],
                            ...component,
                            ...acceleratorToKey()
                        }
                    }
                }
            }
        })
    })

    //console.log(`0 took ${(performance.now() - startTime)}ms`)


    //nastaveni property childrens - slouzi pro snadnejsi vykresleni
    //1. vynulovani
    Object.keys(draft).forEach((key) => {
        let menuItem = draft[key]
        if (menuItem.parentHandle !== undefined)
            draft[menuItem.parentHandle].childrens = []
    })

    //console.log(`1 took ${(performance.now() - startTime)}ms`)


    //2. naplneni
    Object.keys(draft).forEach((key) => {
        let menuItem = draft[key]
        if (menuItem.parentHandle !== undefined)
            draft[menuItem.parentHandle].childrens.push({ id: menuItem.handle, order: menuItem.order })
    })

    //console.log(`2 took ${(performance.now() - startTime)}ms`)


    //3. setrideni podle pole order
    Object.keys(draft).forEach((key) => {
        let menuItem = draft[key]
        if (menuItem.parentHandle !== undefined)
            draft[menuItem.parentHandle].childrens.sort(function (a, b) { return parseFloat(a.order) - parseFloat(b.order) })
    })
    //console.log(`3 took ${(performance.now() - startTime)}ms`)


    return draft
})

export const processScreenComponentsImmer = produce((draft, components, tabId, maskId, tabName) => {
    //console.log("processScreenComponentsImmer", tabId, draft.dashboardVisibility)
    if (tabId > 0 && draft.dashboardVisibility)
        draft.dashboardVisibility = false

    components?.forEach(item => {
        Object.keys(item).forEach((componentName) => {
            //state = processScreenComponent(currentTabId, screenLabel, currentMaskID, componentName, item[componentName], state)
            let component = item[componentName]

            //Pokud jsou vraceny framy "Kontakty" / "O aplikaci" - znamená to, že login proběhl vpořádku
            if (component.specialId === SpecialId.CONTACT || component.specialId === SpecialId.ABOUT) {
                //visibility se bude nastavovat rucne
                component.visible = false
                draft.ws.loggedIn = true
            } else if (component.specialId === SpecialId.USERNAME_HIDDEN_INPUT && component.value != "") {
                localStorage.setItem(LocalStorageKeys.DEFAULT_USERNAME, component.value.substring(0, component.value.indexOf("[")).trimRight())
            }

            //console.log("DRAFT: ", JSON.stringify(draft, null, 2))
            //console.log("-----------------------------------> COMPONENT", component.handle, component, tabId, maskId, JSON.stringify(draft.desktopSession[tabId][maskId][0].childIds, null, 2))
            //4.19.21.7
            let item2Mod
            if (draft.desktopSession[tabId] !== undefined && draft.desktopSession[tabId][maskId] !== undefined)
                item2Mod = draft.desktopSession[tabId][maskId][component.handle]

            if (item2Mod === undefined) {
                if (component.handle !== undefined) {
                    //NEW COMPONENT

                    //console.log("NEW COMPONENT", component.handle, "--->", component.parentHandle, component)

                    //smazany se nepridava
                    if (component.dropFrame)
                        return

                    //pridani do childids
                    if (component.parentHandle == undefined) {
                        component.parentHandle = 0

                        //vyjimka pro element inputFieldElement, ktery je soucasti browse a nema parentHandle, tak se prida
                        if (componentName === "InputFieldElement")
                            component.parentHandle = component.browseId
                    }

                    /*
                    vlozeni do childIds
                        - muze nastat ze je vracen element, ktery ma smazaneho rodice, proto je zde podminka na existenci rodice "if (component.parentHandle !== undefined)"*/
                    //console.log("vkladani", component.parentHandle, draft.desktopSession[tabId][maskId][component.parentHandle], component)
                    if (draft.desktopSession[tabId][maskId][component.parentHandle] !== undefined) {
                        draft.desktopSession[tabId][maskId][component.parentHandle].childIds = draft.desktopSession[tabId][maskId][component.parentHandle].childIds.concat(component.handle)
                    }


                    //nastaveni poctu radku browsu - nenastavuje se pro browse pro vyber ucje
                    if (componentName === "BrowseElement") {
                        let ucjeBrowse = false
                        components.forEach(cmpt => {
                            if (cmpt[Object.keys(cmpt)].specialId === Constants.SpecialId.UCJE_SELECTION)
                                ucjeBrowse = true
                        })

                        //nastaveni defaultniho poctu radku
                        let browseHeight = 10
                        if (!ucjeBrowse)
                            browseHeight = 0

                        component = {
                            ...component,
                            sizePerPage: browseHeight
                        }
                    }

                    //component
                    draft.desktopSession[tabId][maskId][component.handle] = {
                        id: component.handle,
                        childIds: [],
                        componentName,
                        data: component,
                        tabId,
                        maskId
                    }

                    //podpora sidelabels - labely, ktere jsou spojeny s input polem a pridava se jim automaticky znak ":"
                    // pridava se parametr isLabel4InputFieldElement - pokud je true, zarovnava se doprava
                    if (component.sideLabelHandle !== undefined) {
                        draft.desktopSession[tabId][maskId][component.sideLabelHandle].data.caption = draft.desktopSession[tabId][maskId][component.sideLabelHandle].data.caption + ":"
                        draft.desktopSession[tabId][maskId][component.sideLabelHandle].data.isLabel4InputFieldElement = true
                    }
                }
            } else {
                //UPDATE / DELETE COMPONENT
                //console.log("UPDATE / DELETE COMPONENTE", component.handle, "--->", component.parentHandle, component)
                function deleteElement() {
                    draft.desktopSession[tabId][maskId][component.handle].childIds.forEach(child => {
                        // smazani potomku
                        delete draft.desktopSession[tabId][maskId][child]
                    })
                    // smazani componenty
                    delete draft.desktopSession[tabId][maskId][component.handle]

                    //smazani reference v childIds u predka
                    for (var parentId of Object.keys(draft.desktopSession[tabId][maskId])) {
                        if (draft.desktopSession[tabId][maskId][parentId] !== null
                            && draft.desktopSession[tabId][maskId][parentId].childIds != undefined
                            && draft.desktopSession[tabId][maskId][parentId].childIds.includes(component.handle)) {
                            draft.desktopSession[tabId][maskId][parentId].childIds = draft.desktopSession[tabId][maskId][parentId].childIds.filter(val => val != component.handle)
                        }
                    }

                    return draft
                }

                if (componentName === "FrameElement" || componentName === "ModalDialogElement" || componentName === "DialogElement") {
                    if (component.dropFrame)
                        return deleteElement()
                }

                //vyjimka pro element inputFieldElement, ktery je soucasti browse tak se updatuje hodnota
                let pHandle = component.parentHandle
                if (pHandle === undefined)
                    pHandle = draft.desktopSession[tabId][maskId][component.handle].data.parentHandle

                let pBrowseHandle = draft.desktopSession[tabId][maskId][component.handle].data.browseId
                if (componentName === "InputFieldElement" && pHandle !== undefined && pBrowseHandle !== undefined && pHandle === pBrowseHandle) {
                    let rowId = draft.desktopSession[tabId][maskId][pHandle].data.rowId
                    let indexOfRow = draft.desktopSession[tabId][maskId][pHandle].data.browseData?.findIndex(element => element.rowId === rowId)
                    if (draft.desktopSession[tabId][maskId][pHandle].data.browseData && draft.desktopSession[tabId][maskId][pHandle].data.browseData[indexOfRow]) {

                        let indexOfColumn = draft.desktopSession[tabId][maskId][pHandle].data.browseData[indexOfRow].columns.findIndex(element => element.handle === component.handle)
                        if (indexOfColumn > -1) {
                            if (component.preValue !== undefined) {
                                //nalezeni predchoziho radku - nelze pouzit prvek nad (u posledniho radku browsu je vracena preValue i value)
                                let previousRowId = original(draft.desktopSession[tabId][maskId])[pHandle].data.rowId
                                let previousIndexOfRow = original(draft.desktopSession[tabId][maskId])[pHandle].data.browseData?.findIndex(element => element.rowId === previousRowId)

                                //upraveni predchoziho oznaceneho radku radku dle prevVal
                                if (draft.desktopSession[tabId][maskId][pHandle].data.browseData[previousIndexOfRow]) {
                                    draft.desktopSession[tabId][maskId][pHandle].data.browseData[previousIndexOfRow].columns[indexOfColumn].value = component.preValue
                                }
                            } else {
                                if (component.value !== undefined)
                                    draft.desktopSession[tabId][maskId][pHandle].data.browseData[indexOfRow].columns[indexOfColumn].value = component.value
                            }
                        }

                        //nastaveni internal state v InputFieldElement jako castecne oznaceny
                        if (draft.desktopSession[tabId][maskId][component.handle].data.specialId === SpecialId.CHECKBOX) {
                            if (component.value === "*" || component.preValue === "*")
                                draft.desktopSession[tabId][maskId][component.handle].data.internalState = InternalElementState.PARTLY_CHECKED
                        }
                    }
                }

                /*pokud jsou zmeneno poradi "browseColsSorted", tak je nastaven refresh aby se srovnaly soupce v "browseData" dle "browseColsSorted"*/
                if (componentName === "BrowseElement") {
                    let bHandle = draft.desktopSession[tabId][maskId][component.handle].data.handle
                    if (bHandle !== undefined) {
                        let browseOriginBrowseColsSorted = draft.desktopSession[tabId][maskId][bHandle].data.browseColsSorted
                        let browseNewBrowseColsSorted = component.browseColsSorted
                        if (browseNewBrowseColsSorted !== undefined && browseOriginBrowseColsSorted !== browseNewBrowseColsSorted)
                            draft.desktopSession[tabId][maskId][bHandle].data.refresh = true
                    }
                }


                /*Desktop server - nastavuje uz u existujiciho elementu v tride "cz.apso.desktop.server.pool.Communicator.java" pokud je hondota null height = 1, widht = 1,
                 coz je spatne, protoze zmena velikosti po vykresleni muze nastat jenom u virtualnich framu (58,65,66,67)*/
                if (!(draft.desktopSession[tabId][maskId][component.handle].data.specialId === constants.SpecialId.ZOOM_FRAME
                    || draft.desktopSession[tabId][maskId][component.handle].data.specialId === constants.SpecialId.HEADER_FRAME
                    || draft.desktopSession[tabId][maskId][component.handle].data.specialId === constants.SpecialId.BROWSE_FRAME
                    || draft.desktopSession[tabId][maskId][component.handle].data.specialId === constants.SpecialId.RIGHT_FRAME)) {
                    delete component['width']
                    delete component['height']
                }

                //element neni v childIds, nutny update a smazani u prechoziho predka
                //console.log("------> TEST IN ", component.handle, JSON.stringify(draft.desktopSession[tabId][maskId][pHandle], null, 2))
                if (!draft.desktopSession[tabId][maskId][pHandle].childIds.includes(component.handle)) {

                    let previousParentHandle = draft.desktopSession[tabId][maskId][component.handle].data.parentHandle
                    let previousParentChildIds = draft.desktopSession[tabId][maskId][previousParentHandle].childIds
                    //console.log("PARENT HANDLE", previousParentHandle)
                    //console.log("TO REMOVE", component.handle)
                    //console.log("PREVIOUS ARRAY", JSON.stringify(previousParentChildIds, null, 2))

                    //prechozy prvek
                    const newArray = previousParentChildIds.filter(item => item !== component.handle)
                    draft.desktopSession[tabId][maskId][previousParentHandle].childIds = newArray

                    //soucasny predek              
                    draft.desktopSession[tabId][maskId][pHandle].childIds = draft.desktopSession[tabId][maskId][pHandle].childIds.concat(component.handle)
                }

                //pokud se zmeni uploadTarget, tak se maze puvodni nactena data o souboru
                if (componentName === "InputFieldElement" && component.uploadTarget) {
                    if (draft.desktopSession[tabId][maskId][component.handle].data.uploadTarget !== component.uploadTarget) {
                        component.value = ""
                        component.fileSize = 0
                        component.base64 = []
                    }
                }

                draft.desktopSession[tabId][maskId][component.handle].data = {
                    ...draft.desktopSession[tabId][maskId][component.handle].data,
                    ...component
                }
            }
        })
    })

    return draft
})

/* update bunky browsu z inputfieldElementu */
export const updateBrowseInputFieldElementImmer = produce((draft, tabId, maskId, componentId, newValue) => {
    draft.desktopSession[tabId][maskId][componentId].data.value = newValue

    let browseId = draft.desktopSession[tabId][maskId][componentId].data.parentHandle
    if (draft.desktopSession[tabId][maskId][browseId].data.browseData !== undefined) {
        let browseRowId = draft.desktopSession[tabId][maskId][browseId].data.rowId
        draft.desktopSession[tabId][maskId][browseId].data.browseData.forEach(row => {
            if (row.rowId === browseRowId) {
                row.columns.forEach(column => {
                    if (column.handle === componentId) {
                        //console.log(componentId, "UPDATE BUNKY z", column.value, "na", newValue)
                        if (newValue !== undefined)
                            column.value = newValue
                    }
                })
            }
        })
    }

    return draft
})
/*
pokud jsou vracen InputFieldElement, ktery je soucasti browsu, tak se musi aktualizovat i v datech browsu
- lze aktualizovat pouze InputFieldElement, ktery uz byl nacten pomoci akce GET_BROWSE_SCREEN_DATA_REQUEST a ma uz "rowId", InputFieldElement neobsahuje "rowId"
*/
export const updateBrowseDataImmer = produce((draft, components, tabId, maskId, requestRowId) => {
    //console.log("updateBrowseDataImmer components", components, "components", requestRowId)
    //vyber vsech InputFieldElement, ktere jsou soucasti browsu na kterem je focus
    let recordAvailable = true
    let inputFieldElements = {}
    let browseIds = []
    let storeVal
    components?.forEach((item, index) => {
        Object.keys(item).forEach((componentName) => {
            //Dialog - záznam již není k dispozici.Přehled bude aktualizován
            if (componentName === "ModalDialogElement")
                if (item[componentName].specialId == SpecialId.DIALOG_MESSAGE)
                    recordAvailable = false

            if (componentName === "InputFieldElement") {
                storeVal = draft.desktopSession[tabId][maskId][item[componentName].handle].data
                if (storeVal.browseId !== undefined) {
                    if (inputFieldElements[storeVal.browseId] == undefined) {
                        browseIds.push(storeVal.browseId)
                        inputFieldElements = {
                            ...inputFieldElements,
                            [storeVal.browseId]: []
                        }
                    }

                    let inputFieldElementProperties = {
                        value: storeVal.value,
                        ...item[componentName]
                    }

                    inputFieldElements[storeVal.browseId].push(inputFieldElementProperties)
                }
            }
        })
    })

    //UPDATE BUNKY - nova hodnota v InputuFieldElementu
    if (requestRowId === undefined) {
        browseIds.forEach(browseId => {
            if (draft.desktopSession[tabId][maskId][browseId].data.browseData !== undefined) {
                let browseRowId = draft.desktopSession[tabId][maskId][browseId].data.rowId
                draft.desktopSession[tabId][maskId][browseId].data.browseData.forEach(row => {
                    if (row.rowId === browseRowId) {
                        row.columns.forEach(column => {
                            if (storeVal.browseId !== undefined) {
                                inputFieldElements[storeVal.browseId].forEach(newColumn => {
                                    if (column.handle === newColumn.handle) {
                                        //console.log(newColumn.handle, "UPDATE BUNKY z", column.value, "na", newColumn.value, newColumn)
                                        if (newColumn.value !== undefined)
                                            column.value = newColumn.value
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    //UPDATE OZNACENYCH RADKU
    browseIds.forEach(browseId => {
        //console.log("BROWSE", browseId)
        inputFieldElements[browseId].forEach(iField => {
            if (draft.desktopSession[tabId][maskId][browseId].data.browseData !== undefined) {
                draft.desktopSession[tabId][maskId][browseId].data.browseData = [
                    ...draft.desktopSession[tabId][maskId][browseId].data.browseData.map(browseRow => {

                        // pro predchozy radek se ulozi oznaceni preValue
                        if (browseRow.rowId === requestRowId) {
                            let columns = browseRow.columns.map(a_item => {
                                if (a_item.handle === iField.handle) {
                                    if (iField.preValue !== undefined) {
                                        return {
                                            ...a_item,
                                            value: iField.preValue
                                        }
                                    }
                                }
                                return a_item
                            })

                            return {
                                ...browseRow,
                                columns
                            }
                        }
                        return browseRow
                    })
                ]
            }
        })
    })

    return draft
})

export const changeFocusArea = produce((draft, newFocusArea) => {
    //console.log("changeFocusArea", newFocusArea, tabId, maskId, focusElement)

    draft.focusArea = newFocusArea
    draft.browseHasFocus = false

    /*if (draft.desktopSession[tabId] !== undefined
        && draft.desktopSession[tabId][maskId] !== undefined
        && draft.desktopSession[tabId][maskId][focusElement] !== undefined)
        draft.desktopSession[tabId][maskId][focusElement].data.hasFocus = false*/

    return draft
})

export const resizeBrowseColumns = produce((draft, data) => {
    //console.log("resizeBrowseColumns", data)
    let { tabId, maskId, columnsOrder, browseColumnsWidth } = data
    columnsOrder.forEach((columnId, index) => draft.desktopSession[tabId][maskId][columnId].data.inTableWidth = browseColumnsWidth[index])

    return draft
})

export const updateHistoryMenu = produce((draft, data) => {
    Object.keys(draft.desktopHistoryMenu).forEach((key) => {
        if (data.rowItemId === draft.desktopHistoryMenu[key].rowItemId)
            draft.desktopHistoryMenu[key].isUserFocused = data.isUserFocused
        else
            draft.desktopHistoryMenu[key].isUserFocused = false
    })
    return draft
})

export const updateFavoritesMenu = produce((draft, data) => {
    Object.keys(draft.desktopFavoritesMenu).forEach((key) => {
        if (data.rowItemId === draft.desktopFavoritesMenu[key].rowItemId)
            draft.desktopFavoritesMenu[key].isUserFocused = data.isUserFocused
        else
            draft.desktopFavoritesMenu[key].isUserFocused = false
    })
    return draft
})
