import React from 'react'
import { connect } from 'react-redux'

import Constants from '../constants/constants'
import Styles from '../constants/styles'
import useStoreSubscription from '../hooks/useStoreSubscription'

import Navbar from '../components/Navbar'
import InputFieldElement from './desktop_server/InputFieldElement'
import SelectionElement from './desktop_server/SelectionElement'
import RadioSetElement from './desktop_server/RadioSetElement'
import ButtonElement from './desktop_server/ButtonElement'
import LabelElement from './desktop_server/LabelElement'
import Browse from './browse/Browse'
import ModalDialogElement from './desktop_server/ModalDialogElement'
import FrameElementXY from './desktop_server/FrameElementXY'
import EditorElement from './desktop_server/EditorElement'
import ToggleBoxElement from './desktop_server/ToggleBoxElement'
import ImageElement from './desktop_server/ImageElement'
import AccountUnitSelection from './special_frames/11_selection_account_unit'
import Login from './special_frames/13_login'
import RecordDetail from './special_frames/12_record_detail'
import ProgressBarDialog from './dialogs/ProgressBarDialog'

import BSTooltip from './BSTooltip'
import SessionScreen from './SessionScreen'

const { CLOSE_FRAME_BTN } = Constants.CloseButtons

function SessionNode(parent) {
    const { data, childIds, desktopSessionMask } = parent
    const { SpecialId, screenWidth } = Constants

    let ws, currentTabId, currentMaskID, desktopMenu
    useStoreSubscription(store => {
        ws = store.ws
        currentTabId = ws.currentTabId
        currentMaskID = ws.currentMaskID
        desktopMenu = store.desktopMenu
        //desktopSessionMask = store.desktopSession[currentTabId][currentMaskID]
    })

    /*React.useEffect(() => {
        console.log("MOUNT -> SessionNode", parent)
        return () => {
            console.log("UNMOUNT -> SessionNode", parent)
        }
    }, [])*/

    function renderChilds(childId, previousElement) {
        const { id } = parent
        //console.log("renderChilds", childId)
        //console.log(" -> parent", parent)
        return (<ConnectedNode key={"ConnectedNode" + childId} id={childId} parentId={id} previousElement={previousElement} />)
    }

    //logRender('SessionNode')
    ///logRenderProps(parent)

    //nema elementy
    if (childIds === undefined)
        return null

    //schovane elementy
    if (data.layerOrder === -1 || data.visible === false)
        return null

    //setridit podle atributu y
    //console.log("desktopSessionMask", desktopSessionMask)
    let childIdsByY = [...childIds]
    childIdsByY.sort((a, b) => {
        //console.log("A: ", a, " B: ", b, "childIds", childIdsByY)
        //console.log("a: ", desktopSessionMask[a], "b: ", desktopSessionMask[b])
        return parseFloat(desktopSessionMask[a].data.y) - parseFloat(desktopSessionMask[b].data.y)
    })

    //console.log("parent", parent.id, parent)
    //root
    if (parent.id === 0) {
        let previousElement

        function escButtonAndNavbar() {
            //root nem esc
            if (currentMaskID === 0 || currentTabId === 0)
                return

            //console.log("NEMA vlastni tlacitko")
            return <div style={{
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem'
            }}>
                <div className="btn-group" style={Styles.navBarClose}>
                    <Navbar key={"navbar_root"} />
                    <BSTooltip tooltiptext={desktopMenu[Constants.MenuActions.CLOSE_PROGRAM].tooltip} shortcuts={[desktopMenu[Constants.MenuActions.CLOSE_PROGRAM].accelerator, desktopMenu[Constants.MenuActions.CLOSE_PROGRAM].accelerator2, desktopMenu[Constants.MenuActions.CLOSE_PROGRAM].accelerator3]}>
                        <button id={CLOSE_FRAME_BTN} type="button" className="btn-close" aria-label="Close" />
                    </BSTooltip>
                </div>
            </div>
        }

        let hasFrame2Render = false
        if (currentTabId > 0 && currentMaskID > 0)
            parent.childIds.forEach(id => {
                //if (desktopSessionMask[id].data.specialId === undefined)
                hasFrame2Render = true
            })

        let style = {}
        if (!isSpecialFrameWithNoGrid()) {
            let width = screenWidth
            if (data.width)
                width = data.width

            style = {
                display: 'grid',
                gap: '0.2rem',
                gridTemplateColumns: 'repeat(' + width + ', 1fr)'
            }
        }

        function isSpecialFrameWithNoGrid() {
            let isSpecialFrameWithNoGrid = false
            childIdsByY.forEach(childId => {
                if (desktopSessionMask[childId].data.specialId === SpecialId.UCJE_SELECTION
                    || desktopSessionMask[childId].data.specialId === SpecialId.LOGIN)
                    isSpecialFrameWithNoGrid = true
            })
            return isSpecialFrameWithNoGrid
        }

        return (<div>
            {escButtonAndNavbar()}
            <SessionScreen tabId={currentTabId} maskId={currentMaskID} hasframe2render={hasFrame2Render} sessionHeight={parent.sessionHeight}>
                <div id={"fragment_" + parent.id} style={style}>
                    {childIdsByY.map(childId => (renderChilds(childId, desktopSessionMask[childId])))}
                </div>
            </SessionScreen>
        </div>
        )
    }

    //console.log("KOMPONENTY -> SESSION NODE: " + parent.componentName + " " + parent.id, parent)
    switch (parent.componentName) {
        case 'FrameElement': {
            //vyber ucetni jednotky
            if (data.specialId === SpecialId.UCJE_SELECTION)
                return <AccountUnitSelection key={"frm" + parent.id} {...parent} renderChild={renderChilds} />

            //karta zaznamu
            if (data.specialId === SpecialId.RECORD_DETAIL) {
                return <RecordDetail key={"frm" + parent.id} {...parent} renderChild={renderChilds} />
            }

            //prihlaseni
            if (data.specialId === SpecialId.LOGIN)
                return <Login key={"frm" + parent.id} {...parent} renderChild={renderChilds} />

            //uvodni obrazovka
            if (data.specialId === SpecialId.FIRST_SCREEN) {
                return <></>
            }

            //tlacitka pro browse -> home, end, pgUp, pgDown, +, - (vyklesluje se v Browse.js)
            if (data.specialId === SpecialId.BROWSE_BUTTONS)
                return <></>

            if (data.specialId === SpecialId.ABOUT || data.specialId === SpecialId.CONTACT)
                return <ModalDialogElement key={"md" + parent.id} {...parent} renderChild={renderChilds} />
            //return <AppMenuItemDialog {...parent} />

            return <FrameElementXY key={"frm" + parent.id} {...parent} renderChild={renderChilds} />
        }
        case 'ButtonElement':
            return <ButtonElement key={"btn" + parent.id} {...parent} />
        case 'ImageElement':
            return <ImageElement key={"img" + parent.id} {...parent} />
        case 'InputFieldElement':
            return <InputFieldElement key={"inp" + parent.id} {...parent} />
        case 'LabelElement':
            return <LabelElement key={"lbl" + parent.id} {...parent} />
        case 'RectangleElement':
            if (data.height === 1) {
                //horizontal
                //return <hr />
                return <div className='custom_divider_horizontal' />
            } else {
                //vertical
                return <div className='custom_divider_vertical' id={"vertical_line_" + parent.id} />
            }
        case 'ToggleBoxElement':
            return <ToggleBoxElement key={"toggleb" + parent.id} {...parent} />
        case 'EditorElement':
            return <EditorElement key={"editor" + parent.id} {...parent} />
        case 'ModalDialogElement':
            return <ModalDialogElement key={"md" + parent.id} {...parent} renderChild={renderChilds} />
        case 'DialogElement':
            if (data.specialId === SpecialId.LOADING_DIALOG) {
                return <ProgressBarDialog id={parent.id} key={"md" + parent.id} {...parent} />
            }
            return <ModalDialogElement key={"md" + parent.id} {...parent} renderChild={renderChilds} />
        case 'BrowseElement':
            return (<Browse key={"brw" + parent.id} {...parent} />)
        case 'SelectionElement':
            return (<SelectionElement key={"selection" + parent.id} {...parent} />)
        case 'ComboBoxElement':
            return (<SelectionElement key={"selection" + parent.id} {...parent} />)
        case 'RadioSetElement':
            return <RadioSetElement key={"radio" + parent.id} {...parent} />
        default: {
            return (
                <div id={"default" + parent.id} key={parent.id} style={{ border: "4px solid" }}>
                    <h5>
                        {"node: " + parent.componentName + " " + parent.id + "(x: " + desktopSessionMask[parent.id].data.x + " y: " + desktopSessionMask[parent.id].data.y + ")"}
                    </h5>
                    {doRendering()}
                </div>
            )
        }
    }

    function doRendering() {
        //console.log("doRendering")
        let frameContent = [];
        childIdsByY.map((item, index) => {
            frameContent.push(<div id={"r" + index} style={{ border: "4px solid" }} key={"r" + index} className="row">{renderChilds(item)}</div>)
        });

        return frameContent;
    }
}

function mapStateToProps(state, ownProps) {
    /*console.log("---->SESSION NODE: mapStateToProps " + ownProps.id)
    console.log("STATE", state)
    console.log("OWN PROPS", ownProps)
    console.log("------------------------------")*/

    let currentTabId = state.ws.currentTabId
    let maskId = state.ws.currentMaskID

    if (state.desktopSession[currentTabId] == undefined) {
        //console.log("RETURN 1: ", state.desktopSession[0][0][ownProps.id])
        return {
            ...state.desktopSession[0][0][ownProps.id],
            desktopSessionMask: state.desktopSession[currentTabId][maskId],
        }
    } else if (state.desktopSession[currentTabId][maskId] == undefined)
        while (maskId > 0) {
            if (state.desktopSession[currentTabId][maskId] != undefined) {
                //console.log("RETURN 2: ", state.desktopSession[currentTabId][maskId][ownProps.id])
                return {
                    ...state.desktopSession[currentTabId][maskId][ownProps.id],
                    desktopSessionMask: state.desktopSession[currentTabId][maskId],
                }
            }

            maskId--
        }
    else {
        //console.log("RETURN 3: ", state.desktopSession[currentTabId][maskId][ownProps.id])
        return {
            ...state.desktopSession[currentTabId][maskId][ownProps.id],
            desktopSessionMask: state.desktopSession[currentTabId][maskId],
            [Constants.InternalElementState.SESSION_HEIGHT]: state[Constants.InternalElementState.SESSION_HEIGHT]
        }
    }
}

const ConnectedNode = connect(mapStateToProps)(SessionNode)

export default ConnectedNode
