import React from 'react'
import { logRender, logRenderProps } from '../../utils/logging'

import { getIconProps } from './Icon'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import BrowseRow from './BrowseRow'

export default function BrowseBody(props) {
    const { childIds } = props

    const { selRowRef, data, displayContextMenu, columsWidth, tableElementRef } = props
    const { selectBrowseRow } = props
    const { browseData } = data

    let numberOfColumns = 0
    if (browseData != undefined && browseData.length > 0 && browseData[0].columns != undefined)
        numberOfColumns = browseData[0].columns.length

    let ws, currentFocusId, desktopSessionMask
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
        if (store.desktopSession[props.tabId])
            desktopSessionMask = store.desktopSession[props.tabId][props.maskId]
    })

    function isRowHighlighted(row, index) {
        if (row.rowId === data.rowId)
            return true
        else
            return false
    }

    let tableStyle = {
        //display: 'block',
        //width: '100%',
        tableLayout: 'fixed',
    }

    if (browseData == undefined)
        return null

    let existsColoredRowHandle
    childIds.forEach((columnId) => {
        let hColumn = props.desktopSessionMask[columnId].data
        if (hColumn.useBackgroundColor) {
            existsColoredRowHandle = hColumn.handle
        }
    })

    function getBackgroudColor(row) {
        //console.log("existsColoredRow: ", existsColoredRowHandle)
        //console.log(props.desktopSessionMask)
        if (props.browseRowColoredBackground === false)
            return

        let backgroundColor
        if (existsColoredRowHandle)
            row.columns.map((column, index) => {
                //console.log("browseListColumnIconID: ", existsColoredRowHandle, column.handle)
                if (existsColoredRowHandle === column.handle) {
                    let headerCol = props.desktopSessionMask[column.handle].data
                    if (headerCol.browseListColumnIconID != undefined && column.value !== "") {
                        let iconProps = getIconProps(column.value, headerCol)
                        backgroundColor = iconProps.backgroundColor
                    }
                }
            })

        return backgroundColor
    }

    logRender("BrowseBody ")
    logRenderProps(props)
    //console.log('%c render() -> BrowseBody ', 'color: #34eb65')
    //console.log(props)
    let columnOrder = props.desktopSessionMask[props.id].data.browseColsSorted.split(",")
    let columnsVisibility = []
    for (let i = 0; i < columnOrder.length; i++) {
        let columnHeader = props.desktopSessionMask[columnOrder[i]].data

        if ((columnHeader?.browseColVisible || columnHeader?.browseColVisible === undefined)) {
            columnsVisibility.push(true)
        } else {
            columnsVisibility.push(false)
        }
    }

    return (
        <tbody className="tbody" id={"tb" + data.id} key={"tb" + data.id} style={tableStyle}>
            {
                //row
                browseData.map((row, index) => {
                    let hasEditableColumn = false
                    if (isRowHighlighted(row))
                        row.columns.forEach(column => {
                            if (desktopSessionMask[column.handle].data.readOnly === false)
                                hasEditableColumn = true
                        })

                    return <BrowseRow
                        browse={props.desktopSessionMask[props.id]}
                        columnsVisibility={columnsVisibility}
                        columnsHeader={data.browseColsSorted.split(",")}
                        rowIndex={index}
                        key={row.rowId}
                        row={row}
                        selRowRef={selRowRef}
                        isRowHighlighted={isRowHighlighted(row)}
                        columsWidth={columsWidth}
                        displayContextMenu={displayContextMenu}
                        selectBrowseRow={selectBrowseRow}
                        backgroundColor={getBackgroudColor(row)}
                        header={childIds}
                        hasFocus={props.hasFocus}
                        hasEditableColumn={hasEditableColumn}
                        browseRowColoredBackground={props.browseRowColoredBackground}
                    />
                })
            }
        </tbody>
    )
}
