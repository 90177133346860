import React from 'react'
import PropTypes from 'prop-types'

import { getStore } from '..'
//import ApsoLogo from '../resources/images/ic_launcher.png'

export default function AppIcon(props) {
    const { width, height } = props
    return <img src="./ic_launcher.png" className="img-fluid rounded-circle" width={width} height={height} />
}

AppIcon.protoTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.string,
}