import React from 'react'
import { connect } from 'react-redux'

import { getThemeVariables } from '../../theme/getThemeVariables'
import { logRender, logRenderProps } from '../../utils/logging'

function LabelElement(props) {

    let textAlign = 'left'

    //pridano kvuli pismenu "/" mezi InputfieldElementy aby bylo na stredu(7.1)
    if (props.data?.caption?.trim() === "/")
        textAlign = 'center'

    //label, ktery je prirazen k InputFieldElementu s zarovna vzdy doprava
    if (props.data.isLabel4InputFieldElement === true)
        textAlign = 'right'

    //1-right 2-center 0-left
    if (props.data.align === 1)
        textAlign = 'right'
    else if (props.data.align === 2)
        textAlign = 'center'

    logRender("Label")
    logRenderProps(props)

    //<StyledLabel $textAlign={textAlign} $background={getThemeVariables().cardBodyColor}
    return (
        <label
            key={props.id}
            className="col-form-label-sm"
            style={{
                textAlign: textAlign,
                background: getThemeVariables().cardBodyColor,
                width: "100%"
            }}

        >{props.data.caption}</label>

    )
}

function mapStateToProps(state, ownProps) {
    return state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id]
}

export default connect(mapStateToProps)(LabelElement)
