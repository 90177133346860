import React from "react"
import { createPortal } from 'react-dom'
import { useStore } from 'react-redux'

import { logRender, logRenderProps } from "../../utils/logging"
import { getThemeVariables } from "../../theme/getThemeVariables"
import constants from "../../constants/constants"
import { addListener, LISTENER_TYPE } from "../../hooks/desktopListener"
import Styles from '../../constants/styles'
import styles from "../../constants/styles"


export default function KeyboardShortcutsDialog(props) {
    const store = useStore()
    const app = store.getState().app
    const { isKeyboardShortcutsDialogVisible, setKeyboardShortcutsDialogVisibility } = props

    if (!isKeyboardShortcutsDialogVisible)
        return

    const data = app.messageContainer[constants.MessageContainer.KEYBOARD_SHORTCUTS]
    const shortcuts = data.split(';');

    addListener(LISTENER_TYPE.KEYDOWN, document, e => {
        if (e.key === 'Escape')
            setKeyboardShortcutsDialogVisibility(false)
    })

    logRender("KeyboardShortcutsDialog")
    logRenderProps(props)
    return (createPortal(<div id="dialog-container">
        <div
            id="keyboar_shortcuts_dialog"
            aria-modal="true"
            style={Styles.dialogStyle}>
            <div className="modal-content blue_box" style={{ ...Styles.dialogContent, maxWidth: '50%' }} data-bs-backdrop="static">
                <div className="modal-header p-3">
                    <h4 className="modal-title">{app.messageContainer[constants.MessageContainer.KEYBOARD_SHORTCUTS_TITLE]}</h4>
                </div>
                <div className="modal-body p-3 ps-3">
                    <div className="container mb-2 text-start">

                        {shortcuts.map((shortCut, i) => {
                            let parts = shortCut.split(",")
                            let kdbs = []
                            if (parts[1] !== undefined) {
                                let keys = parts[1].split("+")
                                keys.forEach((key, index) => {
                                    if (index > 0)
                                        kdbs.push("+")

                                    kdbs.push(<span key={"shortcut_key_" + index} className="kbd">{key}</span>)
                                })
                            }

                            return <div key={"shortcut_row_" + i} className="row text-start">
                                <div className="col-8">{parts[0]}</div>
                                <div className="col-4">{kdbs}</div>
                            </div>
                        })}
                    </div>
                </div>
                <div className="modal-footer p-3 pb-3">
                    <button
                        type="button"
                        className="btn btn-primary"
                        style={styles.buttonStyle}
                        onClick={e => { setKeyboardShortcutsDialogVisibility(false) }}>{app.messageContainer["button.ok"]}</button>
                </div>
            </div>
        </div >
    </div>, document.body))
}


