import React from "react"

import { logRender, logRenderProps } from '../utils/logging'
import { isLightTheme } from '../app_actions'
import Constants from '../constants/constants'

const { LocalStorageKeys } = Constants

export default function ThemeSwither(props) {
    const [darkTheme, setDarkTheme] = React.useState(!isLightTheme())

    logRender("ThemeSwither")
    logRenderProps(props)
    return (
        <div className="d-flex">
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" checked={darkTheme} onChange={(e) => {
                    if (confirm(props.askRestartMessage)) {
                        setDarkTheme(e.target.checked)

                        localStorage.setItem(LocalStorageKeys.DARK_THEME, e.target.checked)
                        window.location.reload()
                    }
                }} />

                <label className="form-check-label">{props.label}</label>
            </div>
        </div>
    )
}
