import React from 'react'

import { logRender, logRenderProps } from '../../utils/logging'
import RecordActions from './RecordActions'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'
import constants from '../../constants/constants'
import { browseAction, KEY_END, KEY_HOME, KEY_PAGE_UP, KEY_PAGE_DOWN, KEY_UP, KEY_DOWN } from './browseAction'
import { WebSocketContext } from '../../services/WebSocket'

import BSTooltip from '../BSTooltip'
import styles from '../../constants/styles'

export const RESISIZE_BROWSE_INPUT = "resize_browse_input"

export default function BrowseBottom(props) {
    const { CLICK } = LISTENER_TYPE

    const homeRef = React.useRef()
    const endRef = React.useRef()
    const pageUpRef = React.useRef()
    const pageDownRef = React.useRef()
    const upRef = React.useRef()
    const downRef = React.useRef()

    const ws_context = React.useContext(WebSocketContext)

    const { hidePagination, sizePerPage, desktopMenu, ws, desktopSessionMask } = props
    const { setSizePerPage, pressHome, pressPageUp, pressUp, pressDown, pressPageDown } = props

    const btnStyle = "btn btn-primary ms-1"

    const [tempSizePerPage, setTempSizePerPage] = React.useState(sizePerPage)
    //nastaveni hodnoty z reduceru
    React.useEffect(() => {
        setTempSizePerPage(sizePerPage)
    }, [sizePerPage])


    if (hidePagination)
        return null

    addListener(CLICK, homeRef, (e) => browseAction(KEY_HOME, e, ws_context, props.id))
    addListener(CLICK, endRef, (e) => browseAction(KEY_END, e, ws_context, props.id))
    addListener(CLICK, pageUpRef, (e) => browseAction(KEY_PAGE_UP, e, ws_context, props.id))
    addListener(CLICK, pageDownRef, (e) => browseAction(KEY_PAGE_DOWN, e, ws_context, props.id))
    addListener(CLICK, upRef, (e) => browseAction(KEY_UP, e, ws_context, props.id))
    addListener(CLICK, downRef, (e) => { browseAction(KEY_DOWN, e, ws_context, props.id) })

    //TOOLTIP - neexistuje honota pro tooltip, v atributu help je hodnota browsu(trideni dle)
    function renderMoveButtons() {
        let moveButtons = []
        for (const [key, value] of Object.entries(desktopSessionMask)) {
            if (value.componentName === "FrameElement" && value.data.specialId === constants.SpecialId.BROWSE_BUTTONS && value.data.visible === true) {
                value.childIds.forEach(id => {
                    let btn = desktopSessionMask[id]
                    let key = "browse_bottom_btn_" + id
                    switch (btn.data.specialId) {
                        case constants.SpecialId.NAVIGATOR_HOME:
                            moveButtons.push(<BSTooltip key={"tooltip_home"} tooltiptext={btn.data.caption}>
                                <button disabled={!btn.data.sensitivity} key={key} tabIndex="-1" type="button" ref={homeRef} className={btnStyle} style={styles.buttonStyle}>
                                    <i className="far fa-arrow-from-bottom" />
                                </button></BSTooltip>)
                            break
                        case constants.SpecialId.NAVIGATOR_PAGE_UP:
                            moveButtons.push(<BSTooltip key={"tooltip_pg_up"} tooltiptext={btn.data.caption}>
                                <button disabled={!btn.data.sensitivity} key={key} tabIndex="-1" type="button" ref={pageUpRef} className={btnStyle} style={styles.buttonStyle}>
                                    <i className="far fa-chevron-double-up" />
                                </button>
                            </BSTooltip>)
                            break
                        case constants.SpecialId.NAVIGATOR_FORWARD:
                            moveButtons.push(<BSTooltip key={"tooltip_forward"} tooltiptext={btn.data.caption}>
                                <button disabled={!btn.data.sensitivity} key={key} tabIndex="-1" type="button" ref={downRef} className={btnStyle} style={styles.buttonStyle}>
                                    <i className="far fa-angle-down" />
                                </button>
                            </BSTooltip>)
                            break
                        case constants.SpecialId.NAVIGATOR_BACKWARD:
                            moveButtons.push(<BSTooltip key={"tooltip_backward"} tooltiptext={btn.data.caption}>
                                <button disabled={!btn.data.sensitivity} key={key} tabIndex="-1" type="button" ref={upRef} className={btnStyle} style={styles.buttonStyle}>
                                    <i className="far fa-angle-up" />
                                </button>
                            </BSTooltip>)
                            break
                        case constants.SpecialId.NAVIGATOR_PAGE_DOWN:
                            moveButtons.push(<BSTooltip key={"tooltip_pg_down"} tooltiptext={btn.data.caption}>
                                <button disabled={!btn.data.sensitivity} key={key} tabIndex="-1" type="button" ref={pageDownRef} className={btnStyle} style={styles.buttonStyle}>
                                    <i className="far fa-chevron-double-down" />
                                </button>
                            </BSTooltip>)
                            break
                        case constants.SpecialId.NAVIGATOR_END:
                            moveButtons.push(<BSTooltip key={"tooltip_end"} tooltiptext={btn.data.caption}>
                                <button disabled={!btn.data.sensitivity} key={key} tabIndex="-1" type="button" ref={endRef} className={btnStyle} style={styles.buttonStyle}>
                                    <i className="far fa-arrow-from-top" />
                                </button>
                            </BSTooltip>)
                            break
                        default:
                            break
                    }
                })
            }
        }

        return <div className="btn-group">
            {moveButtons}
        </div>
    }

    logRender("BrowseBottom")
    logRenderProps(props)
    //console.log("BrowseBottom", props)
    return (
        <div className="btn-toolbar justify-content-between mt-1">
            <RecordActions desktopMenu={desktopMenu} tabId={props.tabId} maskId={props.maskId} zoomFrameVisibility={props.zoomFrameVisibility} />

            <div className="btn-group">
                {/*<input
                    id={RESISIZE_BROWSE_INPUT}
                    className="form-control form-control-sm"
                    type="number"
                    min="1"
                    max="99"
                    maxLength="2"
                    pattern="\d{2}"
                    value={tempSizePerPage}
                    onKeyDown={e => {
                        if (e.key === "Enter" && e.target.value > 0)
                            setSizePerPage(e.target.value)
                    }}
                    onChange={e => {
                        if (e.target.value.length > 2)
                            setTempSizePerPage(e.target.value.slice(0, 2))
                        else
                            setTempSizePerPage(e.target.value)
                    }} />*/}
                {renderMoveButtons()}
            </div>
        </div>
    )
}

