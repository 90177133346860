import React from 'react'
import { useDispatch, connect } from 'react-redux'

import { focus2Sidebar, focusElement } from '../focus_actions'
import { addListenerWithRefHandler, LISTENER_TYPE } from '../hooks/desktopListener'
import { logRender, logRenderProps } from '../utils/logging'
import SideMenu, { MENU_MAIN, MENU_FAVORITE, MENU_HISTORY } from './SideMenu'
import { WebSocketContext } from '../services/WebSocket'
import { loadNavigatorDataFromRoot } from '../components/MenuItem'

import Constants from '../constants/constants'
import AppIcon from './AppIcon'
import { textWithMnemonic } from '../utils/screen'
import BSTooltip from './BSTooltip'

const { ACTION_GET_FAVORITES } = Constants.WebSocketActions;

function Sidebar(props) {
    const sidebarRef = React.useRef()
    const tabMainRef = React.useRef(null)
    const tabFavoriteRef = React.useRef(null)
    const tabHistoryRef = React.useRef(null)

    const dispatch = useDispatch()

    //let mainActive = document.getElementById(MENU_MAIN + "-tab").classList?.contains("active")
    //let favouriteActive = document.getElementById(MENU_FAVORITE + "-tab").classList?.contains("active")
    //let historyActive = document.getElementById(MENU_HISTORY + "-tab").classList?.contains("active")
    const [hasFocus, setFocus] = React.useState({
        [MENU_MAIN]: false,
        [MENU_FAVORITE]: false,
        [MENU_HISTORY]: false
    })

    const { messageContainer, dbSet } = props

    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, sendMessage } = ws_context


    const msgMain = messageContainer["menu.main.view.navigator.main"]?.split(";")
    const mainMenuLabel = textWithMnemonic(msgMain[0]), mainMenuShortcut = msgMain[1]

    const msgFavourites = messageContainer["menu.main.view.navigator.favourites"]?.split(";")
    const mainFavouritesLabel = textWithMnemonic(msgFavourites[0]), favouritesMenuShortcut = msgFavourites[1]

    const msgHistory = messageContainer["menu.main.view.navigator.history"]?.split(";")
    const mainhistoryLabel = textWithMnemonic(msgHistory[0]), historyMenuShortcut = msgHistory[1]
    /*const mainMenuLabel = messageContainer["window.navigator.main"], mainMenuShortcut = undefined
    const mainFavouritesLabel = messageContainer["window.navigator.favourites"], favouritesMenuShortcut = undefined
    const mainhistoryLabel = messageContainer["window.navigator.history"], historyMenuShortcut = undefined*/

    React.useEffect(() => {
        //sendMessage(createMessage(ACTION_GET_FAVORITES))

        //spusteni menu z url
        let menuLevel = sessionStorage.getItem("menuLevel");
        if (menuLevel != null) {
            sessionStorage.removeItem("menuLevel")
            loadNavigatorDataFromRoot(menuLevel, ws_context, dispatch, true)
        }
    }, [])

    React.useEffect(() => {
        //console.log("use effect")
        const controller = new AbortController()

        function setFocusByTab() {
            if (hasFocus[MENU_MAIN] !== document.getElementById(MENU_MAIN + "-tab").classList?.contains("active")
                || hasFocus[MENU_FAVORITE] !== document.getElementById(MENU_FAVORITE + "-tab").classList?.contains("active")
                || hasFocus[MENU_HISTORY] !== document.getElementById(MENU_HISTORY + "-tab").classList?.contains("active"))

                //console.log("set state 1")
                setFocus({
                    [MENU_MAIN]: document.getElementById(MENU_MAIN + "-tab").classList?.contains("active"),
                    [MENU_FAVORITE]: document.getElementById(MENU_FAVORITE + "-tab").classList?.contains("active"),
                    [MENU_HISTORY]: document.getElementById(MENU_HISTORY + "-tab").classList?.contains("active")
                })
        }

        function focusin(e) {
            //console.log("RELATED TARGET", e.relatedTarget)
            const enteringParent = !sidebarRef.current?.contains(e.relatedTarget)
            if (enteringParent) {
                //console.log("focus in", e)
                setFocusByTab()
            }
        }

        function focusout(e) {
            const leavingParent = !sidebarRef.current?.contains(e.relatedTarget)
            if (leavingParent) {

                //props.dispatch(changeFocusAreaWithBrowseFocusLoss(getRelatedTargetFocusArea(e.relatedTarget)))

                //console.log("focus out", e)
                setFocus({
                    [MENU_MAIN]: false,
                    [MENU_FAVORITE]: false,
                    [MENU_HISTORY]: false
                })
            }
        }

        function onClickListener(e) {
            focus2Sidebar()
        }

        sidebarRef.current?.addEventListener("focusin", focusin, { signal: controller.signal })
        sidebarRef.current?.addEventListener("focusout", focusout, { signal: controller.signal })
        sidebarRef.current?.addEventListener("click", onClickListener, { signal: controller.signal })

        function shownBsTab(e) {
            setFocusByTab()
        }

        tabMainRef.current?.addEventListener("shown.bs.tab", shownBsTab, { signal: controller.signal })
        tabFavoriteRef.current?.addEventListener("shown.bs.tab", shownBsTab, { signal: controller.signal })
        tabHistoryRef.current?.addEventListener("shown.bs.tab", shownBsTab, { signal: controller.signal })

        return () => {
            controller.abort()
        }
    })

    //pomoci tabu je mozne se dostat na jednotlive polozky menu
    addListenerWithRefHandler(LISTENER_TYPE.KEYDOWN, tabMainRef, keydownListener)
    addListenerWithRefHandler(LISTENER_TYPE.KEYDOWN, tabFavoriteRef, keydownListener)
    addListenerWithRefHandler(LISTENER_TYPE.KEYDOWN, tabHistoryRef, keydownListener)
    function keydownListener(event) {
        if (event.key === "Tab") {
            event.preventDefault()
            focus2Sidebar()
        }
    }

    function getStyle(menuType) {
        let borders = {
            borderLeft: 0, borderTop: 0, borderRight: 0,
            background: 'var(--sidebar-bg)',
        }
        return hasFocus[menuType] ? { ...borders, borderBottom: 'solid var(--bs-primary)' } : { ...borders, borderBottom: "3px solid transparent" }
    }

    logRender("Sidebar")
    logRenderProps(props)

    return (
        //blue_border
        <nav id="sidebar" className="sidebar blue_border" tabIndex={0} ref={sidebarRef}>
            <div className="sidebar-content">
                <div className="sidebar-logo-version d-flex align-items-start">
                    <AppIcon width="40" height="40" fontSize="lg" />
                    <div className="flex-grow-1 ms-1 mt-4">
                        <div className="small">{dbSet}</div>
                    </div>
                </div>
                <ul className="nav nav-tabs" role="tablist"
                    style={{
                        borderBottom: "1px solid var(--bs-primary)",
                        paddingBottom: "1px"
                    }}
                    onKeyDown={e => {
                        //nebylo mozne vylezt z tabu, proto tento focus
                        focusElement("sidebar", "Sidebar -> tab -> keydown")
                    }

                    }>
                    <li className="nav-item" role="presentation">
                        <BSTooltip key={"tooltip_sidebar_main"} tooltiptext={mainMenuLabel} shortcuts={[mainMenuShortcut]}>
                            <button
                                id={MENU_MAIN + "-tab"}
                                ref={tabMainRef}
                                className="nav-link active sidebar-tabs"
                                style={getStyle(MENU_MAIN)}
                                data-bs-toggle="tab" data-bs-target={"#" + MENU_MAIN} type="button" role="tab" aria-controls={MENU_MAIN} aria-selected="true">{mainMenuLabel}</button>
                        </BSTooltip>
                    </li>
                    <li className="nav-item" role="presentation">
                        <BSTooltip key={"tooltip_sidebar_favourites"} tooltiptext={mainFavouritesLabel} shortcuts={[favouritesMenuShortcut]}>
                            <button
                                id={MENU_FAVORITE + "-tab"}
                                ref={tabFavoriteRef}
                                className="nav-link sidebar-tabs"
                                style={getStyle(MENU_FAVORITE)}
                                data-bs-toggle="tab" data-bs-target={"#" + MENU_FAVORITE} type="button" role="tab" aria-controls={MENU_FAVORITE} aria-selected="false">{mainFavouritesLabel}</button>
                        </BSTooltip>
                    </li>
                    <li className="nav-item" role="presentation">
                        <BSTooltip key={"tooltip_sidebar_history"} tooltiptext={mainhistoryLabel} shortcuts={[historyMenuShortcut]}>
                            <button
                                id={MENU_HISTORY + "-tab"}
                                ref={tabHistoryRef}
                                className="nav-link sidebar-tabs"
                                style={getStyle(MENU_HISTORY)}
                                data-bs-toggle="tab" data-bs-target={"#" + MENU_HISTORY} type="button" role="tab" aria-controls={MENU_HISTORY} aria-selected="false">{mainhistoryLabel}</button>
                        </BSTooltip>
                    </li>
                </ul>
                <div id="navigator-tab-content" className="tab-content" >
                    <div className="tab-pane fade show active no_outline" id={MENU_MAIN} role="tabpanel" aria-labelledby={MENU_MAIN + "-tab"}>
                        {<SideMenu menuType={MENU_MAIN} />}
                    </div>
                    <div className="tab-pane fade no_outline" id={MENU_FAVORITE} role="tabpanel" aria-labelledby={MENU_FAVORITE + "-tab"}>
                        {<SideMenu menuType={MENU_FAVORITE} />}
                    </div>
                    <div className="tab-pane fade no_outline" id={MENU_HISTORY} role="tabpanel" aria-labelledby={MENU_HISTORY + "-tab"}>
                        {<SideMenu menuType={MENU_HISTORY} />}
                    </div>
                </div>
            </div>
        </nav >
    )
}

function mapStateToProps(state) {
    return {
        messageContainer: state.app.messageContainer,
        dbSet: state.app.title
    }
}

export default connect(mapStateToProps)(Sidebar)