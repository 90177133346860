import { getStore } from '../../index.js'
/**
 *
 * @param {"yyyy-MM-ddThh:mm:ss"} inputValue - hodnota z input elementu
 * @returns "99/99/9999 HH:MM:SS.SSS+HH:MM" - ve formatu progres datetime-tz
 */
export function toProgressDateTimeFormat(inputValue) {
    //console.log("toProgressDateTimeFormat", inputValue)
    if (inputValue === "")
        return inputValue

    let dateTime = new Date(inputValue)

    //do datove typu datetime lze vlozit i format datetime-tz
    let date = inputValue.substring(0, inputValue.indexOf("T"))
    let formattedDate = toProgressDateFormat(date, "9999/99/99")
    //console.log("DATE", formattedDate)

    let time = inputValue.substring(inputValue.indexOf("T") + 1)
    let formattedTime = time.replace("Z", getTimezoneFormatted(dateTime))
    //console.log("TIME", formattedTime)

    //PROGRESS TZ "99/99/9999 HH:MM:SS.SSS+HH:MM"
    //PROGRESS "99/99/9999 HH:MM:SS.SS"
    //console.log("DATE + TIME", formattedDate + " " + formattedTime)
    return formattedDate + " " + time
}



/**
 * Vraci aktulani casovou zonu z operacniho systemu
 * +5:00,-6:00,Z
 * @returns +-HH:MM or Z
 */
export function getTimezoneFormatted(date) {
    let timezone_offset_min = date.getTimezoneOffset(),
        offset_hrs = parseInt(Math.abs(timezone_offset_min / 60)),
        offset_min = Math.abs(timezone_offset_min % 60),
        timezone_standard;

    if (offset_hrs < 10)
        offset_hrs = '0' + offset_hrs

    if (offset_min < 10)
        offset_min = '0' + offset_min

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0)
        timezone_standard = '+' + offset_hrs + ':' + offset_min
    else if (timezone_offset_min > 0)
        timezone_standard = '-' + offset_hrs + ':' + offset_min
    else if (timezone_offset_min == 0)
        timezone_standard = 'Z'

    return timezone_standard
}

/**
 * Prevede  format input elementu na progress format
 * pokud je ve formatu uveden oddelovac, tak musi byt pro den,mesic,rok
 * @param {string} date "YYYY-MM-DD"
 * @returns {string} "progress date format"
 */
export function toProgressDateFormat(date, dateFormat) {
    //console.log("toProgressDateFormat", date, dateFormat)
    //console.log("date === ''", (date === ""))
    if (date === "") {
        return "?"
    }

    let formattedDate = ""
    let splitted = date.split("-")
    let year = splitted[0] == undefined ? "" : splitted[0]
    let month = splitted[1] == undefined ? "" : splitted[1]
    let day = splitted[2] == undefined ? "" : splitted[2]

    if (day.length === 1)
        day = "0" + day
    if (month.length === 1)
        month = "0" + month

    let store = getStore().getState()
    let appDateFormat = store.app.dateFormat
    let dividers = dateFormat.replaceAll(/[0-9]/ig, "").split("")

    //dmy
    appDateFormat.split('').forEach((letter, index) => {
        let divider = ""
        if (index < dividers.length)
            divider = dividers[index]

        switch (letter) {
            case "d":
                formattedDate = formattedDate + day + divider
                break
            case "m":
                formattedDate = formattedDate + month + divider
                break
            case "y":
                formattedDate = formattedDate + year + divider
                break
        }
    })

    return formattedDate
}

/**
 * prevede z progress formatu
 *  - datetime-tz "99/99/9999 HH:MM:SS.SSS+HH:MM" na "yyyy-MM-ddThh:mm:ss+hh:mm"
 *  - datetime "99/99/9999 HH:MM:SS.SS" na "yyyy-MM-ddThh:mm:ss"
                                          * 1977-04-01T14:00:30
 * @retunrs {JsonObject} {date: datum s timezone, date4Input: datum ve formatu pro element input "2022-08-18T08:38"}
 */
export function toInputDateTimeFormat(dateTime) {
    if (!/[0-9]/g.test(dateTime))
        return { date, date4Input: "" }
    //console.log("DATE TIME", dateTime)
    let formattedDate, formattedTime
    //DATE
    let date = dateTime.substring(0, dateTime.indexOf(" "))
    formattedDate = toInputDateFormat(date)

    //TIME
    let time = dateTime.substring(dateTime.indexOf(" ") + 1)
    let hm = time.substring(0, 5)
    formattedTime = hm
    if (time.indexOf("+") > 0 || time.indexOf("-") > 0) {
        let timezone
        if (time.indexOf("+") > 0)
            timezone = time.substring(time.indexOf("+"), time.length)
        else if (time.indexOf("-") > 0)
            timezone = time.substring(time.indexOf("-"), time.length)

        formattedTime = formattedTime + timezone
    }

    return {
        date: new Date(formattedDate + "T" + formattedTime),
        date4Input: formattedDate + "T" + hm
    }
}

/**
 * Prevede progress format na format input elementu yyyy-mm-dd
 * @param {string} "progress date "
 * @returns {string} "YYYY-MM-DD"
 */
export function toInputDateFormat(date) {
    //console.log("toInputDateFormat", "-" + date + "-", "/  /" === date)
    if (date === "" || date === "?" || date === "/  /")
        return ""

    let currentPosition = 0
    let day, month, year
    let store = getStore().getState()
    let appDateFormat = store.app.dateFormat
    let yearOffset = store.app.yearOffset

    //odstraneni oddelovacu ".", "-", "/"
    date = date.replaceAll(/\.|\/|\-/ig, "")

    //dmy
    appDateFormat.split('').forEach((letter) => {
        switch (letter) {
            case "d":
                day = date.substring(currentPosition, currentPosition + 2)
                currentPosition = currentPosition + 2
                break
            case "m":
                month = date.substring(currentPosition, currentPosition + 2)
                currentPosition = currentPosition + 2
                break
            case "y":
                if (date.length === 6) {
                    year = date.substring(currentPosition, currentPosition + 2)
                    currentPosition = currentPosition + 2
                } else {
                    year = date.substring(currentPosition, currentPosition + 4)
                    currentPosition = currentPosition + 4
                }
                break
        }
    })

    year = twoPlacesTo4Places(year, yearOffset)

    //console.log("returning", year + "-" + month + "-" + day)
    return year + "-" + month + "-" + day
}



/*
dle formatu uprava 2 mistneho roku na 4 mistny, pokud je to potreba
dle progress atributu SESSION:YEAR-OFFSET je nastaveno aktualni století např. 1960

==============================
| SESSION:YEAR-OFFSET = 1960 |
==============================
<00,59> => <2000,2059>
<59,99> => <1960,1999>*/
function twoPlacesTo4Places(year, yearOffset) {
    //console.log("twoPlacesTo4Places", year.length)
    //if (year <= 99) {
    if (year.length === 2) {
        let century = Number(yearOffset.substring(0, 2))
        let offsetYear = Number(yearOffset.substring(2))

        // stoleti dle year offset
        if (year >= offsetYear)
            century = String(century)
        else
            century = String(century + 1)

        // 9 => 09
        if (year < 10)
            year = century + "0" + String(year)
        else
            year = century + String(year)
    }

    return year
}