import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BSTooltip from '../BSTooltip'

// import { evaluate } from 'mathjs';
import Constants from '../../constants/constants'
import Styles from '../../constants/styles'
import useDialogDrag from '../../hooks/useDialogDrag';
import {focusElement} from '../../focus_actions';

// oddelovac desetinnych mist
const DECIMAL_SEPARATOR_COMMA = ',';

// interni oddelovac desetinnych mist
const DECIMAL_SEPARATOR_DOT = '.';

// pro pouziti v regexu
const DECIMAL_SEPARATOR_REGEX_DOT = '\\.';

// znamenko 
const EQUAL_SIGN = '=';

// leva zavorka
const LEFT_BRACKET = '(';

// prava zavorka
const RIGHT_BRACKET = ')';

// Definování konstant pro operátory
const OPERATOR_DIVIDE = '/';
const OPERATOR_MULTIPLY = '*';
const OPERATOR_SUBTRACT = '-';
const OPERATOR_ADD = '+';

// Sestavení pole operátorů pomocí definovaných konstant
const OPERATORS = [OPERATOR_DIVIDE, OPERATOR_MULTIPLY, OPERATOR_SUBTRACT, OPERATOR_ADD];


const MAXIMAL_PLUS_RADA = 8;
const MAXIMAL_MINUS_RADA = -6;


// init state pro hodnotu 
const INIT_DISPLAY_STATE_NULL = '0';

// mezera
const CHAR_SPACE = ' ';

// font size v pixelech
const MAX_FONT_SIZE = 14;

const KEY_WORD_ENTER = 'Enter'

const modalRoot = document.getElementById("root")


export default function Calculator(props) {
  const { setCalculatorVisibility, setInputValue, inputDataFormat, id, maskId, tabId, dialogX, dialogY, inputValue, initKey, disableInsertButton } = props;

  const [expression, setExpression] = useState("");
  const [openBrackets, setOpenBrackets] = useState(0);
  const [isDecimalSeparator, setIsDecimalSeparator] = useState(false);
  const [fontSize, setFontSize] = useState(MAX_FONT_SIZE);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);


  // nove start 
  const [isRoundingActive, setIsRoundingActive] = useState(true);
  const [roundUpActive, setRoundUpActive] = useState(false);
  const [roundDownActive, setRoundDownActive] = useState(false);


  const [isFeatureActive, setIsFeatureActive] = useState(true);

  const [decimalCharSeparator, setDecimalCharSeparator] = useState(',');

  const [activeButton, setActiveButton] = useState(null);

  const [precisionText, setPrecisionText] = useState('Přesnost: 0,01 ');
  const [roundOrCropText, setRoundOrCropText] = useState('Zaokrouhlení: matematické');


  const [scrPrecisionText, setScrPrecisionText] = useState(precisionText);
  const [scrRoundOrCropText, setScrRoundOrCropText] = useState(roundOrCropText);




  const isFirstRender = useRef(true);
  const buttonRefs = useRef({});


  // Předpokládaný počet desetinných míst
  const [decimalPlaces, setDecimalPlaces] = useState(getDecimalCount(inputDataFormat));
  const prevDecimalPlaces = useRef(decimalPlaces);


  const [entrRovnaSe, setEntrRovnaSe] = useState(KEY_WORD_ENTER);
  const [entrVlozit, setEntrVlozit] = useState('');




  // format do cz tvaru
  const formatToNumberLocale = useCallback((value, form2format) => {
    const numericString = replaceCommaByDot(removeSpaces(value));
    const numericValue = typeof numericString === 'string' ? parseFloat(numericString) : numericString;
    // Konverze cisla na retezec a formatovani dle cz konvence
    // console.log(parseFloat(numericValue), typeof numericValue, numericValue.toLocaleString('cs-CZ', {maximumFractionDigits: 5}));

    //let returnString = parseFloat(numericValue).toLocaleString('cs-CZ', { maximumFractionDigits: 9, minimumFractionDigits: decimalPlaces });

    let returnString = ""; 

    if (form2format && decimalPlaces >= 0) {
      returnString = parseFloat(numericValue).toLocaleString('cs-CZ', { maximumFractionDigits: 9, minimumFractionDigits: decimalPlaces });
    } else {
      returnString = parseFloat(numericValue).toLocaleString('cs-CZ', { maximumFractionDigits: 9 });
    }


    if (returnString.includes(DECIMAL_SEPARATOR_COMMA) && decimalCharSeparator === DECIMAL_SEPARATOR_DOT) {
      returnString = replaceCommaByDot(returnString);
    }

    // setIsDecimalSeparator(returnString.includes(DECIMAL_SEPARATOR_COMMA));
    setIsDecimalSeparator(returnString.includes(decimalCharSeparator));

    return returnString;
  }, [decimalCharSeparator, decimalPlaces]);







  // const [displayValue, setDisplayValue] = useState(INIT_DISPLAY_STATE_NULL);
  const [displayValue, setDisplayValue] = useState(inputActionOnInput(inputValue));


  useEffect(() => {
    setIsDecimalSeparator(inputValue.includes(DECIMAL_SEPARATOR_DOT));
  }, []);




  // const getDecimalCount = (inputFormat) => {
  function getDecimalCount (inputFormat) {
    if (!inputFormat) {
      return 2;
    }

    // pozici tecky v retezci
    const dotIndex = inputFormat.indexOf(DECIMAL_SEPARATOR_DOT);

    // Pokud neexistuje tečka, vrátíme 0
    if (dotIndex === -1) {
      return 0;
    }

    // pocet des. mist. za teckou
    return inputFormat.length - dotIndex - 1;
  }




  // odstrani carky
  function removeCommas (numberString) {
    return numberString?.replace(/,/g, '');
  }





  const el = document.createElement("div")
  el.id = Constants.portal_calculator

  React.useEffect(() => {
    // append to root when the children of Modal are mounted
    modalRoot.append(el)
    return () => {
      modalRoot.removeChild(el)
    }
  }, [el])



  useEffect(() => {
    // Inicializujeme refs pro každé tlačítko
    OPERATORS.concat(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', '(', ')', '=', 'C', 'DELETE', 'BACKSPACE', '%', '±']).forEach(key => {
      buttonRefs.current[key] = React.createRef();
    });
  }, []);



  const handleDecimalSeparatorChange = (event) => {
    setDecimalCharSeparator(event.target.value);
  };

  const toggleFeature = () => {

    if (isFeatureActive) {
      setScrPrecisionText("");
      setScrRoundOrCropText("");
    } else {
      setScrPrecisionText(precisionText);
      setScrRoundOrCropText(roundOrCropText);
    }


    setIsFeatureActive(prev => !prev);
  };


  const handleUse = () => {
    if (disableInsertButton === false) {
      setInputValue(replaceSpaceWithComma(replaceCommaByDot(displayValue)));
      // setInputValue(removeSpaces(replaceCommaByDot(displayValue)));
      setCalculatorVisibility(false);
    } 
  }


  const handleEsc = () => {
    setCalculatorVisibility(false);
  }


  const toggleRounding = () => {
    const newRoundingState = !isRoundingActive;
    setIsRoundingActive(newRoundingState);

    const newStateOfTxtRnd = newRoundingState ? 'Zaokrouhlení: matematické' : 'Oříznutí';
    setRoundOrCropText(newStateOfTxtRnd);
    if (scrRoundOrCropText !== '') {
      setScrRoundOrCropText(newStateOfTxtRnd);
    }

    // Pokud deaktivujeme "Z", deaktivujeme také "nahoru" a "dolů"
    if (!newRoundingState) {
      setRoundUpActive(false);
      setRoundDownActive(false);
    }
  };



  const toggleRoundUp = () => {
    const newRoundUpState = !roundUpActive;
    setRoundUpActive(newRoundUpState);

    const newStateOfTxtRnd = newRoundUpState ? 'Zaokrouhlení: vždy směrem nahoru' : 'Zaokrouhlení: matematické';
    setRoundOrCropText(newStateOfTxtRnd);
    if (scrRoundOrCropText !== '') {
      setScrRoundOrCropText(newStateOfTxtRnd);
    }

    if (newRoundUpState) {
      setRoundDownActive(false);
      setIsRoundingActive(true);
    }
  };

  const toggleRoundDown = () => {
    const newRoundDownState = !roundDownActive;
    setRoundDownActive(newRoundDownState);

    const newStateOfTxtRnd = newRoundDownState ? 'Zaokrouhlení: vždy směrem dolu' : 'Zaokrouhlení: matematické';
    setRoundOrCropText(newStateOfTxtRnd);
    if (scrRoundOrCropText !== '') {
      setScrRoundOrCropText(newStateOfTxtRnd);
    }

    if (newRoundDownState) {
      setRoundUpActive(false);
      setIsRoundingActive(true);
    }
  };

  const buttonStyle = (isActive) => ({
    backgroundColor: isActive ? 'green' : 'grey',
    // margin: '5px',
    padding: '10px'
  });
  // nove konec 




  // Funkce pro změnu počtu desetinných míst
  const changeDecimalPlaces = (direction) => {
    setDecimalPlaces(prev => {

      if (direction === 'right' && prev < MAXIMAL_PLUS_RADA) {
        return prev + 1;
      } else if (direction === 'left' && prev > MAXIMAL_MINUS_RADA) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };


  function isValidExpression(expression) {
    const regex = /^[0-9*\/\-\+\(\) \.]+$/;
    return regex.test(expression);
  }


  const formatPastedText = useCallback((text) => {

    // Odstranění 'rovná se' na začátku a na konci textu
    let formattedText = text.replace(/^\s*=\s*|\s*=\s*$/g, '');


    // nahrazeni bilych mist mezerou
    formattedText = formattedText.replace(/\s+/g, CHAR_SPACE);


    formattedText = replaceCommaByDot(formattedText);

    // Ujistíme se, že před a za každým operátorem je právě jedna mezera
    OPERATORS.forEach(operator => {
      const regex = new RegExp(`\\s*\\${operator}\\s*`, 'g');
      formattedText = formattedText.replace(regex, ` ${operator} `);
    });

    // Opět nahradit jakákoliv bílá místa jednou mezerou, pro případ, že se vedle sebe objeví víc operátorů
    formattedText = formattedText.replace(/\s+/g, CHAR_SPACE);

    return formattedText.trim();
  }, []);





  const closeSettingsDialog = () => {
    // console.log('close');
    setIsSettingsOpen(false);
    setIsMenuOpen(false);
  }

  const ClickOnSettButton = () => {
    setIsSettingsOpen(true);
    setIsMenuOpen(false);
  }


  const openSettingsDialog = () => {
    setIsSettingsOpen(true);
  }



  // init display value
  const initDispValue = useCallback(() => {
    setIsDecimalSeparator(false);
    setDisplayValue(INIT_DISPLAY_STATE_NULL);
  }, [setIsDecimalSeparator, setDisplayValue]);


  // vymazani vseho
  const clearAll = useCallback(() => {

    actButTimeOut("C");

    setOpenBrackets(0);
    setExpression("");
    initDispValue();
  }, [setOpenBrackets, setExpression, initDispValue]);


  // vymazáni vstupu
  const clearEntry = useCallback(() => {
    
    actButTimeOut("DELETE");

    initDispValue();
  }, [initDispValue]);


  // pridani desetinneho oddelovace
  const addDecimalPoint = useCallback(() => {
    // setDisplayValue(displayValue + DECIMAL_SEPARATOR_COMMA);
    setDisplayValue(displayValue + decimalCharSeparator);
    setIsDecimalSeparator(true);
  }, [decimalCharSeparator, displayValue]);


  // pridani cislice 
  const handleAddDigit = useCallback((digit) => {

    actButTimeOut(digit);

    if (displayValue === INIT_DISPLAY_STATE_NULL) {
      // pouze zamena cislice misto nuly
      setDisplayValue(digit);
    } else {
      if (isDecimalSeparator && digit === INIT_DISPLAY_STATE_NULL) {
        // byl pouzit oddelovac a je pozadavek nuly
        setDisplayValue(displayValue + digit);
      } else {
        setDisplayValue(formatToNumberLocale(displayValue + digit, false));
      }
    }
  }, [displayValue, formatToNumberLocale, isDecimalSeparator]);





  // pridani opeatoru
  const handleAddOperator = useCallback((operator) => {

    // const dispValue = removeSpaces(displayValue);
    let dispValue = Number(replaceCommaByDot(removeSpaces(displayValue))).toString();

    if (decimalCharSeparator === DECIMAL_SEPARATOR_COMMA) {
        dispValue = replaceDotByComma(dispValue);
    }

    setEntrRovnaSe(KEY_WORD_ENTER);
    setEntrVlozit('');


    actButTimeOut(operator);

    // Kontrola, zda predchozi vyraz byl jiz vyhodnocen (obsahuje rovna se)
    if (expression.includes(EQUAL_SIGN)) {
      // Pokud ano, zacne novy vyraz s aktualni hodnotou zobrazenou na displeji
      setExpression(dispValue + CHAR_SPACE + operator + CHAR_SPACE);
    }
    else {
      // vyraz nebyl vyhodnocen
      // konci pravou zavorkou nebo cislici
      if (expression.endsWith(RIGHT_BRACKET) || (/[0-9]$/).test(expression)) {
        // ano, pridam jen operator          
        setExpression(prev => prev + CHAR_SPACE + operator + CHAR_SPACE);
      }
      else {
        // zajima me, jestli vyraz konci operatorem 
        const endsWithOperator = OPERATORS.some(op => expression.endsWith(op + CHAR_SPACE));

        // pokud je na display 0 a vyraz konci operatorem
        if (dispValue === INIT_DISPLAY_STATE_NULL && endsWithOperator) {
          //menim jen posledni operator ve vyrazu
          setExpression(prev => prev = prev.replace(/..$/, operator + CHAR_SPACE));
        }
        else {
          //jen pridam novy operator k aktualnimu vyrazu
          setExpression(prev => prev + dispValue + CHAR_SPACE + operator + CHAR_SPACE);
        }
      }
    }


    // reset displayValue na '0'
    initDispValue();
  }, [displayValue, expression, initDispValue])


  // smazani posledniho znaku
  const handleBackspace = useCallback(() => {

    actButTimeOut("BACKSPACE");

    if (displayValue.length > 1) {

      // pokud konci carkou, shazuju priznak
      displayValue.endsWith(DECIMAL_SEPARATOR_COMMA) && setIsDecimalSeparator(false);

      const DispSlicesVal = displayValue.slice(0, -1);

      if (isDecimalSeparator) { // && !DispSlicesVal.endsWith(DECIMAL_SEPARATOR_COMMA)) {
        setDisplayValue(DispSlicesVal);
      } else {
        setDisplayValue(formatToNumberLocale(DispSlicesVal, false));
      }
    }
    else {
      initDispValue();
    }
  }, [displayValue, formatToNumberLocale, initDispValue, isDecimalSeparator]);

  // procento
  const handlePercent = useCallback(() => {
    const currentValue = Number(replaceCommaByDot(removeSpaces(displayValue)));
    const newValue = currentValue / 100;

    actButTimeOut('%');

    // Aktualizace stavu s novou hodnotou, prevedenou zpet na retezec
    setDisplayValue(formatToNumberLocale(String(newValue), false));

  }, [displayValue, formatToNumberLocale]);

  // zmena znamenka
  const handleChangeSign = useCallback(() => {
    // zmena znamenka
    const currentValue = Number(replaceCommaByDot(removeSpaces(displayValue)));
    const newValue = currentValue * -1;

    // Aktualizace stavu s novou hodnotou, prevedenou zpet na retezec
    setDisplayValue(formatToNumberLocale(String(newValue), false));
  }, [displayValue, formatToNumberLocale]);


  const roundToDecimalPlaces = useCallback((num) => {

    if (!isFeatureActive) {
      // Pokud zaokrouhlení není aktivní, vrať původní číslo
      return num;
    }

    const places = parseInt(decimalPlaces, 10);
    if (!isNaN(places)) {
      const factor = Math.pow(10, places);

      if (roundUpActive) {
        return Math.ceil(num * factor) / factor;
      } else if (roundDownActive) {
        return Math.floor(num * factor) / factor;
      } else if (isRoundingActive) {
        return Math.round(num * factor) / factor;
      } else {
        return Math.trunc(num * factor) / factor;
      }
    }
    return num;
  }, [decimalPlaces, isFeatureActive, isRoundingActive, roundDownActive, roundUpActive]);



  // Metoda pro pocitani vyrazu
  const handleCalculateExpression = useCallback(() => {

    const currentValue = Number(replaceCommaByDot(removeSpaces(displayValue)));

    if (expression.includes(EQUAL_SIGN) /*&& currentValue === 0*/ ) {


      handleUse();
      return;
    }


    if (!disableInsertButton) {
      // po vypoctu hodit entr na tlacitko vlozit
      setEntrRovnaSe('');
      setEntrVlozit(KEY_WORD_ENTER);
    }



    // tento se bude pocitat
    let finalExpression = replaceCommaByDot(expression);
    let addDispValue = true;

    // test na uzavrene zavorky
    if (openBrackets !== 0) {
      // nejaka zavorka tam chybi, bude potreba doplnit

      // Kontrola, zda finalExpression konci nekterym z operandu
      const endsWithOperator = OPERATORS.some(op => finalExpression.endsWith(op + CHAR_SPACE));

      if (endsWithOperator) {
        // konci operatorem, musim mu tam hodit screen
        finalExpression += replaceCommaByDot(removeSpaces(displayValue));
      }

      // doplnuji uz jen pocet uzavirajicich zavorek
      finalExpression += RIGHT_BRACKET.repeat(openBrackets);
      setOpenBrackets(0);
      addDispValue = false;
    }

    // pokud express konci pravou zavorkou, ani potom nepridavat hodnotu displayvalue
    finalExpression.endsWith(RIGHT_BRACKET) && (addDispValue = false);

    if (addDispValue) {
      finalExpression += replaceCommaByDot(removeSpaces(displayValue));
    }

    // console.log('finalExpression:', finalExpression);


    try {
      // vypocet vysledku vyrazu

      if (isValidExpression(finalExpression)) {
        const result = eval(finalExpression);

        // Zaokrouhlení dle aktuálně nastavených pravidel, pokud je aktivní
        const finalResult = roundToDecimalPlaces(result);

        setDisplayValue(formatToNumberLocale(String(finalResult), true));


        if (decimalCharSeparator === DECIMAL_SEPARATOR_COMMA) {
          setExpression(replaceDotByComma(finalExpression) + CHAR_SPACE + EQUAL_SIGN);
        } else {
          setExpression(finalExpression + CHAR_SPACE + EQUAL_SIGN);
        }
      } else {
        setDisplayValue("Neplatný výraz");
      }




    } catch (e) {
      setDisplayValue("Error");
      // console.log(e.message);
    }
  }, [decimalCharSeparator, displayValue, expression, formatToNumberLocale, openBrackets, roundToDecimalPlaces]);


  // zavorky
  const handleBracket = useCallback((bracketType) => {

    actButTimeOut(bracketType);

    if (bracketType === LEFT_BRACKET) {
      if (expression.includes(EQUAL_SIGN)) {
        setExpression(bracketType + removeSpaces(displayValue));
      }
      else {
        setExpression(prev => prev + bracketType);
      }
      setOpenBrackets(prev => prev + 1);
    }
    else
      if (bracketType === RIGHT_BRACKET && openBrackets > 0) {
        setExpression(prev => prev + removeSpaces(displayValue) + bracketType);
        setOpenBrackets(prev => prev - 1);
      }
  }, [displayValue, expression, openBrackets]);


  const actButTimeOut = (key) => {
    setActiveButton(key);
    // Reset aktivního tlačítka po 150 ms
    setTimeout(() => {
      setActiveButton(null); 
    }, 150);

  }

  // smacknuta klavesa
  const handleKeyDown = useCallback((event) => {

    if (isSettingsOpen) {
      return;
    }


    if (event.ctrlKey) return;

    const key = event.key.toUpperCase();

    // Zvlastni pripad pro pravou závorku
    if (key === RIGHT_BRACKET && openBrackets === 0) {
      // Neoznačovat tlačítko jako aktivní a neudělovat fokus, pokud není aktivní
      return;
    }

    if ((key === DECIMAL_SEPARATOR_DOT || key === DECIMAL_SEPARATOR_COMMA)) {
      // Použijeme ref pro aktuálně nastavený oddělovač
      const decimalRef = buttonRefs.current[decimalCharSeparator];
      if (decimalRef && decimalRef.current) {
        decimalRef.current.focus();
        setActiveButton(decimalCharSeparator);
      }
    } else if (buttonRefs.current[key] && buttonRefs.current[key].current) {
      buttonRefs.current[key].current.focus();
    }

    actButTimeOut(key);




    // odchyceni klaves
    if ((key >= '0' && key <= '9')) {
      // numerika
      handleAddDigit(key);
    } else
      if (!isDecimalSeparator && (key === DECIMAL_SEPARATOR_DOT || key === DECIMAL_SEPARATOR_COMMA)) {
        // desetinna tecka / carka
        addDecimalPoint();
      } else
        if (OPERATORS.includes(key)) {
          // operatory
          handleAddOperator(key);
          // vypnuti default akce 
          event.preventDefault();
        } else
          if (key === LEFT_BRACKET || key === RIGHT_BRACKET) {
            // leva nebo prava zavorka
            handleBracket(key);
            event.preventDefault();
          } else
            if (key === 'ENTER' || key === 'E') { 
              event.preventDefault();

              const equalRef = buttonRefs.current['='];
              if (equalRef && equalRef.current) {
                equalRef.current.focus();
              }

              setActiveButton('=');
              // vypocet
              handleCalculateExpression();


            } else
              if (key === 'BACKSPACE' || key === 'B') {
                // Backspace
                handleBackspace();
              } else
                if (key === 'DELETE' || key === 'D') {
                  // delete
                  clearEntry();
                } else
                  if (key === '%') {
                    // procento
                    handlePercent();
                  } else
                    if (key === 'C' /* && !event.ctrlKey*/ ) {
                      clearAll();
                    } else
                      if (key === 'N'  /* && !event.ctrlKey */ ) {
                        const signChangeRef = buttonRefs.current['±'];
                        signChangeRef.current.focus();

                        handleChangeSign();
                        setActiveButton('±');
                      } else
                      if (key === 'ESCAPE' || key === 'Z') {
                        handleEsc();
                      } else
                      if (key === 'V' /* && !event.ctrlKey*/ ) {
                        handleUse();
                      }



    // console.log("key: ", key) ;
    // console.log("buttonRefs.current[key]: ", buttonRefs.current[key]) ;
    // console.log("buttonRefs.current[key].current: ", buttonRefs.current[key].current) ;


    // hup na tlacitko
    if (buttonRefs.current[key] && buttonRefs.current[key].current) {
      buttonRefs.current[key].current.focus();
    }

  }, [openBrackets, isDecimalSeparator, handleChangeSign, handlePercent, handleBracket, handleCalculateExpression, handleBackspace, handleAddOperator, handleAddDigit, decimalCharSeparator, clearEntry, clearAll, addDecimalPoint, activeButton, isSettingsOpen]);
  // }, [, displayValue, expression]);




  // odstraneni mezer
  function removeSpaces (numberString) {
    return numberString.replace(/\s/g, '');
  }

  // nahrada carky teckou
  function replaceCommaByDot (numberString) {
    const regex = new RegExp(DECIMAL_SEPARATOR_COMMA, 'g');
    return numberString.replace(regex, DECIMAL_SEPARATOR_DOT);
  }

  // nahrada tecky carkou
  function replaceDotByComma (numberString) {
    const regex = new RegExp(DECIMAL_SEPARATOR_REGEX_DOT, 'g');
    return numberString.replace(regex, DECIMAL_SEPARATOR_COMMA);
  }

  // Nahrazeni mezery tečkou
  function replaceSpaceWithComma (numberString) {
    return numberString.replace(/\s/g, DECIMAL_SEPARATOR_COMMA);
  }

  function inputActionOnInput (numberString) {

    
    return parseFloat(removeCommas(numberString)).toLocaleString('cs-CZ', { maximumFractionDigits: 9 });

  }



  const calculateFontSize = (content) => {
    // Maximalni pocet znaku, ktery vyzaduje nejvetsi moznou velikost pisma
    // 10
    const maxContentLength = 12;
    // Minimalni velikost písma v pixelech
    //16
    const minFontSize = 18;


    // vypocet velikosti fontu na zaklade delky obsahu
    const fontSize = MAX_FONT_SIZE - (content.length - maxContentLength);
    // zajisti, ze velikost neni mensi nez minimálni velikost
    return Math.max(fontSize, minFontSize);
  };


  // const processPastedText = (text) => {
  const processPastedText = useCallback((text) => {
    // Zde byste měli přidat logiku pro rozpoznání a zpracování vloženého textu
    // Například rozdělení textu na jednotlivé části a jejich následné zpracování

    // const finalExpression = removeSpaces(text);
    const finalExpression = formatPastedText(text);
    setExpression(finalExpression);


    try {
      // vypocet vysledku vyrazu
      if (isValidExpression(finalExpression)) {
        const result = eval(finalExpression);
        // Zaokrouhlení dle aktuálně nastavených pravidel, pokud je aktivní
        const finalResult = roundToDecimalPlaces(result);

        setDisplayValue(formatToNumberLocale(String(finalResult), true));

        if (decimalCharSeparator === DECIMAL_SEPARATOR_COMMA) {
          setExpression(replaceDotByComma(finalExpression) + CHAR_SPACE + EQUAL_SIGN);
        } else {
          setExpression(finalExpression + CHAR_SPACE + EQUAL_SIGN);
        }
      } else {
        setDisplayValue("Neplatný výraz");
      }



    } catch (e) {
      setDisplayValue("E: " + e);
      // console.log(e.message);
    }

  }, [decimalCharSeparator, formatPastedText, formatToNumberLocale, roundToDecimalPlaces]);





  const copyToClipboard = (text) => {
    if (text === "") {
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Text zkopírován!");
    }).catch(err => {
      toast.error("Nepodařilo se zkopírovat text.");
    });
  };








  // Aktualizujte fontSize kdykoliv se zmeni displayValue nebo expression
  useEffect(() => {
    const newFontSize = calculateFontSize(displayValue.length > expression.length ? displayValue : expression);
    setFontSize(newFontSize);
  }, [displayValue, expression]);


  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Nastaví se na false po prvním renderu
    } else if (prevDecimalPlaces.current !== decimalPlaces) {
      // Zobrazujeme toast pouze pokud došlo ke změně decimalPlaces
      toast.dismiss();
      toast.success("Změna řádu");
    }
    prevDecimalPlaces.current = decimalPlaces;
  }, [decimalPlaces]);



  useEffect(() => {
    const handlePaste = (event) => {

      try {
        // Získání textu ze schránky
        const text = event.clipboardData.getData('text/plain');

        if (!text) {
          toast.error("Vložená data nejsou typu text nebo jsou prázdná");
          return;
        }

        // Zpracování textu
        processPastedText(text);

      } catch (error) {
        alert("Nepodařilo se zpracovat data ze schránky. Ujistěte se, že kopírujete text.");
      }

    };

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [processPastedText]);



  // useEffect(() => {
  //   console.log("Aktuální stav isFeatureActive: ", isFeatureActive);
  // }, [isFeatureActive]);


  const refDialog = React.useRef(null)
  const refHeaderDialog = React.useRef(null)
  const refBodytDialog = React.useRef(null)
  useEffect(() => {
    buttonRefs.current['.'] = React.createRef();
    buttonRefs.current[','] = React.createRef();
  }, [decimalCharSeparator]);

  useDialogDrag(modalRoot, { id: id, tabId: tabId, maskId: maskId }, refDialog, refHeaderDialog, refBodytDialog, dialogX, dialogY)



  useEffect(() => {
    // console.log("calc useeffect", refDialog.current, document.activeElement)
    // Při zobrazení komponenty kalkulačky nastavíme fokus
    


    //if(!refDialog.current?.contains(document.activeElement)) {
        
      focusElement("rootCalculator", 'calculator useeffect')
    //}
  }); 



  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  


  useEffect(() => {
    // decimalPlaces
    const TXT_PRESNOST = 'Přesnost: ';
    // let vypocetPresnosti = (1 / Math.pow(10, decimalPlaces));

    let vypocetPresnosti = '';

    if (decimalPlaces >= 0) {
      vypocetPresnosti = (1 / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces);
    } else {

      vypocetPresnosti = (1 / Math.pow(10, decimalPlaces)).toFixed(0);
    }


    if (decimalCharSeparator === DECIMAL_SEPARATOR_COMMA) {
      vypocetPresnosti = replaceDotByComma(vypocetPresnosti);
    }

    vypocetPresnosti = TXT_PRESNOST + vypocetPresnosti;
    setPrecisionText(vypocetPresnosti); 

    if (scrPrecisionText !== '') {
      setScrPrecisionText(vypocetPresnosti);
    }

  }, [decimalPlaces]);


  const calc = 
    <div 
      id="rootCalculator" 
      className="calcDialog"
      tabIndex="0" 
      style={{ ...Styles.dialogStyle, backgroundColor: "transparent", maxWidth: "350px",  width: "350px" }}
      ref={refDialog} 
      >
    

      <div 
        ref={refHeaderDialog} 
        style=
          {
            { 
              backgroundColor: "transparent", 
              cursor: "move"
            }
          }
        >

    
        <div>
            <ToastContainer
              position="top-center"
              autoClose={1000}
              pauseOnHover
            />
        </div>

      

        <div
          style=
            {
              { 
                display: "flex", 
                flexDirection: "column",
                cursor: "move",
                margin: "19px",
              }
            }
          >


          <div 
            style=
              {
                { 
                  height: "10.rem", 
                  color: "black", 
                  cursor: "move", 
                  position: "relative" 
                }
              }
            >
            {/*kalkulačka*/}
          </div>

          {/*        
          <div>
            <button
              className="close-button"
              >
              x
            </button>
        
          </div>
          */}      
        
          {/*div*/}


          <div 
            ref={refBodytDialog} 
            className="calculator"
            >

            <div
              className="display clickable"
              id="expression-display"
              onClick={() => copyToClipboard(expression)}
              style={{ fontSize: `${fontSize}px` }}
              >
              {expression}
            </div>
          
            <div 
              className="display" 
              id="value-display"
              >
              {displayValue}
            </div>

            <div
              className="infoPrecRound"
              >
              <div 
                className="infoPrecision"
                >
                {scrPrecisionText}
              </div>
              <div
                style={{ marginRight: '4px' }}
                >
                {scrRoundOrCropText}
              </div>
            </div>


            <div 
              className="info-btn"
              >


              <BSTooltip id="switch" tooltiptext={"Vypíná/zapíná zaokrouhlení (nebo oříznutí)"} placement={'bottom'}>
                <div>
                  <label 
                    className="switch"
                    >
                  
                    <input 
                      type="checkbox" 
                      checked={isFeatureActive} 
                      onChange={toggleFeature} 
                    />
                  
                    <span 
                      className="slider round"
                      >
                    </span>
                  </label>
                </div>
              </BSTooltip>
             
              <BSTooltip 
                id="setting" 
                placement={'bottom'}
                >
                <button 
                  onClick={openSettingsDialog}
                  >

                  <span 
                    className={`zao ${isFeatureActive ? 'zao-active' : 'zao-inactive'}`}
                    >
                    {decimalPlaces}
                  </span>

                  {isRoundingActive && 
                    <span 
                      className={`zao ${isFeatureActive ? 'zao-active' : 'zao-inactive'}`}
                      >
                      Z
                    </span>
                  }
                
                  {isRoundingActive && roundUpActive && 
                    <span 
                      className={`zao ${isFeatureActive ? 'zao-active' : 'zao-inactive'}`}
                      >
                      ↑
                    </span>
                  }

                  {isRoundingActive && roundDownActive && 
                    <span 
                      className={`zao ${isFeatureActive ? 'zao-active' : 'zao-inactive'}`}
                      >
                      ↓
                    </span>
                  }

                </button>
              </BSTooltip>

            </div>

            {isMenuOpen && 
              (
                <div 
                  className="menu"
                  >
                  <ul>
                    <li 
                      onClick={ClickOnSettButton}
                      >
                      Nastavení
                    </li>
                    <li 
                      onClick={() => setIsSettingsOpen(true)}
                      >
                      Nápověda
                    </li>
                  </ul>
                </div>
              )
            }


            {isSettingsOpen && 
              (
                <div 
                  className="settings-dialog"
                  >
                  <h1>NASTAVENÍ</h1>
                  

                  <div>

                    <h2>{precisionText}</h2>

                    <div 
                      className="decimal-places-setting"
                      >
                      <button 
                        className="settings-btn settings-arrow" 
                        onClick={() => changeDecimalPlaces('left')}
                        >
                        ←
                      </button>
                      <span 
                        className="decimal-places-display"
                        >
                        {decimalPlaces}
                      </span>
                      <button
                        className="settings-btn settings-arrow" 
                        onClick={() => changeDecimalPlaces('right')}
                        >
                        →
                      </button>
                    </div>
                    
                  </div>  

                  
                  <div
                    className="rounding"
                    >
                    <h2>{roundOrCropText}</h2>
                    <div 
                      className="rounding-setting"
                      >
                      <button 
                        style={buttonStyle(isRoundingActive)} 
                        onClick={toggleRounding}
                        >
                        Z
                      </button>

                      <div 
                        className="rnd-sett-arrow"
                        >

                        <button 
                          style={buttonStyle(roundUpActive)} 
                          onClick={toggleRoundUp}
                          >
                          ↑
                        </button>

                        <button 
                          style={buttonStyle(roundDownActive)} 
                          onClick={toggleRoundDown}
                          >
                          ↓
                        </button>
                      </div>
                    </div>
                  </div>


                  <select 
                    className="decimal-separator-dropdown" 
                    value={decimalCharSeparator} 
                    onChange={handleDecimalSeparatorChange}
                    >
                    <option 
                      value="."
                      >
                      Tečka
                    </option>
                    <option 
                      value=","
                      >
                      Čárka
                    </option>
                  </select>

                  <div>
                    <button 
                      className="action" 
                      onClick={closeSettingsDialog}
                      >
                      Zavřít
                    </button>
                  </div>
                </div>
              )
            }

            <BSTooltip id="C" tooltiptext={"Klávesa"} shortcuts={['C']} placement={'bottom'}>
              <button
                ref={buttonRefs.current['C']}
                className={`button-clear action withtext ${activeButton === 'C' ? 'active' : ''}`}
                onClick={clearAll}
                >
                <span 
                  className="textInButton"
                  >
                  Clear all
                </span>
                C
              </button>
            </BSTooltip>

            <BSTooltip id="CE" tooltiptext={"Klávesa"} shortcuts={['Delete']} placement={'bottom'}>
              <button
                ref={buttonRefs.current['DELETE']}
                className={`button-clear action withtext ${activeButton === 'DELETE' ? 'active' : ''}`}
                onClick={clearEntry}
                >
                <span 
                  className="textInButton"
                  >
                  Delete
                </span>
                CE
              </button>
            </BSTooltip>

            <BSTooltip id="backspace" tooltiptext={"Klávesa"} shortcuts={['Backspace']} placement={'bottom'}>
              <button
                ref={buttonRefs.current['Backspace']}
                className={`button-clear action withtext ${activeButton === 'BACKSPACE' ? 'active' : ''}`}
                onClick={handleBackspace}
                >
                <span 
                  className="textInButton"
                  >
                  Backspace
                </span>
                ⌫
              </button>
            </BSTooltip>

            <button
              ref={buttonRefs.current['(']}
              className={`operator digit ${activeButton === '(' ? 'active' : ''}`}
              onClick={() => handleBracket(LEFT_BRACKET)}
              >
              (
            </button>

            <button
              ref={buttonRefs.current['/']}
              className={`operator digit ${activeButton === '/' ? 'active' : ''}`}
              onClick={() => handleAddOperator('/')}
              >
              /
            </button>

            <button
              ref={buttonRefs.current['*']}
              className={`operator digit ${activeButton === '*' ? 'active' : ''}`}
              onClick={() => handleAddOperator('*')}
              >
              &#215;
            </button>

            <button
              ref={buttonRefs.current['-']}
              className={`operator digit ${activeButton === '-' ? 'active' : ''}`}
              onClick={() => handleAddOperator('-')}
              >
              -
            </button>

            <button
              ref={buttonRefs.current['7']}
              className={`digit ${activeButton === '7' ? 'active' : ''}`}
              onClick={() => handleAddDigit('7')}
              >
              7
            </button>

            <button
              ref={buttonRefs.current['8']}
              className={`digit ${activeButton === '8' ? 'active' : ''}`}
              onClick={() => handleAddDigit('8')}
              >
              8
            </button>

            <button
              ref={buttonRefs.current['9']}
              className={`digit ${activeButton === '9' ? 'active' : ''}`}
              onClick={() => handleAddDigit('9')}
              >
              9
            </button>

            <button
              ref={buttonRefs.current['+']}
              className={`operator digit ${activeButton === '+' ? 'active' : ''}`}
              onClick={() => handleAddOperator('+')}
              >
              +
            </button>

            <button
              ref={buttonRefs.current['4']}
              className={`digit ${activeButton === '4' ? 'active' : ''}`}
              onClick={() => handleAddDigit('4')}
              >
              4
            </button>

            <button
              ref={buttonRefs.current['5']}
              className={`digit ${activeButton === '5' ? 'active' : ''}`}
              onClick={() => handleAddDigit('5')}
              >
              5
            </button>

            <button
              ref={buttonRefs.current['6']}
              className={`digit ${activeButton === '6' ? 'active' : ''}`}
              onClick={() => handleAddDigit('6')}
              >
              6
            </button>

            <button
              ref={buttonRefs.current['%']}
              className={`operator ${activeButton === '%' ? 'active' : ''}`}
              onClick={handlePercent}
              >
              %
            </button>

            <button
              ref={buttonRefs.current['1']}
              className={`digit ${activeButton === '1' ? 'active' : ''}`}
              onClick={() => handleAddDigit('1')}
              >
              1
            </button>

            <button
              ref={buttonRefs.current['2']}
              className={`digit ${activeButton === '2' ? 'active' : ''}`}
              onClick={() => handleAddDigit('2')}
              >
              2
            </button>

            <button
              ref={buttonRefs.current['3']}
              className={`digit ${activeButton === '3' ? 'active' : ''}`}
              onClick={() => handleAddDigit('3')}
              >
              3
            </button>

            <button
              ref={buttonRefs.current[RIGHT_BRACKET]}
              className={`operator ${activeButton === RIGHT_BRACKET ? 'active' : ''}`}
              onClick={() => handleBracket(RIGHT_BRACKET)}
              disabled={openBrackets === 0}
              >
              {RIGHT_BRACKET}
            </button>

            <BSTooltip id="±" tooltiptext={"Klávesa"} shortcuts={['n']}>
              <button
                ref={buttonRefs.current['±']}
                className={`digit sign-change withtext ${activeButton === '±' ? 'active' : ''}`}
                onClick={handleChangeSign}
                >
                <span 
                  className="textInButton"
                  >
                  Negation
                </span>
                ±
              </button>
            </BSTooltip>

            <button
              ref={buttonRefs.current['0']}
              className={`digit ${activeButton === '0' ? 'active' : ''}`}
              onClick={() => handleAddDigit('0')}
              >
              0
            </button>

            <button
              ref={buttonRefs.current[decimalCharSeparator]}
              className={`digit decimal ${activeButton === "." || activeButton === "," ? 'active' : ''}`}
              onClick={() => addDecimalPoint()}
              disabled={isDecimalSeparator === true}
              >
              {decimalCharSeparator}
            </button>

            <BSTooltip id="VYP" tooltiptext={"Klávesa"} shortcuts={['Enter']}>
              <button
                ref={buttonRefs.current['=']}
                className={`action withtext ${activeButton === "=" ? 'active' : ''}`}
                onClick={handleCalculateExpression}
                >
                <span 
                  className="textInButton"
                  >
                  {entrRovnaSe}
                </span>
                =
              </button>
            </BSTooltip>

            <BSTooltip id="ZAVRIT" tooltiptext={"Klávesa"} shortcuts={['z']} style={{ gridColumn: "span 2" }}>
              <button
                className="action special"
                
                onClick={handleEsc}
                >
                <span 
                  className="textInButton"
                  >
                  
                </span>
                Zavřít
              </button>
            </BSTooltip>

            <BSTooltip id="VLOZIT" tooltiptext={"Klávesa"} shortcuts={['v']} style={{ gridColumn: "span 2" }}>
              <button
                className="action special tlacitko-sirka-dva with2text"
                onClick={handleUse}
                disabled={disableInsertButton}
                >

                {disableInsertButton === false && entrVlozit !== '' &&
                <span 
                  className="textInButton"
                  >
                  {entrVlozit}
                </span>
                }

                Vložit
              </button>
            </BSTooltip>
          
          </div>

        </div>
      </div>
    </div>

  return createPortal(calc, el);

}