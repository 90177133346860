import { getStore } from '../../index.js'
import { toProgressDateFormat } from './inputFielsElementMethods'
import { onValueChangeByHandle } from '../../store/actions/store_actions'
import Constants from '../../constants/constants'
import { toProgressDateTimeFormat } from './inputFielsElementMethods.js'

function getParentElement(elementId, tabId, maskId) {
    let store = getStore().getState()

    let ws = store.ws
    if (tabId === undefined)
        tabId = ws.currentTabId

    if (maskId === undefined)
        maskId = ws.currentMaskID

    let storeElement = store.desktopSession[tabId][maskId][elementId]

    let parentElement = store.desktopSession[tabId][maskId][storeElement.data.parentHandle]
    return parentElement
}

export function isInDialog(elementId) {
    let id = elementId
    let element = getParentElement(id)
    let parentHandle = element.data.parentHandle
    do {
        let element = getParentElement(id)
        id = element.data.parentHandle
        if (element.componentName === "ModalDialogElement" || element.componentName === "DialogElement")
            return true
    } while (parentHandle > 0)

    return false
}

export function sensitivityRecursively(elementId, tabId, maskId) {
    let store = getStore().getState()
    let storeElement = store.desktopSession[tabId][maskId][elementId]

    if (storeElement.data.sensitivity === false)
        return false

    if (storeElement.data.parentHandle)
        return sensitivityRecursively(storeElement.data.parentHandle, tabId, maskId)
    else
        return true
}

export function parentSensitivity(elementId, tabId, maskId) {
    let parentElement = getParentElement(elementId, tabId, maskId)

    return parentElement.data.sensitivity
}

export function getElementData(elementId) {
    //console.log("getElementData", elementId)
    let store = getStore().getState()
    let ws = store.ws

    let storeElement = store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId]
    let htmlElement = document.getElementById(elementId)

    let elementValue = null, valueISO = null, dataType = null
    let cursorOffset
    //desktop server hodnotu nevraci, klient si hodnotu musi ulozit do storu sam
    let shouldSaveValue = false

    //console.log("element", storeElement)
    switch (storeElement?.componentName) {
        case "InputFieldElement":
            shouldSaveValue = true
            elementValue = htmlElement.value

            //vynulovani vstupniho pole - muze mit napriklad screen hodnotu mimo rozsah 31.02.2023 a value je ""
            if (elementValue === "")
                htmlElement.value = ""

            if (storeElement.data.dataType === "DATE") {
                elementValue = toProgressDateFormat(elementValue, storeElement.data.dataFormat)
                dataType = storeElement.data.dataType
            } else if (storeElement.data.dataType === "DATETIME") {
                elementValue = toProgressDateTimeFormat(elementValue)
                dataType = storeElement.data.dataType
            } else if (storeElement.data.dataType === "DATETIME-TZ") {
                //console.log("elementVAlue", elementValue)
                elementValue = toProgressDateTimeFormat(elementValue)
                dataType = storeElement.data.dataType
            }

            break
        case "EditorElement":
            cursorOffset = htmlElement.selectionStart + 1
            break
        /*case "ComboBoxElement":
            console.log("el", storeElement)
            elementValue = storeElement.data.values[parseInt(htmlElement.value)]
            break;*/
    }

    return {
        dataType: dataType,
        value: elementValue,
        isDifferentFromStore: elementValue !== storeElement?.data?.value,
        componentName: storeElement?.componentName,
        cursorOffset: cursorOffset,
        shouldSaveValue: shouldSaveValue,
        storeElement: storeElement
    }
}

export function saveValue2Store(id, data) {
    if (data.isDifferentFromStore) {
        let value = data.value

        //combobox si to resi sam v onChange()
        if (data.componentName === "ComboBoxElement")
            return

        getStore().dispatch(onValueChangeByHandle(id, "value", value))
    }
}