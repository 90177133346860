import React from 'react'
import { createPortal } from 'react-dom'

import { logRender, logRenderProps } from '../../utils/logging'

import Styles from '../../constants/styles'
import icons from '../../resources/icons.json'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'
import styles from '../../constants/styles'

export function findIconKey(unicode) {
    for (const [key, value] of Object.entries(icons)) {
        if (value.unicode === unicode)
            return key
    }
    return ""
}

export default function IconsDialog(props) {
    const { isIconsDialogVisible, setDialogIconsVisibility, selectIconCallback, messageContainer } = props

    addListener(LISTENER_TYPE.KEYDOWN, document, e => {
        if (e.key === 'Escape')
            setDialogIconsVisibility(false)
    })

    if (!isIconsDialogVisible)
        return

    logRender("IconsDialog")
    logRenderProps(props)
    return (createPortal(<div id="dialog-container">
        <div
            id="icons_dialog"
            className="modal-dialog"
            aria-modal="true"
            style={{
                ...Styles.dialogStyle,
                minWidth: "40%"
            }}>
            <div className="modal-content blue_box" style={{ ...Styles.dialogContent, maxWidth: '50%' }} data-bs-backdrop="static">
                <div className="modal-header">
                    <h4 className="modal-title"></h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e => {
                        setDialogIconsVisibility(false)
                    }}></button>
                </div>
                <div className="modal-body p-3">
                    {(() => {
                        let buttons = []
                        for (const [key, value] of Object.entries(icons)) {
                            //console.log(key, value);
                            buttons.push(<button onClick={e => {
                                selectIconCallback(value, key)
                                setDialogIconsVisibility(false)
                            }} type="button" className="btn btn-outline-secondary btn-lg" style={{ width: "3.5rem", height: '3.5rem' }}>
                                <span><i className={"fas fa-" + key + " fa-lg"}></i></span>
                            </button>)
                        }

                        return <div style={{ display: 'grid', gridGap: '1rem .5rem', gridTemplateColumns: 'repeat(auto-fill, minmax(4rem, 1fr)' }}>
                            {buttons}
                        </div>
                    })()}
                </div>
                <div className="modal-footer p-3 pb-3">
                    <button
                        type="button"
                        className="btn btn-secondary me-2"
                        style={styles.buttonStyle}
                        onClick={e => {
                            setDialogIconsVisibility(false)
                        }}>{messageContainer["button.storno"]}</button>
                </div>
            </div>
        </div>
    </div >, document.body))
}