import React from 'react'
import { useDispatch } from 'react-redux'

import { logRender, logRenderProps } from '../utils/logging'
import { textWithMnemonic } from '../utils/screen'

import { WebSocketContext } from '../services/WebSocket'
import Constants from '../constants/constants'
import useStoreSubscription from '../hooks/useStoreSubscription'
import { addListener, addListenerWithRefHandler, LISTENER_TYPE } from '../hooks/desktopListener'
import { renderMenuItemChildrens } from './Navbar'
import { MENU_MAIN, MENU_FAVORITE, MENU_HISTORY } from './SideMenu'
import { loadNavigatorDataFromRoot } from './MenuItem'
import { focus2Sidebar } from '../focus_actions'
import SessionTabs, { SHOW_IN_MENU, CLOSE_ALL, CLOSE_ALL_RIGHT } from './SessionTabs'

const { EXECUTE_PROGRAM_NAVIGATOR_MAIN, EXECUTE_PROGRAM_NAVIGATOR_FAVORITES,
    EXECUTE_PROGRAM_NAVIGATOR_HISTORY, CLOSE_PROGRAM } = Constants.WebSocketActions
const { CLICK } = LISTENER_TYPE
const { FUNCTIONS } = Constants.MenuActions

export const CM_BROWSE = "CM_BROWSE"
export const CM_SESSION_TABS = "CM_SESSION_TABS"
export const CM_FAVOURITE_BUTTONS = "CM_FAVOURITE_BUTTONS"

function ContextMenu(props) {
    const ws_context = React.useContext(WebSocketContext)
    const [contextData, setContextData] = React.useState({ visible: false, posX: 0, posY: 0, type: props.type, disabled: false, menuItemProps: [{}, {}, {}, {}] })

    const { desktopMenu, popupMenu, popupFavourites, popupHistory, ctxRef } = props
    let ws, currentFocusId

    const dispatch = useDispatch()

    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
    })

    React.useEffect(() => {
        if (ctxRef.current)
            ctxRef.current.displayContextMenu = (e, type, menuItemProps) => {
                let contextMenuProps = { visible: true, posX: e.clientX, posY: e.clientY, type: type, menuItemProps: menuItemProps }
                setContextData(contextMenuProps)
            }
    })

    addListener(CLICK, ctxRef, (event) => { //zavreni pomoci escape z app.js
        if (ctxRef.current && contextData.visible) {
            if (event.target.id !== "star_checkbox")
                setContextData({ ...contextData, visible: false })
        }
    })

    /**
    * Skrytí kontextového menu při kliknutí mimo vyhledávácí okno
    */
    addListener(CLICK, document, (event) => {
        if (ctxRef.current && !ctxRef.current.contains(event.target) && contextData.visible) {
            setContextData({ ...contextData, visible: false })
        }
    })


    //posunuti menu, kdyz by se melo zobrazit mimo okno - kliknu moc ke kraju
    React.useLayoutEffect(() => {
        if (contextData.posY + ctxRef.current?.offsetHeight > window.innerHeight)
            setContextData({ ...contextData, posY: contextData.posY - ctxRef.current?.offsetHeight })

        if (contextData.posY + ctxRef.current?.offsetHeight > window.innerHeight && contextData.posY !== 1) {
            let top = contextData.posY - ctxRef.current?.offsetHeight
            if (top < 0)
                top = 1
            setContextData({ ...contextData, posY: top })
        }

    }, [contextData])

    function renderPopupMenu() {
        let itemProps = {}

        let items = []
        if (props.type === MENU_MAIN) {
            items = popupMenu?.map((item, i) => {
                let enabled = item.sensitive
                if (contextData.type === "submenu") {
                    if (item.event === 1 || item.event === 5 || item.event === 6)
                        enabled = false
                } else if (contextData.type === "menu") {
                    if (item.event === 4)
                        enabled = false
                }

                itemProps = {
                    ...contextData.menuItemProps,
                    ...item
                }
                return dropdownItem(props.type, item.label, enabled, item.separator, itemProps, i)
            })
        } else if (props.type === MENU_FAVORITE) {
            items = popupFavourites?.map((item, i) => {
                let enabled = item.sensitive
                if (contextData.disabled && item.event === 1)
                    enabled = false

                itemProps = {
                    ...itemProps,
                    ...item
                }

                itemProps = {
                    ...contextData.menuItemProps,
                    ...item
                }
                return dropdownItem(props.type, item.label, enabled, item.separator, itemProps, i)
            })

        } else if (props.type === MENU_HISTORY) {
            items = popupHistory?.map((item, i) => {
                let enabled = item.sensitive
                if (contextData.disabled && item.event === 1)
                    enabled = false

                itemProps = {
                    ...itemProps,
                    ...item
                }

                itemProps = {
                    ...contextData.menuItemProps,
                    ...item
                }
                return dropdownItem(props.type, item.label, enabled, item.separator, itemProps, i)
            })

        } else if (props.type === CM_SESSION_TABS) {
            items = contextData?.menuItemProps?.map((item, i) => {
                return dropdownItem(props.type, item?.label, item?.enabled, item.isSeparator, item, i)
            })
        }

        return items
    }


    function dropdownItem(type, label, enabled, divider, menuItemProps, index) {

        const menuItemRef = React.useRef()

        addListenerWithRefHandler(CLICK, menuItemRef, e => {
            if (type === CM_SESSION_TABS) {
                //console.log("CM_SESSION_TABS", "focus2Sidebar done")
                //focus2Sidebar()

                if (menuItemProps.action == SHOW_IN_MENU) {
                    document.getElementById(MENU_MAIN + "-tab")?.click()
                    loadNavigatorDataFromRoot(menuItemProps.level, ws_context, dispatch, false)
                } else if (menuItemProps.action == CLOSE_ALL) {
                    ws_context.sendMessage(ws_context.createMessage(CLOSE_PROGRAM, ws_context.createRequest({ tabId: 1 })))
                } else if (menuItemProps.action == CLOSE_ALL_RIGHT) {
                    ws_context.sendMessage(ws_context.createMessage(CLOSE_PROGRAM, ws_context.createRequest({ tabId: (parseInt(menuItemProps.tabId) + 1) })))
                }

            } else {
                let action
                if (type === MENU_MAIN)
                    action = EXECUTE_PROGRAM_NAVIGATOR_MAIN
                else if (type === MENU_FAVORITE)
                    action = EXECUTE_PROGRAM_NAVIGATOR_FAVORITES
                else if (type === MENU_HISTORY)
                    action = EXECUTE_PROGRAM_NAVIGATOR_HISTORY

                ws_context.sendMessage(ws_context.createMessage(action, ws_context.createRequest({
                    ...menuItemProps
                })))
            }

            setContextData({ ...contextData, visible: false })
        })

        if (divider)
            return <li key={"li_" + index + "_" + type}><hr className="dropdown-divider" /></li>
        else
            return <li key={"li_" + index + "_" + type + "_" + menuItemProps.event}>
                <a ref={menuItemRef} className={"dropdown-item" + (enabled ? "" : " disabled")} href="#">{textWithMnemonic(label, false, true)}</a>
            </li >
    }

    if (contextData.type === CM_BROWSE && desktopMenu[FUNCTIONS].sensitivity === false)
        return

    logRender("ContextMenu")
    logRenderProps(props)
    return (
        <ul id={contextData.type} ref={ctxRef} className={contextData.visible ? "dropdown-menu webtop-dropdown-items show" : "dropdown-menu webtop-dropdown-items"}
            style={{
                display: (contextData.visible ? 'block' : 'none'),
                position: "fixed",
                left: contextData.posX,
                top: contextData.posY
            }}>
            {((contextData.type === CM_BROWSE) ? renderMenuItemChildrens(desktopMenu[FUNCTIONS], desktopMenu, false, "context_menu") : renderPopupMenu())}
        </ul>
    )
}

ContextMenu.defaultProps = {
    ctxRef: { current: {} }
}

function areEqual(prevProps, nextProps) {
    return (JSON.stringify(prevProps.desktopMenu) === JSON.stringify(nextProps.desktopMenu)
        && prevProps.popupMenu === nextProps.prevProps
        && prevProps.popupFavourites === nextProps.popupFavourites
        && prevProps.popupHistory === nextProps.popupHistory)
}

export default React.memo(ContextMenu, areEqual)