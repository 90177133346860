import React from 'react';
import { connect } from 'react-redux'

import ApsoLogo from '../../resources/images/ic_launcher.png'
import ButtonElement from '../desktop_server/ButtonElement'
import InputFieldElement from '../desktop_server/InputFieldElement'
import { logRender, logRenderProps } from '../../utils/logging'
import SettingsDropdown from '../../components/SettingsDropdown'

import Constants from '../../constants/constants'
import AppIcon from '../AppIcon';
import ToggleBoxElement from '../desktop_server/ToggleBoxElement';
import constants from '../../constants/constants';
const { USERNAME, RESTORE_PASSWORD, INPUT_PASSWORD, CHECKBOX_LDAP } = Constants.SpecialId

function Login(props) {
    const { data, childIds, currentFocusId, loginScreen } = props
    const { defaultButtonHandle, stornoButtonHandle } = data

    let btnOkProps, btnStornoProps, lblUsernameProps, lblPasswordProps, inputUsernameProps, inputPasswordProps, restorePasswordProps, ldapProps

    logRender("Login")
    logRenderProps(props)

    //nelezeni browse
    childIds.forEach(item => {
        let element = loginScreen[item];
        if (element.componentName === "ButtonElement") {
            //OK
            if (element.id === defaultButtonHandle)
                btnOkProps = element

            //STORNO
            if (element.id === stornoButtonHandle)
                btnStornoProps = element
        } else if (element.componentName === "LabelElement") {
            if (element.data.y === 1)
                lblUsernameProps = element
            if (element.data.y === 2)
                lblPasswordProps = element
        } else if (element.componentName === "InputFieldElement") {
            //v eng nevraci speciali id 57 pro username
            //if (element.data.specialId === USERNAME && element.data.visible)
            if (element.data.y === 1 && element.data.visible)
                inputUsernameProps = element
            if (element.data.y === 2 && element.data.visible)
                inputPasswordProps = element
        }

        //console.log("element", element)
        if (element.data.specialId === RESTORE_PASSWORD)
            restorePasswordProps = element
        else if (element.data.specialId === CHECKBOX_LDAP)
            ldapProps = element
    })

    return (
        <div>
            <div className='position-absolute top-0 end-0'>
                <SettingsDropdown />
            </div>
            <main className="w-90">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">

                                <div className="text-center mb-4">
                                    <h1 className="h2">{data.header}</h1>
                                    <p className="lead">{props.title}</p>
                                </div>

                                <div className="card" style={{ boxShadow: "0px 0px 20px 5px rgba(195,192,192,.5)" }}>
                                    <div className="card-body">
                                        <div className="m-sm-4">
                                            <div className="text-center">
                                                <AppIcon width={100} height={100} fontSize={"4x"} />
                                            </div>
                                            <form>
                                                <div className="form-group mb-4">
                                                    <label className="col-form-label" htmlFor={inputUsernameProps.id}>{lblUsernameProps.data.caption}</label>
                                                    <InputFieldElement id={inputUsernameProps.id} key={"inp" + inputUsernameProps.id} {...inputUsernameProps}
                                                        leftIcon={"fas fa-user"}
                                                        placeholder={lblUsernameProps.data.caption} />
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label" htmlFor={inputPasswordProps.id}>{lblPasswordProps.data.caption}</label>
                                                    <InputFieldElement id={inputPasswordProps.id} key={"inp" + inputPasswordProps.id} {...inputPasswordProps}
                                                        type="password"
                                                        leftIcon={"fas fa-key"}
                                                        placeholder={lblPasswordProps.data.caption} />
                                                </div>
                                                {restorePasswordProps !== undefined ? <div className="form-group mb-2">
                                                    <small>
                                                        <a href="#" id={restorePasswordProps.id}>Obnovit</a>
                                                    </small>
                                                </div> : <div className="form-group mb-4">
                                                </div>}
                                                {ldapProps !== undefined ? <div className="form-group align-items-center mb-4">
                                                    <ToggleBoxElement key={"toggleb" + ldapProps.id} {...ldapProps} />
                                                </div> : null}
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <ButtonElement id={btnOkProps.id} key={"btn" + btnOkProps.id} {...btnOkProps} />
                                                    </div>
                                                    <div className="col">
                                                        <ButtonElement id={btnStornoProps.id} key={"btn" + btnStornoProps.id} {...btnStornoProps} />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

function mapStateToProps(state, ownProps) {
    /*console.log("LOGIN -> mapStateToProps state: ", state)
    console.log("LOGIN -> mapStateToProps ownProps: ", ownProps)*/
    return {
        ...state.desktopSession[state.ws.currentTabId][state.ws.currentMaskID][ownProps.id],
        currentFocusId: state.ws.focusId,
        loginScreen: state.desktopSession[state.ws.currentTabId][state.ws.currentMaskID],
        title: state.app.title,
    }
}

export default connect(mapStateToProps)(Login)
