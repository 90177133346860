import React from 'react'

import { getBootstrapVariables } from '../../theme/getThemeVariables'
import { logRender, logRenderProps } from '../../utils/logging'

export function getIconProps(value, columnHeader) {
    return processHeaderProps(value, columnHeader)
}

export default function Icon(props) {
    let { value, columnHeader } = props

    let iconProps = processHeaderProps(value, columnHeader)

    let style = {}
    if (iconProps.color != undefined)
        style = { color: iconProps.color }

    logRender("Icon")
    logRenderProps(props)

    /*if (iconProps?.badgeClass)
        return <span className="badge badge-danger-light">
            {iconProps.tooltip}
        </span>*/

    return <span style={style}>
        <i id={props.id} className={iconProps.icon} title={iconProps.tooltip} />
    </span>
}

function processHeaderProps(value, columnHeader) {
    const { browseListColumnIconID, browseListColumnValue, browseListColumnIconTooltip } = columnHeader
    let ids, values, tooltips, backgroundColor

    const CIRCLE_BLUE = "1"
    const CIRCLE_GREEN = "2"
    const CIRCLE_RED = "3"
    const CIRCLE_YELLOW = "4"
    const CIRCLE_GREY = "5"
    const STAR_RED = "6"
    const STAR_YELLOW = "7"
    const STAR_BLUE = "8"
    const STAR_GREEN = "9"
    const STAR_GREY = "10"
    const CHECK = "11"
    const TRASH = "12"
    const HAND = "13"
    const EXCLAMATION_TRIANGLE = "14"
    const EXCLAMATION_CIRCLE = "15"
    const FOLDER = "16"
    const WRENCH = "17"
    const CALENDAR = "18"
    const CIRLCE_PINK = "19"
    const STAR_PURPLE = "20"
    const TOMBSTONE = "21"

    ids = browseListColumnIconID.split("\u0001")
    values = browseListColumnValue.split("\u0001")


    let index = values.indexOf(value)
    let key = ids[index]

    let icon, color, badgeClass
    let tooltip = ""
    if (browseListColumnIconTooltip != undefined) {
        tooltips = browseListColumnIconTooltip.split("\u0001")
        tooltip = tooltips[index]
    }


    /*
    console.log("--ICON", props)
    console.log("IDIS", ids)
    console.log("VAALUES", values)
    console.log("INDEX", index)
    console.log("KEY", key)*/

    switch (key) {
        case CIRCLE_BLUE:
            icon = "fas fa-circle"
            color = getBootstrapVariables().blue
            backgroundColor = getBootstrapVariables().blue100
            badgeClass = "badge-primary-light"
            break
        case CIRCLE_GREEN:
            icon = "fas fa-circle"
            color = getBootstrapVariables().green
            backgroundColor = getBootstrapVariables().green100
            badgeClass = "badge-success-light"
            break
        case CIRCLE_RED:
            icon = "fas fa-circle"
            color = getBootstrapVariables().red
            backgroundColor = getBootstrapVariables().red100
            badgeClass = "badge-danger-light"
            break
        case CIRCLE_YELLOW:
            icon = "fas fa-circle"
            color = getBootstrapVariables().yellow
            backgroundColor = getBootstrapVariables().yellow100
            badgeClass = "badge-warning-light"
            break
        case CIRCLE_GREY:
            icon = "fas fa-circle"
            color = getBootstrapVariables().gray
            backgroundColor = getBootstrapVariables().gray100
            badgeClass = "badge-secondary-light"
            break
        case STAR_RED:
            icon = "fas fa-star"
            color = getBootstrapVariables().red
            backgroundColor = getBootstrapVariables().red100
            break
        case STAR_YELLOW:
            icon = "fas fa-star"
            color = getBootstrapVariables().yellow
            backgroundColor = getBootstrapVariables().yellow100
            break
        case STAR_BLUE:
            icon = "fas fa-star"
            color = getBootstrapVariables().blue
            backgroundColor = getBootstrapVariables().blue100
            break
        case STAR_GREEN:
            icon = "fas fa-star"
            color = getBootstrapVariables().green
            backgroundColor = getBootstrapVariables().green100
            break
        case STAR_GREY:
            icon = "fas fa-star"
            color = getBootstrapVariables().gray
            backgroundColor = getBootstrapVariables().gray100
            break
        case CHECK:
            icon = "fas fa-check"
            color = getBootstrapVariables().green
            backgroundColor = getBootstrapVariables().green100
            break
        case TRASH:
            icon = "fas fa-trash-alt"
            color = getBootstrapVariables().red
            backgroundColor = getBootstrapVariables().red100
            break
        case HAND:
            icon = "fas fa-hand-paper"
            color = getBootstrapVariables().red
            backgroundColor = getBootstrapVariables().red100
            break
        case EXCLAMATION_TRIANGLE:
            icon = "fas fa-exclamation-triangle"
            color = "#FF5722"
            break
        case EXCLAMATION_CIRCLE:
            icon = "fas fa-exclamation-circle"
            color = getBootstrapVariables().blue
            backgroundColor = getBootstrapVariables().blue100
            break
        case FOLDER:
            icon = "fas fa-folder"
            color = getBootstrapVariables().blue
            backgroundColor = getBootstrapVariables().blue100
            break
        case WRENCH:
            icon = "fas fa-wrench"
            color = getBootstrapVariables().blue
            backgroundColor = getBootstrapVariables().blue100
            break
        case CALENDAR:
            icon = "far fa-calendar-alt"
            color = getBootstrapVariables().gray
            break
        case CIRLCE_PINK:
            icon = "fas fa-circle"
            color = getBootstrapVariables().pink
            backgroundColor = getBootstrapVariables().pink100
            break
        case STAR_PURPLE:
            icon = "fas fa-star"
            color = getBootstrapVariables().purple
            backgroundColor = getBootstrapVariables().purple100
            break
        case TOMBSTONE:
            icon = "fas fa-tombstone"
            color = "#9C27B0"
            break
        default:
            break
    }

    return { icon, color, tooltip, backgroundColor, badgeClass }
}