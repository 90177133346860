import React from 'react'
import { connect } from 'react-redux'

import MessageDialog from './MessageDialog'

function RenderMessageDialogs(props) {
    const { messageDialog } = props

    let dialogs = []
    //console.log("render dialogs", messageDialog);
    if (messageDialog) {
        //dialogy se zobrazuji postupne od nejpozdeji vlozeneho
        messageDialog.slice().reverse().forEach((message, index) => {
            dialogs.push(<MessageDialog key={"md" + index} {...message} {...index} />)
        })
    }

    return dialogs
}

function mapStateToProps(state) {
    return {
        messageDialog: state.messageDialog
    }
}

export default connect(mapStateToProps)(RenderMessageDialogs)