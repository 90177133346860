import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { Toast } from 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { getStore } from '..'
import { setNotificationOptions } from '../store/actions/ws_actions'

function ToastStack(props) {
    const { notifications } = props
    return <div className="toast-container top-0 end-0 p-3">
        {notifications
            .filter(notification => {
                //console.log("FILTRUJU", notification.showed, notification.showed === false)
                return notification.showed === false
            })
            .map((notification, index) => {
                return <MemoizedCustomToast key={"notification_" + index} notification={notification} index={index} />
            })}
    </div>
}

const MemoizedCustomToast = React.memo(CustomToast, (prevProps, nextProps) => {
    return (prevProps.notification.requestId === nextProps.notification.requestId && prevProps.notification.showed === nextProps.notification.showed)
})

function CustomToast(props) {
    const toastRef = React.useRef()
    const dispatch = useDispatch()

    const { notification } = props

    React.useEffect(() => {
        const bsToast = new Toast(toastRef.current)
        bsToast.show()
        const timer = setTimeout(() => {
            if (getStore().getState().notifications.find(item => (item.requestId === notification.requestId && item.showed === false)))
                dispatch(setNotificationOptions(notification.requestId, { showed: true }))

            clearTimeout(timer)
        }, 6 * 1000)

        return () => {
            //nefunguje dispose - classlist of undefined            
            /*if (Toast.getInstance(toastRef.current) !== null)
                bsToast.dispose()*/
        }
    }, [notification])

    //1=Default, 2=Success, 3=Warning, 4=Error 
    let backgroundColor
    switch (notification.type) {
        case 1:
            backgroundColor = "text-bg-primary"
            break
        case 2:
            backgroundColor = "text-bg-success"
            break
        case 3:
            backgroundColor = "text-bg-warning"
            break
        case 4:
            backgroundColor = "text-bg-danger"
            break
        default:
            break
    }

    return <div
        ref={toastRef}
        className={"toast align-items-center border-0 " + backgroundColor}
        role="alert"
        data-bs-autohide="false">
        <div className="d-flex">
            <div className="toast-body" style={{ whiteSpace: 'pre-wrap', }}>
                <strong>{notification.message}</strong>
            </div>
            <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
}

function mapStateToProps(state, ownProps) {
    return {
        notifications: state.notifications
    }
}

export default connect(mapStateToProps)(ToastStack)
