import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.bundle.min.js'

import { logRender, logRenderProps } from '../utils/logging'
import { LISTENER_TYPE } from '../hooks/desktopListener'

/**
 * 
 * @param {{tooltiptext: string, shortcuts: Array.<string>, placement: {'top', 'right', 'bottom', 'left'}}} props 
 */
function BSTooltip(props) {
    const tooltipRef = useRef()
    let bsProps = bsProps
    const DELAY = 600
    const HIDE_AFTER = 2000

    useEffect(() => {
        const controller = new AbortController()

        if (props.tooltipEnabled) {
            let tooltiptext = ""
            let shortcutBadges = ""
            let placement = "top"

            if (props.tooltiptext)
                tooltiptext = props.tooltiptext

            props.shortcuts?.forEach(shortcut => {
                if (shortcut !== undefined && shortcut !== "")
                    shortcutBadges += "<span key=" + 'span_' + props.key + " class='badge text-bg-warning ms-2'>" + shortcut + "</span>"
            })

            if (props.placement)
                placement = props.placement

            let tooltip = undefined
            if (tooltiptext !== "" || shortcutBadges !== "") {
                /* tigger: 'hover' + delay: 500 
                    -> nefungovalo to spravne (tooltip se napr. zobrazil kdyz byl dialog nad elementem, ktery ma tooltip)
                    , proto se  to dela manualne pomoci show/hide v metodach onMouseEnter a onMouseLeave*/
                tooltip = new Tooltip(tooltipRef.current, {
                    title: tooltiptext + shortcutBadges,
                    trigger: "manual",
                    html: true,
                    placement: placement
                })

                if (tooltipRef.current) {
                    tooltipRef.current.addEventListener(LISTENER_TYPE.MOUSE_ENTER, onMouseEnterListener, { signal: controller.signal })
                    tooltipRef.current.addEventListener(LISTENER_TYPE.MOUSE_LEAVE, onMouseLeaveListener, { signal: controller.signal })
                }
            }

            let hasMousePointer = false
            let executorTimeoutId, hiderTimeoutId
            function onMouseEnterListener(e) {
                e.preventDefault()

                hasMousePointer = true
                executorTimeoutId = setTimeout(() => {
                    if (hasMousePointer && tooltip !== null && tooltip._element !== null) {
                        tooltip.show()
                        hiderTimeoutId = setTimeout(() => {
                            tooltip.hide()
                            clearTimeout(hiderTimeoutId)
                        }, HIDE_AFTER)
                        clearTimeout(executorTimeoutId)
                    }
                }, DELAY)
            }

            function onMouseLeaveListener(e) {
                hasMousePointer = false
                if (tooltip !== null && tooltip._element !== null) {
                    //console.log("HIDE", tooltip)
                    tooltip.hide()
                    clearTimeout(hiderTimeoutId)
                    clearTimeout(executorTimeoutId)
                }
            }

            return () => {
                if (tooltip)
                    tooltip.dispose()

                controller.abort()
            }
        }
    })

    let cleanedProps = {}
    for (var key in props) {
        if (key !== "tooltiptext"
            && key !== "shortcutBadges"
            && key !== "tooltipEnabled"
            && key != "dispatch")
            cleanedProps = {
                ...cleanedProps,
                [key]: props[key]
            }
    }

    if (props.ref !== undefined)
        tooltipRef = props.ref


    logRender("BSTooltip")
    logRenderProps(props)
    if (props.tooltipEnabled)
        return <div
            ref={tooltipRef}
            style={props.style}
            {...cleanedProps}> {props.children}</div >
    else
        return <div {...cleanedProps}> {props.children}</div >
}

BSTooltip.protoTypes = {
    tooltiptext: PropTypes.string,
    shortcuts: PropTypes.arrayOf(PropTypes.string),
    placement: PropTypes.placement,
}

function mapStateToProps(state, ownProps) {
    return {
        tooltipEnabled: state.tooltipEnabled
    }
}

export default connect(mapStateToProps)(BSTooltip)

