import React from 'react'
import PropTypes from 'prop-types'

import { logRender } from './utils/logging'
import constants from './constants/constants'
import { useStore } from 'react-redux'
import styles from './constants/styles'
import RenderMessageDialogs from './components/dialogs/RenderMessageDialogs'

/**
 * 
 * @param {{errorMessage: string, enableTimesIcon: boolean, enableReloadButton: boolean, delayed: boolean}} props 
 */
export default function ConnectionProblem({ errorMessage, enableTimesIcon, enableReloadButton, delayed }) {

    //defaultni hodnoty pokud nebyl nacten messageContainer
    let restartButtonText = constants.LocalMessageContainer.RESTART

    //hodnoty hlasek z desktop serveru
    const messageContainer = useStore().getState().app?.messageContainer
    if (messageContainer && messageContainer[constants.MessageContainer.RESTART])
        restartButtonText = messageContainer[constants.MessageContainer.RESTART]

    if (delayed === undefined)
        delayed = false

    const [isRendered, setIsRendered] = React.useState(!delayed)

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsRendered(true)
        }, 10000)

        return () => clearTimeout(timer)
    }, [])

    if (!isRendered) {
        return <RenderMessageDialogs />
    }


    logRender("NoConnection")
    console.log("ConnectionProblem", isRendered)
    return (<div className="container d-flex flex-column">
        <div className="row vh-100">
            <div className="mx-auto d-table">
                <div className="d-table-cell align-middle">
                    <div className="d-flex justify-content-center">
                        {enableTimesIcon ? <div className="text-center">
                            <i className="fal fa-times fa-10x" />
                        </div> : <div className="text-center spinner-border" style={{
                            width: '3rem',
                            height: '3rem'
                        }} />}
                    </div>

                    <div className="text-center pt-2">
                        {errorMessage}
                    </div>
                    <RenderMessageDialogs />

                    {enableReloadButton ? <div className="text-center pt-2">
                        <button
                            className="btn btn-primary ms-1"
                            style={styles.buttonStyle}
                            onClick={e => {
                                window.location.reload()
                            }}>{restartButtonText}</button>
                    </div> : null}
                </div>
            </div>
        </div>
    </div>)
}

ConnectionProblem.prototype = {
    errorMessage: PropTypes.string,
    enableTimesIcon: PropTypes.bool,
    enableReloadButton: PropTypes.bool,
    delayed: PropTypes.bool,
}