import { useEffect } from 'react'

import { getStore } from '../index'
import { hasSessionFocus, focusElement, hasNavbarFocus, hasNavigationFocus } from '../focus_actions'

/*
- nastaveni focusu dle serveru pomoci hodnoty ve storu pokud ma session focus
- nelze nastavovat v App.js, protoze jeste elementy nemusi byt vykresleny*/
export default function useFocusObserver(elementId, calledFromComponent) {
    useEffect(() => {
        /*if (getStore().getState().ws.focusId === elementId)
            console.log("useFocusObserver", elementId, document.activeElement.getAttribute("id"), document.activeElement.getAttribute("id") === elementId?.toString())*/

        

        if (elementId
            && getStore().getState().ws.focusId === elementId
            && document.activeElement.getAttribute("id") !== elementId?.toString()
            && hasSessionFocus()
            && !hasNavbarFocus()
            && !hasNavigationFocus()
        ) {
            focusElement(elementId, "useFocusObserver(" + calledFromComponent + ")")
        }
    })
}