import React from 'react'
import { connect } from 'react-redux'

import useStoreSubscription from '../hooks/useStoreSubscription'
import Constants from '../constants/constants'
import { logRender, logRenderProps } from '../utils/logging'
import ContextMenu, { CM_SESSION_TABS } from './ContextMenu'
import { getStore } from '../index'
import { addListener, addListenerWithRefHandler } from '../hooks/desktopListener'
import { LISTENER_TYPE } from '../hooks/desktopListener'
import { focus2SessionMain } from '../focus_actions'

import BSTooltip from './BSTooltip'

const { CONTEXT_MENU, CLICK, MOUSEDOWN } = LISTENER_TYPE

const { CLOSE_TAB_BTN } = Constants.CloseButtons

export const SHOW_IN_MENU = "SHOW_IN_MENU"
export const CLOSE_ALL = "CLOSE_ALL"
export const CLOSE_ALL_RIGHT = "CLOSE_ALL_RIGHT"

function SessionTabs(props) {
    const [hasFocus, setFocus] = React.useState(false)
    const refContextMenu = React.useRef({})
    let keys = Object.keys(props.desktopSession)

    let messageContainer = getStore().getState().app.messageContainer

    let ws, currentTabId, desktopMenu
    useStoreSubscription(store => {
        ws = store.ws
        currentTabId = ws.currentTabId
        desktopMenu = store.desktopMenu
    })

    React.useEffect(() => {
        const controller = new AbortController()

        let elMain = document.getElementById("main_session")

        function focusin(e) {
            const enteringParent = !elMain.contains(e.relatedTarget)
            if (enteringParent) {
                //console.log("focus in", e)
                setFocus(true)
            }
        }

        function focusout(e) {
            const leavingParent = !elMain.contains(e.relatedTarget)
            if (leavingParent) {
                //console.log("focus out -> ", e)
                setFocus(false)
            }
        }

        elMain.addEventListener("focusin", focusin, { signal: controller.signal })
        elMain.addEventListener("focusout", focusout, { signal: controller.signal })

        return () => {
            controller.abort()
        }
    })

    function NavItem({ tabId, tabName }) {
        let isLastTab = false
        const refNavItem = React.useRef()

        if (currentTabId.toString() === tabId.toString())
            isLastTab = true

        let enableContextMenu = false
        if (tabName != undefined && tabName.charAt("0") > -1)
            enableContextMenu = true

        addListenerWithRefHandler(MOUSEDOWN, refNavItem, e => {
            focus2SessionMain()
        })
        addListener(CONTEXT_MENU, refNavItem, e => {
            e.preventDefault()

            if (!enableContextMenu) return
            refContextMenu.current.displayContextMenu(e, CM_SESSION_TABS, [
                {
                    label: messageContainer['showInMenu'],
                    action: SHOW_IN_MENU,
                    enabled: true,
                    isSeparator: false,
                    level: tabName.substring(0, tabName.indexOf(". "))
                },
                {
                    isSeparator: true
                },
                {
                    label: desktopMenu[Constants.MenuActions.CLOSE_ALL_TABS].label,
                    action: CLOSE_ALL,
                    enabled: true,
                    isSeparator: false
                },
                {
                    label: messageContainer['closeTabsRight'],
                    action: CLOSE_ALL_RIGHT,
                    enabled: (tabId < (keys.length - 1)) ? true : false,
                    isSeparator: false,
                    tabId: tabId
                }
            ])
        })

        function CloseButton() {
            return <BSTooltip
                style={{ paddingLeft: '1ch', display: 'flex' }}
                tooltiptext={desktopMenu[Constants.MenuActions.CLOSE_TAB].tooltip}
                shortcuts={[desktopMenu[Constants.MenuActions.CLOSE_TAB].accelerator, desktopMenu[Constants.MenuActions.CLOSE_TAB].accelerator2, desktopMenu[Constants.MenuActions.CLOSE_TAB].accelerator3]}>
                <button id={CLOSE_TAB_BTN} type="button" className="btn-close" aria-label="Close" />
            </BSTooltip>
        }

        return (
            <li className="nav-item" ref={refNavItem}>
                <p className={"nav-link " + (isLastTab ? "active" : "disabled")} href="#" style={{
                    padding: '.5rem .5rem .2rem .5rem',
                    borderBottom: (hasFocus && isLastTab) ? 'solid var(--bs-primary)' : '3px solid transparent',
                    cursor: "pointer"
                }}>
                    <div style={{ display: 'flex' }}>
                        <div>{tabName}</div>
                        {(isLastTab ? <CloseButton /> : "")}
                    </div>
                </p>
            </li >)
    }

    //console.log("SESSION TABS -> render()", props)
    //logRender("SessionTabs ")
    logRenderProps(props)
    return (
        <React.Fragment>
            <ul className="nav nav-tabs" style={{ "--bs-nav-tabs-border-width": 0 }}>
                {keys.map(tabId => {
                    if (tabId !== "0") {
                        let tabName = ""
                        if (props.desktopSession[tabId].name != undefined)
                            tabName = props.desktopSession[tabId].name

                        return <NavItem key={"navitem" + tabId} tabId={tabId} tabName={tabName} />
                    }
                })}
            </ul>
            <ContextMenu ctxRef={refContextMenu} type={CM_SESSION_TABS} />
        </React.Fragment>
    )
}

function mapStateToProps(state, ownProps) {
    //console.log("state", state.desktopSession)
    //console.log("ownProps", ownProps.desktopSession)

    //odstraneni objektu v masce, zustane jenom nazev masky
    let tabs = {}
    for (const [tabKey, tab] of Object.entries(state.desktopSession)) {
        //console.log(tab, tab.name)
        let maskName
        for (const [maskKey, mask] of Object.entries(tab)) {
            //console.log("maskKey", maskKey, "mask", mask.name)
            maskName = mask.name
        }

        tabs = {
            ...tabs,
            [tabKey]: {
                name: maskName
            }
        }
    }

    //console.log("TABS", tabs)
    return {
        desktopSession: tabs,
    }
}

function areEqual(prevProps, nextProps) {
    //console.log("prevProps", prevProps)
    //console.log("nextProps", nextProps)
    return (JSON.stringify(prevProps.desktopSession) === JSON.stringify(nextProps.desktopSession))
}

export default connect(mapStateToProps)(React.memo(SessionTabs, areEqual))
