import React from 'react'
import { connect, useStore } from 'react-redux'

import { clientUUID, getStore } from './index'
import SessionNode from './components/SessionNode'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'
import { WebSocketContext } from './services/WebSocket'
import Navigation from './components/Navigation'
import Spinner from './components/Spinner'
import { initDB, dropDatabase } from './store/indexedDB'
import RenderMessageDialogs from './components/dialogs/RenderMessageDialogs'
import SessionTabs from './components/SessionTabs'
import Constants from './constants/constants'
import { LISTENER_TYPE } from './hooks/desktopListener'
import { focus2Sidebar, focusElement, getRelatedTargetFocusArea, hasSidebarFocus, ACTION_ESCAPE_NAVBAR, ACTION_CLICK_OUTSIDE_OF_NAVBAR } from './focus_actions'
import { onValuesChangeByHandle, changeFocusAreaWithBrowseFocusLoss, showAboutApp, showContact, onValuesChangeInMask } from './store/actions/store_actions'
import { getSpecialIdElement } from './store/storageMethods'

import { doTabAction, disableOtherListeners, getEventElement, isEntryable } from './app_actions'
import { hasSessionFocus, hasNavbarFocus } from './focus_actions'
import { handleToggleBoxElementOnChange } from './components/desktop_server/ToggleBoxElement'
import { handleSelectionElementOnChange, handleSelectionElementOnClick } from './components/desktop_server/SelectionElement'
import { handleButtonElement } from './components/desktop_server/ButtonElement'
import { handleRadioSetElementClick } from './components/desktop_server/RadioSetElement'
import { getElementData, saveValue2Store, parentSensitivity, sensitivityRecursively } from './components/desktop_server/elementMethods'
import { handleNavbarEvents } from './components/Navbar'
import { getUserName } from './components/Navigation'
import { logDebug } from './utils/logging'
import ModalDialogElement, { MODAL_DIALOG_ABOUT, MODAL_DIALOG_CONTACT } from './components/desktop_server/ModalDialogElement'
import { browseAction } from './components/browse/browseAction'
import BSToast from './components/BSToast'
import { getBrowser } from './utils/clientInfo'
import { getEmptyRowProp } from './utils/actions'
import pjson from '../package.json'
import { CM_SESSION_TABS, CM_BROWSE } from './components/ContextMenu'
import { BREAK_BUTTON } from './components/desktop_server/ModalDialogElement'
import RestoreConnection from './RestoreConnection'
import { MENU_FAVORITE, MENU_HISTORY, MENU_MAIN } from './components/SideMenu'
import ConnectionProblem from './ConnectionProblem'
import { CUSTOM_MODAL_DIALOG_ID } from './components/CustomModalDialog'
import { RESISIZE_BROWSE_INPUT } from './components/browse/BrowseBottom'
import ToastStack from './components/ToastStack'
import { func } from 'prop-types'
import Dashboards from './components/Dashboard/Dashboards'

const { Keyboard, SpecialId, WebSocketActions, SetValueRequest, CloseButtons, MenuActions, ElementsIds, MessageContainer, InternalElementState } = Constants
const { KEYDOWN, CLICK, DOUBLE_CLICK, CONTEXT_MENU, MOUSEDOWN, ON_CHANGE } = LISTENER_TYPE
const { LOCKED_RECORD_BREAK, BUTTON_MODAL_NO } = SpecialId
const { BREAK, SET_VALUE_REQUEST, CLOSE_PROGRAM, INTERRUPT, SET_LOGGED_USERNAME } = WebSocketActions
const { EVENT_ENTRY, MENU_ACTION, CHOOSE_ACTION } = SetValueRequest
const { CLOSE_FRAME_BTN, CLOSE_MASK_BTN, CLOSE_TAB_BTN, CLOSE_DIALOG_BTN, CLOSE_DIALOG_SYSTEM_ERROR_BTN } = CloseButtons
const { CLOSE_PROGRAM: MENU_ITEM_CLOSE_PROGRAM, SHOW_DETAIL_RECORD, CLOSE_TAB } = MenuActions

export function findElementByPartialId(partialId) {
    const elements = document.getElementsByTagName('*')
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i]
        if (element.id.includes(partialId)) {
            return element
        }
    }
    return null
}

export function couldCallWS(ws_context) {
    //POKUD EXIDSTUJE DIALOG SE SYSTEMOVOU CHYBOUR "ERROR_SYSTEM", NESMI SE NIC SPUSTIT
    let messageDialog = getStore().getState().messageDialog
    let existErrorTypDialog = false
    messageDialog.forEach(dialog => {
        if (dialog.errorType === Constants.ERRORS.ERROR_SYSTEM)
            existErrorTypDialog = true
    })

    if (ws_context.getRequestsQueue().size() === 0 && existErrorTypDialog === false)
        return true

    return false
}

function preventDefault(event, id) {
    console.log("@PREVENT DEFAULT", id)
    event.preventDefault()
}

function stopPropagation(event, id) {
    console.log("@STOP PROPAGATION", id)
    event.stopPropagation()
}

function App(props) {
    const ws_context = React.useContext(WebSocketContext)
    const store = useStore()
    const sessionRef = React.useRef()
    const { createMessage, createRequest, sendMessage, getTimeOfLastRequest, sendMessageImmediately } = ws_context
    const { loggedIn, dispatch } = props
    let ws = store.getState().ws
    let dialogs = []

    React.useEffect(() => {
        if (loggedIn) sendMessage(createMessage(SET_LOGGED_USERNAME, createRequest({ username: getUserName() })))
    }, [loggedIn])

    React.useEffect(() => {
        //nastaveni title v tabu
        let currentMaskName = "Apso"
        let tabKeys = Object.keys(props.desktopSession)
        const numberOfTabs = tabKeys.length - 1
        if (numberOfTabs > 0) {
            const maskKeys = Object.keys(props.desktopSession[numberOfTabs])
            const lastMask = props.desktopSession[numberOfTabs][maskKeys[maskKeys.length - 1]]
            if (lastMask?.name)
                currentMaskName = "Apso - " + lastMask.name
        }

        if (document.title !== currentMaskName)
            document.title = currentMaskName


        const controller = new AbortController()

        function focusout(e) {
            //console.log("out", e)
            const leavingParent = !sessionRef.current?.contains(e.relatedTarget)
            if (leavingParent)
                dispatch(changeFocusAreaWithBrowseFocusLoss(getRelatedTargetFocusArea(e.relatedTarget)))
        }

        if (sessionRef.current && sessionRef.current !== null) {
            sessionRef.current.addEventListener("focusout", focusout, { signal: controller.signal })
        }

        return () => {
            controller.abort()
        }
    })

    /*Pri prekresleni se nastavuje focus dle storu*/
    React.useEffect(() => {
        /*console.log("APP", document.activeElement)
        console.log("HAS SIDEBAR FOCUS", hasSidebarFocus(), store.getState())
        console.log("HAS SESSION FOCUS", hasSessionFocus(), store.getState())*/

        function existModalDialogInRoot() {
            for (const [key, value] of Object.entries(props.desktopSession[0][0])) {
                if (value.componentName === "ModalDialogElement")
                    return true
            }
            return false
        }

        if (loggedIn) {
            /*pri otevrem dialogu a prepntuni do sidebar (F2) ma focus session i sidebar dle metod hasSessionFocus(store.focusArea === null), hasSidebarFocus*/
            if (hasSessionFocus() && !hasSidebarFocus()) {
                focusElement(ws.focusId, "after app render")

                //oznaceni textu inputu
                let element = document.getElementById(ws.focusId)
                if (element && element.tagName.toLowerCase() === "input") {
                    element.select()
                    //console.log("@DEBUG SELECT",)
                }

            } else if (Object.keys(props.desktopSession).length === 1 && !existModalDialogInRoot()) {
                //pokud neexistuje zadny element v session, tak se provede focus na sidebar
                focus2Sidebar()
            }
        } else
            focusElement(ws.focusId, "after app render")
    })

    React.useEffect(() => {
        /*console.log("------------")
        console.log("--APP INIT--")
        console.log("------------")*/

        //vyzobani url parametru
        let clientParams = []
        let queryParameters = new URLSearchParams(location.search)
        queryParameters?.forEach((value, key) => {
            clientParams.push(key + "=" + value)

            if (key === "menuLevel")
                sessionStorage.setItem("menuLevel", value)
        })

        history.replaceState(null, "", "/") //smazani url parametru       

        initDB(clientUUID)
            .then(result => {
                //console.log(result + clientUUID)
                ws_context.initWS(clientParams, status => { }, "APP INIT")
            })
            .catch(error => {
                console.error('Error:', error)
                if (confirm("IndexedDB initialization problem. Page will be reloaded." + error))
                    window.location.reload()
            })

        const controller = new AbortController()
        window.addEventListener('beforeunload', e => {
            dropDatabase()
        }, { signal: controller.signal })

        return () => { controller.abort() }
    }, [])

    React.useEffect(() => {
        function handleUserInput(event) {
            let ws = store.getState().ws

            //id elementu muze byt slozene z vice casti -> RadioSetElement
            let element = getEventElement(event)

            logDebug("handleUserInput ELEMENT", element)
            logDebug("handleUserInput", event.type, event)
            //console.log("active element", document.activeElement)

            //console.log("handleUserInput", event.type, event)
            //console.log("element", element)

            //Preruseni (Ctrl+S)
            let interruptMenuItem = getStore().getState().desktopMenu[Constants.MenuActions.INTERRUPT]
            if (interruptMenuItem.sensitivity && event.keyCode === Keyboard.S && event.ctrlKey && Object.keys(store.getState().desktopSession).length > 1) {
                disableOtherListeners(event, "App.handleUserInput()")
                sendMessageImmediately(createMessage(INTERRUPT, createRequest({ action: INTERRUPT, focus: Constants.MenuActions.INTERRUPT })), INTERRUPT)
                return
            }

            if ((event.srcElement.id === BREAK_BUTTON && event.type === CLICK) || (event.srcElement.id === BREAK_BUTTON && event.type === KEYDOWN && (event.key === "Enter" || event.key === " "))) {
                var btn = document.getElementById(BREAK_BUTTON)
                if (btn)
                    btn.disabled = true
                sendMessageImmediately(createMessage(INTERRUPT, createRequest({ action: INTERRUPT, focus: Constants.MenuActions.INTERRUPT })), INTERRUPT)
                return
            }

            if (ws_context.getRequestsQueue().size() > 0) {
                //console.log("block UI ", event)
                if (event.target.getAttribute('id') === ElementsIds.A_DOWNLOAD)
                    return

                //keydown
                if (element !== undefined && element.data.specialId == LOCKED_RECORD_BREAK && (event.type === "mousedown" || event.type === "click" || (event.type === "keydown" && (event.key === "Enter" || event.code === "Space")))) {
                    if (!event.target.disabled)
                        sendMessage(createMessage(BREAK, createRequest({ action: BREAK }))) //proved break                    

                    event.target.disabled = true //deaktivuj, aby neposilal znovu
                } else {

                    /////////////////////////////////////////
                    // blokace UI pri zpracovani pozadavku //
                    /////////////////////////////////////////
                    // vsechny eventy zaregistrované v App jsou přerušeny


                    //console.error("udalost preskocena", event.type, event.target, event)
                    //console.log("QUEUE", ws_context.getRequestsQueue().get())
                    disableOtherListeners(event, "queue size" + ws_context.getRequestsQueue().size(), element)
                    return
                }
            }

            //function functionDropd

            function focus2Session() {
                //if (ws.currentTabId > 0)
                focusElement(ws.focusId, "handleUserInput")
            }

            function isMoveButton(specialId) {
                switch (specialId) {
                    case SpecialId.NAVIGATOR_FORWARD:
                        return true
                    case SpecialId.NAVIGATOR_BACKWARD:
                        return true
                }
                return false
            }

            /*
            ---------------------------------
            | predprocessor event listeneru |
            ---------------------------------
            - provede se dříve než dojde k události v komponentě, lze zakazat distribuci udalosti dál
            - vyuziva se kvuli rizeni focusu ze strany progressu a ne z jedntotlivych html elementu,
            pokud by udalost probulala az do elementu doslo by k automatickemu focusu, ktery muze byt odlisny od toho co je na serveru
         
            osetreny obecne udalosti
                - keydown:
                    TAB, SHIFT+TAB, keydown->ESC, CLICK
                - mousedown:
                    CLICK
                - keydown:
                    sipky (↑ = SHIFT+TAB,↓ = TAB)
         
            Odesilani hodnoty pri zmene:
                - RadioSetElement, ComboboxElement, SelectionElement, ToggleBoxElement
            Odesilani hodnoty pri opusteni pole:
                - InputFieldElement, EditorElement(uklada se pouze pozice kursoru, promenna byl zmenen text ano/ne)
         
            priklady slozitejsich pripadu:
            -   pri focusu na EditorElement a klikem na cerveny trojuhelnik pro nabidku v InputFieldElemntu se musi spustit
                SET_ATTRIBUTE + EVENT_ENTRY + MENU_ACTION.MENU_ACTION_OFFER
            */

            //console.log("QUEUE SIZE", ws_context.getRequestsQueue().size())

            //POKUD EXIDSTUJE DIALOG SE SYSTEMOVOU CHYBOUR "ERROR_SYSTEM", NESMI SE NIC SPUSTIT
            let messageDialog = store.getState().messageDialog
            let existErrorTypDialog = false
            messageDialog.forEach(dialog => {
                if (dialog.errorType === Constants.ERRORS.ERROR_SYSTEM)
                    existErrorTypDialog = true
            })

            if (ws_context.getRequestsQueue().size() === 0 && existErrorTypDialog === false) {

                /*sidebar ctr + 1/2/3*/
                if (event.ctrlKey && (event.code === "Numpad1" || event.code === "Digit1")) {
                    preventDefault(event, "@1")
                    focus2Sidebar(ACTION_CLICK_OUTSIDE_OF_NAVBAR)
                    document.getElementById(MENU_MAIN + "-tab")?.click()
                    return
                } else if (event.ctrlKey && (event.code === "Numpad2" || event.code === "Digit2")) {
                    preventDefault(event, "@2")
                    focus2Sidebar(ACTION_CLICK_OUTSIDE_OF_NAVBAR)
                    document.getElementById(MENU_FAVORITE + "-tab")?.click()
                    return
                } else if (event.ctrlKey && (event.code === "Numpad3" || event.code === "Digit3")) {
                    preventDefault(event, "@3")
                    focus2Sidebar(ACTION_CLICK_OUTSIDE_OF_NAVBAR)
                    document.getElementById(MENU_HISTORY + "-tab")?.click()
                    return
                }

                if (event.type === KEYDOWN) {
                    //prehazovani focus navigator <-> okno
                    if (event.key == "F2") {
                        disableOtherListeners(event, "focus navigator <-> session")
                        if (hasSidebarFocus())
                            focus2Session()
                        else
                            focus2Sidebar()

                        return
                    } else if (event.key == "F10") {
                        disableOtherListeners(event, "focus navbar dropdown")
                        //focusElement("dropdown_1", "f10")
                        document.getElementById("dropdown_1").click()
                        return
                    } else if (event.ctrlKey && event.key == "ArrowRight") {
                        disableOtherListeners(event, "focus navigator <-> session")
                        focus2Session()
                        return
                    } else if (event.ctrlKey && event.key == "ArrowLeft") {
                        disableOtherListeners(event, "focus navigator <-> session")
                        focus2Sidebar()
                        return
                    } /*else if (event.ctrlKey && event.shiftKey && event.key === "1") {
                        document.getElementById(MENU_MAIN + "-tab")?.click()
                        return
                    } else if (event.ctrlKey && event.shiftKey && event.key === "2") {
                        document.getElementById(MENU_FAVORITE + "-tab")?.click()
                        return
                    } else if (event.ctrlKey && event.shiftKey && event.key === "3") {
                        document.getElementById(MENU_HISTORY + "-tab")?.click()
                        return
                    }*/
                } else if (event.type === MOUSEDOWN && event.target.id === CLOSE_FRAME_BTN) {
                    logDebug("Cancel on mousedown for btn close")
                    return
                }

                //EDITOVATELNA BUNKA BROWSU
                if (element !== undefined && element.componentName === "InputFieldElement" && element.data.readOnly === false) {
                    if (event.type !== MOUSEDOWN) {
                        //handleBrowseInputFieldElement(event, ws_context)
                        browseAction(event.key, event, ws_context, element.data.browseId)
                        return
                    }
                }

                //sipky v navigatoru se resi primo v navigatoru, ne tady
                if (document.getElementById("navigator-tab-content")?.contains(document.activeElement)) return

                //zavirani programu
                let closeMenuItem = store.getState().desktopMenu[MENU_ITEM_CLOSE_PROGRAM]
                if (closeMenuItem === undefined)
                    return

                //na povol backspace v zadavani textu a zakaz propagaci do navbaru
                if (event.key !== undefined && event.key === closeMenuItem?.accelerator3 && isEntryable(event)) {
                    return
                }

                //globalni vyhledavani se ridi bez progresu
                if (document.getElementById(Constants.globalSearch)?.contains(event.target)) {
                    if (event.key === "Escape") {
                        if (store.getState()[Constants.InternalElementState.RELATED_TARGET_ID]) {
                            //vraceni zpet do hledani
                            if (event.srcElement.id.indexOf("search_result_row_") > -1)
                                document.getElementById("search_input").focus()
                            else
                                focusElement(store.getState()[Constants.InternalElementState.RELATED_TARGET_ID], "app->search->esc")
                        } else
                            focus2Session()
                    }
                    return
                }

                /*logDebug("0 ----------------------------------------------> before doEscape",
                    "whole condition", ((event.target.id === CLOSE_FRAME_BTN ||
                        event.target.id === CLOSE_MASK_BTN ||
                        event.target.id === CLOSE_TAB_BTN ||
                        event.target.id === CLOSE_DIALOG_BTN ||
                        getHandleOfDropdownItem(event.target.id) === CLOSE_TAB.toString() ||
                        getHandleOfDropdownItem(event.target.id) === MENU_ITEM_CLOSE_PROGRAM.toString()) && (event.type === CLICK || event.type === MOUSEDOWN || (event.type === KEYDOWN && event.key === "Enter"))) || //prvni cast je kliknuti/enter/mezernik na tlacitko krizek
                ((event.key !== undefined && event.key === closeMenuItem.accelerator) ||
                    (event.key !== undefined && event.key === closeMenuItem.accelerator2) ||
                    (event.key !== undefined && event.key === closeMenuItem?.accelerator3 )),
                    "1", (event.target.id === CLOSE_FRAME_BTN ||
                        event.target.id === CLOSE_MASK_BTN ||
                        event.target.id === CLOSE_TAB_BTN ||
                        event.target.id === CLOSE_DIALOG_BTN ||
                        getHandleOfDropdownItem(event.target.id) === CLOSE_TAB.toString() ||
                        getHandleOfDropdownItem(event.target.id) === MENU_ITEM_CLOSE_PROGRAM.toString()),
                    "2: ", (event.type === CLICK || event.type === MOUSEDOWN || (event.type === KEYDOWN && event.key === "Enter")),
                    "3: ", ((event.target.id === CLOSE_FRAME_BTN ||
                        event.target.id === CLOSE_MASK_BTN ||
                        event.target.id === CLOSE_TAB_BTN ||
                        event.target.id === CLOSE_DIALOG_BTN ||
                        getHandleOfDropdownItem(event.target.id) === CLOSE_TAB.toString() ||
                        getHandleOfDropdownItem(event.target.id) === MENU_ITEM_CLOSE_PROGRAM.toString()) && (event.type === CLICK || event.type === MOUSEDOWN || (event.type === KEYDOWN && event.key === "Enter"))),
                    "4", ((event.key !== undefined && event.key === closeMenuItem.accelerator) ||
                        (event.key !== undefined && event.key === closeMenuItem.accelerator2) ||
                        (event.key !== undefined && event.key === closeMenuItem?.accelerator3)),
                    "event.target.id", event.target.id,
                    "getHandleOfDropdownItem(event.target.id)", getHandleOfDropdownItem(event.target.id),
                    "event.type", event.type
                )*/

                if (((event.target.id === CLOSE_FRAME_BTN ||
                    event.target.id === CLOSE_MASK_BTN ||
                    event.target.id === CLOSE_TAB_BTN ||
                    event.target.id === CLOSE_DIALOG_BTN ||
                    getHandleOfDropdownItem(event.target.id) === CLOSE_TAB.toString() ||
                    getHandleOfDropdownItem(event.target.id) === MENU_ITEM_CLOSE_PROGRAM.toString()) && (event.type === CLICK || event.type === MOUSEDOWN || (event.type === KEYDOWN && event.key === "Enter"))) || //prvni cast je kliknuti/enter/mezernik na tlacitko krizek
                    ((event.key !== undefined && event.key === closeMenuItem.accelerator) ||
                        (event.key !== undefined && event.key === closeMenuItem.accelerator2) ||
                        (event.key !== undefined && event.key === closeMenuItem?.accelerator3 /*&& !isEntryable(event)*/))) { //accelerator3 == backspace -> zakaz ho na entry pole

                    //zavreni context menu nad browsem/session tabem                    
                    let browseElement = document.getElementById(CM_BROWSE)
                    if (browseElement?.classList?.contains("show"))
                        return
                    let tabElement = document.getElementById(CM_SESSION_TABS)
                    if (tabElement?.classList?.contains("show"))
                        return

                    //zavreni dropdown menu navbaritems
                    let breakApp = false
                    for (let item of document.getElementsByClassName("webtop-dropdown-items")) {
                        if (!item.disabled && item.classList?.contains("show")) {
                            item.click()
                            return breakApp = true
                        } else if (item.contains(document.activeElement)) {
                            //esc pokud je focus v rootu
                            if (document.activeElement.getAttribute("id")?.includes("dropdown_"))
                                focusElement(ws.focusId, "navbar esc 1", ACTION_ESCAPE_NAVBAR)
                            return breakApp = true
                        } else {
                            /*
                            //pokud je zobrazen dropdown provede se focus na aktivni prvek
                            let elementsDropdown = document.querySelectorAll('[id^=dropdown_]')
                            elementsDropdown.forEach(element => {
                                if (element.classList.contains("show")) {
                                    focusElement(ws.focusId, "navbar esc 2", ACTION_ESCAPE_NAVBAR)
                                    return breakApp = true
                                }
                            })*/
                        }
                    }
                    if (breakApp)
                        return

                    if (doEscape(event, closeMenuItem))
                        return
                }

                //NAVBAR
                //console.log(document.activeElement)
                if (document.getElementById("navbar_menu_items")?.contains(document.activeElement)) {
                    handleNavbarEvents(event)
                    return
                }

                let doEnterActionOnFrame = true
                //jedna se o progress komponentu
                if (element !== undefined) {

                    //kontrola zda neni element disabled, nebo jeho rodic
                    if (sensitivityRecursively(element.id, element.tabId, element.maskId) === false) {
                        preventDefault(event, "@4")
                        stopPropagation(event, "@2")

                        return
                    }

                    //zpracovani obecnych udalosti
                    let processed = false
                    let shift = event.shiftKey ? true : false
                    if (event.type === KEYDOWN) {
                        if (event.keyCode === Keyboard.TAB) {
                            if (element.componentName === "EditorElement") {
                                if (!element.data.sensitivity || element.data.readOnly || parentSensitivity(element.id, element.tabId, element.maskId))
                                    return
                                else {
                                    disableOtherListeners(event, "keydown tab editor", element)

                                    if (!event.shiftKey) {
                                        let htmlElement = document.getElementById(ws.focusId)
                                        //console.log("selectionStart", htmlElement.selectionStart)
                                        let start = htmlElement.selectionStart
                                        let end = htmlElement.selectionEnd

                                        // set textarea value to: text before caret + tab + text after caret
                                        let newVal = htmlElement.value.substring(0, start) +
                                            "\t" + htmlElement.value.substring(end)
                                        let cursorOffset = start + 1

                                        dispatch(onValuesChangeByHandle(ws.focusId, {
                                            cursorOffset: cursorOffset,
                                            value: newVal,
                                        }))
                                    }
                                }
                            } else {
                                doTabAction(ws_context, event, shift)
                                processed = true
                            }
                        } else if (event.keyCode === Keyboard.UP && element.componentName !== "EditorElement" && element.componentName !== "BrowseElement" && !isMoveButton(element.data.specialId)) {
                            //ARROW UP
                            doTabAction(ws_context, event, true)
                            processed = true
                        } else if (event.keyCode === Keyboard.DOWN && event.altKey === false && element.componentName !== "EditorElement" && element.componentName !== "BrowseElement" && !isMoveButton(element.data.specialId)) {
                            //ARROW DOWN
                            doTabAction(ws_context, event, false)
                            processed = true
                        } else if (event.keyCode === Keyboard.ENTER && !event.ctrlKey && element.componentName === "EditorElement") {
                            doEnterActionOnFrame = false
                        }
                    } else if (event.type === MOUSEDOWN) {
                        //provedeni ENTRY pouze na vybrane elementy(InputFieldElement, )
                        //console.log("CLICK-" + event.target.id.split(";")[0].toString() + "- !== -" + ws.focusId.toString() + "-", (event.target.id.split(";")[0].toString() !== ws.focusId.toString()), isEntryable(event))
                        //console.log("IS IT TRUE", (event.target.id.split(";")[0] !== ws.focusId.toString() && isEntryable(event) && element.componentName !== "SelectionElement"))
                        if (event.target.id.split(";")[0] !== ws.focusId.toString()
                            && isEntryable(event)
                            && element.componentName !== "SelectionElement"
                            && element.componentName !== "ComboBoxElement") {
                            //disableOtherListeners(event, "click", element)

                            let elData2Save = getElementData(ws.focusId)
                            let requestData = {
                                handle: ws.focusId,
                                newFocus: event.target.id,
                                action: EVENT_ENTRY
                            }

                            if (elData2Save.shouldSaveValue) {
                                saveValue2Store(ws.focusId, elData2Save)
                                requestData = {
                                    ...requestData,
                                    value: elData2Save.value
                                }
                            }

                            //browse cell handle
                            if (event.target.id.indexOf("b_col_") > -1) {
                                requestData = {
                                    ...getEmptyRowProp(),
                                    ...requestData,
                                    newFocus: event.target.id.split("_")[3]
                                }
                            }

                            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest(requestData)))
                            processed = true

                            //oznac cele input pole pri kliknuti mysi
                            if (event.target.tagName.toLowerCase() === 'input') {
                                event.target.select()
                                //console.log("@DEBUG SELECT KLIK")
                            }

                            return
                        }

                    } else if (event.type === CLICK) {
                        //console.log("CLICK", element.componentName)
                        //console.log(event.target.id.toString(), ws.focusId.toString())
                        if (element.componentName === "ToggleBoxElement") {
                            handleToggleBoxElementOnChange(event, ws_context)
                            processed = true
                        } else if (element.componentName === "RadioSetElement") {
                            handleRadioSetElementClick(event, ws_context)
                            processed = true
                        } else if (element.componentName === "SelectionElement") {
                            handleSelectionElementOnClick(event, ws_context)
                            processed = true
                        } /*else if (element.componentName === "ComboBoxElement") {
                            handleSelectionElementClick(event, ws_context)
                            processed = true
                        }*/

                    } else if (event.type === ON_CHANGE) {
                        if (element.componentName === "ComboBoxElement"
                            || element.componentName === "SelectionElement") {
                            handleSelectionElementOnChange(event, ws_context)
                            processed = true
                        } else if (element.componentName === "ToggleBoxElement") {
                            handleToggleBoxElementOnChange(event, ws_context)
                            processed = true
                        } else if (element.componentName === "InputFieldElement" && element.data.dataType === "LOGICAL") {
                            dispatch(onValuesChangeByHandle(props.id, "value", event.target.value))
                            processed = true
                        }
                    } else if (event.type === DOUBLE_CLICK) {
                        if (element.componentName === "SelectionElement") {
                            doDefaultAction()
                        }
                    }

                    if (!processed) {
                        /*zpracovani specialnich akci pro dane komponenty
                        */
                        switch (element.componentName) {
                            case "ButtonElement":
                                //click, keydown-enter
                                if (event.type === KEYDOWN && event.keyCode === Keyboard.ENTER) doEnterActionOnFrame = false
                                handleButtonElement(event, element, ws_context)
                                break
                            case "BrowseElement":
                                browseAction(event.key, event, ws_context, element.id)
                            case "InputFieldElement":
                                //keydown-f3
                                //handleInputFieldElement(event, ws_context, element.value)
                                break
                            default:
                                //event.preventDefault()
                                //event.stopPropagation()
                                logDebug("Neimplementovan keylistener " + event.type + " pro element -> " + element.componentName)
                                break
                        }
                    }
                }

                if (doEnterActionOnFrame && event.type === KEYDOWN && event.key == "Enter" && !hasSidebarFocus()) {
                    doDefaultAction(event)
                }
            }
        }

        function doDefaultAction(event) {
            //ve firefoxu se otevrel kalendar a zustal viset
            if (event?.target?.type === "date")
                preventDefault(event, "@6")


            let rootaCalc = document.getElementById("rootCalculator");
            if (rootaCalc !== undefined && rootaCalc !== null) return


            let ws = store.getState().ws

            //pokud existuje error dialog, defaulni akce se neprovadi
            let messageDialog = document.getElementById("dialog-container")
            if (messageDialog !== undefined && messageDialog !== null)
                return

            //udelej default akci na framu
            let currentSession = store.getState().desktopSession[ws.currentTabId][ws.currentMaskID]
            //console.log("data", currentSession[ws.focusId])
            let defBtnHandle = null
            if (currentSession[ws.focusId] !== undefined) {
                if (currentSession[ws.focusId].data?.defaultButtonHandle === undefined) {
                    defBtnHandle = currentSession[currentSession[ws.focusId].data.parentHandle].data.defaultButtonHandle
                } else {
                    defBtnHandle = currentSession[ws.focusId].data?.defaultButtonHandle
                }
            }

            if (defBtnHandle != null && currentSession[defBtnHandle].data.sensitivity) {
                if (currentSession[defBtnHandle].data.visible) {
                    document.getElementById(defBtnHandle)?.click()
                } else {
                    sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                        ...getEmptyRowProp(),
                        handle: ws.focusId,
                        newFocus: defBtnHandle,
                        action: CHOOSE_ACTION,
                    })))
                }
                return
            }
        }

        /**
         * Zavreni masky / programu / tabu
         * @param {*} event
         */
        function doEscape(event) {
            /*console.log("DO ESCAPE", event)            
            if (ws_context.getRequestsNoBlock().size() > 0)
                ws_context.getRequestsNoBlock().erase()*/

            if (document.getElementById("rootCalculator")?.contains(document.activeElement)) return

            //neprovadet zavirani, pokud neni focus na zadne komponente, nastal problem s hvezdickou v navbar + esc
            //logDebug("1 --> doEscape", document.activeElement.tagName)
            if (document.activeElement.tagName === "BODY")
                return true

            //logDebug("2 --> doEscape", event.target.id)
            //umozneni backspace v zadani input mozstvi radku v browsu
            if (event.target.id === RESISIZE_BROWSE_INPUT)
                return

            let closeMenuItem = store.getState().desktopMenu[MENU_ITEM_CLOSE_PROGRAM]

            /*existuje dialog - o aplikaci/kontakt/...*/
            let about = document.getElementById(MODAL_DIALOG_ABOUT)
            //logDebug("3 --> doEscape", about)
            if (about) {
                preventDefault(event, "@7")
                stopPropagation(event, "@3")

                dispatch(showAboutApp(false))
                return
            }

            let contact = document.getElementById(MODAL_DIALOG_CONTACT)
            //logDebug("4 --> doEscape", contact)
            if (contact) {
                preventDefault(event, "@8")
                stopPropagation(event, "@4")
                dispatch(showContact(false))
                return
            }

            //globalni nastaveni v navigation
            //logDebug("5 --> doEscape", document.getElementById("settings_dialog"))
            if (document.getElementById("settings_dialog"))
                return

            //logDebug("6 --> doEscape", document.getElementById("keyboar_shortcuts_dialog"))
            if (document.getElementById("keyboar_shortcuts_dialog"))
                return

            //logDebug("7 --> doEscape", document.getElementById(CUSTOM_MODAL_DIALOG_ID))
            if (document.getElementById(CUSTOM_MODAL_DIALOG_ID))
                return

            //logDebug("8 --> doEscape", document.getElementById("icons_dialog"))
            if (document.getElementById("icons_dialog"))
                return

            //logDebug("9 --> doEscape", findElementByPartialId("favorite_settings_dialog"))
            if (findElementByPartialId("favorite_settings_dialog"))
                return

            let ws = store.getState().ws
            let currentSession = store.getState().desktopSession[ws.currentTabId][ws.currentMaskID]

            function existModalDialog() {
                for (const [key, value] of Object.entries(currentSession)) {
                    if (value.componentName === "ModalDialogElement" || value.componentName === "DialogElement")
                        return true
                }
                return false
            }

            //lze zavirat klikem bez focusu
            //logDebug("10 --> doEscape", !hasSessionFocus() + "," + event.type !== MOUSEDOWN + "," + !existModalDialog())
            if (!hasSessionFocus() && event.type !== MOUSEDOWN && !existModalDialog())
                return

            //Pokud ma focus Navbar pro pohybovani mezi polozkami menu, tak escape nesmi zavrit program ale jenom predat focus na aktualni html element dle focusId
            //console.log("ESCAPE", event.target.id)
            //console.log("HAS NAVBAR FOCUS", hasNavbarFocus(), event)
            if (hasNavbarFocus()) {
                //escape rusi focus/dropdown
                //logDebug("11 --> doEscape", event.key === "Escape")
                if (event.key === "Escape") {
                    return false
                }
            }

            let stornoBtnHandle = null
            //zavreni system error dialogu
            //logDebug("12 --> doEscape", event.target.id === CLOSE_DIALOG_SYSTEM_ERROR_BTN)
            if (event.target.id === CLOSE_DIALOG_SYSTEM_ERROR_BTN) {
                document.getElementById(CLOSE_DIALOG_SYSTEM_ERROR_BTN)?.click()
                return true
            }

            //logDebug("13 --> doEscape", event.target.id === CLOSE_TAB_BTN + "," + getHandleOfDropdownItem(event.target.id) === CLOSE_TAB.toString())
            if (event.target.id === CLOSE_TAB_BTN || getHandleOfDropdownItem(event.target.id) === CLOSE_TAB.toString()) {
                sendMessage(createMessage(CLOSE_PROGRAM, createRequest({ tabId: ws.currentTabId })))
                return true
            }

            //zavreni pres storno btn        
            let focusedElement = currentSession[ws.focusId]
            let parentHandle = currentSession[ws.focusId].data.parentHandle
            let parentElement = currentSession[parentHandle]
            let grandParentHandle = currentSession[parentElement.id]?.data.parentHandle
            let grandParentElement = currentSession[grandParentHandle]

            const sendedValue = getElementData(ws.focusId).value
            const sendedElementId = ws.focusId
            const sendedTabId = ws.currentTabId
            const sendedMaskid = ws.currentMaskID

            /*hledani storno button -> 1) element storno button 2) parent element storno button 3) grand parent storno button */
            if (focusedElement?.data?.stornoButtonHandle !== undefined)
                stornoBtnHandle = focusedElement.data.stornoButtonHandle
            else if (parentElement?.data?.stornoButtonHandle !== undefined)
                stornoBtnHandle = parentElement.data.stornoButtonHandle
            else if (grandParentElement?.data?.stornoButtonHandle !== undefined)
                stornoBtnHandle = grandParentElement.data.stornoButtonHandle

            //zavreni message2 - chova se jinak, nema stornoButtonHandle a defaultButtonHandle
            if (stornoBtnHandle == null && currentSession[ws.focusId].data?.specialId == BUTTON_MODAL_NO) {
                stornoBtnHandle = ws.focusId
            }

            //logDebug("14 --> doEscape", stornoBtnHandle != null + "," + currentSession[stornoBtnHandle]?.data?.sensitivity)
            if (stornoBtnHandle != null && currentSession[stornoBtnHandle].data.sensitivity) {
                sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                    ...getEmptyRowProp(),
                    handle: ws.focusId,
                    newFocus: stornoBtnHandle,
                    action: CHOOSE_ACTION,
                    value: sendedValue,
                })))

                if (sendedValue !== null)
                    dispatch(onValuesChangeInMask(sendedElementId, sendedTabId, sendedMaskid, { value: sendedValue }))

                return true
            }

            //zavreni masky -> navrat do browse
            //logDebug("15 --> doEscape", stornoBtnHandle === null + "," + store.getState().desktopMenu[SHOW_DETAIL_RECORD]?.checked + "," + store.getState().desktopMenu[SHOW_DETAIL_RECORD]?.sensitivity)
            if (stornoBtnHandle === null && store.getState().desktopMenu[SHOW_DETAIL_RECORD].checked && store.getState().desktopMenu[SHOW_DETAIL_RECORD].sensitivity) {
                sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                    ...getEmptyRowProp(),
                    handle: ws.focusId,
                    newFocus: SHOW_DETAIL_RECORD,
                    action: MENU_ACTION,
                    value: sendedValue,
                })))

                if (sendedValue !== null)
                    dispatch(onValuesChangeInMask(sendedElementId, sendedTabId, sendedMaskid, { value: sendedValue }))

                return true
            }

            switch (event.target.id) {
                case CLOSE_DIALOG_BTN:
                    //zavreni dialogu
                    break
                case CLOSE_MASK_BTN:
                    //zavreni masky
                    break
                case CLOSE_FRAME_BTN:
                    //krizek na frame (ne maska)
                    break
                case CLOSE_TAB_BTN:
                    //krizek na zalozce tab - resi se o kousek vys - ma prednost pred spoustou veci
                    break
                case MENU_ITEM_CLOSE_PROGRAM:
                    //zavrit pomoci menu item "zavrit program"
                    logDebug("16 --> doEscape" + "," + closeMenuItem.sensitivity)
                    if (closeMenuItem.sensitivity) {
                        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                            ...getEmptyRowProp(),
                            handle: ws.focusId,
                            newFocus: MENU_ITEM_CLOSE_PROGRAM,
                            action: MENU_ACTION,
                            value: sendedValue,
                        })))

                        if (sendedValue !== null)
                            dispatch(onValuesChangeInMask(sendedElementId, sendedTabId, sendedMaskid, { value: sendedValue }))
                        return true
                    }
                default:
                    break
            }


            logDebug("17 --> doEscape", ws.currentMaskID != 0 && closeMenuItem?.sensitivity)
            if (ws.currentMaskID != 0 && closeMenuItem.sensitivity) {//nezkousej zavrit prazdnou obrazovku
                //kdyz nic jineho, posli UCPG
                sendMessage(createMessage(CLOSE_PROGRAM, createRequest({ maskId: ws.currentMaskID })))
                return true
            } else {
                return false
            }


        }

        function getHandleOfDropdownItem(id) {
            if (id?.split("_").length > 3)
                return id.split("_")[3]
        }



        /*
        Dle probublávání událostí se událost nejprve spustí na nejvnitřnějším elementu a poté se postupně spustí na předcích.
        Aby bylo možné řídit listenery v App.js je nastaven atribut useCapture = true, který zajistí aby se listener odchytil nejdříve zde*/
        let useCapture = true
        const controller = new AbortController()
        window.addEventListener(KEYDOWN, handleUserInput, { capture: useCapture, signal: controller.signal })
        window.addEventListener(MOUSEDOWN, handleUserInput, { capture: useCapture, signal: controller.signal })
        window.addEventListener(DOUBLE_CLICK, handleUserInput, { capture: useCapture, signal: controller.signal })
        window.addEventListener(CLICK, handleUserInput, { capture: useCapture, signal: controller.signal })
        window.addEventListener(CONTEXT_MENU, handleUserInput, { capture: useCapture, signal: controller.signal })
        window.addEventListener(ON_CHANGE, handleUserInput, { capture: useCapture, signal: controller.signal })

        //window.addEventListener(ON_BLUR, handleUserInput)
        //window.addEventListener(ON_FOCUS, handleUserInput)

        return () => {
            controller.abort()
        }
    }, [])

    function renderAppDialogs() {
        let about = getSpecialIdElement(SpecialId.ABOUT)
        let contact = getSpecialIdElement(SpecialId.CONTACT)

        dialogs = []
        if (about?.data?.visible) {
            dialogs.push(<ModalDialogElement
                id={about.id}
                key={"md" + about.id}
                tabId={props.ws.currentTabId}
                maskId={props.ws.currentMaskID}
                aboutDialogVisibility={contact.data.visible}
                {...about} />)
        }


        if (contact?.data?.visible) {
            dialogs.push(<ModalDialogElement
                id={contact.handle}
                key={"md" + contact.handle}
                tabId={props.ws.currentTabId}
                maskId={props.ws.currentMaskID}
                contactDialogVisibility={contact.data.visible}

                {...contact} />)
        }

        return dialogs
    }

    function browserCompatibilityCheck() {
        if (props.app.messageContainer) {
            let msg = props.app.messageContainer[MessageContainer.UNSUPPORTED_BROWSER]
            let msgArray
            if (msg)
                msgArray = msg.split(";")

            if (msgArray && msgArray.length !== 3) return

            let textHeader, textBody
            let supportedBrowsers = pjson.browsersList.toString()
            textHeader = msgArray[0]
            textBody = msgArray[1] + supportedBrowsers.replaceAll(",", ", ")

            /*if('showOpenFilePicker' in window){//dodatečná detekce podpory File System Access API
                alert("supported")
            } else {
                alert("not supported")
            }*/
            if (!(supportedBrowsers.includes(getBrowser())/* && 'showOpenFilePicker' in window*/)) {
                return <BSToast
                    iconClassName="fas fa-exclamation-triangle text-danger"
                    textHeader={textHeader}
                    textBody={textBody}
                    autohide={false} />
            }
        }
    }

    function renderVersion() {
        if (props.clientVersion !== undefined && props.clientVersion !== "")
            return <div className='position-absolute bottom-0 end-0 p-2 text-muted'>
                <i className='far fa-copyright pe-1'></i>
                {props.clientVersion + ": " + pjson.version + ", [pid: " + props.app.pid + "]"}
            </div>
    }

    function isProgramExecutedByFrame() {
        return loggedIn && props.desktopHistoryMenu === undefined
    }

    function isDashboardVisible() {
        return (props.dashboardVisibility || (isProgramExecutedByFrame() || Object.keys(props.desktopSession).length === 1))
    }

    function isSessionVisible() {
        return (Object.keys(props.desktopSession).length > 1 && props.dashboardVisibility === false)
    }

    logDebug("App.js", props)
    //console.log("App -> render", props)
    //console.log("LOGGED IN", loggedIn)
    //web socket spojeni nikdy neexistovalo, vykresluje se se zpozdenim 2s
    if (props.ws?.connected == undefined) {
        logDebug("@1 render -> ConnectionProblem")
        //defaultni hodnoty pokud nebyl nacten messageContainer
        let msgConnectionFailed = Constants.LocalMessageContainer.CANNOT_CONNECT_TO_SERVER

        //hodnoty hlasek z desktop serveru
        if (props.messageContainer) {
            if (props.messageContainer[Constants.MessageContainer.CANNOT_CONNECT_TO_SERVER])
                msgConnectionFailed = props.messageContainer[Constants.MessageContainer.CANNOT_CONNECT_TO_SERVER]
        }
        return <React.Fragment>
            <ToastStack toastStackId={1} />
            <ConnectionProblem delayed={true} errorMessage={msgConnectionFailed} enableTimesIcon={true} enableReloadButton={true} />
        </React.Fragment>
    }

    //web socket spojeni uz existovalo
    if (props.ws?.connected === false) {
        logDebug("@2 render -> RestoreConnection")
        return <React.Fragment>
            <ToastStack toastStackId={2} />
            <RestoreConnection />
        </React.Fragment>
    }


    //standartni zobrazeni po prihlaseni uzivatele
    if (loggedIn && props.desktopHistoryMenu) {
        logDebug("@3 render -> standartni zobrazeni po prihlaseni uzivatele")
        return (
            <div className="wrapper">
                <ToastStack toastStackId={3} />
                <RenderMessageDialogs />
                <Sidebar />
                <div className="main">
                    <Navigation />
                    <main id="main_session" ref={sessionRef} className="content no_outline" tabIndex={0} style={{ paddingBottom: '0rem', overflowX: 'auto' }}>
                        {((isDashboardVisible()) ? <div>
                            <Dashboards />
                            {/*zobrazeni dialogu nad v tabid=0 a maskid=0*/}
                            {(ws.currentTabId === 0) ? <SessionNode key={"rootSessionNode"} id={0} /> : null}
                        </div> : null)}

                        <Spinner />

                        {renderAppDialogs()}

                        {isSessionVisible() ? <div className="container-fluid p-0">
                            <div className="tab" style={{ marginBottom: '0rem' }} >

                                <SessionTabs />
                                <div style={{ minWidth: '69rem' }}>
                                    <div className="tab-content" style={{ padding: '0rem' }}>
                                        <SessionNode key={"rootSessionNode"} id={0} />
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                    </main>
                    <Footer currentTabId={ws.currentTabId} currentMaskID={ws.currentMaskID} focusId={ws.focusId} />
                </div>
            </div>
        )
    }

    //specialni program spusteni odkazem bez menu
    if (loggedIn && props.desktopHistoryMenu === undefined) {
        logDebug("@4 render -> specialni program spusteni odkazem bez menu")
        return <div className="main">
            <RenderMessageDialogs />
            <ToastStack toastStackId={4} />
            <main id="main_session" ref={sessionRef} className="content no_outline" tabIndex={0} style={{ paddingBottom: '0rem', overflowX: 'auto' }}>
                <Spinner />

                {renderAppDialogs()}

                <div className="container-fluid p-0">
                    <div className="tab" style={{ marginBottom: '0rem' }} >

                        <SessionTabs />
                        <div style={{ minWidth: '69rem' }}>
                            <div className="tab-content" style={{ padding: '0rem' }}>
                                <SessionNode key={"rootSessionNode"} id={0} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer currentTabId={ws.currentTabId} currentMaskID={ws.currentMaskID} focusId={ws.focusId} />
        </div>
    }

    // vyber ucetni jednotky/ login frame / externi odkaz na program
    logDebug("@5 render -> ostatni obrazovky")
    return (
        <React.Fragment>
            <ToastStack toastStackId={5} />
            {browserCompatibilityCheck()}
            <RenderMessageDialogs />
            <main id="main_session">
                <SessionNode key={"rootSessionNode"} id={0} />
            </main>

            {renderVersion()}
        </React.Fragment>
    )
}

function mapStateToProps(state, ownProps) {
    //ownProps jsou prazne

    //console.log("App -> mapStateToProps()")
    //console.log("state: ", state)
    return {
        loggedIn: state.ws.loggedIn,
        desktopSession: state.desktopSession,
        darkTheme: state.darkTheme,
        desktopNavigatorMenu: state.desktopNavigatorMenu,
        desktopMenu: state.desktopMenu,
        ws: state.ws,
        app: state.app,
        messageContainer: state.messageContainer,
        clientVersion: (state.app.messageContainer) ? state.app.messageContainer[Constants.MessageContainer.APP_VERSION] : "",
        desktopHistoryMenu: state.desktopHistoryMenu,
        notifications: state.notifications,
        dashboardVisibility: state.dashboardVisibility
    }
}

export default connect(mapStateToProps)(App)
