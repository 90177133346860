import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { moveBrowseColumns, onValueChangeByHandle } from '../../store/actions/store_actions'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'
import { ITEM_PREFIX } from './BrowseColumnsSettingsDialog'
import { logDebug } from '../../utils/logging'

const { MOUSEDOWN, ON_CHANGE } = LISTENER_TYPE

/**
 * Jedna polozka v ramci nastaveni sloupcu browse v dropdown. Pomoci teto polozky se nastavuje u sloupce visible true/false a jejich poradi
 * @param {*} props
 * @returns
 */
export default function BrowseColumnsSettingsItem(props) {
    let { label, id, visible, browseHandle, tooltip, index, setDividerIndex, browseColsSorted, dragItemOver } = props

    const dispatch = useDispatch()

    const thisRef = React.useRef()
    const inputRef = React.useRef()

    //stopPropagation zajisti aby dropdown-item listener neprepsal mousedown, nastal pak problem, ze nebyla zavolana udalost onChange
    addListener(MOUSEDOWN, inputRef, e => { e.stopPropagation() })
    addListener(ON_CHANGE, inputRef, e => {
        //prohod data v reduceru pro radky
        dispatch(onValueChangeByHandle(
            id,
            'browseColVisible',
            e.target.checked,
        ))
    })

    //console.log("render", props)
    return (
        <li ref={thisRef} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center draggable" draggable={true} title={tooltip}
            onDragEnter={e => {
                dragItemOver.current = index
                setDividerIndex(index)
                //console.log("SETTINTG", index)
            }}
            onDragOver={e => {
                e.preventDefault() //ikonka not allowed
            }}
            onDragEnd={e => {
                setDividerIndex(-1)

                if (index == dragItemOver.current) {
                    console.log("Drop poradi sloupcu mimo povolenou oblast")
                    return
                }

                //prohod sloupce v reduceru pro vsechny radky
                dispatch(moveBrowseColumns(
                    browseHandle,
                    index,
                    dragItemOver.current
                ))
            }}>

            <div>
                <input
                    id={ITEM_PREFIX + id}
                    className="me-2"
                    type="checkbox"
                    defaultChecked={visible}
                    ref={inputRef}
                />
                {label}
            </div>
            <i className="fas fa-grip-lines" />
        </li>
    )

}

BrowseColumnsSettingsItem.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    visible: PropTypes.bool,
    browseHandle: PropTypes.number,
    tooltip: PropTypes.string,
    index: PropTypes.number,
    dragItemOver: PropTypes.object
}