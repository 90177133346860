import React from "react"
import { connect } from 'react-redux'

import { logRender, logRenderProps } from '../utils/logging'

import SettingsDialog from './dialogs/SettingsDialog'
import { showAboutApp, showContact } from '../store/actions/store_actions'
import { getSpecialIdElement } from '../store/storageMethods'
import Constants from '../constants/constants'
import ElementWithListener from "./ElementWithListener"
import KeyboardShortcutsDialog from "./dialogs/KeyboardShortcutsDialog"

function SettingsDropdown(props) {
    const [isLoggingDialogVisible, setLoggingDialogVisibility] = React.useState(false)
    const [isKeyboardShortcutsDialogVisible, setKeyboardShortcutsDialogVisibility] = React.useState(false)
    const { app, loggedIn, dispatch } = props

    function renderLoggedDropDownItems() {
        if (loggedIn)
            return (
                <>
                    <li>
                        <ElementWithListener
                            doAction={(event) => {
                                if (!(event instanceof MouseEvent) && !(event instanceof KeyboardEvent && event.key === "Enter")) return

                                let layer = 0
                                let contact = getSpecialIdElement(Constants.SpecialId.CONTACT)
                                if (contact.data.visible)
                                    layer = contact.data.layer + 1
                                dispatch(showAboutApp(true, layer))
                            }}
                            mouseListener={true}>
                            <a className="dropdown-item" href="#">{app.menuItems['ABOUT'].name}</a>
                        </ElementWithListener>
                    </li>
                    <li>
                        <ElementWithListener
                            doAction={(event) => {
                                if (!(event instanceof MouseEvent) && !(event instanceof KeyboardEvent && event.key === "Enter")) return

                                let layer = 0
                                let about = getSpecialIdElement(Constants.SpecialId.ABOUT)
                                if (about.data.visible)
                                    layer = about.data.layer + 1
                                dispatch(showContact(true, layer))
                            }}
                            mouseListener={true} >
                            <a className="dropdown-item" href="#">{app.menuItems['CONTACT'].name}</a>
                        </ElementWithListener>
                    </li>
                    <li>
                        <a className="dropdown-item" href={app.teamviewerURL} target="_blank">{app.menuItems['SUPPORT'].name}</a>
                    </li>
                    <li>
                        <a className="dropdown-item" href="#">{app.menuItems['BUG_REPORT'].name}</a>
                    </li>
                    <li>
                        <a className="dropdown-item" onClick={e => {
                            setKeyboardShortcutsDialogVisibility(!isKeyboardShortcutsDialogVisible)
                        }} href="#">{app.messageContainer[Constants.MessageContainer.KEYBOARD_SHORTCUTS_TITLE]}</a>
                    </li>
                    <div className="dropdown-divider" />
                </>
            )
    }

    logRender("SettingsDropdown")
    logRenderProps(props)

    return (
        <div>
            <div className="dropdown">
                <a className="nav-icon dropdown-toggle webtop-dropdown-items" href="#" id="settings" data-bs-toggle="dropdown" tabIndex={-1}>
                    <i className="fas fa-cog" />
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    {renderLoggedDropDownItems()}

                    <li className="dropdown-item" onClick={e => {
                        setLoggingDialogVisibility(!isLoggingDialogVisible)
                    }}>{props.app.messageContainer[Constants.MessageContainer.SETTINGS]}</li>
                </ul>
            </div>
            {isLoggingDialogVisible ? <SettingsDialog
                key="SettingsDialog"
                isLoggingDialogVisible={isLoggingDialogVisible}
                setLoggingDialogVisibility={setLoggingDialogVisibility} /> : ""}

            {isKeyboardShortcutsDialogVisible ? <KeyboardShortcutsDialog
                key="KeyboardShortcutsDialog"
                isKeyboardShortcutsDialogVisible={isKeyboardShortcutsDialogVisible}
                setKeyboardShortcutsDialogVisibility={setKeyboardShortcutsDialogVisibility} /> : ""}
        </div >
    )
}

function mapStateToProps(state) {
    return {
        loggedIn: state.ws.loggedIn,
        app: state.app,
        supportedLanguages: state.app.supportedLanguages,
    }
}

export default connect(mapStateToProps)(SettingsDropdown)
