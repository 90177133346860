import React from 'react'

import Constants from '../../constants/constants'
import Styles from '../../constants/styles'
import ButtonElement from '../desktop_server/ButtonElement'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'
import styles from '../../constants/styles'

export default function DecideDialog(props) {
    const { data, id, style, refDialog, headerProps, desktopSessionMask, iconName, childIds } = props
    const { CLOSE_DIALOG_BTN } = Constants.CloseButtons

    let dialogText, buttons = [], btnYes, btnNo, btnStorno, btnOk
    childIds?.forEach(id => {
        let component = desktopSessionMask[id]

        if (!component && !component.data)
            return

        //console.log(component)    
        if (component.data.specialId === Constants.SpecialId.DIALOG_EDITOR)
            dialogText = component
        else if (component.componentName === "ButtonElement")
            buttons.push(<ButtonElement classNameTooltip="me-1" className={"btn btn-primary focusable me-3"} style={styles.buttonStyle} key={"btn" + component.id} {...component} />)
    })

    //console.log("props", props)
    return <div
        key={id}
        ref={refDialog}
        style={style}
        className='modal-dialog'
        id="modal_dialog"
        aria-modal="true" >
        <div className="modal-content blue_box" style={Styles.dialogContent}>
            <div {...headerProps}>
                {(iconName !== "") ? <i className={iconName + " fal fa-2x me-2"}></i> : null}
                <h4 className="modal-title">{data.header}</h4>
                {(data.stornoButtonHandle !== undefined ? <button id={CLOSE_DIALOG_BTN} type="button" className="btn-close" aria-label="Close" /> : "")}
            </div>
            <div className="modal-body p-3">
                <div className="row">
                    <p className="text-break" style={{ maxHeight: '50vh', overflow: 'auto', whiteSpace: 'pre-line', textAlign: 'justify', textJustify: 'inter-word' }}>
                        {dialogText.data.value}
                    </p>
                </div>
            </div>
            <div className="modal-footer pb-3 pe-3" style={{/* display: 'flex', width: '100%', justifyContent: 'center' */ }}>
                {buttons}
            </div>
        </div>
    </div >
}