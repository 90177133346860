//vraceni zvyrazneneho radku
export function getSelectedItem(mask, browseId) {
    let columns = []
    let columnsIds = mask[browseId].childIds
    columnsIds.map(columnId => {
        let columnData = mask[columnId].data
        columns.push({
            handle: columnData.handle,
            value: columnData.value
        })
    })
    return { rowId: mask[browseId].data.rowId, columns: columns }
}

export function processLoadUp(msg, newBrowseData) {
    //console.log("↑↑↑ LOAD UP", msg)
    let data = tranform2TableData(msg.data.BrowseDataResponse)
    let reachTop = false

    //console.log(data.length + " < " + Math.abs(msg.request.numRows))
    if (data.length < Math.abs(msg.request.numRows))
        reachTop = true
    else
        data.pop(); //-1

    data.map(column => {
        newBrowseData.unshift(column)
    })

    return { newBrowseData, reachTop, data }
}

export function processLoadDown(msg, newBrowseData) {
    //console.log("↓↓↓ LOAD DOWN", msg)
    let data = tranform2TableData(msg.data.BrowseDataResponse)
    let reachBottom = false
    //console.log(data.length, Math.abs(msg.request.numRows), data)

    if (data.length < Math.abs(msg.request.numRows))
        reachBottom = true
    else
        data.pop() //-1

    data.map(column => {
        //console.log(column)
        newBrowseData.push(column)
    })

    return { newBrowseData, reachBottom, data }
}

export function tranform2TableData(browseDataResponse, columnOrder) {
    let browseData = []
    //console.log("browseDataResponse", browseDataResponse)
    //console.log("columnOrder", columnOrder)

    if (browseDataResponse != undefined && browseDataResponse.rows != undefined) {
        browseDataResponse.rows.forEach((value => {
            let browseRow = {}
            let rowColumns = []

            columnOrder.forEach(columnId => {
                const found = value.columns.find(element => element.handle.toString() === columnId);
                //console.log("found", found)
                rowColumns.push(found)
            })

            browseRow = {
                rowId: value.rowId,
                columns: rowColumns
            }

            //if (browseData.find(obj => { return obj.rowId === value.rowId }) === undefined)
                browseData.push(browseRow)
           /* else
                console.log("UZ EXISTUJE v seznamu")*/
        }))
    }

    return browseData
}
