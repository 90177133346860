import React from 'react';
import { connect } from 'react-redux'

import { hasSessionFocus } from '../../focus_actions'
import Constants from '../../constants/constants'
import RecordActions from '../browse/RecordActions'
import { WebSocketContext } from '../../services/WebSocket'
import { renderChildrens2Screen } from '../renderChildrens2Screen'
import { logRender, logRenderProps } from '../../utils/logging'
import { getEmptyRowProp } from '../../utils/actions'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import ElementWithListener from '../ElementWithListener'
import { addListener } from '../../hooks/desktopListener'

import { LISTENER_TYPE } from '../../hooks/desktopListener'
import FavoriteFunctions from '../FavoriteFunctions';
import { getWidthInChars } from '../../utils/screen';
import styles from '../../constants/styles';
const { KEYDOWN } = LISTENER_TYPE;

const { SET_VALUE_REQUEST } = Constants.WebSocketActions
const { MENU_ACTION, CHOOSE_ACTION } = Constants.SetValueRequest
const { SHOW_DETAIL_RECORD, NEXT_DETAIL_PAGE } = Constants.MenuActions
const { SpecialId, screenWidth, screenHeight, frameHeight } = Constants

function RecordDetail(props) {
    //let renderTime = Date.now()

    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context
    const { data, desktopSessionMask, desktopMenu } = props
    const { Keyboard } = Constants
    const btnStyle = "btn btn-primary ms-1 focusable"

    let ws, currentFocusId
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
    })

    let buttons = []
    let elUp, elDown, elPgUp, elPgDown, elHome, elEnd
    /*React.useEffect(() => {
        console.log("%c RecordDetail render time ->: " + (Date.now() - renderTime), 'color: #34eb65')
    })*/

    let hasMoveButtons = false
    for (const [key, value] of Object.entries(desktopSessionMask)) {
        //console.log("KEY: " + key, value)
        if (value.data != undefined && value.data.specialId === SpecialId.BROWSE_BUTTONS) {
            //console.log("BUTTONS", value)
            value.childIds.forEach(itemId => {
                let element = desktopSessionMask[itemId]
                if (element.data.sensitivity && element.data.visible)
                    hasMoveButtons = true

                let innerProps = {
                    id: itemId,
                    disabled: !element.data.sensitivity || !desktopSessionMask[element.data.parentHandle].data.sensitivity,
                    doAction: () => pressButton(element.id),
                    outerHtmlClassName: btnStyle,
                    style: styles.buttonStyle,
                    mouseListener: true
                }

                if (value.data.visible === false)
                    return

                switch (element.data.specialId) {
                    case SpecialId.NAVIGATOR_HOME:
                        elHome = element
                        buttons.push(<ElementWithListener key={"btnHome_" + itemId + "_" + value.data.id} {...innerProps}>
                            <i className="fas fa-arrow-from-bottom" />
                        </ElementWithListener>)
                        break
                    case SpecialId.NAVIGATOR_END:
                        elEnd = element
                        buttons.push(<ElementWithListener key={"btnEnd_" + itemId + "_" + value.data.id} {...innerProps}>
                            <i className="fas fa-arrow-from-top" />
                        </ElementWithListener>)
                        break
                    case SpecialId.NAVIGATOR_PAGE_UP:
                        elPgUp = element
                        /*buttons.push(<ElementWithListener disabled={!element.data.sensitivity} key={"btnEnd_" + itemId} doAction={() => pressButton(element.id)} outerHtmlClassName={btnStyle} mouseListener={true} >
                            <i className="fas fa-chevron-double-up" />
                        </ElementWithListener>)*/
                        break
                    case SpecialId.NAVIGATOR_PAGE_DOWN:
                        elPgDown = element
                        /*buttons.push(<ElementWithListener disabled={!element.data.sensitivity} key={"btnPgDown_" + itemId} doAction={() => pressButton(element.id)} outerHtmlClassName={btnStyle} mouseListener={true} >
                            <i className="fas fa-chevron-double-down" />
                        </ElementWithListener>)*/
                        break
                    case SpecialId.NAVIGATOR_FORWARD:
                        elDown = element
                        buttons.push(<ElementWithListener key={"btnDown_" + itemId + "_" + value.data.id} {...innerProps}>
                            <i className="fas fa-angle-down" />
                        </ElementWithListener>)
                        break
                    case SpecialId.NAVIGATOR_BACKWARD:
                        elUp = element
                        buttons.push(<ElementWithListener key={"btnUp_" + itemId + "_" + data.id} {...innerProps}>
                            <i className="fas fa-angle-up" />
                        </ElementWithListener>)
                        break
                    default:
                        break
                }
            })
            break
        }
    }

    addListener(KEYDOWN, document, (event) => {
        //console.log("RECORD DETAIL EVENT", event.target instanceof HTMLTextAreaElement)
        if (hasSessionFocus() && hasMoveButtons && event.target instanceof HTMLTextAreaElement == false) {
            switch (event.keyCode) {
                case Keyboard.UP:
                    event.preventDefault()
                    event.stopPropagation() //jinak se zavola tab action v app
                    pressButton(elUp.id)
                    break
                case Keyboard.DOWN:
                    event.preventDefault()
                    event.stopPropagation() //jinak se zavola tab action v app
                    pressButton(elDown.id)
                    break
                case Keyboard.HOME:
                    event.preventDefault()
                    event.stopPropagation() //jinak se zavola tab action v app
                    pressButton(elHome.id)
                    break
                case Keyboard.END:
                    event.preventDefault()
                    event.stopPropagation() //jinak se zavola tab action v app
                    pressButton(elEnd.id)
                    break
                default:
                    break
            }
        }
    })

    function setValueRequest(data) {
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            ...data
        })))
    }

    function pressButton(id) {
        if (!desktopSessionMask[id].data.sensitivity) return
        if (!desktopSessionMask[desktopSessionMask[id].data.parentHandle].data.sensitivity) return

        if (ws_context.getRequestsQueue().size() > 0) return

        setValueRequest({
            handle: currentFocusId,
            newFocus: id,
            action: CHOOSE_ACTION
        })
    }

    /* TODO dodelat odsazeni aby bylo staticke
    -   SessionNode - RectangleElement - ma vysku pouze 1rem
    */
    function getGap() {
        return 0 + 'rem'

        let height = 0
        //console.log("length", props.childIds.length)
        let lastComponent
        props.childIds.forEach(handle => {
            if (desktopSessionMask[handle].data.visible)
                lastComponent = desktopSessionMask[handle]
        })


        if (lastComponent) {
            //console.log("LAST COMPONENT", lastComponent)
            height = lastComponent.data?.y

            height = 15 - height
        }


        //console.log("GAB", height, lastComponent.data?.y)

        return height * 2 + 'rem'
    }

    logRender("RecordDetail")
    logRenderProps(props)
    //console.log("RecordDetail -> render()", props)

    return <div id={data.id} key={data.id} style={{
        gridArea: '1 / 1 /' + screenHeight + ' / ' + screenWidth,
        display: "flex",
        position: "relative",
        flexDirection: "column",
        minWidth: getWidthInChars(props.data.width)
    }}>

        <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'row wrap' }}>
            <h4 className="h4">{data.header}</h4>
            <span className="hover-icon h4">
                <ElementWithListener style={{ padding: '0 0 0 0.5rem' }} doAction={() => {
                    //NEXT MASK
                    setValueRequest({
                        handle: currentFocusId,
                        newFocus: NEXT_DETAIL_PAGE,
                        action: MENU_ACTION
                    })
                }}
                    mouseListener={true}>
                    <i className='far fa-angle-right font-icon fa-lg' />
                </ElementWithListener>
            </span>
            {/*
            {(desktopMenu[NEXT_DETAIL_PAGE].sensitivity === true) ? <span className="hover-icon h4">
                <ElementWithListener style={{ padding: '0 0 0 0.5rem' }} doAction={() => {
                    //NEXT MASK
                    setValueRequest({
                        handle: currentFocusId,
                        newFocus: NEXT_DETAIL_PAGE,
                        action: MENU_ACTION
                    })
                }}
                    mouseListener={true}>
                    <i className='far fa-angle-right font-icon fa-lg' />
                </ElementWithListener>
            </span> : ""}            
            */}

            <div className="btn-toolbar mb-1 ms-4" >
                <FavoriteFunctions />
            </div>
        </div>

        {renderChildrens2Screen(props)}

        <div className="btn-toolbar justify-content-between mt-1 mb-1">
            <RecordActions desktopMenu={desktopMenu} tabId={props.tabId} maskId={props.maskId} />
            <div className="btn-group">
                {buttons}
            </div>
        </div>
    </div >
}

function mapStateToProps(state, ownProps) {
    return {
        ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id],
        desktopSessionMask: state.desktopSession[ownProps.tabId][ownProps.maskId],
        desktopMenu: state.desktopMenu
    }
}

function areEqual(prevProps, nextProps) {
    //console.log("prevProps", prevProps)
    //console.log("nextProps", nextProps)

    return (JSON.stringify(prevProps.desktopSessionMask[prevProps.id]) === JSON.stringify(nextProps.desktopSessionMask[nextProps.id])
        && JSON.stringify(prevProps.desktopMenu) === JSON.stringify(nextProps.desktopMenu))
}

export default connect(mapStateToProps)(React.memo(RecordDetail, areEqual))