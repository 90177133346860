import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import { configureStore } from '@reduxjs/toolkit'

import { uuidv4 } from './utils/actions'
import appReducer from './store/reducer/reducer'
import Constants from './constants/constants'

import * as serviceWorker from './serviceWorker'

import App from './App'

import WebSocketProvider from './services/WebSocket'
import ThemeSelector from './theme/ThemeSelector'

import { emptyDesktopStore } from './store/actions/ws_actions'
import { onValueChange } from './store/actions/store_actions';
import { dropDatabase } from './store/indexedDB';
import styles from './constants/styles';
import { getSessionHeight } from './components/SessionScreen';
import constants from './constants/constants';

const { LocalStorageKeys, InternalElementState } = Constants

//pocatecni nastaveni local storage
//svetleho tematu
if (localStorage.getItem(LocalStorageKeys.DARK_THEME) === null)
	localStorage.setItem(LocalStorageKeys.DARK_THEME, false)

//podbarveni radku v browsu
if (localStorage.getItem(LocalStorageKeys.BROWSE_ROW_COLORED_BACKGROUND) === null)
	localStorage.setItem(LocalStorageKeys.BROWSE_ROW_COLORED_BACKGROUND, true)

//tooltip
if (localStorage.getItem(LocalStorageKeys.TOOLTIP) === null)
	localStorage.setItem(LocalStorageKeys.TOOLTIP, true)

//homepage
if (localStorage.getItem(LocalStorageKeys.HOMEPAGE_SYSTEM_INFO) === null)
	localStorage.setItem(LocalStorageKeys.HOMEPAGE_SYSTEM_INFO, true)
if (localStorage.getItem(LocalStorageKeys.HOMEPAGE_FAVORITE) === null)
	localStorage.setItem(LocalStorageKeys.HOMEPAGE_FAVORITE, true)
if (localStorage.getItem(LocalStorageKeys.HOMEPAGE_RECENT_OPEN) === null)
	localStorage.setItem(LocalStorageKeys.HOMEPAGE_RECENT_OPEN, true)

//logovani
if (localStorage.getItem(LocalStorageKeys.LOGGING_WEB_SOCKETS) === null)
	localStorage.setItem(LocalStorageKeys.LOGGING_WEB_SOCKETS, false)
if (localStorage.getItem(LocalStorageKeys.LOGGING_DEBUG) === null)
	localStorage.setItem(LocalStorageKeys.LOGGING_DEBUG, false)
if (localStorage.getItem(LocalStorageKeys.LOGGING_FOCUS) === null)
	localStorage.setItem(LocalStorageKeys.LOGGING_FOCUS, false)
if (localStorage.getItem(LocalStorageKeys.LOGGING_PROPS) === null)
	localStorage.setItem(LocalStorageKeys.LOGGING_PROPS, false)
if (localStorage.getItem(LocalStorageKeys.LOGGING_RENDER) === null)
	localStorage.setItem(LocalStorageKeys.LOGGING_RENDER, false)

//zoom frame
if (localStorage.getItem(LocalStorageKeys.ZOOM_FRAME_VISIBILITY) === null)
	localStorage.setItem(LocalStorageKeys.ZOOM_FRAME_VISIBILITY, true)

export let clientUUID = uuidv4()
export function generateNewClientUUID() {
	clientUUID = uuidv4()
}

export function resetSession(calledFrom) {
	if (clientUUID)
		dropDatabase()

	//NEW CLIENT UUID
	generateNewClientUUID()

	//NEW STORE
	getStore().dispatch(emptyDesktopStore())

	sessionStorage.clear() //smazani temp hodnot v session storage - pouziva se pro url parametry
	//INIT WEB_CONTEXT - se provede v App.js v metode useEffect()

	//console.log("calledFrom", calledFrom)
}

export const preloadedState = {
	app: {
		menuItems: {

		}
	},
	desktopSession: {
		0: {
			name: "root",
			[0]: {
				0: {
					id: 0,
					componentName: "ROOT",
					childIds: [],
					data: {}
				}
			}
		}
	},
	desktopNavigatorMenu: {
		"0": {
			rowId: "0",
			childIds: [],
			rowName: "ROOT",
			isOpen: true,
			isMenu: true,
			isAllowed: false,
			programName: "",
			programParameter: "",
			rowItemId: "",
			scrollTo: false,
			isUserFocused: false
		},
	},
	desktopMenu: [

	],
	ws: {
		connected: undefined,
		loggedIn: false,
		wsMessage: "",
		serverUUID: "",
		clientUUID: "",
		focusId: 0,
		currentMaskID: 0,
		currentTabId: 0
	},
	currentFocusId: 0,
	showSpinner: false,
	messageDialog: [],
	mnemonic: false,
	tooltipEnabled: localStorage.getItem(LocalStorageKeys.TOOLTIP) === "true",
	browseRowColoredBackground: localStorage.getItem(LocalStorageKeys.BROWSE_ROW_COLORED_BACKGROUND) === "true",
	zoomFrameVisibility: localStorage.getItem(LocalStorageKeys.ZOOM_FRAME_VISIBILITY) === "true",
	focusArea: undefined,
	smallScreen: false,
	sessionHeight: getSessionHeight(),
	notifications: [],
	[constants.InternalElementState.DASHBOARD_VISIBILITY]: true
}

const store = configureStore({
	reducer: appReducer,
	preloadedState,
	devTools: process.env.NODE_ENV !== 'production',
})

export function getStore() {
	return store
}

const container = document.getElementById('root');
const root = createRoot(container)

function RootDiv() {
	sessionStorage.clear()

	React.useEffect(() => {

		//test screen width
		var mediaQuery = window.matchMedia("(min-width: 768px)")
		//console.log("mediaQuery", mediaQuery)
		function handleMediaQueryChange(event) {
			//console.log("handleMediaQueryChange", event)
			if (event.matches) {
				// Media query matches, the width is greater than or equal to 768px
				//console.log("Width is 768px or more")
				getStore().dispatch(onValueChange(InternalElementState.SMALL_SCREEN, false))
			} else {
				// Media query doesn't match, the width is less than 768px
				//console.log("Width is less than 768px")
				getStore().dispatch(onValueChange(InternalElementState.SMALL_SCREEN, true))
				//console.log("dispatch", true)
			}
		}

		const controller = new AbortController()
		mediaQuery.addEventListener("change", handleMediaQueryChange, { signal: controller.signal })
		handleMediaQueryChange(mediaQuery)

		//set color theme
		if (localStorage.getItem(LocalStorageKeys.DARK_THEME) === 'true') {
			document.querySelector('html').setAttribute("data-bs-theme", "dark")
			document.getElementById("page_body").setAttribute("data-theme", "dark")
			//document.documentElement.dataset.theme = "dark"

		} else if (localStorage.getItem(LocalStorageKeys.DARK_THEME) === 'false') {
			document.querySelector('html').setAttribute("data-bs-theme", "light")
			document.getElementById("page_body").setAttribute("data-theme", "default")
			//document.documentElement.dataset.theme = "light"
		}

		return () => { controller.abort() }
	}, [])

	return <ErrorBoundary
		onError={(error) => {
			//console.log("STORE", getStore().getState())
			//console.log("OnError", error, componentStack)
			let data = {
				clientUUID: clientUUID,
				error: error.message,
				componentStack: error.stack,
			}

			fetch('./config.json')
				.then(response => response.json())
				.then(confData => {
					fetch(confData.rest_api_url, {
						method: "POST",
						mode: 'no-cors',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => {
						//console.log("Request complete! response:", res)
					})
				})
		}}
		FallbackComponent={(eprops) => {

			React.useEffect(() => {
				if (clientUUID)
					dropDatabase()
			}, [])

			//console.log("eprops", eprops)
			return <div className="vstack gap-2 mx-auto">
				<div className="bg-light border">
					<h4 className="alert-heading">Nastala aplikační chyba:</h4>
				</div>
				<div className="bg-light border"><pre>{eprops.error.message}</pre></div>
				<div className="bg-light border"><pre>{eprops.error.stack}</pre></div>
				<div>
					{/*<button className="btn btn-primary me-2" style={styles.buttonStyle} onClick={eprops.resetErrorBoundary}>Obnovit</button>*/}
					<button className="btn btn-primary me-2" style={styles.buttonStyle} onClick={eprops.resetErrorBoundary}>Restartovat aplikaci</button>
					<button className="btn btn-primary me-2" style={styles.buttonStyle} onClick={e => {
						eprops.resetErrorBoundary
						window.open('mailto:dev@apso.cz?subject=Hlášení chyb&body=' + eprops.error.stack)
					}

					}>Nahlásit chybu</button>
				</div>
			</div>
		}}
		onReset={() => {
			resetSession("index.js -> onReset")
		}}>
		<WebSocketProvider>
			<ThemeSelector>
				<App />
			</ThemeSelector>
		</WebSocketProvider>
	</ErrorBoundary >
}

root.render(<Provider store={store}>
	<RootDiv />
</Provider>)

serviceWorker.unregister()
