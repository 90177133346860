import React from 'react';
import { connect } from 'react-redux'

import { parentSensitivity } from './elementMethods'
import { disableOtherListeners } from '../../app_actions'
import Constants from '../../constants/constants'
import { getStore } from '../../index.js'
import { logRender, logRenderProps } from '../../utils/logging'
import { getEmptyRowProp } from '../../utils/actions'
import { onValueChangeByHandle } from '../../store/actions/store_actions'
import useFocusObserver from '../../hooks/useFocusObserver'

const { SET_VALUE_REQUEST } = Constants.WebSocketActions
const { VALUE_CHANGED } = Constants.SetValueRequest

export function handleRadioSetElementClick(event, ws_context) {
    //console.log("handleRadioSetElementClick")
    const { createMessage, createRequest, sendMessage } = ws_context

    disableOtherListeners(event, "RadioSetElement.handleRadioSetElementClick()")

    let store = getStore().getState()
    let ws = store.ws
    let desktopSessionMask = store.desktopSession[ws.currentTabId][ws.currentMaskID]

    let handle = event.target.id.split(";")[0]
    let value = event.target.id.split(";")[1]

    let previousElementValue
    if (ws.focusId?.toString() !== handle?.toString())
        previousElementValue = { value: desktopSessionMask[ws.focusId].data.value }

    sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
        ...getEmptyRowProp(),
        handle: ws.focusId,
        newFocus: handle,
        action: VALUE_CHANGED,
        newFocusValue: value,
        ...previousElementValue
    })), response => {
        /*MH -> existuje nejake pravidlo kdy se to ma zapsat
            - kdyz u je focus na radioSetElementu, tak se vraci prazdna odpoved
            - kdyz neni focus na radioSetElementu, tak se vraci RadioSetElement ale bez nove hodnoty, PROC(je to zmena)???

        lze zkouset na 10.19.14
        */

        //je to ok
        //if (response.data && response.data.length === 0)
        getStore().dispatch(onValueChangeByHandle(handle, "value", value))

    })
}

function RadioSetElement(props) {
    const { id, data } = props
    const values = props.data.values
    const inline = (props.data.height === 1) ? "form-check form-check-inline" : "form-check"

    //console.log("RadioSetElement ID: ", id, props)
    useFocusObserver(id, "RadioSetElement")

    logRender("RadioSetElement")
    logRenderProps(props)
    return (
        <form>
            {values.map((val, index) => {
                //console.log("RadioSetElement: " + (data.value === val.value), val, index)
                return (
                    <div key={"radioElement" + index} className={inline} style={{ minHeight: '2rem' }}>

                        <input className="form-check-input"
                            id={id + ";" + val.value}
                            type="radio"
                            name="flexRadioDefault"
                            onChange={e => { }}
                            checked={data.value === val.value}
                            value={val.value}
                            disabled={data.sensitivity === false || !props.parentSensitivity} />
                        <label className="form-check-label" htmlFor={"flexRadioDefault" + val.value}>
                            {val.label}
                        </label>
                    </div>
                )
            })}
        </form>
    )
}

function mapStateToProps(state, ownProps) {
    //console.log("mapStateToProps", state)
    return {
        ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id],
        parentSensitivity: parentSensitivity(ownProps.id, ownProps.tabId, ownProps.maskId),
        currentFocusId: state.ws.focusId,
    }
}

function areEqual(prevProps, nextProps) {
    /*console.log("ARE EQUAL", (prevProps.data.value === nextProps.data.value))
    console.log("prevProps", prevProps)
    console.log("nextProps", nextProps)*/

    return (prevProps.data.value === nextProps.data.value
        && prevProps.data.sensitivity === nextProps.data.sensitivity
        && prevProps.parentSensitivity === nextProps.parentSensitivity)
}

export default connect(mapStateToProps)(React.memo(RadioSetElement, areEqual))