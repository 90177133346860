import React from 'react'
import { connect } from 'react-redux'

import { logRender, logRenderProps } from '../../utils/logging'
import { getBase64File } from '../../store/indexedDB'

/*
TODO - zohlednit
retainShape - zachovani pomeru stran
*/
function ImageElement(props) {
    const [base64, setBase64] = React.useState()
    const { data } = props
    let style = {}


    React.useEffect(() => {
        if (data.imageFileName !== undefined) {
            // nacte obrazek z indexedDB
            getBase64File(data.imageFileName, base64File => {
                setBase64(base64File)
            })
        }
    })

    if (data.centered)
        style = {
            ...style,
            position: 'relative',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '100%',
            height: 'auto'
        }

    logRender("ImageElement")
    logRenderProps(props)
    //console.log("PROPS", props)
    if (base64 == undefined)
        return <i className="fas fa-ban" />

    return <img style={style} src={"data:image/png;base64, " + base64} />
}

function mapStateToProps(state, ownProps) {
    let currentTabId = state.ws.currentTabId
    let maskId = state.ws.currentMaskID
    return state.desktopSession[currentTabId][maskId][ownProps.id]
}

export default connect(mapStateToProps)(ImageElement)
