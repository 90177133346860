import React from 'react'
import { connect } from 'react-redux'

import TableActionButtons from './TableActionButtons'
import BrowseHeader from './BrowseHeader'
import BrowseBody from './BrowseBody'
import ContextMenu from '../ContextMenu'
import BrowseBottom from './BrowseBottom'
import { getSelectedItem } from './data'
import { WebSocketContext, KEY_REQUEST } from '../../services/WebSocket'
import Constants from '../../constants/constants'
import { logRender, logRenderProps } from '../../utils/logging'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import { emptyBrowseData, changeFocusAreaWithBrowseFocusLoss, onValueChange, onValuesChangeInMask } from '../../store/actions/store_actions'
import { getPxWidthByLetters, getXScreenValue } from '../../utils/screen'

import { addListenerWithRefHandler, LISTENER_TYPE } from '../../hooks/desktopListener'
import { CM_BROWSE } from '../ContextMenu'
import { focusElement, getRelatedTargetFocusArea } from '../../focus_actions'
import FavoriteFunctions from '../FavoriteFunctions'
import BrowseColumnsSettingsDialog, { COLUMNS_SETTING_PROP } from './BrowseColumnsSettingsDialog'
import { getSpecialId } from '../../store/storageMethods'
import { getSessionHeight } from '../SessionScreen'
import constants from '../../constants/constants'
import { triggerBrowseGo } from '../../store/actions/ws_actions'

const { SCROLL, RESIZE } = LISTENER_TYPE

const { GET_BROWSE_DATA_REQUEST, GET_BROWSE_SCREEN_DATA_REQUEST, SELECT_BROWSE_ROW_REQUEST, GET_BROWSE_DATA_AND_SELECT_REQUEST, SET_VALUE_REQUEST } = Constants.WebSocketActions

export const UP = "up"
export const DOWN = "down"

export const ROW_HEIGHT = 23
export const MIN_COLUMN_WIDTH = 40
export const MIN_COLUMN_WIDTH_PX = "40px"
export const SETTINGS_PADDING = "0.5rem"


/*
nacitani dat:
    1. GET_BROWSE_SCREEN_DATA_REQUEST
        - nacteni vsech dat pro zobrazeni(3 buffery naraz)
        - ve WebSocket.js se plni do scrollOptions{} puvodni pozice scrollbaru odskrolovani
    2. GET_BROWSE_DATA_REQUEST
        - nacteni celeho bufferu pokud doskroluju na zacatek/konec
        - ve WebSocket.js se plni do scrollOptions{} radek a smer pro odskrolovani
    3. GET_BROWSE_DATA_AND_SELECT_REQUEST
        - spojeni nacteni radku + vyberu radku
        - ve WebSocket.js se plni do scrollOptions{} radek a smer pro odskrolovani

    pozn. při akcích SELECT_BROWSE_ROW_REQUEST a SET_VALUE_REQUEST se v reducer.js -> processTreeItems() maze pro browse scrollOptions{}
*/
function Browse(props) {
    //let renderTime = Date.now()

    const refContextMenu = React.useRef()
    const browseRef = React.useRef(null)
    const tableRef = React.useRef(null)
    const selRowRef = React.useRef(null)
    const scrollingRowRef = React.useRef(null)
    const tableElementRef = React.useRef(null)

    const { data, desktopSessionMask, desktopMenu, accountUnitSelection, dispatch, tabId, maskId } = props
    const { browseData, sizePerPage } = data

    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage, getRequestsQueue } = ws_context
    const [tableBodyHeight, setTableBodyHeight] = React.useState(getHeightSize(sizePerPage))
    const [loadingData, setLoadingData] = React.useState(false)

    //ucje - pocet radku
    React.useEffect(() => {
        //vyber ucetni jednotky - 10 radku
        if (getSpecialId(data.parentHandle) === Constants.SpecialId.UCJE_SELECTION) {
            if (browseData?.length > 0) {
                let browseDataLength = browseData?.length
                let numRows = 15

                if (browseDataLength && browseDataLength < 15)
                    numRows = browseDataLength

                if (browseDataLength === 0)
                    numRows = 1

                if (numRows !== sizePerPage)
                    setSizePerPage(numRows)
            }
        }
    })

    addListenerWithRefHandler(SCROLL, tableRef, handleScroll)
    //uprava vysky browsu podle toho zda je viditelny horizontalni scrollbar

    /*addListener(LISTENER_TYPE.MOUSEDOWN, document, e => {
        isResizing = true
    })

    addListener(LISTENER_TYPE.MOUSEUP, document, e => {
        isResizing = false
    })*/

    function handleResize(iSizePerPage) {
        if (tableRef.current) {
            function isHorizontalScrollbarVisible() {
                return tableRef.current.scrollWidth > tableRef.current.clientWidth
            }

            //console.log("handleResize", iSizePerPage, sizePerPage)
            if (getHeightSize(iSizePerPage) !== 0) {
                const horizontalScrollbarHeight = 9
                let heightByRows = getHeightSize(iSizePerPage)
                if (isHorizontalScrollbarVisible() && tableBodyHeight === heightByRows) {
                    if (tableBodyHeight !== (heightByRows + horizontalScrollbarHeight))
                        setTableBodyHeight(heightByRows + horizontalScrollbarHeight)
                } else if (!isHorizontalScrollbarVisible() && tableBodyHeight === (getHeightSize(iSizePerPage) + horizontalScrollbarHeight)) {
                    if (tableBodyHeight !== heightByRows)
                        setTableBodyHeight(heightByRows)
                }
            }
        }
    }

    React.useEffect(() => {
        //ve firefoxu se obcas ztratil fucus
        focusElement(currentFocusId, "Browse.useEffect()")
    }, [])

    /*po zmene poctu radku, upravit vysku dle scrollbaru */
    React.useEffect(() => {
        handleResize(sizePerPage)
    }, [sizePerPage, tableBodyHeight])

    React.useEffect(() => {
        const controller = new AbortController()

        function focusin(e) {
            //console.log("in", e)
            const enteringParent = !tableElementRef.current?.contains(e.relatedTarget)
            if (enteringParent) {
                //console.log("focus in", e, props)
                //console.log("active element", document.activeElement)
                //dispatch(onValuesChangeInMask(props.id, props.tabId, props.maskId, { hasFocus: true }))
                dispatch(onValueChange("browseHasFocus", true))
            }
        }

        function focusout(e) {
            //console.log("out", e)
            const leavingParent = !tableElementRef.current?.contains(e.relatedTarget)
            if (leavingParent)
                dispatch(changeFocusAreaWithBrowseFocusLoss(getRelatedTargetFocusArea(e.relatedTarget)))
        }

        if (tableElementRef.current && tableElementRef.current !== null) {
            tableElementRef.current.addEventListener("focusin", focusin, { signal: controller.signal })
            tableElementRef.current.addEventListener("focusout", focusout, { signal: controller.signal })
        }

        return () => {
            controller.abort()
        }
    })

    let ws, currentFocusId, reachTop, reachBottom, scrollOptions, sleep, messageDialog
    useStoreSubscription(store => {
        messageDialog = store.messageDialog
        ws = store.ws
        currentFocusId = ws.focusId
        sleep = ws.sleep

        if (store.desktopSession[tabId] && store.desktopSession[tabId][maskId]) {
            let browseProperties = store.desktopSession[tabId][maskId][props.id]
            if (browseProperties != undefined) {
                reachTop = browseProperties.data.reachTop
                reachBottom = browseProperties.data.reachBottom
                scrollOptions = browseProperties.data.scrollOptions
                //console.log("SCROLLOPTIONS", scrollOptions, browseProperties)
            }
        }
    })

    function getZoomFrameHeightByStore() {
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
        for (const [key, value] of Object.entries(desktopSessionMask)) {
            if (value?.data?.specialId === constants.SpecialId.ZOOM_FRAME)
                return value.data.height * rootFontSize * 2
        }
    }

    function getHeaderFrameHeightByStore() {
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
        for (const [key, value] of Object.entries(desktopSessionMask)) {
            if (value?.data?.specialId === constants.SpecialId.HEADER_FRAME)
                return value.data.height * rootFontSize * 2
        }
    }

    function getScreenEmptySpace() {
        //console.log("---> GET SCREEEN EMPTY SPACE", props.sessionHeight)
        let browseTableContentId = "browseScrollDiv"

        let rootFrame = document.getElementById(constants.ElementsIds.SESSION_SCREEN)
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
        let rootHeight = getSessionHeight() * rootFontSize

        //nad browsem - obsazený prostor        
        let browseElement = document.getElementById(constants.ElementsIds.BROWSE_FRAME)?.getBoundingClientRect()
        let browseContent = document.getElementById(browseTableContentId)?.getBoundingClientRect()
        let spaceAboveBrowse = browseElement?.y - rootFrame?.getBoundingClientRect()?.y
        //pokud jeste header frame neni vykresleny prevezme se vyska header frame elementu ze storu
        /*
        let headerFrameStoreHeight = getHeaderFrameHeightByStore()
        if (headerFrameStoreHeight && spaceAboveBrowse < headerFrameStoreHeight)
            spaceAboveBrowse = headerFrameStoreHeight*/

        //pod browsem - obsazený prostor        
        let zoomFrameElement = document.getElementById(Constants.ElementsIds.ZOOM_FRAME)?.getBoundingClientRect()
        let spaceUnderBrowse = 0
        if (zoomFrameElement)
            spaceUnderBrowse = zoomFrameElement.height

        //pokud jeste zoomframe neni vykresleny prevezme se vyska zoomframe elementu ze storu
        /*
        let zoomFrameStoreHeight = getZoomFrameHeightByStore()
        if (zoomFrameStoreHeight && spaceUnderBrowse < zoomFrameStoreHeight)
            spaceUnderBrowse = zoomFrameStoreHeight*/

        //celkem obsazeno na obrazovce
        let browseContentHeight = 0
        browseContentHeight = browseContent?.height

        let emptyBrowseHeight = browseElement?.height - browseContentHeight
        let occupiedSpace = spaceUnderBrowse + spaceAboveBrowse + emptyBrowseHeight

        //browse s prvky nad nebo pod
        if (props.data.verticalScale > 1)
            return (rootHeight - occupiedSpace) / props.data.verticalScale

        if (occupiedSpace)
            return rootHeight - occupiedSpace

        return 15 * rootFontSize
    }

    React.useEffect(() => {
        getNumberOfRows()
    }, [props.zoomFrameVisibility, props.sessionHeight, props.data.sensitivity])

    function getNumberOfRows() {
        //zmena poctu radku pouze pro browse se sensitivitou
        if (/*data.sensitivity && - delalo to problem pri zmensovani a zvetsovani zoom okna - nespocitala se vyska*/ getSpecialId(data.parentHandle) !== Constants.SpecialId.UCJE_SELECTION) {
            let numOfRows = Math.floor(getScreenEmptySpace() / ROW_HEIGHT) - 2
            if (numOfRows < 1)
                numOfRows = 1

            if (sizePerPage !== numOfRows && numOfRows > 0)
                setSizePerPage(numOfRows)
        }
    }

    /*NACTENI DAT BROWSU
    - pokud je vracen refresh == true nebo refresh je prazdny
        -> TODO -MH, jelikoz se predava pro refresh rowId, tak se po refreshy vrati data zasobniku oznacene polozky, uzivatel ale muze byt odscrolovany jinde
            - mozne reseni -> jako rowid se posle prostredni radek v aktulnim zasobniku + verticticalScrollPosition a nebude se oznacovat jako tomu je ted kdybychom tuto akci provedli
    - pri zmene mnozstvi dat pro zobrazeni*/
    //useLayoutEffect - gets triggered synchronously after all DOM mutation
    React.useLayoutEffect(() => {
        //console.log("Browse => useLayoutEffect()", props.zoomFrameVisibility)
        let rowIdOutOfBuffer = true
        let verticalPosition = 0

        if (browseData !== undefined) {
            // pro nastaveni aktulani pozice - data se nacitaji pro refresh
            browseData.find((element, index) => {
                if (element.rowId === data.rowId) {
                    verticalPosition = index + 1
                    rowIdOutOfBuffer = false
                }
            })
        }

        //console.log("HERE", sleep === null, data.refresh === true, data.refresh === undefined, data.sensitivity)
        if (sleep === null && (data.refresh === true || data.refresh === undefined) && data.sensitivity) {
            //verticalScrollPosition = tableRef.current.scrollTop

            if (sizePerPage > 0) {
                if (data.rowId === undefined || data.rowId === null || data.rowId === '&Null;') {
                    //neobsahuje radky nutne smazat data browsu
                    dispatch(emptyBrowseData(props.id))
                } else {
                    if (loadingData === false) {
                        //existuje rowId (browse obsahuje radky)
                        let numBuffers = 3

                        let reqData = {
                            rowId: data.rowId,
                            selectedItem: getSelectedItem(desktopSessionMask, props.id),
                            handle: props.data.handle,
                            numRows: sizePerPage,
                            verticalPosition,
                            numBuffers,
                            scrollOptions: {
                                loadData: true,
                                verticalScrollPosition: scrollOptions?.verticalScrollPosition ?? 0,
                                horizontalScrollPosition: scrollOptions?.horizontalScrollPosition ?? 0
                            }
                        }

                        //pokud nastala chyba, nenastavi se nacteni dat a nastane zacykleni
                        if (messageDialog?.length > 0)
                            return

                        sendMessage(createMessage(GET_BROWSE_SCREEN_DATA_REQUEST, createRequest(reqData)), response => {
                            setLoadingData(false)

                            if (props.triggerGoEvent) {
                                sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({ handle: props.id, action: Constants.SetValueRequest.BROWSE_GO })), response => {
                                    dispatch(triggerBrowseGo(props.id, true))
                                })
                            }
                        })
                        setLoadingData(true)
                    }
                }
            }
        }

        let selRowRef = document.getElementById(data.rowId)
        let prevSelRowRef = getPreviousRowElement(data.rowId)

        //pokud se provadi refresh, tak se nastavi puvodni skrolovaci pozice
        if (scrollOptions?.action === GET_BROWSE_SCREEN_DATA_REQUEST
            || scrollOptions?.action === GET_BROWSE_DATA_AND_SELECT_REQUEST
            || scrollOptions?.action === GET_BROWSE_DATA_REQUEST) {

            if (tableRef.current.scrollLeft !== data.scrollOptions.horizontalScrollPosition && tableRef.current.scrollTop !== data.scrollOptions.verticalScrollPosition)
                tableRef.current.scrollTo(data.scrollOptions.horizontalScrollPosition, data.scrollOptions.verticalScrollPosition)
        }

        //console.log("selRowRef", selRowRef)
        //console.log("selRowRef.offsetTop: ", selRowRef.offsetTop)

        function doScroll(x, y, tag) {
            if (x !== tableRef.current.scrollLeft || y !== tableRef.current.scrollTop) {
                //console.log("do scroll", tag, x, y)
                tableRef.current.scrollTo(x, y, tag)
            }
        }

        function doScrollElement(el, arg, tag) {
            //console.log("do scroll element", tag, el, arg)
            el.scrollIntoView(arg)
        }

        //console.log("browseData", browseData)
        //console.log("isBrowseHeaderVisible: ", isBrowseHeaderVisible())
        if (browseData !== undefined) {
            //console.log("SCROLING OPTIONS: ", scrollOptions)
            if (scrollOptions !== undefined && (scrollOptions.loadData === undefined || scrollOptions.loadData === false)) {
                //console.log("SCROLLING", scrollingRowRef, scrollOptions, data)
                if (scrollOptions.direction === UP) {
                    if (browseData != undefined) {
                        let el = getPreviousRowElement(scrollOptions.rowId)
                        if (el !== undefined) {
                            //console.log("1 scrolling", true, el)
                            if (prevSelRowRef === selRowRef) {
                                //console.log("scrolling to 0")
                                //prvni radek by byl schovany pod sticky header
                                doScroll(tableRef.current.scrollLeft, 0, "@scroll - 1.1")
                            } else {
                                //console.log("scrolling to element", el)
                                //pri skrolovani 2x nahoru a pokud je u predka dalsi scrollbar tak "scrollIntoView()" nefunguje, proto je pouzito "scrollTo()"
                                if (!reachTop)
                                    doScroll(tableRef.current.scrollLeft, el.offsetTop, "@scroll - 1.2")
                                else
                                    doScrollElement(el, true, "@scroll - 1.3")
                            }
                        }
                    } else {
                        //console.error("2 scrolling not implemented", true)
                        doScrollElement(scrollingRowRef.current, true, "@scroll - 2")
                    }
                } else if (scrollOptions.direction === DOWN) {
                    //V chrome to funguje samo, ve firefoxu ne
                    //scrollingRowRef.current.scrollIntoView(false)
                    let element = document.getElementById(scrollOptions.rowId)
                    if (element !== null) {
                        doScroll(tableRef.current.scrollLeft, element.offsetTop - sizePerPage * ROW_HEIGHT, "@scroll - 3.1")
                    }
                } else {
                    if (scrollOptions.verticalScrollPosition)
                        doScroll(scrollOptions.horizontalScrollPosition, scrollOptions.verticalScrollPosition, "@scroll - 3.2 scroll dle scrollOptions")
                }
            } else {
                //scrolovani vybraneho radku pokud je v neviditelne casti
                //console.log("tableRef", tableRef)
                //console.log("tableRef.scrollTop: ", tableRef.scrollTop)
                if (selRowRef && tableRef.current) {
                    //console.log(selRowRef.offsetTop, tableRef.current.scrollTop, " = ", (selRowRef.offsetTop - tableRef.current.scrollTop))
                    let selRowPosition = selRowRef.offsetTop - tableRef.current.scrollTop

                    selRowPosition = selRowRef.offsetTop - tableRef.current.scrollTop
                    //console.log("selRowPosition: " + selRowPosition, "ROW_HEIGHT: " + ROW_HEIGHT)
                    if (selRowPosition < ROW_HEIGHT) {
                        if (reachTop) {
                            //console.log("4 selRowRef", selRowRef)
                            if (prevSelRowRef === selRowRef) {
                                //prvni radek by byl schovany pod sticky header
                                doScroll(tableRef.current.scrollLeft, 0, "@scroll - 4.1")
                            } else
                                doScroll(tableRef.current.scrollLeft, (selRowRef.offsetTop - ROW_HEIGHT), "@scroll - 4.2")
                        } else if (isBrowseHeaderVisible()) {
                            //pokud je zobrazena hlavicka tabulky, vybrany radek je schovany pod hlavickou -> skroluje se o jeden radek vys aby byl videt
                            if (browseData !== undefined) {
                                let el = getPreviousRowElement(data.rowId)
                                if (el !== undefined) {
                                    doScroll(tableRef.current.scrollLeft, (selRowRef.offsetTop - ROW_HEIGHT), "@scroll - 5")
                                }
                            }
                        } else {
                            doScroll(tableRef.current.scrollLeft, (selRowRef.offsetTop), "@scroll - 6")
                        }
                    } else if ((tableRef.current.offsetHeight - selRowPosition) < ROW_HEIGHT) {
                        if (selRowRef.rowIndex === browseData.length) {
                            //odskrolovani posledniho radku az na konec
                            doScroll(tableRef.current.scrollLeft, tableRef.current.scrollHeight, "@scroll - 7.1")
                        } else {
                            if (isBrowseHeaderVisible()) {
                                doScroll(tableRef.current.scrollLeft, (selRowRef.offsetTop - sizePerPage * ROW_HEIGHT), "@scroll - 7.2")
                            } else
                                doScroll(tableRef.current.scrollLeft, (selRowRef.offsetTop - (sizePerPage - 1) * ROW_HEIGHT), "@scroll - 7.2")
                        }
                    } else {
                        //console.log("NO SCROLLING")
                    }
                }
            }
        }
    })

    /*React.useEffect(()=>{
        console.log("------------------------------------------------------------------------")
        console.log("%c Browse render time ->: " + (Date.now() - renderTime), 'color: #34eb65')
    })*/

    function handleScroll(e) {
        if (data.sensitivity) {
            e.preventDefault()
            //console.log("handle scroll", tableRef.current.scrollTop)
            //console.log("1 BOTTOM", (tableRef.current.scrollHeight - tableRef.current.scrollTop) - tableRef.current.clientHeight, " < ", ROW_HEIGHT)

            //if (scrollTop != tableRef.current.scrollTop) {
            let scrollTop = tableRef.current.scrollTop

            const bottom = (tableRef.current.scrollHeight - tableRef.current.scrollTop) - tableRef.current.clientHeight < 1
            //console.log("2 BOTTOM", (tableRef.current.scrollHeight - tableRef.current.scrollTop) - tableRef.current.clientHeight, " < ", ROW_HEIGHT)

            const top = tableRef.current.scrollTop === 0

            if (bottom && !reachBottom && browseData !== undefined) {
                loadDown()
            } else if (top && !reachTop && browseData !== undefined) {
                loadUp()
            } else {
                dispatch(onValuesChangeInMask(props.id, props.tabId, props.maskId, {
                    scrollOptions: {
                        ...scrollOptions,
                        loadData: false,
                        direction: undefined,
                        horizontalScrollPosition: tableRef.current.scrollLeft,
                        verticalScrollPosition: tableRef.current.scrollTop
                    }
                }))
            }
        }

        /*horizontalScrollPosition = tableRef.current.scrollLeft
        verticalScrollPosition = tableRef.current.scrollTop*/


    }



    function getPreviousRowElement(rowId) {
        let previousRow, selIndex
        if (browseData !== undefined) {
            browseData.find((element, index) => {
                //console.log(element.rowId + " === " + rowId)
                if (element.rowId === rowId) {
                    selIndex = index
                    if (selIndex > 0)
                        selIndex--
                    return selIndex
                }
            })

            //console.log("INDEX", selIndex)

            if (browseData[selIndex] !== undefined)
                previousRow = document.getElementById(browseData[selIndex].rowId)
        }
        return previousRow
    }

    function loadDown() {
        /*console.log("-----")
        console.log("--D -")
        console.log("-----")
        console.log("- | -")
        console.log("- | -")
        console.log("- ↓ -")
        console.log("-----")
        console.log(browseData[browseData.length - 1])*/
        if (browseData[browseData.length - 1] !== undefined)
            getBrowseDataRequest(sizePerPage, browseData[browseData.length - 1].rowId)
    }

    function loadUp() {
        /*console.log("-----")
        console.log("- UP-")
        console.log("-----")
        console.log("- ↑ -")
        console.log("- | -")
        console.log("- | -")
        console.log("------")
        console.log(browseData[0])*/
        if (browseData[0] !== undefined)
            getBrowseDataRequest(sizePerPage * -1, browseData[0].rowId)
    }

    function getBrowseDataRequest(numRows, rowId, callback) {
        //console.log("getBrowseDataRequest")
        let keyRequest = GET_BROWSE_DATA_REQUEST + "," + rowId

        let data = {
            handle: props.data.handle,
            numRows: numRows,
            scrollOptions: {
                ...scrollOptions,
                loadData: false,
                horizontalScrollPosition: props.data.scrollOptions?.horizontalScrollPosition ?? 0,
                verticalScrollPosition: props.data.scrollOptions?.verticalScrollPosition ?? 0
            }
        }

        //existuje rowId (browse obsahuje radky)
        if (rowId !== '&Null;') {
            data = {
                ...data,
                rowId: rowId
            }

            //mohlo by se potkat s GET_BROWSE_DATA_AND_SELECT_REQUEST 
            if (getRequestsQueue().size() === 0)
                sendMessage(createMessage(GET_BROWSE_DATA_REQUEST, createRequest(data), { [KEY_REQUEST]: keyRequest }), callback)
        }
    }

    /*OZNACENI RADKU BROWSU */
    function selectBrowseRow(newRow, callback) {

        //console.log("DESKTOP -> selectBrowseRow()", props)
        //console.log(desktopSessionMask)

        //pridani informaci k editoru(po zmene radku se muze vracet ScreenDescription s EditorElementem, ktery neobsahuje sourceFileName, proto se musi predavat)
        //EditorElement ma pro vsechny radky stejny sourceFileName
        let editors = []
        for (const [key, value] of Object.entries(desktopSessionMask)) {
            if (value.componentName === "EditorElement")
                editors.push({ handle: value.data.handle, sourceFileName: value.data.sourceFileName })
        }

        //console.log("NEW ROW", newRow)
        let keyRequest = SELECT_BROWSE_ROW_REQUEST + "," + newRow.rowId

        if (getRequestsQueue().size() === 0) {
            sendMessage(createMessage(SELECT_BROWSE_ROW_REQUEST, createRequest({
                handle: props.id,
                rowId: newRow.rowId,
                editors
            }), { [KEY_REQUEST]: keyRequest }), callback)
        }
    }

    function getColumnWidths() {
        let columsWidth = []
        let columnOrder = data.browseColsSorted.split(",")

        columnOrder.forEach(columnId => {
            let hData = desktopSessionMask[columnId].data
            let width = hData.width
            if (hData.inTableWidth && hData.inTableWidth > -1)
                width = hData.inTableWidth

            //15px - dorovnani ascending/descending + settings icon
            let colWidth = getPxWidthByLetters(width, true) + 15
            let colWidthPx = colWidth + "px"
            if (width <= 1) {
                //colWidthPx = MIN_COLUMN_WIDTH_PX
                colWidthPx = '80px'
                colWidth = 80
            }

            let cw = {
                width: hData.width,
                colWidthPx: colWidthPx,
                colWidth: colWidth,
                colWidthPercent: getXScreenValue(width)
            }

            if (hData.inTableWidth > -1)
                cw = {
                    ...cw,
                    inTableWidth: hData.inTableWidth
                }

            columsWidth.push(cw)
        })

        return columsWidth
    }

    function getHeightSize(size) {
        let sizeHeight = ROW_HEIGHT * size
        if (isBrowseHeaderVisible())
            sizeHeight = sizeHeight + ROW_HEIGHT

        return sizeHeight
    }

    function setSizePerPage(size) {
        //console.log("set size per page", size)

        //obsah se maze, jinak by se muselo porovnavat jake rakdy do browsu patri
        dispatch(emptyBrowseData(props.id))

        if (getHeightSize(size) !== 0)
            setTableBodyHeight(getHeightSize(size))

        dispatch(onValuesChangeInMask(props.id, tabId, maskId, {
            refresh: true,
            sizePerPage: size
        }))
    }

    function isBrowseHeaderVisible() {
        let visibility = false

        props.childIds.map(columnId => {
            let hColumn = desktopSessionMask[columnId].data

            if (hColumn.label !== "&Null;") {
                visibility = true
                return
            }
        })

        return visibility
    }

    function displayContextMenu(e, type) {
        if (refContextMenu.current && e.target.className !== "inputURL")
            refContextMenu.current.displayContextMenu(e, type)
    }

    logRender("Browse")
    logRenderProps(props)
    //console.log('render() -> Browse ', props.data.sensitivity)
    //console.log("BROWSE DATA", browseData)

    /*if (browseData == undefined)
        return <div ref={tableRef}></div>*/

    let tableStyle = {
        borderCollapse: 'collapse',
        marginBottom: 0,
        tableLayout: 'fixed'
    }

    if (props.data.browseColsSorted == undefined)
        return null

    function renderBrowseOptions() {
        if (props.hidePagination)
            return null
        return <div className="btn-toolbar justify-content-between mt-1" >
            <div className='mb-1'>
                <FavoriteFunctions />
            </div>
            <div className='mb-1'
                style={{
                    display: 'flex',
                    flexWrap: 'wrap-reverse',
                    placeContent: 'flex-end',
                    alignItems: 'baseline'
                }}>
                {<TableActionButtons
                    desktopMenu={desktopMenu}
                    desktopSessionMask={desktopSessionMask}
                    browseId={data.handle}
                    tabId={tabId}
                    maskId={maskId} />}
            </div>
        </div>
    }

    function getWidthByColumnsStyle() {
        if (getSpecialId(data.parentHandle) === Constants.SpecialId.UCJE_SELECTION)
            return {}

        let widthByChars = 0
        let columnOrder = data.browseColsSorted.split(",")
        columnOrder.forEach(id => {
            let hColumn = desktopSessionMask[id].data
            let columnWidth = hColumn.width
            if (hColumn.inTableWidth && hColumn.inTableWidth > -1)
                columnWidth = hColumn.inTableWidth

            widthByChars = widthByChars + columnWidth
        })

        return { width: getPxWidthByLetters(widthByChars) }
    }

    if (getSpecialId(data.parentHandle) === Constants.SpecialId.UCJE_SELECTION) {
        let actualNumRows = props?.data?.browseData?.length
        if (actualNumRows < sizePerPage) {
            let newHeight = getHeightSize(actualNumRows)
            if (tableBodyHeight !== newHeight && newHeight !== 0)
                setTableBodyHeight(newHeight)
        }
    }

    function BrowseSpinner() {
        console.log("data", data.browseData)
        if (loadingData) {
            return <div className="spinner-border" role="status" style={{ position: "absolute", top: "50%", left: "50%" }}>
                <span className="visually-hidden">Loading...</span>
            </div>
        }
    }

    return (
        <div id={"browse_" + props.id} ref={browseRef}>
            <ContextMenu ctxRef={refContextMenu} desktopMenu={desktopMenu} type={CM_BROWSE} />
            {renderBrowseOptions()}

            <BrowseColumnsSettingsDialog
                key={"browse_columns_setting_" + props.id}
                desktopSessionMask={desktopSessionMask}
                browseHandle={props.id}
                tabId={tabId}
                maskId={maskId}
                isModalOpen={desktopSessionMask[props.id].data[COLUMNS_SETTING_PROP]} />

            <div id="browseScrollDiv" className="table-responsive-sm" ref={tableRef} style={{
                height: tableBodyHeight + 'px',
                overflow: 'auto',
                //position: 'relative'
            }}>
                {/*<BrowseSpinner />*/}
                <table id={props.id} ref={tableElementRef} tabIndex={0} className="table table-sm table-hover table-bordered"
                    style={{
                        ...tableStyle,
                        ...getWidthByColumnsStyle()
                    }}>

                    {isBrowseHeaderVisible() ? < BrowseHeader
                        {...props}
                        desktopSessionMask={desktopSessionMask}
                        columsWidth={getColumnWidths()}
                        desktopMenu={desktopMenu}
                        tableElementRef={tableElementRef}
                        handleResize={handleResize}
                        sizePerPage={sizePerPage}
                        minColumnsWidth={MIN_COLUMN_WIDTH_PX} /> : null}

                    <BrowseBody
                        {...props}
                        tableElementRef={tableElementRef}
                        desktopMenu={desktopMenu}
                        sizePerPage={sizePerPage}
                        displayContextMenu={displayContextMenu}
                        selectBrowseRow={selectBrowseRow}
                        selRowRef={selRowRef}
                        hasFocus={props.hasFocus}
                        columsWidth={getColumnWidths()}
                        browseRowColoredBackground={props.browseRowColoredBackground}
                    />
                </table>
            </div>

            <BrowseBottom
                {...props}
                desktopMenu={desktopMenu}
                desktopSessionMask={desktopSessionMask}
                ws={ws}
                sizePerPage={sizePerPage}
                setSizePerPage={setSizePerPage}
                zoomFrameVisibility={props.zoomFrameVisibility} />

        </div >
    )
}

function mapStateToProps(state, ownProps) {
    /*console.log("Browse -> mapStateToProps()", state)
    console.log("state: ", state)
    console.log("ownProps: ", ownProps)*/
    return {
        ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id],
        desktopMenu: state.desktopMenu,
        desktopSessionMask: state.desktopSession[ownProps.tabId][ownProps.maskId],
        hasFocus: state.browseHasFocus,
        browseRowColoredBackground: state.browseRowColoredBackground,
        zoomFrameVisibility: state.zoomFrameVisibility,
        [Constants.InternalElementState.SESSION_HEIGHT]: state[Constants.InternalElementState.SESSION_HEIGHT],
    }
}

export default connect(mapStateToProps)(Browse)
