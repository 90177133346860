export const ON_VALUE_CHANGE = "ON_VALUE_CHANGE"
export const ON_VALUES_CHANGE_ON_MASK = "ON_VALUES_CHANGE_ON_MASK"
export const ON_DESKTOP_MENU_VALUES_CHANGE = "ON_DESKTOP_MENU_VALUES_CHANGE"
export const ON_VALUES_CHANGE = "ON_VALUES_CHANGE"
export const ON_BROWSE_CELL_CHANGED = "ON_BROWSE_CELL_CHANGED"
export const ON_DATA_CHANGE = "ON_DATA_CHANGE"
export const EMPTY_BROWSE_DATA = "EMPTY_BROWSE_DATA"
export const SHOW_ABOUT_APP = "SHOW_ABOUT_APP"
export const SHOW_CONTACT = "SHOW_CONTACT"
export const SHOW_SPINNER = "SHOW_SPINNER"
export const MOVE_BROWSE_COLUMNS = "MOVE_BROWSE_COLUMNS"
export const RESIZE_BROWSE_COLUMN = "RESIZE_BROWSE_COLUMN"
export const RESIZE_BROWSE_COLUMNS = "RESIZE_BROWSE_COLUMNS"
export const CHANGE_ALL_COLS_VISIBILITY = "CHANGE_ALL_COLS_VISIBILITY"
export const SET_INTERRUPT_AVAILABILITY = "SET_INTERRUPT_AVAILABILITY"
export const CHANGE_FOCUS_AREA = "CHANGE_FOCUS_AREA"

/**
 * Změna hodnoty dle handlu komponenty a klíče pro aktualnho tabu a masky v objektu "data"
 * InputFieldElement.id=[id].data.[key] = value
 * @param {number} id - nepovinne pole, handle komponenty
 * @param {string} key - klic(nazev atributu) pro zmenu hodnoty
 * @param {*} value - hodnota pole
 */
export const onValueChangeByHandle = (id, key, value) => ({
    type: ON_VALUE_CHANGE,
    id: id,
    key: key,
    value: value
})

/**
 * Změna hodnot ktere jsou v json objektu "newValues" v objektu "data" dle zadaneho id, tabu a masky
 * InputFieldElement.id=[id].data = {value}
 * @param {number} id - handle komponenty
 * @param {number} tabId - id tabu
 * @param {number} maskId - id masky
 * @param {*} newValues - objekt s hodnotami
 */
export const onValuesChangeInMask = (id, tabId, maskId, newValues) => ({
    type: ON_VALUES_CHANGE_ON_MASK,
    id: id,
    tabId: tabId,
    maskId: maskId,
    newValues: newValues
})

/**
 * Ulozeni hodnot ktere jsou v json objektu "newValues" dle handlu komponenty pro aktualnho tabu a masky
 * @param {number} id - nepovinne pole, handle komponenty
 * @param {*} newValues
 * @returns
 */
export const onValuesChangeByHandle = (id, newValues) => ({
    type: ON_VALUES_CHANGE,
    id: id,
    newValues: newValues,
})

export const onBrowseCellChanged = (rowId, id, newValue, component) => {
    //console.log("onBrowseCellChanged", component)

    return {
        type: ON_BROWSE_CELL_CHANGED,
        rowId: rowId,
        id: id,
        newValue: newValue,
        component: component
    }
}

/**
 * Provedení změny hodnoty dle klíče v root struktuře storu
 * @param {string} key - klic pro zmenu hodnoty
 * @param {*} value - hodnota pole
 */
export const onValueChange = (key, value) => ({
    type: ON_VALUE_CHANGE,
    key: key,
    value: value
})

//pridani/akutalizace hodnot v atulni maske, lepsi vyzivat mentodu onValuesChangeInMask se zadamim tabId a maskId
export const onDataChange = (id, data) => ({
    type: ON_DATA_CHANGE,
    id: id,
    data: data,
})

export const onDesktopMenuValuesChange = (handle, values) => ({
    type: ON_DESKTOP_MENU_VALUES_CHANGE,
    handle: handle,
    values: values
})

export const emptyBrowseData = (id) => ({
    type: EMPTY_BROWSE_DATA,
    id: id,
})

export const showAboutApp = (visibility, layer) => ({
    type: SHOW_ABOUT_APP,
    visibility: visibility,
    layer: layer
})

export const showContact = (visibility, layer) => ({
    type: SHOW_CONTACT,
    visibility: visibility,
    layer: layer
})

export const showSpinner = (visibility) => ({
    type: SHOW_SPINNER,
    visibility: visibility
})

export const moveBrowseColumns = (browseHandle, initPosition, newPosition) => ({
    type: MOVE_BROWSE_COLUMNS,
    browseHandle: browseHandle,
    from: initPosition,
    to: newPosition
})

export const resizeBrowseColumn = (data) => ({
    type: RESIZE_BROWSE_COLUMN,
    ...data
})

export const resizeBrowseColumns = (data) => ({
    type: RESIZE_BROWSE_COLUMNS,
    ...data
})

export const changeAllColumnsVisibility = (browseHandle, visibility) => ({
    type: CHANGE_ALL_COLS_VISIBILITY,
    browseHandle: browseHandle,
    visibility: visibility
})

export const setInterruptAvailability = (sensitivity) => ({
    type: SET_INTERRUPT_AVAILABILITY,
    sensitivity: sensitivity
})

export const changeFocusAreaWithBrowseFocusLoss = (newFocusArea) => ({
    type: CHANGE_FOCUS_AREA,
    newFocusArea: newFocusArea,
})