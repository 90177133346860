import React, { Fragment } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'


import { logRender, logRenderProps } from '../../utils/logging'
import Constants from '../../constants/constants'
import { textWithMnemonic } from '../../utils/screen'
import Styles from '../../constants/styles'
import IconsDialog from './IconsDialog'
import { WebSocketContext } from '../../services/WebSocket'
import { findIconKey } from './IconsDialog'
import { onDesktopMenuValuesChange } from '../../store/actions/store_actions'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'
import styles from '../../constants/styles'


export default function FavoriteSettingsDialog(props) {
    const { isDialogVisible, setDialogVisibility, messageContainer, menuItem } = props

    const dispatch = useDispatch()
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context

    const [isIconsDialogVisible, setDialogIconsVisibility] = React.useState(false)
    const [itemName, setItemName] = React.useState(textWithMnemonic(menuItem.favUserDescription))
    const [itemIcon, setItemIcon] = React.useState(menuItem.favFAUnicode)

    const selectIconCallback = (icon, key) => { setItemIcon(icon.unicode) }

    addListener(LISTENER_TYPE.KEYDOWN, document, e => {
        if (e.key === 'Escape')
            setDialogVisibility(false)
    })

    if (!isDialogVisible)
        return

    logRender("FavoriteSettingsDialog")
    logRenderProps(props)
    return (<Fragment>
        {createPortal(<div id="dialog-container">
            <div
                className="modal-dialog"
                id={"favorite_settings_dialog_" + menuItem.handle}
                aria-modal="true"
                style={Styles.dialogStyle}>
                <div className="modal-content blue_box" data-bs-backdrop="static" style={{ ...Styles.dialogContent, maxWidth: '50%' }}>
                    <div className="modal-header p-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4 className="modal-title">{textWithMnemonic(menuItem.label).toUpperCase()}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e => {
                            setDialogVisibility(false)
                        }}></button>
                    </div>
                    <div className="modal-body p-3">
                        <div className="mb-3">
                            <label htmlFor={"i_name_" + menuItem.handle} className="form-label">{messageContainer[Constants.MessageContainer.NAME]}</label>
                            <input id={"i_name_" + menuItem.handle} className="form-control" placeholder={menuItem.label} value={itemName} onChange={e => {
                                setItemName(e.target.value)
                            }} />
                        </div>
                        <div className="mb-3">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label htmlFor={"i_icon_" + menuItem.handle} className="form-label">{messageContainer[Constants.MessageContainer.ICON]}</label>
                            </div>
                            <br />
                            <div style={{ display: 'flex', alignItems: 'end', gap: '.5rem' }}>
                                <button className="btn btn-outline-secondary" style={{
                                    width: '5rem',
                                    height: '5rem',
                                }} onClick={e => {
                                    setDialogIconsVisibility(true)
                                }}><span>{itemIcon === "" ? <i className={"fas fa-ellipsis-h fa-2x"} /> : <i className={"fas fa-" + findIconKey(itemIcon) + " fa-3x"} />}</span></button>
                                <button type="button" className="btn btn-outline-danger" onClick={e => { setItemIcon("") }}>
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer p-3 pb-3">
                        <button
                            type="button"
                            className="btn btn-secondary me-2"
                            style={styles.buttonStyle}
                            onClick={e => {
                                setDialogVisibility(false)
                            }}>{messageContainer["button.storno"]}</button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            style={styles.buttonStyle}
                            onClick={e => {
                                setDialogVisibility(false)

                                sendMessage(createMessage(Constants.WebSocketActions.SET_ATTRIBUTE, createRequest({
                                    navbarItemHandle: menuItem.handle,
                                    favUserDescription: itemName,
                                    favFAUnicode: itemIcon
                                })), response => {
                                    response.data.forEach(resp => {
                                        if (resp.OkResponse) {
                                            dispatch(onDesktopMenuValuesChange(menuItem.handle, {
                                                favUserDescription: itemName,
                                                favFAUnicode: itemIcon
                                            }))
                                        }
                                    })
                                })
                            }}>{messageContainer["button.ok"]}</button>
                    </div>
                </div>
            </div>
        </div>, document.body)
        }
        <IconsDialog
            isIconsDialogVisible={isIconsDialogVisible}
            setDialogIconsVisibility={setDialogIconsVisibility}
            selectIconCallback={selectIconCallback}
            messageContainer={messageContainer} />
    </Fragment >)
}
