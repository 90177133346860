import React from 'react'
import { WebSocketContext, STATUS_OPEN } from './services/WebSocket'
import { logRender, logRenderProps } from './utils/logging'
import { useStore } from 'react-redux'
import constants from './constants/constants'
import ConnectionProblem from './ConnectionProblem'
import RenderMessageDialogs from './components/dialogs/RenderMessageDialogs'

export default function RestoreConnection(props) {
    const NUMBER_OF_ATTEMPTS = 5

    //defaultni hodnoty pokud nebyl nacten messageContainer
    let msgConnectionFailed = constants.LocalMessageContainer.RESTORE_CONNECTION_FAILED
    let msgConnectionInProgress = constants.LocalMessageContainer.RESTORE_CONNECTION_IN_PROGRESS

    //hodnoty hlasek z desktop serveru
    const messageContainer = useStore().getState().app?.messageContainer
    if (messageContainer) {
        if (messageContainer[constants.MessageContainer.RESTORE_CONNECTION_FAILED])
            msgConnectionFailed = messageContainer[constants.MessageContainer.RESTORE_CONNECTION_FAILED]
        if (messageContainer[constants.MessageContainer.RESTORE_CONNECTION_IN_PROGRESS])
            msgConnectionInProgress = messageContainer[constants.MessageContainer.RESTORE_CONNECTION_IN_PROGRESS]
    }

    const ws_context = React.useContext(WebSocketContext)

    const [attempt, setAttempt] = React.useState(1)
    let reconectionFinished = attempt > NUMBER_OF_ATTEMPTS

    React.useEffect(() => {
        if (attempt < NUMBER_OF_ATTEMPTS) {
            const executerId = setTimeout(() => {
                ws_context.initWS([], status => {
                    //console.log("STATUS", status)
                    if (status === STATUS_OPEN)
                        setAttempt(NUMBER_OF_ATTEMPTS)
                    else
                        setAttempt(attempt + 1)
                }, "RestoreConnection")
                clearTimeout(executerId)
            }, 1000)
        } else if (attempt === NUMBER_OF_ATTEMPTS) {
            const executerId = setTimeout(() => {
                setAttempt(attempt + 1)
                clearTimeout(executerId)
            }, 1000)

        }

    }, [attempt])

    logRender("RestoreConnection")
    logRenderProps(props)
    
    const errorMessage = reconectionFinished ? msgConnectionFailed : msgConnectionInProgress + " " + attempt + "/" + NUMBER_OF_ATTEMPTS
    return <React.Fragment>
        <RenderMessageDialogs />
        <ConnectionProblem errorMessage={errorMessage} enableTimesIcon={reconectionFinished} enableReloadButton={reconectionFinished} />
    </React.Fragment>
}