import { getThemeVariables } from "../theme/getThemeVariables"
import Constants from '../constants/constants'
import { auto } from "@popperjs/core"

export default {
    fontAwesomeBtnStyle: {
        fontSize: '150%'
    },

    navBarClose: {
        display: 'flex',
        flexWrap: 'wrap-reverse',
        placeContent: 'flex-start space-between',
        alignItems: 'baseline'
    },

    /*dialogStyle: {
        maxWidth: '100%',
        margin: '0 auto',
        position: 'absolute',
        left: '25%',
        top: '25%',
        transform: 'translate(-50 %, -50 %)',
        display: 'flex',
        flexDirection: 'column',
        //minWidth: '40%',
        zIndex: Constants.modalDialogZIndex,
        filter: 'drop-shadow(2px 4px 6px gray)',
        background: getThemeVariables().cardBodyColor,
        "--bs-modal-header-padding-x": "1rem",
        "--bs-modal-header-padding-y": "1rem",
        "--bs-modal-header-padding": "1rem 1rem"
    },*/

    dialogStyle: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: "100%",
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "--bs-modal-header-padding-x": "1rem",
        "--bs-modal-header-padding-y": "1rem",
        "--bs-modal-header-padding": "1rem 1rem"
    },

    dialogContent: {
        maxWidth: '85%',
        minWidth: '40%',
        background: getThemeVariables().cardBodyColor,
        filter: 'drop-shadow(2px 4px 6px gray)',
        zIndex: Constants.modalDialogZIndex,
        flexDirection: 'column'
    },

    buttonStyle: {
        "--bs-btn-padding-y": ".25rem",
        "--bs-btn-padding-x": ".5rem",
        "--bs-btn-font-size": ".75rem"
    }
}