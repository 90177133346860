import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { remToPx } from '../../utils/screen'

ChartJS.register(ArcElement, Tooltip, Legend, Colors)

export default function Graph(props) {
    const { data, widgeHeadertRef, widgetTileRef } = props
    const dsDWDGraph = data?.dsDWDGraph
    const ttDWDGraphData = dsDWDGraph?.ttDWDGraphData
    const ttDWDGraphDesc = dsDWDGraph?.ttDWDGraphDesc

    //console.log("ttDWDGraphData", ttDWDGraphData)
    //console.log("ttDWDGraphDesc", ttDWDGraphDesc)
    if (ttDWDGraphData === undefined || ttDWDGraphDesc === undefined)
        return

    const ttGraphType = ttDWDGraphDesc[0]?.graphType
    const graphAnimate = ttDWDGraphDesc[0]?.graphAnimate
    switch (ttGraphType) {
        case "doughnut":
            return <ChartDoughnut ttDWDGraphDesc={ttDWDGraphDesc} ttDWDGraphData={ttDWDGraphData} widgetTileRef={widgetTileRef} widgeHeadertRef={widgeHeadertRef} graphAnimate={graphAnimate} />
    }
}

const ChartDoughnut = (props) => {
    const { ttDWDGraphDesc, ttDWDGraphData, widgetTileRef, widgeHeadertRef, graphAnimate } = props
    const labels = ttDWDGraphDesc[0]?.graphLabels?.split("\u0001")
    const ttGraphData = ttDWDGraphData[0]?.graphData?.split("\u0001")
    const tooltipText = ttDWDGraphData[0]?.tooltipText?.split("\u0001")

    let graphData = {
        labels: labels,
        datasets: [
            {
                data: ttGraphData,
                borderWidth: 1
            }
        ]
    }

    if (widgetTileRef.current === undefined || widgeHeadertRef.current === undefined)
        return

    let tileClientRect = widgetTileRef.current?.getBoundingClientRect()
    let tileHeight = tileClientRect.height
    let tileWidth = tileClientRect.width

    let headerClientRect = widgeHeadertRef.current.getBoundingClientRect()
    let graphHeight = tileHeight - headerClientRect.height - remToPx(1.25)

    //console.log("render -> doughnut", graphHeight)
    return <Doughnut
        data={graphData}
        width={tileWidth}
        height={graphHeight}
        options={{
            responsive: true,
            maintainAspectRatio: false, //- pri prohozeni do mensi dlazdice se graf neprekresli ale pokud je to zakomentovano tak se hure vykresli
            animation: {
                animateRotate: graphAnimate,
                animateScale: false
            },
            plugins: {
                legend: {
                    position: 'right'
                },
                tooltip: {
                    //enabled: false,
                    callbacks: {
                        label: (conext) => {
                            return tooltipText[conext.dataIndex]
                        }
                    }
                }
            }
        }} />
}