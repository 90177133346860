export default {
    screenWidth: 78,
    minModalDialogWidth: '60rem',
    modalDialogZIndex: 910,
    screenHeight: 23,
    frameHeight: 13,
    chunk_size: 500 * 1024,
    globalSearch: "GLOBAL_SEARCH",
    portal_dialog: "PORTAL_DIALOG",
    portal_calculator: "PORTAL_CALCULATOR",
    LocalStorageKeys: {
        MENU_EMPTY_ROWS: "menu_empty_rows",
        DARK_THEME: "dark_theme",
        CURRENT_LANGUAGE: "lang",
        DEFAULT_USERNAME: "default_username",
        LOGGING_WEB_SOCKETS: "logging_web_sockets",
        LOGGING_FOCUS: "logging_focus",
        LOGGING_DEBUG: "logging_debug",
        LOGGING_RENDER: "logging_render",
        LOGGING_PROPS: "logging_props",
        TOOLTIP: "tooltip",
        BROWSE_ROW_COLORED_BACKGROUND: "browseRowColoredBackground",
        HOMEPAGE_RECENT_OPEN: "homepage_recent_open",
        HOMEPAGE_FAVORITE: "homepage_favorite",
        HOMEPAGE_SYSTEM_INFO: "homepage_system_info",
        ZOOM_FRAME_VISIBILITY: "zoom_frame_visibility"
    },
    CloseButtons: {
        CLOSE_MASK_BTN: "CLOSE_MASK_BTN",
        CLOSE_FRAME_BTN: "CLOSE_FRAME_BTN",
        CLOSE_TAB_BTN: "CLOSE_TAB_BTN",
        CLOSE_DIALOG_BTN: "CLOSE_DIALOG_BTN",
        CLOSE_DIALOG_SYSTEM_ERROR_BTN: "CLOSE_DIALOG_SYSTEM_ERROR_BTN"
    },
    WebSocketActions: {
        SET_VALUE_REQUEST: "SET_VALUE_REQUEST",
        CONNECT: "CONNECT",
        GET_SCREEN: "GET_SCREEN",
        ACTION_GET_NAVIGATOR: "ACTION_GET_NAVIGATOR",
        ACTION_GET_FAVORITES: "ACTION_GET_FAVORITES",
        ACTION_GET_HISTORY: "ACTION_GET_HISTORY",
        EXECUTE_PROGRAM_NAVIGATOR_MAIN: "EXECUTE_PROGRAM_NAVIGATOR_MAIN",
        EXECUTE_PROGRAM_NAVIGATOR_FAVORITES: "EXECUTE_PROGRAM_NAVIGATOR_FAVORITES",
        EXECUTE_PROGRAM_NAVIGATOR_HISTORY: "EXECUTE_PROGRAM_NAVIGATOR_HISTORY",
        CLOSE_PROGRAM: "CLOSE_PROGRAM",
        CLOSE_SESSION: "CLOSE_SESSION",
        SET_ATTRIBUTE: "SET_ATTRIBUTE",
        SEARCH_QUERY: "SEARCH_QUERY",
        RUN_SEARCH_PROGRAM: "RUN_SEARCH_PROGRAM",
        ACTION_ERROR: "ACTION_ERROR",
        GET_ROW_LOCKED_MESSAGE: "GET_ROW_LOCKED_MESSAGE",
        PRESS_LOCKED_ROW_OK: 'PRESS_LOCKED_ROW_OK',
        TERMINATE: "TERMINATE",
        BREAK: "BREAK",
        INTERRUPT: "INTERRUPT",
        FORCE_CLOSE_PROGRAM: "FORCE_CLOSE_PROGRAM",
        SYSTEM_ERROR_OK: "SYSTEM_ERROR_OK",
        SET_LOGGED_USERNAME: "SET_LOGGED_USERNAME",
        PING: "PING",
        GET_WIDGET_DATALIST: "GET_WIDGET_DATALIST",
        GET_WIDGETS: "GET_WIDGETS",
        GET_WIDGET: "GET_WIDGET",
        SET_DASHBOARD: "SET_DASHBOARD",
        SET_WIDGET: "SET_WIDGET",

        //browse
        GET_BROWSE_DATA_REQUEST: "GET_BROWSE_DATA_REQUEST",
        GET_BROWSE_SCREEN_DATA_REQUEST: "GET_BROWSE_SCREEN_DATA_REQUEST",
        GET_BROWSE_DATA_AND_SELECT_REQUEST: "GET_BROWSE_DATA_AND_SELECT_REQUEST",
        SELECT_BROWSE_ROW_REQUEST: "SELECT_BROWSE_ROW_REQUEST",
        CHOOSE_BROWSE_ROW: "CHOOSE_BROWSE_ROW",
        SET_BROWSE_CELL_VALUE_REQUEST: "SET_BROWSE_CELL_VALUE_REQUEST",

        //prace se soubory
        UPLOAD_FILE_CHUNK: "UPLOAD_FILE_CHUNK",
        UPLOAD_DIR_FILE_CHUNK: "UPLOAD_DIR_FILE_CHUNK",
        DOWNLOAD_CHUNK: "DOWNLOAD_CHUNK",
        CLOSE_SESSION_OK: "CLOSE_SESSION_OK"
    },
    ERRORS: {
        ERROR_DEFAULT: "ERROR_DEFAULT",
        ERROR_LOCKED_ROW: "ERROR_LOCKED_ROW",
        ERROR_TERMINATE: "ERROR_TERMINATE",
        ERROR_WEB_SOCKET: "ERROR_WEB_SOCKET",
        ERROR_SYSTEM: "ERROR_SYSTEM"
    },
    //cz.apso.desktop.request.SetValueRequest
    SetValueRequest: {
        EVENT_ENTRY: 1, //CLICK na pole
        TAB_ACTION: 2,
        SHIFT_TAB_ACTION: 3,
        CHOOSE_ACTION: 4,
        DEFAULT_ACTION: 5,
        MENU_ACTION: 6,
        BROWSE_GO: 9,
        BRW_HOME: 11,
        BRW_END: 12,
        VALUE_CHANGED: 13,
        SORT: 14,
        FIELD_DETAIL_ACTION: 15,
        CHOOSE_ROW_NO_MOVE: 16,
        MENU_ACTION_OFFER: 21
    },
    MenuEvents: {
        RUN: 1,
        SHORT_CUT: 2,
        OPTIONS: 3,
        MENU_HELP: 4,
        ADD_FAVOURITE: 5,
    },
    MenuActions: {
        ROOT_RECORD: 1,
        NEW_RECORD: 2,
        CHANGE_RECORD: 3,
        DUPLICATE_RECORD: 4,
        DELETE_RECORD: 5,
        PRINT: 6,
        SEARCH: 7,
        SELECT_RECORD: 9,
        EDIT: 10,
        OFFER: 21,
        FUNCTIONS: 22,
        DISPLAY: 23,
        SHOW_DETAIL_RECORD: 26,        //maska
        NEXT_DETAIL_PAGE: 27,          //maska
        REFRESH_SCREEN: 28,
        TOOLS: 31,
        LOAD_MAILS: 32,
        MAILS_NOTIFICATIONS: 33,
        OPTIONS: 34,
        PROGRAM: 35,
        HELP: 38,
        ABOUT_APP: 42,
        DEFAULT_TABLE_COLUMNS_ORDER: 43,
        CLOSE_PROGRAM: 46,
        INTERRUPT: 47,
        ATTACHED_DOCUMENTS: 48,
        CHANGE_USER: 50,
        CHANGE_ACCOUNTING_UNIT: 51,
        CLOSE_TAB: 53,
        EXPORT_2_XML: 54,
        FILTER_OPTIONAL: 59,
        DEFAULT_TABLE_ROW_ACTION: 60,
        SELECT_ALL: 69,
        DESELECT_ALL: 70,
        SUM_OF_TABLE: 75,
        CLOSE_ALL_TABS: 76,
    },
    //seznam special id je definovan v gener65/desktop.i
    SpecialId: {
        FIRST_SCREEN: 1,
        FIRST_SCREEN_INPUT_FIELD_ELEMENTS: 2,
        NAVIGATOR_HOME: 3,
        NAVIGATOR_END: 4,
        NAVIGATOR_PAGE_UP: 5,
        NAVIGATOR_PAGE_DOWN: 6,
        NAVIGATOR_FORWARD: 7,
        NAVIGATOR_BACKWARD: 8,
        BROWSE_BUTTONS: 9,
        UCJE_SELECTION: 11,
        RECORD_DETAIL: 12,
        LOGIN: 13,
        USERNAME_HIDDEN_INPUT: 16,
        UCJE_HIDDEN_INPUT: 17,
        DIALOG_MESSAGE: 18,
        DIALOG_ERROR: 19,
        DIALOG_QUESTION_2: 20,
        DIALOG_QUESTION_3: 21,
        DIALOG_EDITOR: 22,
        BUTTON_MODAL_OK: 23,
        BUTTON_MODAL_YES: 24,
        BUTTON_MODAL_NO: 25,
        BUTTON_MODAL_STORNO: 26,
        CHECKBOX_LDAP: 30,
        INPUT_FILE_NAME: 33,
        INPUT_DIR_NAME: 34,
        OUTPUT_FILE_NAME: 35,
        OUTPUT_DIR_NAME: 36,
        LOADING_DIALOG: 37,
        DIALOG_FILTER: 38,
        CONTACT: 41,
        ABOUT: 42,
        VERSION: 43,
        APP_VERSION: 48,
        CHECKBOX: 52,
        DIALOG_LOCKED_ROW: 55,
        LOCKED_RECORD_BREAK: 56,
        USERNAME: 57,
        RIGHT_FRAME: 58,
        BOTTOM_BUTTONS_WITH_MARGIN: 59,
        LOADING_DIALOG_PERCENT: 60,
        LOADING_DIALOG_TIME: 61,
        LOADING_DIALOG_TEXT: 62,
        INPUT_PASSWORD: 63,
        RESTORE_PASSWORD: 64,
        ZOOM_FRAME: 65,
        HEADER_FRAME: 66,
        BROWSE_FRAME: 67
    },

    Keyboard: {
        TAB: 9,
        ENTER: 13,
        SHIFT: 16,
        CONTROL: 17,
        ALT: 18,
        ESC: 27,
        SPACE: 32,
        PAGE_UP: 33,
        PAGE_DOWN: 34,
        END: 35,
        HOME: 36,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
        E: 69,
        L: 76,
        P: 80,
        S: 83,
        ONE: 97,
        TWO: 98,
        THREE: 99,
        EIGHT: 104,
        F3: 114,
    },
    InternalElementState: {
        //slouzi pro nastaveni interniho stavu pro checkbox v browse header zaskrtnout/odskrtnout vse
        CHECKED: "checked",
        UNCHECKED: "unchecked",
        PARTLY_CHECKED: "partly_checked",
        FAVOURITE: "favourite",
        SMALL_SCREEN: "smallScreen",
        RELATED_TARGET_ID: "relatedTargetId",
        SESSION_HEIGHT: "sessionHeight",
        DASHBOARD_VISIBILITY: "dashboardVisibility"
    },
    ElementsIds: {
        A_DOWNLOAD: "a_download",
        HEADER_FRAME: "header_frame",
        ZOOM_FRAME: "zoom_frame",
        BROWSE_FRAME: "browse_frame",
        RIGHT_FRAME: "right_frame",
        SESSION_SCREEN: "session_screen"
    },
    MessageContainer: {
        FAVOURITES: 47,
        FAVORITE: "window.navigator.favourites",
        HISTORY: "window.navigator.history",
        MENU_EMPTY_ROWS: "dialog.userSettings.emptyRowsLabel",
        BROWSE_ROW_COLORED: "dialog.userSettings.colorRowInBrowse",
        LOGGING: 293,
        SETTINGS: 294,
        DETAIL: 295,
        TOOLTIP: 296,
        KEYBOARD_SHORTCUTS_TITLE: 297,
        KEYBOARD_SHORTCUTS: 298,
        UNSUPPORTED_BROWSER: 299,
        LANGUAGE: "dialog.userSettings.languageLabel",
        MODIFY: 303,
        MANAGE_COLUMNS: 304,
        NAME: 305,
        ICON: 306,
        APP_VERSION: 307,
        HOME_PAGE: 308,
        SYSTEM_INFO: 309,
        SELECT: 310,
        REMOVE: 311,
        ADD_2_FAVORITES: 312,
        REMOVE_FROM_FAVORITES: 313,
        CANNOT_CONNECT_TO_SERVER: 314,
        RESTORE_CONNECTION_FAILED: 315,
        RESTORE_CONNECTION_IN_PROGRESS: 316,
        RESTART: 317,
        HIDE_COLUMN: 318,
        ADJUST_WIDTH: 319,
        INVALID_CHARACTER_FOR_FILE_OR_FOLDER: 320,
        ADJUST_ALL_COLUMNS_WIDTH: 321,
        ADJUST_COLUMN_WIDTH: 324,
        ADJUST_COLUMN_WIDTH_WITH_HEADER: 325,
        ADJUST_ALL_COLUMNS_WIDTH_WITH_HEADER: 326,
        HIDE_ZOOM_FRAME: 327,
        SHOW_ZOOM_FRAME: 328,
        UPDATE_WIDGET: 329,
        REMOVE_WIDGET_FROM_USER_DASHBOARD: 330,
        ADD_WIDGET_TO_USER_DASHBOARD: 331,
    },
    LocalMessageContainer: {
        RESTART: "Restartovat",
        CANNOT_CONNECT_TO_SERVER: "Nepodařilo se připojit k serveru.",
        RESTORE_CONNECTION_FAILED: "Obnovení spojení se serverem se nepovedlo.",
        RESTORE_CONNECTION_IN_PROGRESS: "Probíhá obnovení spojení se serverem"
    },
    Handles: {
        ROOT_FRAME: 0,
    },
    Values: {
        ERROR_VALUE: "&Error;"
    }
}