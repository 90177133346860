import React from 'react'
import { createPortal } from 'react-dom'

import constants from '../../constants/constants'
import Styles from '../../constants/styles'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import BSTooltip from '../BSTooltip'
import { textWithMnemonic } from '../../utils/screen'
import { focusElement } from '../../focus_actions'

//spatne zobrazení dialogu
import useDialogDrag from '../../hooks/useDialogDrag'
import styles from '../../constants/styles'

export const BREAK_BUTTON = "break_button"

/*ProgressBarDialog se casto prekresluje, proto je zvlast mimo komponentu ModalDialogElement, ktera ma redux metodu connect pro automaticke prekreslovani
    - ma vlastni portal
    - nefunguje pohyb s dialogem  */
function ProgressBarDialog(props) {
    const { data, isBreakAllowed, id, tabId, maskId } = props

    const refDialog = React.useRef()
    const closeBtnRef = React.useRef()
    const refHeaderDialog = React.useRef()
    const modalRoot = document.getElementById("root")
    const el = document.createElement("div")
    el.id = constants.portal_dialog

    let ws, currentFocusId, interruptMenuItem
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
        interruptMenuItem = store.desktopMenu[constants.MenuActions.INTERRUPT]
    })

    // append to root when the children of Modal are mounted 
    React.useEffect(() => {
        modalRoot.append(el)

        return () => {
            modalRoot.removeChild(el)
        }
    }, [el])

    /*
    Nastaveni focusu - pri prvnim vykresleni
    Portal se vykresluje mimo DOM - komponenty uvnitr volaji metodu render drive nez portal*/
    React.useEffect(() => { focusElement(BREAK_BUTTON, "ProgressBarDialog.useEffect()") })

    useDialogDrag(modalRoot, { ...props, }, refDialog, refHeaderDialog, data.dialogX, data.dialogY)

    let showProgressbar = false
    props.childIds?.forEach(id => {
        if (constants.SpecialId.LOADING_DIALOG_PERCENT === props.desktopSessionMask[id].data.specialId && props.desktopSessionMask[id].data.value > 0)
            showProgressbar = true
    })

    let style = {
        ...Styles.dialogStyle,
        width: data.width * 1.4 + "ch",
        zIndex: constants.modalDialogZIndex + data.layerOrder,
    }

    const ProgressBarText = ({ id }) => {
        const [labelText, setLabelText] = React.useState()

        React.useEffect(() => { focusElement(BREAK_BUTTON, "ProgressBarDialog.ProgressBarText.useEffect()") })

        useStoreSubscription(store => {
            //console.log(tabId, maskId, id, store, store.desktopSession)
            if (store.desktopSession[tabId]
                && store.desktopSession[tabId][maskId]
                && store.desktopSession[tabId][maskId][id]
                && store.desktopSession[tabId][maskId][id].data
                && store.desktopSession[tabId][maskId][id].data.value
                && labelText !== store.desktopSession[tabId][maskId][id].data.value) {
                setLabelText(store.desktopSession[tabId][maskId][id].data.value)
            }
        })

        return <label>{labelText}</label>
    }

    const ProgressHorizontalBar = ({ id }) => {
        const [value, setValue] = React.useState()

        React.useEffect(() => { focusElement(BREAK_BUTTON, "ProgressBarDialog.ProgressHorizontalBar.useEffect()") })

        useStoreSubscription(store => {
            //console.log(tabId, maskId, id, store, store.desktopSession)
            if (store.desktopSession[tabId]
                && store.desktopSession[tabId][maskId]
                && store.desktopSession[tabId][maskId][id]
                && store.desktopSession[tabId][maskId][id].data
                && store.desktopSession[tabId][maskId][id].data.value
                && value !== store.desktopSession[tabId][maskId][id].data.value) {
                setValue(store.desktopSession[tabId][maskId][id].data.value)
            }
        })

        return <div className="pt-2">
            <div className="progress" role="progressbar" aria-valuenow={value} aria-valuemin="0" aria-valuemax="100">
                <div className="progress-bar" style={{ width: value + '%' }}>{value + '%'}</div>
            </div>
        </div>
    }

    //console.log("RENDER PROGRESS BAR DIALOG", props)
    return createPortal(<div
        key={id}
        id="modal_dialog_loading_dialog"
        ref={refDialog}
        style={style}
        aria-modal="true"
        data-bs-backdrop="false">
        <div className="modal-content blue_box" style={{ ...Styles.dialogContent, maxWidth: '70%' }}>
            <div className="modal-header p-3" ref={refHeaderDialog} style={{ /*cursor: 'move' */ }}>
                <h4 className="modal-title">{data.header}</h4>
            </div>
            <div className="modal-body p-3">
                <div className="row">
                    {(() => {
                        if (!showProgressbar)
                            return <div className="col-2 text-center  align-self-center">
                                <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                    })()}

                    <div className={showProgressbar ? "" : "col-10"}>
                        {(() => {
                            let dialogArray = []
                            let c_time, c_text, c_hbar

                            props.childIds?.forEach(id => {
                                //console.log("data", props.desktopSessionMask[id].data)

                                if (constants.SpecialId.LOADING_DIALOG_TIME === props.desktopSessionMask[id].data.specialId)
                                    c_time = <div key={"c_time"} className="row">
                                        <div className="col-2 fw-bolder">
                                            <label>{props.desktopSessionMask[id].data.label}</label>
                                        </div>
                                        <div className="col">
                                            <ProgressBarText id={id} />
                                        </div>
                                    </div>

                                if (constants.SpecialId.LOADING_DIALOG_TEXT === props.desktopSessionMask[id].data.specialId)
                                    c_text = <div key={"c_text"} className="row">
                                        <div className="col-2 fw-bolder">
                                            <label>{props.desktopSessionMask[id].data.label}</label>
                                        </div>
                                        <div className="col">
                                            <ProgressBarText id={id} />
                                        </div>
                                    </div>

                                if (showProgressbar && constants.SpecialId.LOADING_DIALOG_PERCENT === props.desktopSessionMask[id].data.specialId)
                                    c_hbar = <ProgressHorizontalBar key={"c_hbar"} id={id} />
                            })

                            dialogArray.push(c_time)
                            dialogArray.push(c_text)
                            dialogArray.push(c_hbar)
                            return dialogArray
                        })()}
                    </div>
                </div>
            </div>
            <div className="modal-footer pb-3 pe-3">
                {interruptMenuItem?.sensitivity ? <BSTooltip
                    tooltiptext={interruptMenuItem.tooltip}
                    shortcuts={[interruptMenuItem.accelerator]}>
                    <button
                        type="button"
                        ref={closeBtnRef}
                        id={BREAK_BUTTON}
                        className="btn btn-danger me-2"
                        style={styles.buttonStyle}
                    >{textWithMnemonic(interruptMenuItem?.label, false, true)}</button>
                </BSTooltip> : null}
            </div>
        </div>
    </div>, el)
}

function areEqual(prevProps, nextProps) {
    //console.log("prevProps", prevProps)
    //console.log("nextProps", nextProps)
    //console.log("ARE EQUAL", JSON.stringify(prevProps.desktopSessionMask[prevProps.id]) === JSON.stringify(nextProps.desktopSessionMask[nextProps.id]))
    //console.log("1", JSON.stringify(prevProps.desktopSessionMask[prevProps.id]))
    //console.log("2", JSON.stringify(nextProps.desktopSessionMask[nextProps.id]))
    return (JSON.stringify(prevProps.desktopSessionMask[prevProps.id]) === JSON.stringify(nextProps.desktopSessionMask[nextProps.id]))
}

export default React.memo(ProgressBarDialog, areEqual)

