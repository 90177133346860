import React from 'react'
import { connect, useStore } from 'react-redux'

//import 'react-perfect-scrollbar/dist/css/styles.css'

import MenuItem from './MenuItem'
import ContextMenu from './ContextMenu'

import { logRender, logRenderProps } from '../utils/logging'

import { wsSaveNavigatorMenuItems, wsSaveFavoritesItems, wsSaveHistoryItems, wsUpdateHistoryMenuItem } from '../store/actions/ws_actions'

import Constants from '../constants/constants'
import { addListener, LISTENER_TYPE } from '../hooks/desktopListener'
import { func } from 'prop-types'
const { KEYDOWN, CONTEXT_MENU } = LISTENER_TYPE

const { Keyboard } = Constants

export const MENU_MAIN = "main"
export const MENU_FAVORITE = "favorite"
export const MENU_HISTORY = "history"

function SideMenu(props) {
    const refContextMenu = React.useRef({})
    const store = useStore()

    let { menu, menuType, dispatch, popupMenu, popupFavourites, popupHistory } = props

    addListener(KEYDOWN, document, handleKeys)

    function handleKeys(event) {
        //console.log("SideMenu handleKeys", event)

        let mainActive = document.getElementById(MENU_MAIN + "-tab").classList?.contains("active")
        let favouriteActive = document.getElementById(MENU_FAVORITE + "-tab").classList?.contains("active")
        let historyActive = document.getElementById(MENU_HISTORY + "-tab").classList?.contains("active")

        if (menuType === MENU_MAIN && mainActive === false)
            return
        if (menuType === MENU_FAVORITE && favouriteActive === false)
            return
        if (menuType === MENU_HISTORY && historyActive === false)
            return

        if (document.getElementById("sidebar").contains(document.activeElement)) {
            let navigatorMenuItem = undefined
            for (var menuItem of Object.values(menu)) {
                //console.log(menuItem)
                if (menuItem.isUserFocused && menuItem.isAllowed) navigatorMenuItem = menuItem
            }
            switch (event.keyCode) {
                case Keyboard.UP:
                    moveInMenu(Keyboard.UP)
                    break
                case Keyboard.DOWN:
                    moveInMenu(Keyboard.DOWN)
                    break
                case Keyboard.LEFT:

                    if (event.ctrlKey)
                        return

                    if (navigatorMenuItem == undefined) return
                    if (navigatorMenuItem.isMenu) {
                        if (navigatorMenuItem.isOpen) {
                            document.getElementById("menu" + navigatorMenuItem.rowItemId).click() //zavri toto otevrene menu
                        } else {
                            if (navigatorMenuItem.parentId != 0) document.getElementById("menu" + menu[navigatorMenuItem.parentId].rowItemId).click() //zavri rodice pri kliknuti doleva
                        }
                    } else {
                        if (navigatorMenuItem.parentId != 0) document.getElementById("menu" + menu[navigatorMenuItem.parentId].rowItemId).click() //zavri rodice pri kliknuti doleva
                    }
                    break
                case Keyboard.RIGHT:
                    if (event.ctrlKey || navigatorMenuItem == undefined) return
                    if (navigatorMenuItem.isMenu) {
                        if (navigatorMenuItem.isOpen && navigatorMenuItem?.childIds.length > 0) {
                            moveInMenu(Keyboard.DOWN) //Vyber prvniho potomka
                        } else {
                            document.getElementById("menu" + navigatorMenuItem.rowItemId).click() //otevri menu
                        }
                    }
                    break
                case Keyboard.ENTER:
                    if (navigatorMenuItem)
                        document.getElementById("menu" + navigatorMenuItem.rowItemId)?.click() //otevri item / menu
                    break
                case Keyboard.HOME:
                    moveInMenu(Keyboard.HOME)
                    break
                case Keyboard.END:
                    moveInMenu(Keyboard.END)
                    break
                default:
                    break
            }
        }
    }

    /**
     * Pohni se v menu navigator up,down,home,end
     * @param {KeyCode} keycode - keycode stisknute klavesy
     */
    function moveInMenu(keyCode) {
        let menuArray = []
        let markedMenuItem
        let markedMenuItemPosition = -1
        let newMarkedMenuItem

        let index = 0
        let menuKeys = Object.keys(menu).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        for (const key in menuKeys) {
            let menuItemKey = menuKeys[key]
            if (menu.hasOwnProperty(menuItemKey)) {
                if (menuItemKey === "0" && menuType === MENU_MAIN) continue //ROOT s key 0 je pouze u main menu..

                let mItem = {
                    ...menu[menuItemKey],
                    mItemKey: menuItemKey
                }

                if (mItem.isUserFocused) {
                    markedMenuItem = mItem
                    markedMenuItemPosition = index
                }

                if (mItem.isAllowed && checkParentsActive(mItem)) {
                    menuArray.push(mItem)
                    index++
                }
            }
        }

        if (keyCode === Keyboard.END)
            newMarkedMenuItem = menuArray[menuArray.length - 1]

        if (keyCode === Keyboard.HOME)
            newMarkedMenuItem = menuArray[0]

        if (keyCode === Keyboard.DOWN) {
            if (markedMenuItemPosition === menuArray.length - 1)
                newMarkedMenuItem = menuArray[0]
            else
                newMarkedMenuItem = menuArray[markedMenuItemPosition + 1]
        }

        if (keyCode === Keyboard.UP) {
            if (markedMenuItemPosition === 0)
                newMarkedMenuItem = menuArray[menuArray.length - 1]
            else
                newMarkedMenuItem = menuArray[markedMenuItemPosition - 1]
        }

        let newMenu = {
            ...menu
        }
        if (markedMenuItem)
            newMenu = {
                ...newMenu,
                [markedMenuItem.mItemKey]: {
                    ...menu[markedMenuItem.mItemKey],
                    isUserFocused: false
                }
            }
        if (newMarkedMenuItem)
            newMenu = {
                ...newMenu,
                [newMarkedMenuItem.mItemKey]: {
                    ...menu[newMarkedMenuItem.mItemKey],
                    isUserFocused: true
                }
            }

        switch (menuType) {
            case MENU_MAIN:
                dispatch(wsSaveNavigatorMenuItems(newMenu))
                break
            case MENU_FAVORITE:
                dispatch(wsSaveFavoritesItems(newMenu))
                break
            case MENU_HISTORY:
                dispatch(wsUpdateHistoryMenuItem({
                    rowItemId: newMarkedMenuItem.rowItemId,
                    isUserFocused: true
                }))
                break
            default: {
                console.error("Neznamy typ menu.")
                return
            }
        }
    }

    /**
     * Pohni se v menu navigator nahoru nebo dolu - oznac pomoci props
     * @param {KeyCode} moveDown - keycode stisknute klavesy - sipka nahoru/dolu
     */
    /*function moveInMenuVertical(keyCode) {
        let moveDown = keyCode === Keyboard.DOWN ? true : false
        let markFocused = ""
        let markUnfocused = undefined
        let firstAllowedItem = "" //prvni polozka, na kterou skoc, kdyz uzivatel jeste nema zadnou vybranou
        let sortedDesktopNavigatorMenu = Object.keys(menu).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))

        if (!moveDown) sortedDesktopNavigatorMenu.reverse()

        for (var menuItemKey of sortedDesktopNavigatorMenu) {
            if (menuItemKey === "0" && menuType === MENU_MAIN) continue //ROOT s key 0 je pouze u main menu..

            if (menu[menuItemKey].isAllowed && checkParentsActive(menu[menuItemKey])) {
                if (firstAllowedItem == "") firstAllowedItem = menuItemKey
                if (markUnfocused !== undefined) {
                    markFocused = menuItemKey
                    break
                }
            }

            if (menu[menuItemKey].isUserFocused) {
                markUnfocused = menuItemKey
                continue
            }
        }

        if (markFocused === "") markFocused = firstAllowedItem

        let saveMenuType = ""
        switch (menuType) {
            case MENU_MAIN:
                saveMenuType = wsSaveNavigatorMenuItems
                break
            case MENU_FAVORITE:
                saveMenuType = wsSaveFavoritesItems
                break
            case MENU_HISTORY:
                saveMenuType = wsSaveHistoryItems
                break
            default: {
                console.error("Neznamy typ menu.")
                return
            }
        }

        let updMenu = {
            [markFocused]: {
                ...menu[markFocused],
                isUserFocused: true
            }
        }

        if (markUnfocused !== undefined) {
            updMenu = {
                ...updMenu,
                [markUnfocused]: {
                    ...menu[markUnfocused],
                    isUserFocused: false,
                }
            }
        }

        dispatch(saveMenuType({
            ...menu,
            ...updMenu
        }))

    }*/

    /**
     * Kontrola, zda jsou rodiče až do rootu otevřený (prop: isOpen)
     * @param {MenuItem} menuItem
     * @returns true pokud jsou rodiče až do rootu otevřený (prop: isOpen)
     */
    function checkParentsActive(menuItem) {
        if (menuType == MENU_FAVORITE || menuType == MENU_HISTORY) return true //nemaji stromovou strukturu, jen plochou
        if (menuItem.rowId === 0) return true //final rodic
        if (menu[menuItem.parentId].rowId != 0) {
            if (menu[menuItem.parentId].isOpen === false) return false

            return checkParentsActive(menu[menuItem.parentId])
        } else {
            return true
        }
    }

    function displayContextMenu(e, type, menuItemProps, menuType) {
        refContextMenu.current.displayContextMenu(e, type, menuItemProps)

        markMenuRow(menuType, menuItemProps)
    }

    function markMenuRow(menuType, menuItemProps) {
        let menuKeys
        let newMenu = {}
        if (menuType === MENU_MAIN) {
            menuKeys = Object.keys(store.getState().desktopNavigatorMenu)
            menuKeys.forEach(key => {
                const menuItem = store.getState().desktopNavigatorMenu[key]
                newMenu[key] = {
                    ...menuItem,
                    isUserFocused: (menuItemProps.rowItemId === menuItem.rowItemId)
                }
            })
        } else if (menuType === MENU_FAVORITE) {
            menuKeys = Object.keys(store.getState().desktopFavoritesMenu)
            menuKeys.forEach(key => {
                const menuItem = store.getState().desktopFavoritesMenu[key]
                newMenu[key] = {
                    ...menuItem,
                    isUserFocused: (menuItemProps.rowItemId === menuItem.rowItemId)
                }
            })
        }

        switch (menuType) {
            case MENU_MAIN:
                dispatch(wsSaveNavigatorMenuItems(newMenu))
                break
            case MENU_FAVORITE:
                dispatch(wsSaveFavoritesItems(newMenu))
                break
            case MENU_HISTORY:
                dispatch(wsUpdateHistoryMenuItem({
                    rowItemId: menuItemProps.rowItemId,
                    isUserFocused: true
                }))
                break
            default: {
                console.error("Neznamy typ menu.")
                return
            }
        }
    }

    logRender("SideMenu " + menuType)
    logRenderProps(props)
    //console.log("MENU", props)

    if (menuType == MENU_MAIN) {
        return (
            <>
                <ul className="sidebar-nav">
                    {<MenuItem id={0} menuType={menuType} displayContextMenu={displayContextMenu} />}
                </ul>
                <ContextMenu ctxRef={refContextMenu} type={menuType} popupMenu={popupMenu} popupFavourites={popupFavourites} />
            </>
        )
    } else if (menuType == MENU_FAVORITE || menuType == MENU_HISTORY) {
        if (menu == undefined) return ""
        let items = []
        for (var key of Object.keys(menu)) {
            items.push(<MenuItem key={key} id={key} menuType={menuType} displayContextMenu={displayContextMenu} />)
        }

        return (
            <>
                <ul className="sidebar-nav">
                    {items}
                </ul>
                {<ContextMenu ctxRef={refContextMenu} type={menuType} popupMenu={popupMenu} popupFavourites={popupFavourites} popupHistory={popupHistory} />}
            </>)
    } else {
        return ""
    }
}

function mapStateToProps(state, ownProps) {
    switch (ownProps.menuType) {
        case MENU_MAIN: return {
            menu: state.desktopNavigatorMenu,
            popupMenu: state.popupNavigator
        }
        case MENU_FAVORITE: return {
            menu: state.desktopFavoritesMenu,
            popupFavourites: state.popupFavourites
        }
        case MENU_HISTORY: return {
            menu: state.desktopHistoryMenu,
            popupHistory: state.popupHistory
        }
        default: return
    }
}

/*function areEqual(prevProps, nextProps) {
    return (JSON.stringify(prevProps.desktopNavigatorMenu) === JSON.stringify(nextProps.desktopNavigatorMenu))
}*/

export default connect(mapStateToProps)(SideMenu)/*(React.memo(SideMenu, areEqual))*/
