import React from 'react'
import { connect } from 'react-redux'

import { doTabAction, getEventElement } from '../../app_actions'
import { WebSocketContext } from '../../services/WebSocket'
import Constants from '../../constants/constants'
import { logRender, logRenderProps, logDebug } from '../../utils/logging'
import { onValueChangeByHandle, onBrowseCellChanged, onValuesChangeInMask, onValueChange, onValuesChangeByHandle } from '../../store/actions/store_actions'

import ElementWithListener from '../ElementWithListener'
import { getStore } from '../../index.js'
import { getElementData, saveValue2Store, parentSensitivity } from './elementMethods'
import { toProgressDateFormat, toProgressDateTimeFormat, toInputDateTimeFormat, toInputDateFormat } from './inputFielsElementMethods.js'

import useFocusObserver from '../../hooks/useFocusObserver'
import { addListenerWithRefHandler, LISTENER_TYPE } from '../../hooks/desktopListener'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import { focusElement } from '../../focus_actions'
import BSTooltip from '../BSTooltip'
import { textWithMnemonic } from '../../utils/screen'
import { getEmptyRowProp } from '../../utils/actions'
import DataList from './DataList'
import styles from '../../constants/styles'
import { Badge } from '../browse/BrowseRow.jsx'

const { KEYDOWN, CLICK } = LISTENER_TYPE
const { SET_VALUE_REQUEST, SET_BROWSE_CELL_VALUE_REQUEST } = Constants.WebSocketActions
const { EVENT_ENTRY, FIELD_DETAIL_ACTION, MENU_ACTION, MENU_ACTION_OFFER, } = Constants.SetValueRequest
const { INPUT_FILE_NAME, INPUT_DIR_NAME, OUTPUT_FILE_NAME, OUTPUT_DIR_NAME, USERNAME } = Constants.SpecialId
const { Keyboard, chunk_size, LocalStorageKeys } = Constants

import Calculator from '../calculator/Calculator.js'

/*const StyledInput = styled.input`
padding-right: 2p;
padding-left: 2px;
border: 2px solid var(--bs-red);
text-align:${({ dataType }) => (dataType == "INTEGER" || dataType == "DECIMAL") ? "right" : ""};
`*/

export function handleBrowseInputFieldElement(event, ws_context, callback) {
    const { createMessage, createRequest, sendMessage } = ws_context

    let store = getStore().getState()
    let ws = store.ws

    let storeElement = store.desktopSession[ws.currentTabId][ws.currentMaskID][ws.focusId]
    let browseHandle = storeElement.data.parentHandle
    let browseElement = store.desktopSession[ws.currentTabId][ws.currentMaskID][browseHandle]
    let request


    //console.log("handleBrowseInputFieldElement", event)
    if (event.type === KEYDOWN) {
        //"TAB" / "SHIFT + TAB" -> pohybovani doprava, doleva
        //console.log("DATA", getElementData(ws.focusId).value)
        //console.log("KEY", event.key)
        if (event.key === "Tab") {
            let shift = event.shiftKey ? true : false
            doTabAction(ws_context, event, shift)
            let elData = getElementData(ws.focusId)
            getStore().dispatch(onBrowseCellChanged(browseElement.data.rowId, ws.focusId, elData.value, elData.storeElement))
        } else if (event.key === "Escape") {
            request = {
                handle: ws.focusId,
                keyEvent: event.key,
                value: getElementData(ws.focusId).value,
            }
        } else if (event.key === 'Enter' || event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === "PageDown" || event.key === "PageUp") {
            request = {
                handle: ws.focusId,
                value: getElementData(ws.focusId).value,
                keyEvent: event.key
            }
        } else
            logDebug("neosetrena klavesa", event.key, event.type)

    } else if (event.type === CLICK) {
        if (event.which === 1) {
            request = {
                handle: ws.focusId,
                value: getElementData(ws.focusId).value,
                keyEvent: event.type
            }
        }
    }

    /*
    Escape
        1) SetValueRequest.BRW_END_ERROR
    Enter
        1) SetValueRequest.BRW_ROW_LEAVE
        2) SetValueRequest.BRW_GO
    ArrowDown, ArrowUp, PageDown, PageUp -> Browse.pressUp(), pressDown(), pressPageDown(), pressPageUp()
        1) SetValueRequest.BRW_ROW_LEAVE
        2) SelectBrowseRowRequest
        3) SetValueRequest.BRW_ROW_ENTRY
        4) SetValueRequest.EVENT_ENTRY
    click -> vola se z BrowseRow.doEntry()
        1) SetValueRequest.BRW_ROW_LEAVE
        2) SelectBrowseRowRequest
        3) SetValueRequest.BRW_ROW_ENTRY
        4) SetValueRequest.EVENT_ENTRY
    Tab
        1) SetValueRequest (TAB_ACTION(2) || SHIFT_TAB_ACTION(3))
    */
    //console.log("REQUEST>>>>", request)
    if (request !== undefined) {
        let elData = getElementData(ws.focusId)
        getStore().dispatch(onBrowseCellChanged(browseElement.data.rowId, ws.focusId, elData.value, elData.storeElement))
        sendMessage(createMessage(SET_BROWSE_CELL_VALUE_REQUEST, createRequest(request)), response => {
            //console.log("response", response)
            //kontrola jestli nastala chyba
            if (response.screenVariables.focusId === request.handle) {
                if (callback !== undefined)
                    callback()
            }
        })
    }
}

/*
Popis
------
pri zmene se hodnota pole uklada do statu, do storu hodnotu uklada az dalsi akce
jako je tab, onclick(ok button), ... pomoci metody elementMethods.getElementData()*/

//TODO - nelze vlozi 0 na zacatek ->formatBeforFloatingPoint(), pomoci parse int je cislo smazano
function InputFieldElement(props) {
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context

    const refInput = React.useRef(null)
    const fileInputRef = React.useRef(null)

    const { data, leftIcon, dispatch, focusId } = props
    const { dataType, value } = data
    const dataFormat = data.dataFormat

    const [pastedData, setPastedData] = React.useState("")
    let { dataFormatMask, longDataFormat } = createDataFormatMask(data)

    //vyuziva se pro klavesu backspace u character pole aby bylo mozne nastavit pozici
    const [pressedKey, setPressedKey] = React.useState()
    const [inputValue, setInputValue] = React.useState(value)
    const [initVal, setInitVal] = React.useState(value)
    const [selectionStart, setSelectionStart] = React.useState("")
    const [selectionEnd, setSelectionEnd] = React.useState("")
    const [recalculateCursorPosition, setRecalculateCursorPosition] = React.useState(true) //pomocna promena, ktera spusti trigger na prepocet pozice kurzoru pomoci zmeny stavu true/false (nema nic spolecneho s true a false, pouze je to pomocna promenna, co se meni)

    const [cursorPosition, setCursorPosition] = React.useState(0)
    const [selectionInternal, setSelectionInternal] = React.useState({ start: 0, end: 0 })

    const tooltipId = "t_" + props.id
    const tooltipIdOffer = "o_" + props.id

    const [calculatorVisibility, setCalculatorVisibility] = React.useState(false);
    const [inputCalcValue, setInputCalcValue] = React.useState("");
    const [initKey, setInitKey] = React.useState("");


    //nastaveni hodnoty z reduceru
    React.useEffect(() => {
        //console.log("USE EFFETCT", data)
        if (data.dataType === "DATETIME" || data.dataType === "DATETIME-TZ")
            setInputValue(toInputDateTimeFormat(value).date4Input)
        else {
            if (data.dataType === "DECIMAL" && value != undefined && value.charAt(0) === "-") { //odstraneni mezer u decimal mezi znaminkem minus a hodnotou - urceno formatem ze serveru
                let tmpValue = value.substring(1).trimStart()
                tmpValue = "-" + tmpValue
                setInputValue(tmpValue)
            } else {
                setInputValue(value)
            }
        }
    }, [value])

    /*aktivace focusu pro browse pokud inputfield je soucasti tabulky (editovatelny browse) */
    React.useEffect(() => {
        const controller = new AbortController()

        function focusin(e) {
            //console.log("in", e)
            if (data.browseId !== undefined)
                dispatch(onValueChange("browseHasFocus", true))
        }

        if (refInput.current && refInput.current !== null)
            refInput.current.addEventListener("focusin", focusin, { signal: controller.signal })

        return () => {
            controller.abort()
        }
    })


    useFocusObserver(props.id, "InputFieldElement")
    addListenerWithRefHandler(LISTENER_TYPE.KEYDOWN, refInput, e => {
        if (dataType === "CHARACTER")
            setPressedKey({ key: e.key, ctrlKey: e.ctrlKey })
    })

    let currentFocusId, currentMaskID, currentTabId, yearOffset, offerTooltip, offerShortcut, messageContainer, currentFocusedInputFieldElementWidth
    useStoreSubscription(store => {
        currentFocusId = store.ws.focusId
        currentMaskID = store.ws.currentMaskID
        currentTabId = store.ws.currentTabId
        yearOffset = store.app.yearOffset
        offerTooltip = textWithMnemonic(store.desktopMenu[Constants.MenuActions.OFFER]?.tooltip, false)
        offerShortcut = store.desktopMenu[Constants.MenuActions.OFFER]?.accelerator
        messageContainer = store.app.messageContainer
        currentFocusedInputFieldElementWidth = store.currentFocusedInputFieldElementWidth
    })

    React.useEffect(() => {
        //console.log("USE EFFECT -> TestInputFieldElement", cursorPosition, refInput.current.value)
        if (refInput.current !== null && dataType !== "DATE" && dataType !== "DATETIME" && dataType !== "DATETIME-TZ" && dataType !== "LOGICAL")
            refInput.current.setSelectionRange(cursorPosition, cursorPosition)
    }, [inputValue, cursorPosition, recalculateCursorPosition])

    React.useEffect(() => {
        if (selectionInternal.start == selectionInternal.end) return

        if (refInput.current !== null && dataType !== "DATE" && dataType !== "DATETIME" && dataType !== "DATETIME-TZ" && dataType !== "LOGICAL") {
            refInput.current.setSelectionRange(selectionInternal.start, selectionInternal.end)
        }
    }, [selectionInternal])

    React.useEffect(() => {//select textu pri prvnim renderu nove vznikle obrazovky -> dle aktualniho focusu
        if (refInput.current !== null && dataType !== "DATE" && dataType !== "DATETIME" && dataType !== "DATETIME-TZ" && dataType !== "LOGICAL" && currentFocusId === data.handle) {
            refInput.current.select()
            //console.log("@DEBUG SELECT ref input")
        }
    }, [])

    //nastaveni currentFocusedInputFieldElementWidth pro footer na zobrazeni zbyvajicich znaku
    React.useEffect(() => {
        //console.log(currentFocusedInputFieldElementWidth, "!==", inputValue.length, currentFocusedInputFieldElementWidth !== inputValue.length)
        if (props.id === currentFocusId && currentFocusedInputFieldElementWidth?.width !== inputValue.length) {
            dispatch(onValueChange("currentFocusedInputFieldElementWidth", { handle: props.id, width: inputValue.length }))
        }
    })

    /*React.useEffect(() => {
        if (currentFocusId === props.id && props.data.allowQuickHintSearch) {
            console.log("handleHintSearch", inputValue)
            sendMessage(createMessage(Constants.WebSocketActions.GET_WIDGET_DATALIST, createRequest({
                value: inputValue,
                handle: props.id
            })), response => {
                console.log("RESPONSE", response)
            })
        }
    }, [inputValue])*/

    /* --------------------- FUCNTIONS ---------------------*/

    function createDataFormatMask(data) {
        let dataFormat = data.dataFormat
        let dataType = data.dataType

        //rozepsani formatu x(4)/9(3) na xxxx/999...
        if (dataType == "CHARACTER") {
            let foundShortVersion = false
            let repeatTimes = ""
            let longFormat = ""

            for (let i = 0; i < dataFormat.length; i++) {
                if ("xna!9".indexOf(dataFormat.charAt(i).toLocaleLowerCase()) > -1 && dataFormat.charAt(i + 1) === "(") {
                    foundShortVersion = true
                    repeatTimes = dataFormat.substring(i + 2).match(/\d*/g)[0]
                    for (let j = 0; j < repeatTimes; j++) {
                        longFormat += dataFormat.charAt(i).toLocaleLowerCase()
                    }
                } else {
                    if (!foundShortVersion) longFormat += dataFormat.charAt(i).toLocaleLowerCase()
                    if (dataFormat.charAt(i) === ")") foundShortVersion = false
                }
            }

            let final = (longFormat == "") ? dataFormat.replaceAll(" ", "⎵").replaceAll(/[xna!9]/gi, " ") : longFormat.replaceAll(" ", "⎵").replaceAll(/[xna!9]/gi, " ") //⎵ = mezera jako oddelovac
            return { dataFormatMask: final, longDataFormat: (longFormat == "") ? dataFormat : longFormat }
        }
        return { dataFormatMask: "", longDataFormat: dataFormat } //masku vyuzivame nyni jen u character
    }


    function formatText(newText, checkFromPosition, modDataFormatMask, newSelectionStart, moveCursorForward, prevSelectionStart, backspace) {
        let formattedText = newText.substring(0, checkFromPosition)
        let newTextCharPosition = checkFromPosition

        //zrychleni pri formatu x(<cislo>) - napr x(30)
        if (new RegExp(/^x\(\d+\)$/gi).test(dataFormat)) {
            let numberOfChars = parseInt(dataFormat.match(/\d+/))
            for (let i = checkFromPosition; i < numberOfChars; i++) {
                formattedText += newText.charAt(i)
                if (newText.charAt(i) === "") break
            }
            setInputValue(formattedText)
            setCursorPosition(newSelectionStart)
            return true
        }

        for (let i = checkFromPosition; i < longDataFormat.length; i++) {
            let dataFormatCharacter = longDataFormat.charAt(i)
            let charToAdd = ""

            while (newText.charAt(newTextCharPosition) != " " && newText.charAt(newTextCharPosition) === modDataFormatMask.charAt(newTextCharPosition) && newTextCharPosition < modDataFormatMask.length) {
                newTextCharPosition++
            }

            charToAdd = newText.charAt(newTextCharPosition) === "" ? " " : newText.charAt(newTextCharPosition)

            if (dataFormatCharacter === "x") {
                formattedText += charToAdd

            } else if (dataFormatCharacter.toLocaleLowerCase() === "n") {
                if (!new RegExp(/[\s\wěéřťýůúíóášďčňž]/gi).test(charToAdd)) {
                    return false
                }
                formattedText += charToAdd
            } else if (dataFormatCharacter.toLocaleLowerCase() === "a") {
                if (!new RegExp(/[\sa-zěéřťýůúíóášďčňž]/gi).test(charToAdd)) {
                    return false
                }
                formattedText += charToAdd
            } else if (dataFormatCharacter === "!") {
                if (!new RegExp(/[\sa-zěéřťýůúíóášďčňž]/gi).test(charToAdd)) {
                    return false
                }
                formattedText += charToAdd.toUpperCase()
            } else if (dataFormatCharacter === "9") {
                if (!new RegExp(/[\d\s]/gi).test(charToAdd)) {
                    return false
                }
                formattedText += charToAdd
            } else if (dataFormatCharacter === "⎵") {//mezera jako oddelovac
                formattedText += " "
            } else {
                formattedText += dataFormatCharacter
                newTextCharPosition--
            }

            newTextCharPosition++
            if (dataFormatMask.trim() == 0 && i >= (newText.length - 1)) {
                break
            }
        }

        setInputValue(formattedText)

        if (moveCursorForward) {
            for (let i = newSelectionStart; i < modDataFormatMask.length; i++) {
                if (modDataFormatMask.charAt(i) === " ") break
            }
        } else if (backspace) {
            for (let i = 0; i < newSelectionStart; i++) {
                if (modDataFormatMask.charAt(newSelectionStart - i) === " ") break
            }
        }
        return true
    }

    /*Formatovani celych cisel */
    function formatNumbersAfterFloatingPoint(newText) {
        let valuesWithoutFormat = newText.replaceAll(",", "")
        let formattedText = ""
        let valuesWithoutFormatIndex = valuesWithoutFormat.length - 1

        if (longDataFormat.indexOf(".") == -1 && newText.length > longDataFormat.length && newText.charAt(0) != "0") return null //pro integer

        if (longDataFormat.indexOf(".") != -1 && newText.length > longDataFormat.indexOf(".") && newText.charAt(0) != "0") { //pro decimal
            return null
        }

        if (newText.length == longDataFormat.indexOf(".") && longDataFormat.charAt(0) == "-" && newText.charAt(0) != "-") {
            return null
        }

        let checkTo = longDataFormat.indexOf(".") == -1 ? (longDataFormat.length - 1) : (longDataFormat.indexOf(".") - 1) //prvni cast pro integer, druha pro decimal
        for (let i = checkTo; i >= 0; i--) {
            let dataFormatCharacter = longDataFormat.charAt(i)
            let charToAdd = valuesWithoutFormat.charAt(valuesWithoutFormatIndex)
            if ("9".indexOf(dataFormatCharacter.toLocaleLowerCase()) > -1) {
                if (new RegExp(/[0-9]/g).test(charToAdd)) {
                    formattedText = charToAdd + formattedText
                } else if (charToAdd === "") {
                    formattedText = 0 + formattedText
                } else {
                    return null
                }
                valuesWithoutFormatIndex -= 1
            } else if ("z>".indexOf(dataFormatCharacter.toLocaleLowerCase()) > -1) {
                if (new RegExp(/[0-9\-]/g).test(charToAdd)) {
                    formattedText = charToAdd + formattedText
                } else if (charToAdd === "") {
                    formattedText = 0 + formattedText
                } else {
                    return null
                }
                valuesWithoutFormatIndex -= 1
            } else if (dataFormatCharacter.toLocaleLowerCase() === "-") {
                //if (newText.charAt(0) === "-") formattedText = dataFormatCharacter + formattedText //pokud tam jeste symbol "-" neni
                //valuesWithoutFormatIndex -= 1
            } else {
                formattedText = dataFormatCharacter + formattedText
            }
        }

        let textIndex = formattedText.charAt(0) === "-" ? 1 : 0
        let startScan = longDataFormat.indexOf(".") === -1 ? 0 : longDataFormat.indexOf(".") - formattedText.length //integer / decimal
        for (let i = startScan; i <= formattedText.length; i++) {
            if (i == startScan && (longDataFormat.charAt(i) == "-")) {
                continue
            }
            if ((formattedText.charAt(textIndex) == "0" || formattedText.charAt(textIndex) == "," || formattedText.charAt(textIndex) === "-") && ">z,".indexOf(longDataFormat.charAt(i).toLocaleLowerCase()) > -1) {
                textIndex++
                continue
            } else {
                formattedText = formattedText.slice(textIndex)
                break
            }

        }

        if (longDataFormat.charAt(0) == "-" && newText.charAt(0) == "-" && formattedText.charAt(0) !== "-"/*&& longDataFormat.slice(1, longDataFormat.indexOf(".")).length == formattedText.length*/) formattedText = "-" + formattedText
        return formattedText
    }

    /* formatovani desetinnych cisel */
    function formatNumbersBeforeFloatingPoint(newText) {
        let valuesWithoutFormat = newText.replaceAll(",", "")
        let formattedText = ""
        let valuesWithoutFormatIndex = 0
        let dataFormatBeforeFloatingPoint = longDataFormat.slice(longDataFormat.indexOf(".") + 1)

        for (let i = 0; i < dataFormatBeforeFloatingPoint.length; i++) {
            let dataFormatCharacter = dataFormatBeforeFloatingPoint.charAt(i)
            let charToAdd = valuesWithoutFormat.charAt(valuesWithoutFormatIndex)
            if (dataFormatCharacter.toLocaleLowerCase() === "9") {
                if (new RegExp(/[0-9]/g).test(charToAdd)) {
                    formattedText += charToAdd
                } else if (charToAdd === "") {
                    formattedText += 0
                } else {
                    return null
                }
                valuesWithoutFormatIndex++
            } else if (dataFormatCharacter.toLocaleLowerCase() === "<") {
                if (new RegExp(/[0-9\-]/g).test(charToAdd)) {
                    formattedText += charToAdd
                } else if (charToAdd === "") {
                    formattedText += 0
                } else {
                    return null
                }
                valuesWithoutFormatIndex++
            } else {
                formattedText = dataFormatCharacter + formattedText
            }
        }

        let removeZerosToPos = dataFormatBeforeFloatingPoint.indexOf("<")
        if (removeZerosToPos > -1) {
            for (let i = (formattedText.length - 1); i >= removeZerosToPos; i--) {
                if (formattedText.charAt(i) == "0") {
                    if (i == removeZerosToPos) {
                        formattedText = formattedText.slice(0, i)
                        break
                    }
                    continue
                } else {
                    formattedText = formattedText.slice(0, i + 1)
                    break
                }
            }
        }

        return formattedText
    }

    function renderLeftIcon() {
        if (leftIcon == undefined)
            return

        return <span className="input-group-text input-group-text-sm" id="basic-addon2">
            <span>
                <i className={leftIcon} />
            </span>
        </span>
    }

    function doEntry(newFocus, callback) {
        let elData2Save = getElementData(currentFocusId)
        saveValue2Store(currentFocusId, elData2Save)
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: currentFocusId,
            newFocus: newFocus,
            value: elData2Save.value,
            action: EVENT_ENTRY
        })), callback)
    }

    function showOfferTriangle() {
        if ((data.hasValuesOffer && data.sensitivity) || (data.hasValuesOffer && data.readOnly === false))
            return <BSTooltip id={tooltipIdOffer} tooltiptext={offerTooltip} shortcuts={[offerShortcut]}>
                <ElementWithListener
                    tooltipIdOffer={tooltipIdOffer}
                    doAction={() => {
                        if (props.id !== currentFocusId) {
                            doEntry(props.id, (response) => {
                                sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                                    ...getEmptyRowProp(),
                                    handle: props.id,
                                    newFocus: MENU_ACTION_OFFER,
                                    value: getElementData(props.id).value,
                                    action: MENU_ACTION
                                })))
                            })
                        } else
                            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                                ...getEmptyRowProp(),
                                handle: props.id,
                                newFocus: MENU_ACTION_OFFER,
                                value: getElementData(props.id).value,
                                action: MENU_ACTION
                            })))
                    }}
                    outerHtmlClassName="red-triangle"
                    mouseListener={true} />
            </BSTooltip>
    }

    function showDetailTriangle() {
        if (data.fieldDetailAction)
            return <BSTooltip id={tooltipIdOffer} tooltiptext={messageContainer[Constants.MessageContainer.DETAIL]}>
                <ElementWithListener
                    id={"detail_triangle_" + props.id}
                    style={{ zIndex: 1 }}
                    doAction={() => {
                        //console.log("DO ACTION", FIELD_DETAIL_ACTION)
                        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                            ...getEmptyRowProp(),
                            handle: currentFocusId,
                            newFocus: props.id,
                            action: FIELD_DETAIL_ACTION
                        })))
                    }}
                    outerHtmlClassName="green-triangle"
                    mouseListener={true} />
            </BSTooltip>
    }

    /**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
    function humanFileSize(bytes, si = false, dp = 1) {
        if (bytes === 0)
            return "0 kB"

        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    function readFileAsBase64Array(file, callback) {
        let base64File = []
        let fileSize = file.size
        let offset = 0

        function chunkReaderBlock(_offset, length, _file) {
            let r = new FileReader()
            let blob = _file.slice(_offset, length + _offset)
            r.onload = (evt) => {
                if (evt.target.error == null) {
                    offset += evt.target.result.length
                    base64File.push(btoa(evt.target.result))
                } else {
                    console.error("Read error: " + evt.target.error)
                    return
                }

                if (offset >= fileSize) {
                    //console.log("Done reading file", base64File)
                    callback(base64File)
                    return
                }

                // of to the next chunk
                chunkReaderBlock(offset, chunk_size, file)
            }
            r.readAsBinaryString(blob)
        }

        // now let's start the read with the first block
        chunkReaderBlock(offset, chunk_size, file)
    }

    function renderFileInputField() {
        //console.log("RENDER INPUT FIELD ELEMENT FILE", inputValue, data)
        let fileSizeVal = (data.fileSize !== undefined ? data.fileSize : 0)
        return (<form id="file-upload" autoComplete="off" onSubmit={e => {
            //aby se nevolal refresh stranky
            e.preventDefault()
        }}>
            <div className="input-group input-group-sm">
                <input id={props.id} type="text" readOnly ref={refInput} className={"form-control form-control-sm " + (props.data.invalid ? "is-invalid" : "")} value={inputValue} />
                <span className="input-group-text">{humanFileSize(fileSizeVal, true)}</span>
                <ElementWithListener tabindex={0} mouseListener={true} keyListener={true} doAction={(e) => {
                    fileInputRef.current.click()
                }} >
                    <button className="btn btn-primary" style={styles.buttonStyle} type="button">...</button>
                </ElementWithListener>
                <input ref={fileInputRef} id={data.handle} type="file"
                    style={{
                        height: '0',
                        width: '0',
                        visibility: 'hidden',
                        overflow: 'hidden',
                        position: 'absolute'
                    }}
                    onChange={e => {
                        doEntry(props.id, response => {
                            if (fileInputRef.current.files.length > 0) {
                                //ULOZENI HODNOTY
                                //refInput.current.focus()
                                focusElement(props.id, "InputFieldElement.renderFileInputField().onChange()")

                                let selectedFile = fileInputRef.current.files[0]
                                let fName = selectedFile.name
                                let fSize = selectedFile.size
                                //console.log("ulozeni hodnoty", fName, fSize)
                                readFileAsBase64Array(fileInputRef.current.files[0], base64FileArray => {
                                    dispatch(onValuesChangeInMask(props.id, props.tabId, props.maskId, {
                                        value: fName,
                                        fileSize: fSize,
                                        base64: base64FileArray
                                    }))
                                })
                            }
                        })
                    }} />
                <div className="invalid-feedback">
                    {props.data.invalidMessage}
                </div>
            </div>
        </form >)
    }

    function renderFolderInputField() {
        function readFiles(files, callback) {
            let filesArray = []
            let processed = 0
            let iteration = 0

            for (var key of Object.keys(files)) {
                iteration++

                let file = files[key]
                //console.log("file", file)
                readFileAsBase64Array(file, (base64FileArray) => {
                    /*if (processed === 0)
                        console.log("FILE", file)*/

                    filesArray.push({
                        webkitRelativePath: file.webkitRelativePath,
                        fileSize: file.size,
                        fileName: file.name,
                        base64: base64FileArray
                    })

                    processed++
                    if (processed === Object.keys(files).length)
                        callback(filesArray)
                })
            }
        }

        return <div className="input-group input-group-sm">
            <input id={props.id} type="text" readOnly ref={refInput} className={"form-control form-control-sm " + (props.data.invalid ? "is-invalid" : "")} value={inputValue} />
            <input
                ref={fileInputRef}
                type="file"
                id="ctrl"
                webkitdirectory="true"
                directory="true"
                multiple
                //onClick={e=>{ pri pouziti metody doEntry() se nezobrazi dialog pro vyber, proto se pousti az v onChange()}}
                onChange={e => {
                    doEntry(props.id)

                    //console.log("fileInputRef.current.files", fileInputRef.current.files)
                    /*console.log("entries", fileInputRef.current)
                    console.log("web", e.target.webkitEntries)
                    console.log("web", fileInputRef.current.webkitEntries)
                    console.log("web", fileInputRef.current.target.webkitEntries)*/
                    /*e.target.webkitEntries.forEach((entry) => {
                        console.log("ENTRY", entry)
                    })*/
                    if (fileInputRef.current.files.length > 0) {
                        //ULOZENI HODNOTY
                        readFiles(fileInputRef.current.files, filesArray => {
                            let folderName = ""
                            if (fileInputRef.current.files[0] !== undefined) {
                                folderName = fileInputRef.current.files[0].webkitRelativePath.substring(0, fileInputRef.current.files[0].webkitRelativePath.indexOf("/"))
                            }

                            dispatch(onValuesChangeInMask(props.id, props.tabId, props.maskId, {
                                value: folderName,
                                base64Files: filesArray
                            }))
                        })
                    }
                }} />
            <div className="invalid-feedback">
                {props.data.invalidMessage}
            </div>
        </div>
    }

    function renderInputField() {
        //console.log(data.handle, inputValue, props.data.value)
        let numOfRows = inputValue.split('\n').length

        const keyEqu = "="

        const keysToActiveCalc = [keyEqu];


        //zmenseni odsazeni
        let style = {
            paddingRight: '2px',
            paddingLeft: '2px'
        }



        function isDisabled() {
            if (!props.parentSensitivity)
                return true

            return (data.sensitivity !== undefined) ? !data.sensitivity : (data.readOnly === true)  //readOnly - editovatelna bunka browsu
        }

        if (inputValue === Constants.Values.ERROR_VALUE) {
            setInputValue("X")

            style = {
                ...style,
                color: 'red'
            }
        }

        let inputProps = {
            id: data.handle,
            autoComplete: "off",
            style,
            value: inputValue,
            "aria-label": props.data.help, //pro nevidome
            ref: refInput,
            placeholder: props.placeholder,
            disabled: isDisabled(),
            type: props.type,
            onFocus: e => {
                //napr. ctrl + z skoci na prechozi pole a je potreba nastavit aktualni focus
                if (data.handle !== currentFocusId) {
                    sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                        ...getEmptyRowProp(),
                        handle: currentFocusId,
                        newFocus: data.handle,
                        action: EVENT_ENTRY
                    })))

                }
            },
            onChange: e => {
                dispatch(onValueChangeByHandle(props.id, "value", e.target.value))
            }
        }

        /*
        "dataType"
        ----------
        -   INTEGER     (dataFormat: ">>9", "->,>>>,>>9", "->>,>>>,>>>,>>9.99")
        -   DATE        (dataFormat: "99/99/9999")
        -   DATETIME    23.5 -> funkce vnorene_programy() -> edit
        -   DATETIME-TZ 23.5 -> funkce vnorene_programy() -> edit
        -   LOGICAL     (dataFormat: "Ano/Ne", "yes/no")
        -   CHARACTER   (dataFormat: "X", "X(4)", "	x-xx-xx-x-xx")
        -   DECIMAL     (dataFormat: "zzzz9.99", "zz,zzz")
        */
        switch (dataType) {
            case "CHARACTER":
                inputProps = {
                    ...inputProps,
                    type: (data.blank) ? "password" : "text",
                    onKeyDown: e => {
                        if (e.key === "Home") {
                            let tmpCursorPos = cursorPosition
                            setCursorPosition(0)
                            setRecalculateCursorPosition(!recalculateCursorPosition)
                            if (e.shiftKey) {
                                setSelectionInternal({ start: 0, end: tmpCursorPos })
                            }
                            e.preventDefault()
                        } else if (e.key === "End") {
                            let tmpCursorPos = cursorPosition
                            setCursorPosition(inputValue.length)
                            setRecalculateCursorPosition(!recalculateCursorPosition)
                            if (e.shiftKey) {
                                setSelectionInternal({ start: tmpCursorPos, end: inputValue.length })
                            }
                            e.preventDefault()
                        }
                    },
                    onPaste: e => {
                        let clipboardData = e.clipboardData || window.clipboardData;
                        setPastedData(clipboardData.getData('Text'))
                    },
                    onChange: e => {
                        ///////////////na tohle se radsi nekoukej!!!! Formatovani characteru/////////////////
                        let modDataFormatMask = dataFormatMask
                        let moveCursorForward = false
                        let backspace = false
                        let moveCursorBy = 0

                        if (dataFormatMask != "") {
                            if (selectionStart == selectionEnd) {//vkladam jeden ci vice symbolu na pozici nebo mackat backspace/delete
                                if (inputValue.length < e.target.value.length) {//pridal jsem jeden ci vice symbolu
                                    if (pastedData == "") {//pridavam jeden symbol
                                        moveCursorBy++
                                        if (dataFormatMask.charAt(selectionStart) != " ") moveCursorBy++
                                        modDataFormatMask = dataFormatMask.substring(0, selectionStart) + " " + dataFormatMask.substring(selectionStart)
                                    } else { //vlozil jsem vice symbolu
                                        moveCursorBy += pastedData.length
                                        moveCursorBy += dataFormatMask.substring(selectionStart, selectionStart + Math.min(pastedData.length, dataFormatMask.length)).replaceAll(" ", "").length
                                        modDataFormatMask = dataFormatMask.substring(0, selectionStart)
                                        for (let i = 0; i < pastedData.length; i++) {
                                            modDataFormatMask += " "
                                        }
                                        modDataFormatMask += dataFormatMask.substring(selectionStart)
                                    }
                                    moveCursorForward = true
                                } else {
                                    if (e.target.selectionStart < selectionStart) {//backspace
                                        modDataFormatMask = dataFormatMask.substring(0, selectionStart - 1) + dataFormatMask.substring(selectionStart)
                                        moveCursorBy--
                                        if (dataFormatMask.charAt(e.target.selectionStart) != " ") {
                                            backspace = true
                                            //moveCursorBy--
                                        }
                                    } else { //delete
                                        modDataFormatMask = dataFormatMask.substring(0, selectionStart) + dataFormatMask.substring(selectionStart + 1)
                                        moveCursorForward = true
                                        if (dataFormatMask.charAt(e.target.selectionStart) != " ") {
                                            moveCursorBy++
                                        }
                                    }
                                }
                            } else {
                                if (pastedData == "") {

                                    if ((inputValue.length - (selectionEnd - selectionStart)) == e.target.value.length) {//pouze jsem smazal vybrana data. Nenapsal jsem zadny symbol
                                        modDataFormatMask = dataFormatMask.substring(0, selectionStart) + dataFormatMask.substring(selectionEnd)
                                    } else {//misto vybrane casti jsem napsal pismeno
                                        modDataFormatMask = dataFormatMask.substring(0, selectionStart) + " " + dataFormatMask.substring(selectionEnd)
                                        moveCursorBy++
                                    }
                                } else {
                                    modDataFormatMask = dataFormatMask.substring(0, selectionStart)
                                    for (let i = 0; i < pastedData.length; i++) {
                                        modDataFormatMask += " "
                                    }
                                    modDataFormatMask += dataFormatMask.substring(selectionEnd)
                                    moveCursorBy += dataFormatMask.substring(selectionStart, selectionStart + Math.min(pastedData.length, dataFormatMask.length)).replaceAll(" ", "").length
                                    //moveCursorForward = true
                                }
                            }
                        }
                        setPastedData("")

                        if ((e.target.value.length === 1 && e.target.value.charAt(0) === "?") || (dataFormatMask.trimEnd() === inputValue && e.target.value.charAt(0) === "?")) {
                            //otaznik na prvni pozici
                            setInputValue("?")
                            setCursorPosition(1)

                        } else {
                            //e.target.selectionStart
                            let passedFormat = formatText(e.target.value, Math.min(selectionStart, e.target.selectionStart), modDataFormatMask, e.target.selectionStart, moveCursorForward, selectionStart, backspace)
                            //vyjimka pro "ctrl + backspace" se nenastavuje pozice
                            if (!(pressedKey.key === "Backspace" && pressedKey.ctrlKey)) {
                                if (passedFormat) {
                                    setCursorPosition(Math.max(selectionStart + moveCursorBy, 0))
                                } else {
                                    setCursorPosition(selectionStart)
                                }
                            }
                        }
                        setRecalculateCursorPosition(!recalculateCursorPosition)
                    },

                    onSelect: e => {
                        setSelectionStart(e.target.selectionStart)
                        setSelectionEnd(e.target.selectionEnd)
                    }
                }
                break
            case "DATE":
                let maxDate = "9999-12-31"

                //format 99-99-99 se musi omezit pouze na 100 let dle session:year-offset
                let countOfNumbers = (props.data.dataFormat.match(/9/g) || []).length
                if (countOfNumbers === 6) {
                    let century = Number(yearOffset.substring(0, 2))
                    let offsetYear = Number(yearOffset.substring(2))

                    maxDate = String(century + 1) + offsetYear - 1 + "-12-31"
                    let minDate = String(century) + offsetYear + "-01-01"

                    inputProps = {
                        ...inputProps,
                        min: minDate
                    }
                }

                inputProps = {
                    ...inputProps,
                    type: (!props.data.sensitivity && !/[0-9]/g.test(value)) ? "" : "date",
                    max: maxDate,
                    maxLength: 8,
                    value: toInputDateFormat(inputValue),
                    onChange: e => {
                        //console.log("onChange-", e.target.value, '-', (e.target.value === ""))
                        //if (e.target.value !== "")
                        setInputValue(toProgressDateFormat(e.target.value, props.data.dataFormat))
                    }
                }
                break
            case "DATETIME":
                //console.log("DATETIME", inputValue)
                //progess vraci pevny format "99/99/9999 HH:MM:SS"
                inputProps = {
                    ...inputProps,
                    type: (!props.data.sensitivity && !/[0-9]/g.test(value)) ? "" : "datetime-local",
                    onChange: e => { setInputValue(e.target.value) }
                }
                break
            case "DATETIME-TZ":
                //console.log("DATETIME-TZ", inputValue, value)
                inputProps = {
                    ...inputProps,
                    type: (!props.data.sensitivity && !/[0-9]/g.test(value)) ? "" : "datetime-local",
                    onChange: e => { setInputValue(e.target.value) }
                }
                break
            case "INTEGER":
                inputProps = {
                    ...inputProps,
                    type: "text",
                    onKeyDown: e => {
                        if (e.key === "Home") {
                            let tmpCursorPos = cursorPosition
                            setCursorPosition(0)
                            setRecalculateCursorPosition(!recalculateCursorPosition)
                            if (e.shiftKey) {
                                setSelectionInternal({ start: 0, end: tmpCursorPos })
                            }
                            e.preventDefault()
                        } else if (e.key === "End") {
                            let tmpCursorPos = cursorPosition
                            setCursorPosition(inputValue.length)
                            setRecalculateCursorPosition(!recalculateCursorPosition)
                            if (e.shiftKey) {
                                setSelectionInternal({ start: tmpCursorPos, end: inputValue.length })
                            }
                            e.preventDefault()
                        } else if (e.key === "-" && longDataFormat.charAt(0) === "-") {
                            e.preventDefault()
                            if (inputValue.charAt(0) !== "-") { //pridani zmenka -
                                setInputValue("-" + inputValue)
                                setCursorPosition(cursorPosition + 1)
                                setRecalculateCursorPosition(!recalculateCursorPosition)
                            }
                        } else if (keysToActiveCalc.includes(e.key)) {
                          // bingo
                          setInitKey(e.key);
                          setCalculatorVisibility(true);
                          e.preventDefault();
                          return;
                        } 
                    },
                    onChange: e => {
                        if (data.allowNull && (e.target.value === "?")) { //vlozil jsem jen otaznik
                            setInputValue("?")
                            setCursorPosition(0)
                            setRecalculateCursorPosition(!recalculateCursorPosition)
                            return
                        }

                        if (new RegExp(/^[0-9,-]+$/).test(e.target.value) || e.target.value === "") {
                            let afterFloating = formatNumbersAfterFloatingPoint(e.target.value)
                            if (afterFloating == null) {//cela cast cisla neprosla validaci
                                setCursorPosition(selectionStart)
                                setRecalculateCursorPosition(!recalculateCursorPosition)
                                return
                            }
                            setInputValue(afterFloating)

                            if (selectionEnd - selectionStart == inputValue.length || e.target.value === "") { //smazal jsem cele //prepsal jsem cele pole
                                setCursorPosition(afterFloating.length)
                            } else { //cast cela cisla
                                if (selectionStart == e.target.selectionStart) {//delete
                                    setCursorPosition(selectionStart)
                                } else {//backspace nebo pridani
                                    setCursorPosition(selectionStart + (afterFloating.length - inputValue.length))
                                }
                            }

                        } else {
                            setCursorPosition(selectionStart)
                        }

                        setRecalculateCursorPosition(!recalculateCursorPosition)

                    },
                    onSelect: e => {
                        setSelectionStart(e.target.selectionStart)
                        setSelectionEnd(e.target.selectionEnd)
                    }
                }
                break
            case "DECIMAL":
                inputProps = {
                    ...inputProps,
                    type: "text",
                    onPaste: e => {
                        let clipboardData = e.clipboardData || window.clipboardData;
                        setPastedData(clipboardData.getData('Text'))
                    },
                    onKeyDown: e => {
                            console.log(e.key);
                            if (e.key === "Home") {
                                let tmpCursorPos = cursorPosition
                                setCursorPosition(0)
                                setRecalculateCursorPosition(!recalculateCursorPosition)
                                if (e.shiftKey) {
                                    setSelectionInternal({ start: 0, end: tmpCursorPos })
                                }
                                e.preventDefault()
                            } else if (e.key === "End") {
                                let tmpCursorPos = cursorPosition
                                setCursorPosition(inputValue.length)
                                setRecalculateCursorPosition(!recalculateCursorPosition)
                                if (e.shiftKey) {
                                    setSelectionInternal({ start: tmpCursorPos, end: inputValue.length })
                                }
                                e.preventDefault()
                            } else if (e.key === "." || e.code == "NumpadDecimal") {//presun na pozici desetinne tecky
                                setCursorPosition(inputValue.indexOf(".") + 1)
                                setRecalculateCursorPosition(!recalculateCursorPosition)
                                e.preventDefault()
                            } else if (e.key === "-" && longDataFormat.charAt(0) === "-") {
                                e.preventDefault()

                                //pokud zadam minus hned na zacatku, potrebuju zustat na pozici mezi minus a 0. Jinak se mi to posune o pozici bliz k minus a zadavat hned desitky misto jednotek
                                if (inputValue.charAt(0) !== "-" && (value == inputValue || longDataFormat.charAt(0) === "-")) {
                                    setInputValue("-" + inputValue)
                                    setCursorPosition(inputValue.indexOf(".") + 1)
                                }
                            } else if (keysToActiveCalc.includes(e.key)) {
                              // bingo
                              setInitKey(e.key);
                              setCalculatorVisibility(true);
                              e.preventDefault();
                              return;
                            }
                    },
                    onChange: e => {
                        if (e.target.value.indexOf(".") == -1 && (e.target.value.length == (inputValue.length - 1)) && inputValue.indexOf(".") > -1) { //posun kolem desetinne tecky backspace a delete
                            if (selectionStart <= inputValue.indexOf(".")) {//zleva od des tecky
                                setCursorPosition(inputValue.indexOf(".") + 1)
                            } else {
                                setCursorPosition(inputValue.indexOf("."))
                            }
                            setInputValue(inputValue)
                            setRecalculateCursorPosition(!recalculateCursorPosition)
                            return
                        } else if (data.allowNull && (e.target.value === "?")) { //vlozil jsem jen otaznik
                            setInputValue("?")
                            setCursorPosition(0)
                            setRecalculateCursorPosition(!recalculateCursorPosition)
                            return
                        }

                        let moveCursorBy = 0

                        if (selectionStart == selectionEnd) {//vkladam jeden ci vice symbolu na pozici nebo mackat backspace/delete
                            if (inputValue.length < e.target.value.length) {//pridal jsem jeden ci vice symbolu
                                if (pastedData == "") {//pridavam jeden symbol
                                    if (longDataFormat.indexOf(".") != -1) moveCursorBy++
                                    /*if (dataFormatMask.charAt(selectionStart) != " ") {
                                        console.log("adding 3:"+dataFormatMask.charAt(selectionStart)+":")
                                        moveCursorBy++
                                    }*/
                                } else { //vlozil jsem vice symbolu
                                    moveCursorBy += pastedData.length
                                    //moveCursorBy += dataFormatMask.substring(selectionStart, selectionStart+Math.min(pastedData.length, dataFormatMask.length)).replaceAll(" ","").length
                                }
                            } else {
                                if (e.target.selectionStart < selectionStart) {//backspace
                                    if (longDataFormat.indexOf(".") != -1) moveCursorBy--
                                } else { //delete
                                    if (longDataFormat.indexOf(".") == -1) moveCursorBy++
                                    if (dataFormatMask.charAt(e.target.selectionStart) != " ") {
                                        //moveCursorBy++
                                    }
                                }
                            }
                        } else {
                            if (pastedData == "") {
                                if ((inputValue.length - (selectionEnd - selectionStart)) == e.target.value.length) {//pouze jsem smazal vybrana data. Nenapsal jsem zadny symbol
                                } else {//misto vybrane casti jsem napsal pismeno
                                    moveCursorBy++
                                }
                            } else {
                                //moveCursorBy += dataFormatMask.substring(selectionStart, selectionStart + Math.min(pastedData.length,dataFormatMask.length)).replaceAll(" ","").length
                                moveCursorBy += pastedData.length
                            }
                        }

                        setPastedData("")

                        if (new RegExp(/^[0-9.,-]+$/).test(e.target.value) || e.target.value === "") {

                            let floatingPointPos = e.target.value.indexOf(".")
                            let afterFloating
                            if (floatingPointPos == -1) {
                                afterFloating = formatNumbersAfterFloatingPoint(e.target.value)
                            } else {
                                afterFloating = formatNumbersAfterFloatingPoint(e.target.value.slice(0, floatingPointPos))
                            }
                            if (afterFloating == null) {//cela cast cisla neprosla validaci
                                setCursorPosition(selectionStart)
                                setRecalculateCursorPosition(!recalculateCursorPosition)
                                return
                            }

                            let beforeFloating
                            if (floatingPointPos == -1) {
                                beforeFloating = formatNumbersBeforeFloatingPoint("")
                            } else {
                                beforeFloating = formatNumbersBeforeFloatingPoint(e.target.value.slice(floatingPointPos + 1))
                            }

                            if (beforeFloating == null) {//desetinna cast neprosla validaci
                                setCursorPosition(selectionStart)
                                setRecalculateCursorPosition(!recalculateCursorPosition)
                                return
                            }

                            let prevValue = inputValue
                            if (longDataFormat.indexOf(".") == -1) {//protoze decimal nemusi byt decimal, ale jen dlouhy integer.. jak proste..
                                setInputValue(afterFloating)
                            } else {
                                setInputValue(afterFloating + "." + beforeFloating)
                            }

                            if (selectionEnd - selectionStart == prevValue.length || e.target.value === "") {//prepsal jsem cele pole / smazal
                                setCursorPosition(afterFloating.length)
                            } else if (selectionStart <= prevValue.indexOf(".")) { //cast cela cisla
                                if (selectionStart == e.target.selectionStart) {//delete
                                    setCursorPosition(selectionStart + (afterFloating.length - prevValue.indexOf(".") + 1))
                                } else {//backspace nebo pridani
                                    setCursorPosition(selectionStart + (afterFloating.length - prevValue.indexOf(".")))
                                }
                            } else if (floatingPointPos == -1) { //decimal bez des. tecky
                                if (selectionStart == e.target.selectionStart) {//delete
                                    setCursorPosition(selectionStart + (afterFloating.length - prevValue.length + 1))
                                } else {//backspace nebo pridani
                                    setCursorPosition(selectionStart + afterFloating.length - prevValue.length)
                                }
                            } else {//desetinna
                                setCursorPosition(selectionStart + moveCursorBy)
                            }

                        } else {
                            setCursorPosition(selectionStart)
                        }

                        setRecalculateCursorPosition(!recalculateCursorPosition)

                    },
                    onSelect: e => {
                        setSelectionStart(e.target.selectionStart)
                        setSelectionEnd(e.target.selectionEnd)
                    }
                }
                break
            case "LOGICAL":
                inputProps = {
                    id: data.handle,
                    ref: refInput,
                    disabled: isDisabled()
                }
                //SWITCH ?
                //<input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>

                break
            default:
                inputProps = { type: "text" }
                break
        }

        if (data.dataType === "LOGICAL") {
            let options = data.dataFormat.split("/")

            return (<select
                {...inputProps}
                className="form-select form-select-sm"
                onKeyDown={e => {
                    //zabrani otevreni options, protoze se enterem potvrzuje frame defaultAction
                    if (e.key === "Enter")
                        e.preventDefault()
                }}
                style={{
                    paddingRight: '0',
                    paddingLeft: '0',
                    backgroundSize: '10px 8px',
                    backgroundPosition: 'right .1rem center'
                }}
            //defaultValue={props.data.value}
            >
                {options.map((item, index) => {
                    //pridan trim, napr. 25.15 - pole Posta "Ano/Ne "
                    return <option
                        key={"logical_select_option_" + index}
                        selected={item.trim() === props.data.value.trim()}
                        value={item.trim()}>{item.trim()}</option>
                })}
            </select>)
        }

        if (numOfRows > 1) {
            //TEXTAREA - pokud InputFieldElement obsahuje vice radku
            inputProps = {
                ...inputProps,
                rows: numOfRows,
                className: "form-control",
                style: { resize: 'none' }
            }
            return <textarea {...inputProps} />
        } else {
            //INPUT - jeden radek
            inputProps = {
                ...inputProps,
                className: "form-control form-control-sm"
            }


            if (data.fieldDetailAction) {
                //Detail
                return <div className="input-group-s minput-group-prepend" style={{ width: "100%" }}>
                    <span className="input-group-text form-control-sm url_span">
                        <a
                            tabIndex="-1"
                            aria-label={data.help}
                            className={"inputURL"}
                            style={{ padding: "0 2px 0 2px" }}
                            href={data.URL}
                            target="blank"
                            onClick={e => {
                                sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                                    ...getEmptyRowProp(),
                                    handle: currentFocusId,
                                    newFocus: props.id,
                                    action: FIELD_DETAIL_ACTION
                                })))
                            }}>
                            {data.value}
                        </a>
                    </span>
                </div >
            } else if (data.URL != undefined && data.URL != "" && inputProps.disabled) {
                //Url
                return <div className="input-group-s minput-group-prepend" style={{ width: "100%" }}>
                    <span className="input-group-text form-control-sm url_span" style={{ paddingLeft: "2px" }}>
                        <a tabIndex="-1" aria-label={data.help} className={"inputURL"} href={data.URL} title={data.URLTooltip} target="blank"
                            style={{ padding: "0" }}>{data.value}
                        </a>
                    </span>
                </div>
            } else {
                let isErrorValue = getStore().getState().desktopSession[currentTabId][currentMaskID][props.id].data.value === Constants.Values.ERROR_VALUE
                //Default
                inputProps = {
                    ...inputProps,
                    style: {
                        ...inputProps.style,
                        color: (isErrorValue ? 'red' : ""),
                        fontWeight: (isErrorValue ? 'bold' : ""),
                        border: ((data.highlight) ? "1px solid rgba(255, 0, 0, 0.5)" : ""),
                        textAlign: ((dataType == "INTEGER" || dataType == "DECIMAL") ? "right" : ""),
                        paddingRight: "2px",
                        paddingLeft: "2px",
                    }
                }
                return <React.Fragment>
                    <input
                        aria-label={data.help}
                        {...inputProps}
                        list={"datalist_" + data.handle} >

                    </input>
                    {(currentFocusId === props.id && props.data.allowQuickHintSearch) ? <DataList id={props.id} tabId={props.tabId} maskId={props.maskId} datalist_id={"datalist_" + data.handle} inputValue={inputValue} /> : null}
                </React.Fragment>
            }
        }
    }

    function renderInput() {
        return <div className="input-group input-group-sm">
            {renderLeftIcon()}

            {/*showDetailTriangle()*/}
            {calculatorVisibility && (
                <Calculator
                    id={props.id}
                    tabId={props.tabId}
                    maskId={props.maskId}
                    setCalculatorVisibility={setCalculatorVisibility}
                    setInputValue={setInputValue}
                    inputDataFormat={props.data.dataFormat}
                    dialogX={props.data.dialogX}
                    dialogY={props.data.dialogY}
                    inputValue={inputValue}
                    initKey={initKey}
                    disableInsertButton={false}
                />
            )}

            {renderInputField()}

            {showOfferTriangle()}
        </div>
    }

    logRender("InputFieldElement")
    logRenderProps(props)
    // console.log("InputField -> render()", props, inputValue)

    if (!data.visible)
        return ""

    return (<div className="form-group">
        {((data.label !== "&Null;" && data.sideLabelHandle === false) ? (<label htmlFor={data.handle}>{data.label + " - " + data.handle}</label>) : "")}
        {(() => {
            //VYBER SOUBORU
            if (props.data.specialId === INPUT_FILE_NAME)
                return renderFileInputField()
            else if (props.data.specialId === INPUT_DIR_NAME)
                return renderFolderInputField()
            else if (props.data.specialId === OUTPUT_DIR_NAME || props.data.specialId === OUTPUT_FILE_NAME) {
                //zadejte naze souboru(slozka - zip)
                return <BSTooltip id={tooltipId} tooltiptext={props.data.help}>
                    <div className="input-group input-group-sm">
                        <input className={"form-control form-control-sm " + (props.data.invalid ? "is-invalid" : "")} id={data.handle} ref={refInput} defaultValue={inputValue} size={props.data.width} disabled={!data.sensitivity} />
                        <div className="invalid-feedback">
                            {props.data.invalidMessage}
                        </div>
                    </div>
                </BSTooltip>
            } else {
                let badgeValues = props.data.badgeValue?.split("\u0001")
                //console.log(props.data.sensitivity, badgeValues, props)
                if (props.data.sensitivity === false && badgeValues && badgeValues?.indexOf(inputValue) > -1) {
                    return <Badge
                        value={inputValue}
                        badgeValues={badgeValues}
                        badgeTooltips={props.data.badgeTooltip?.split("\u0001")}
                        badgeColors={props.data.badgeColor?.split("\u0001")} />
                } else {
                    if (data.URL != undefined && data.URL != "")
                        return renderInput()
                    else
                        return <BSTooltip id={tooltipId} tooltiptext={props.data.help} placement='left'>
                            {renderInput()}
                        </BSTooltip>
                }
            }
        })()}
    </div >)
}

function mapStateToProps(state, ownProps) {
    //console.log("mapStateToProps", state, ownProps)
    let value = state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id].data.value
    if (value === undefined)
        value = ""

    if (value == "" && ownProps.data.specialId === USERNAME) {//nastav username z minuleho prihlaseni
        let defaultUsername = localStorage.getItem(LocalStorageKeys.DEFAULT_USERNAME)
        if (defaultUsername != null) value = defaultUsername
    }

    return {
        ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id],
        parentSensitivity: parentSensitivity(ownProps.id, ownProps.tabId, ownProps.maskId),
        data: {
            ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id].data,
            value
        },
        focusId: state.ws.focusId
    }
}

function areEqual(prevProps, nextProps) {
    //console.log("ARE EQUAL", prevProps, nextProps)
    //console.log("ARE EQUAL", prevProps.data.value === nextProps.data.value, prevProps.data.sensitivity === nextProps.data.sensitivity, prevProps.data.fileSize === nextProps.data.fileSize)
    //console.log("ARE EQUAL", prevProps.data.value, "===", nextProps.data.value, prevProps.data.value === nextProps.data.value)

    return (prevProps.data.value === nextProps.data.value
        && prevProps.data.sensitivity === nextProps.data.sensitivity
        && prevProps.parentSensitivity === nextProps.parentSensitivity
        && prevProps.data.hasValuesOffer === nextProps.data.hasValuesOffer
        && prevProps.data.readOnly === nextProps.data.readOnly
        && prevProps.data.fileSize === nextProps.data.fileSize
        && prevProps.data.invalid === nextProps.data.invalid
        && prevProps.data.dataFormat === nextProps.data.dataFormat
        && prevProps.data.dialogX === nextProps.data.dialogX
        && prevProps.data.dialogY === nextProps.data.dialogY
        )
}

export default connect(mapStateToProps)(React.memo(InputFieldElement, areEqual))
