import React from 'react'
import PropTypes, { func } from 'prop-types'
import { connect } from 'react-redux'

import Constants from '../../constants/constants'
import { logRender, logRenderProps } from '../../utils/logging'
import { textWithMnemonic } from '../../utils/screen'
import { getEmptyRowProp } from '../../utils/actions'
import { onValuesChangeByHandle, onDataChange, onValuesChangeInMask } from '../../store/actions/store_actions'

import { getElementData, saveValue2Store, parentSensitivity } from './elementMethods'
import useFocusObserver from '../../hooks/useFocusObserver'
import { getStore } from '../../index.js'

import { LISTENER_TYPE, addListener } from '../../hooks/desktopListener'
import BSTooltip from '../BSTooltip'
import constants from '../../constants/constants'
import styles from '../../constants/styles'
import { hasSessionFocus } from '../../focus_actions.js'

const { KEYDOWN, CLICK } = LISTENER_TYPE
const { SET_VALUE_REQUEST, UPLOAD_FILE_CHUNK, UPLOAD_DIR_FILE_CHUNK } = Constants.WebSocketActions
const { CHOOSE_ACTION, TAB_ACTION, SHIFT_TAB_ACTION } = Constants.SetValueRequest
const { INPUT_FILE_NAME, INPUT_DIR_NAME, OUTPUT_DIR_NAME, OUTPUT_FILE_NAME, LOCKED_RECORD_BREAK } = Constants.SpecialId
const { Keyboard } = Constants

export function handleButtonElement(event, element, ws_context) {
    //console.log("handleButtonElement", event)
    const { createMessage, createRequest, sendMessage } = ws_context
    let store = getStore().getState()
    let ws = store.ws

    //type: "click"
    //type: "keydown" -> keyCode: 13
    //console.log(event.type === CLICK, (event.type === KEYDOWN && event.keyCode === Keyboard.ENTER))
    if (event.type === CLICK || (event.type === KEYDOWN && event.keyCode === Keyboard.ENTER)) {
        let requestSended = false
        let mask = store.desktopSession[ws.currentTabId][ws.currentMaskID]
        let parent = store.desktopSession[ws.currentTabId][ws.currentMaskID][element.data.parentHandle]
        // OK / STORNO
        let okPressed = parent.data.defaultButtonHandle === element.id
        let stornoPressed = parent.data.stornoButtonHandle === element.id

        if (parent !== undefined && (okPressed || stornoPressed)) {

            //kontrola zda rodicovsky element neobsahuje element se special id
            //console.log("kontrola ", mask)
            parent.childIds.forEach(elementId => {
                //console.log("kontrola special id", mask[elementId].data.specialId)
                if (mask[elementId].data.sensitivity) {
                    switch (mask[elementId].data.specialId) {
                        case INPUT_FILE_NAME:
                            //console.log("UPLOAD el", elementId, mask[elementId])
                            requestSended = true

                            var currentPart = 0
                            var numParts = mask[elementId].data.base64?.length - 1

                            /*rekuzivní volání uploadu casti souboru*/
                            function uploadChunk(part) {
                                //console.log("numParts", numParts, "currentPart", currentPart, numParts === currentPart)

                                let base64Chunk = ""
                                if (mask[elementId].data?.base64 !== undefined)
                                    base64Chunk = mask[elementId].data?.base64[part]

                                let uploadChunkRequest = {
                                    uploadFilePath: mask[elementId].data.uploadTarget,
                                    part: part,
                                    plainText: false,
                                    base64Chunk: base64Chunk,
                                    numParts: numParts,
                                }

                                sendMessage(createMessage(UPLOAD_FILE_CHUNK, createRequest({
                                    UploadChunkRequest: {
                                        ...uploadChunkRequest
                                    }
                                })), response => {
                                    //console.log("callback ", response)
                                    if (currentPart < numParts) {
                                        currentPart++
                                        uploadChunk(currentPart)
                                    } else {
                                        //2.krok stisknuti ok
                                        doSetValueRequest()
                                    }
                                })
                            }

                            if (okPressed) {
                                if (mask[elementId].data.base64 !== undefined && mask[elementId].data.base64 !== "" && mask[elementId].data.base64.length !== 0) {
                                    //1.krok upload souboru
                                    uploadChunk(currentPart)
                                } else {
                                    //press button
                                    //doSetValueRequest()
                                    getStore().dispatch(onDataChange(elementId, {
                                        invalid: true,
                                        invalidMessage: store.app.messageContainer["file.empty"]
                                    }))

                                    requestSended = true
                                }

                            } else if (stornoPressed) {
                                //vymazani vybraneho souboru a jeho velikosti
                                getStore().dispatch(onValuesChangeByHandle(elementId, {
                                    base64: "",
                                    fileSize: 0,
                                }))

                                //press button
                                doSetValueRequest()
                            }

                            break
                        case INPUT_DIR_NAME:
                            //upload adresare
                            var currentFile = 0
                            var currentPart = 0
                            var directories = []

                            requestSended = true

                            /*rekuzivní volání uploadu souboru a jejich casti*/
                            function uploadFileChunk(file, part) {
                                var numParts = mask[elementId].data.base64Files[file].base64.length - 1
                                var numfiles = mask[elementId].data.base64Files.length - 1

                                let uploadChunkRequest = {
                                    base64Chunk: mask[elementId].data.base64Files[file].base64[part],
                                    webkitRelativePath: mask[elementId].data.base64Files[file].webkitRelativePath,
                                    fileSize: mask[elementId].data.base64Files[file].fileSize,
                                    uploadFilePath: mask[elementId].data.uploadTarget,
                                    part: part,
                                    numParts: numParts,
                                    fileName: mask[elementId].data.base64Files[file].fileName,
                                    createDir: false,
                                    plainText: false
                                }

                                //test zda se ma vytvorit novy adresar
                                let dirName = uploadChunkRequest.webkitRelativePath.replace(uploadChunkRequest.fileName, "")
                                let exists = directories.find(dir => dir === dirName)
                                if (exists === undefined) {
                                    directories.push(dirName)

                                    uploadChunkRequest = {
                                        ...uploadChunkRequest,
                                        createDir: true
                                    }
                                }

                                if (currentPart === numParts && currentFile === numfiles) {
                                    uploadChunkRequest = {
                                        ...uploadChunkRequest,
                                        lastPart: true
                                    }
                                }

                                //console.log("FILE", currentFile, "/", numfiles, 'PART', currentPart, '/', numParts)
                                sendMessage(createMessage(UPLOAD_DIR_FILE_CHUNK, createRequest({
                                    UploadChunkRequest: {
                                        ...uploadChunkRequest
                                    }
                                })), response => {
                                    //console.log("callback ", response)
                                    if (currentPart === numParts) {
                                        if (currentFile === numfiles) {
                                            //2.krok stisknuti ok
                                            doSetValueRequest()
                                        } else {
                                            currentFile++
                                            uploadFileChunk(currentFile, currentPart)
                                        }
                                    } else {
                                        currentPart++
                                        uploadFileChunk(currentFile, currentPart)
                                    }
                                })
                            }

                            if (okPressed) {
                                if (mask[elementId].data.base64Files !== undefined && mask[elementId].data.base64Files.length > 0)
                                    uploadFileChunk(currentFile, currentPart)
                                else {
                                    getStore().dispatch(onDataChange(elementId, {
                                        invalid: true,
                                        invalidMessage: store.app.messageContainer["dir.empty"]
                                    }))

                                    requestSended = true
                                }
                            } else if (stornoPressed) {
                                //vymazani vybraneho souboru a jeho velikosti
                                getStore().dispatch(onValuesChangeByHandle(elementId, {
                                    base64: "",
                                    fileSize: 0,
                                }))

                                //press button
                                doSetValueRequest()
                            }
                            break
                        case OUTPUT_DIR_NAME:
                            if (okPressed) {
                                let elementData = getElementData(elementId)
                                if (elementData.value !== undefined && elementData.value.length === 0 && elementData.sensitivity) {
                                    getStore().dispatch(onDataChange(elementId, {
                                        invalid: true,
                                        invalidMessage: store.app.messageContainer["dir.empty"]
                                    }))

                                    requestSended = true
                                } else if (elementData.value.indexOf("\\") > -1 || elementData.value.indexOf("/") > -1 || elementData.value.indexOf(":") > -1) {
                                    getStore().dispatch(onDataChange(elementId, {
                                        invalid: true,
                                        invalidMessage: store.app.messageContainer[constants.MessageContainer.INVALID_CHARACTER_FOR_FILE_OR_FOLDER]
                                    }))

                                    requestSended = true
                                }
                            }
                            break
                        case OUTPUT_FILE_NAME:
                            if (okPressed) {
                                let elementData = getElementData(elementId)
                                if (elementData.value !== undefined && elementData.value.length === 0) {
                                    getStore().dispatch(onDataChange(elementId, {
                                        invalid: true,
                                        invalidMessage: store.app.messageContainer["file.empty"]
                                    }))

                                    requestSended = true
                                } else if (elementData.value.indexOf("\\") > -1 || elementData.value.indexOf("/") > -1 || elementData.value.indexOf(":") > -1) {
                                    getStore().dispatch(onDataChange(elementId, {
                                        invalid: true,
                                        invalidMessage: store.app.messageContainer[constants.MessageContainer.INVALID_CHARACTER_FOR_FILE_OR_FOLDER]
                                    }))

                                    requestSended = true
                                }

                            }
                            break
                        default:
                            break
                    }
                }
            })
        }

        //console.log("requestSended", requestSended)
        if (requestSended === false)
            doSetValueRequest()
    } else if (event.type === KEYDOWN && (event.keyCode === Keyboard.LEFT || event.keyCode === Keyboard.RIGHT)) {
        let wsAction = event.keyCode === Keyboard.RIGHT ? TAB_ACTION : SHIFT_TAB_ACTION
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: ws.focusId,
            action: wsAction
        })))
    }

    function doSetValueRequest() {
        if (element.data.specialId === LOCKED_RECORD_BREAK) {
            //PRERUSENI ZAMCENE VETY - resi se v APP.js
        } else {
            let requestData = {
                handle: ws.focusId,
                newFocus: element.id,
                action: CHOOSE_ACTION
            }

            let elData2Save = getElementData(ws.focusId)
            //console.log("BUTTON doSetValueRequest", elData2Save)
            if (elData2Save.shouldSaveValue) {
                saveValue2Store(ws.focusId, elData2Save)
                requestData = {
                    ...getEmptyRowProp(),
                    ...requestData,
                    value: elData2Save.value
                }
            }

            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest(requestData)))
        }
    }
}

/**
 * @param {Object} props
 * @param {ChildrenFn} props.caption
 * @param {ChildrenFn} props.handle
 */
function ButtonElement(props) {
    const btnRef = React.useRef(null)

    const { id, data, desktopMenu, focusedElement } = props

    //mnemonika
    addListener(KEYDOWN, document, event => {
        let caption = props.data.caption
        if (event.type === KEYDOWN && caption && caption.indexOf("&") > -1) {
            let letterIndex = caption.indexOf("&") + 1
            let fuctionalKey = caption.substring(letterIndex, letterIndex + 1).toLowerCase()

            if (event.key === fuctionalKey
                && desktopMenu[constants.MenuActions.FUNCTIONS].sensitivity === false
                && hasSessionFocus()
                && focusedElement?.componentName === "ButtonElement") {

                event.preventDefault()

                //fosuc na tlacitku, moznost spustit mnemoniky bez alt
                btnRef.current.click()
            } else if (event.key === fuctionalKey
                && event.altKey
                && desktopMenu[constants.MenuActions.FUNCTIONS].sensitivity === false) {

                event.preventDefault()

                //fosuc neni tlacitku, moznost spustit mnemoniky s alt klavesou
                btnRef.current.click()
            }
        }
    })

    useFocusObserver(props.id, "ButtonElement")

    logRender("Button")
    logRenderProps(props)
    return (<BSTooltip tooltiptext={data.help} className={props.classNameTooltip}>
        <button
            id={id}
            className={(props.className ? props.className : "btn btn-primary focusable")}
            style={{
                ...styles.buttonStyle,
                minWidth: "100%",
                marginTop: (data.specialId === (constants.SpecialId.BOTTOM_BUTTONS_WITH_MARGIN || constants.SpecialId.LOCKED_RECORD_BREAK) ? '.5rem' : '0rem')
            }}
            type="button"
            key={id}
            ref={btnRef}
            disabled={data.sensitivity === false || !props.parentSensitivity}>{textWithMnemonic(props.data.caption, true)}</button>
    </BSTooltip>)
}

function mapStateToProps(state, ownProps) {
    return {
        ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id],
        parentSensitivity: parentSensitivity(ownProps.id, ownProps.tabId, ownProps.maskId),
        desktopMenu: state.desktopMenu,
        focusedElement: state.desktopSession[ownProps.tabId][ownProps.maskId][state.ws.focusId],

    }
}

ButtonElement.propTypes = {
    data: PropTypes.shape({
        caption: PropTypes.string,          //"OK"
        handle: PropTypes.number,           //1021
        height: PropTypes.number,           //1
        help: PropTypes.string,             //""
        horizontalMove: PropTypes.number,   //2
        horizontalScale: PropTypes.number,  //0
        parentHandle: PropTypes.number,     //1020
        sensitivity: PropTypes.bool,        //true
        verticalMove: PropTypes.number,     //1
        verticalScale: PropTypes.number,    //0
        visible: PropTypes.bool,            //true
        width: PropTypes.number,            //10
        x: PropTypes.number,                //12
        y: PropTypes.number                 //13
    }),
    childIds: PropTypes.arrayOf(PropTypes.number),
    componentName: PropTypes.string,
    tabId: PropTypes.number,                //1
    maskId: PropTypes.number,               //1
    className: PropTypes.string,
    classNameTooltip: PropTypes.string,

}

export default connect(mapStateToProps)(ButtonElement)
