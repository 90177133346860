import React from 'react'
import { connect } from 'react-redux'
import pjson from '../../package.json'
import Constants from '../constants/constants'

import { logRender, logRenderProps } from '../utils/logging'

function Footer(props) {
    const { title, focusedElement, clientVersion, currentFocusedInputFieldElementWidth } = props
    const help = focusedElement?.data?.help

    function getInputFieldElementSize() {
        let dataType = focusedElement?.data?.dataType
        let dataFormat = focusedElement?.data?.dataFormat
        if (focusedElement?.componentName === "InputFieldElement"
            && (dataType === "CHARACTER" && dataFormat?.startsWith("x(")) || dataType === "INTEGER" || dataType === "DECIMAL") {
            const regex = /x\((.*?)\)/
            let width = dataFormat.match(regex)

            if (width !== null)
                width = width[1]
            else
                width = focusedElement?.data?.width

            return "[" + currentFocusedInputFieldElementWidth?.width + "/" + width + "] znaků"
        }
    }

    function showFieldInfo() {
        let remainText, fieldText

        if (help !== undefined && help !== "") {
            fieldText = " " + help
        }

        remainText = getInputFieldElementSize()
        if (remainText)
            fieldText = fieldText ? fieldText + " " + remainText : " " + remainText

        if (fieldText)
            return <label className="text-dark text-muted" readOnly>
                <i className="far fa-info-circle" />
                {fieldText}
            </label>
    }

    logRender("Footer")
    logRenderProps(props)
    return (
        <footer className="footer" style={{ filter: 'drop-shadow(2px 4px 6px gray)', zIndex: 100, display: 'flex', justifyContent: (help ? 'space-between' : 'flex-end'), flexWrap: 'wrap' }} >
            {showFieldInfo()}

            <div className='text-muted'>
                <i className='far fa-copyright pe-1'></i>
                <a href="https://www.apso.cz" target="_blank" tabIndex={-1} className="text-muted" >{clientVersion + " " + pjson.version}</a>
                {", pid: [" + props.pid + "]"}
            </div>
        </footer>
    )
}

function mapStateToProps(state, ownProps) {
    return {
        title: state.app.title,
        pid: state.app.pid,
        focusedElement: state.desktopSession[ownProps.currentTabId][ownProps.currentMaskID][ownProps.focusId],
        clientVersion: state.app.messageContainer[Constants.MessageContainer.APP_VERSION],
        currentFocusedInputFieldElementWidth: state.currentFocusedInputFieldElementWidth
    }
}

export default connect(mapStateToProps)(Footer)