import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'

import BSTooltip from '../BSTooltip'
import CustomModalDialog from '../CustomModalDialog'
import { getStore } from '../..'
import BrowseColumnsSettingsItem from './BrowseColumnsSettingsItem'
import ElementWithListener from '../ElementWithListener'
import constants from '../../constants/constants'
import { textWithMnemonic } from '../../utils/screen'
import { changeAllColumnsVisibility, onValuesChangeInMask } from '../../store/actions/store_actions'
import { WebSocketContext } from '../../services/WebSocket'
import styles from '../../constants/styles'

export const ITEM_PREFIX = "browse_columns_settings_item_"
export const COLUMNS_SETTING_PROP = "columns_setting_prop"

function BrowseColumnsSettingsDialog(props) {
    const { desktopSessionMask, browseHandle, tabId, maskId, isModalOpen } = props
    const dispatch = useDispatch()
    const settingsRef = React.useRef()
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context
    const [dividerIndex, setDividerIndex] = React.useState(false)
    const browseColsSorted = desktopSessionMask[browseHandle].data.browseColsSorted.split(",")
    const dragItemOver = React.useRef()

    const query = "[id*=\"" + ITEM_PREFIX + "\"]"
    let allColumnsVisible = true

    function selectAll(selectAll) {
        let checkboxes = settingsRef.current.querySelectorAll(query)
        checkboxes.forEach(element => { element.checked = selectAll })

        dispatch(changeAllColumnsVisibility(
            browseHandle,
            selectAll
        ))
    }

    function getColumnsOrderAndVisibility2() {
        let checkboxes = settingsRef.current.querySelectorAll(query)

        let colsOrder = ""
        let colsVisible = ""
        checkboxes.forEach(element => {
            if (colsOrder !== "") {
                colsOrder += ","
                colsVisible += ","
            }

            colsOrder += element.id.replace(ITEM_PREFIX, "")
            colsVisible += element.checked ? "1" : "0"
        })

        return {
            columnsOrder: colsOrder,
            columnsVisible: colsVisible
        }
    }

    function onClose() {
        dispatch(onValuesChangeInMask(browseHandle, tabId, maskId, { [COLUMNS_SETTING_PROP]: false }))
    }

    //console.log("RENDER SETTINGS", props)
    return (<CustomModalDialog
        key={"browse_columns_settings_" + browseHandle}
        isOpen={isModalOpen}
        onClose={onClose}
        textHeader={getStore().getState().app.messageContainer[constants.MessageContainer.MANAGE_COLUMNS]}
        assign2Component={{
            id: browseHandle,
            tabId: tabId,
            maskId: maskId
        }}
        dialogX={desktopSessionMask[browseHandle].data.dialogX}
        dialogY={desktopSessionMask[browseHandle].data.dialogY}
        width={'40rem'}>
        <div className='m-1' /*style={{ display: 'flex', justifyContent: 'center' }}*/>
            <button type="button" className="btn btn-primary me-1" style={styles.buttonStyle} onClick={e => selectAll(true)}>{textWithMnemonic(getStore().getState().desktopMenu[constants.MenuActions.SELECT_ALL]?.label, false, false)}</button>
            <button type="button" className="btn btn-primary" style={styles.buttonStyle} onClick={e => selectAll(false)}>{textWithMnemonic(getStore().getState().desktopMenu[constants.MenuActions.DESELECT_ALL]?.label, false, false)}</button>
        </div>

        <div ref={settingsRef} className="overflow-auto" style={{ maxHeight: "50vh" }}>
            <div id={"colsSettings"} className="list-group">
                {browseColsSorted?.map((columnId, index) => {
                    let visible = (desktopSessionMask[columnId].data?.browseColVisible || desktopSessionMask[columnId].data?.browseColVisible === undefined)
                    if (allColumnsVisible && !visible)
                        allColumnsVisible = false

                    return <Fragment key={"f_bcsi_" + index}>
                        <BrowseColumnsSettingsItem
                            key={ITEM_PREFIX + columnId}
                            label={desktopSessionMask[columnId].data.label}
                            tooltip={desktopSessionMask[columnId].data.tooltip}
                            id={columnId}
                            visible={visible}
                            browseHandle={browseHandle}
                            index={index}
                            browseColsSorted={browseColsSorted}
                            setDividerIndex={setDividerIndex}
                            dragItemOver={dragItemOver} />
                        {(dividerIndex === index) ? <div style={{ height: '1', borderBottom: '2px dashed #aaa' }} /> : null}
                    </Fragment>
                })}
            </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <BSTooltip tooltiptext={getStore().getState().app.messageContainer['dialog.userSettings.okButton']} >
                <ElementWithListener mouseListener={true} outerHtmlClassName={"btn btn-primary mt-1"} style={styles.buttonStyle} doAction={() => {
                    //ulozeni stavu visibility a poradi jednotlivych sloupcu
                    let { columnsOrder, columnsVisible } = getColumnsOrderAndVisibility2()
                    //console.log("columnsOrder", columnsOrder)
                    //console.log("columnsVisible", columnsVisible)

                    sendMessage(createMessage(constants.WebSocketActions.SET_ATTRIBUTE, createRequest({
                        browseHandle: browseHandle,
                        columnsOrder: columnsOrder,
                        columnsVisible: columnsVisible
                    })), response => onClose())
                }}>
                    <i className='fas fa-check me-1'></i>
                    {getStore().getState().app.messageContainer["dialog.userSettings.okButton"]}
                </ElementWithListener>
            </BSTooltip>
        </div>
    </CustomModalDialog >)
}

function areEqual(prevProps, nextProps) {
    return (
        prevProps.isOpen === nextProps.isOpen &&
        prevProps.desktopSessionMask[prevProps.browseHandle].data.browseColsSorted === nextProps.desktopSessionMask[nextProps.browseHandle].data.browseColsSorted &&
        prevProps.desktopSessionMask[prevProps.browseHandle].data[COLUMNS_SETTING_PROP] === nextProps.desktopSessionMask[nextProps.browseHandle].data[COLUMNS_SETTING_PROP] &&
        prevProps.desktopSessionMask[prevProps.browseHandle].data.dialogX === nextProps.desktopSessionMask[nextProps.browseHandle].data.dialogX &&
        prevProps.desktopSessionMask[prevProps.browseHandle].data.dialogY === nextProps.desktopSessionMask[nextProps.browseHandle].data.dialogY
    )
}

export default React.memo(BrowseColumnsSettingsDialog, areEqual)
