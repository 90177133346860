import React from 'react'
import { logRender, logRenderProps } from '../utils/logging.js'
import { isLightTheme } from '../app_actions'

const DarkTheme = React.lazy(() => import('./darkTheme'))
const LightTheme = React.lazy(() => import('./lightTheme'))

function ThemeSelector(props) {
    logRender("ThemeSelector")
    logRenderProps(props)
    return (
        <>
            <React.Suspense fallback={<></>}>
                {(isLightTheme()) ? <LightTheme>{props.children}</LightTheme> : <DarkTheme>{props.children}</DarkTheme>}
            </React.Suspense>

        </>
    )
}

export default ThemeSelector