import { preloadedState } from '../../index'
import { produce } from 'immer'

import { logDebug } from '../../utils/logging'
import Constants from '../../constants/constants'
import {
    ON_VALUE_CHANGE, ON_VALUES_CHANGE, ON_DATA_CHANGE, EMPTY_BROWSE_DATA, SHOW_ABOUT_APP, SHOW_CONTACT, SHOW_SPINNER,
    MOVE_BROWSE_COLUMNS, RESIZE_BROWSE_COLUMN, CHANGE_ALL_COLS_VISIBILITY, SET_INTERRUPT_AVAILABILITY,
    ON_BROWSE_CELL_CHANGED, ON_VALUES_CHANGE_ON_MASK, CHANGE_FOCUS_AREA, ON_DESKTOP_MENU_VALUE_CHANGE,
    ON_DESKTOP_MENU_VALUES_CHANGE,
    RESIZE_BROWSE_COLUMNS
} from '../actions/store_actions'
import {
    ADD_MESSAGE_DIALOG, EMPTY_DESKTOP_STORE, LOGIN, ADD_NAVIGATOR_MENU_ITEMS, REMOVE_MASK,
    REMOVE_MESSAGE_DIALOG, SET_BROWSE_DATA, SET_CONNECTION_DATA, SET_RECONNECT_DATA, SET_DISCONNECT, SET_FOCUS_ID,
    TREE_ITEMS, UPDATE_NAVIGATOR_MENU_ITEM, SET_BROWSE_DATA_AND_SELECT, FAVORITES_ITEMS, HISTORY_ITEMS,
    NAVIGATOR_POPUP, FAVORITE_POPUP, UPDATE_HISTORY_MENU_ITEM, UPDATE_FAVORITES_MENU_ITEM, SET_DASHBOARDS,
    SET_WIDGETS, SET_WIDGET_DATA, SET_ADD_NOTIFICATIONS, REMOVE_NOTIFICATION, SET_NOTIFICATION_OPTIONS, REMOVE_NOTIFICATIONS, HISTORY_POPUP, TRIGGER_BROWSE_GO
} from '../actions/ws_actions'
import { emptyBrowse, setBrowseData, resizeBrowseColumn } from './browse'
import {
    markNavigatorMenuItemOpenImmer, processMenuItemsImmer, processScreenComponentsImmer,
    updateBrowseDataImmer, updateBrowseInputFieldElementImmer, changeFocusArea, resizeBrowseColumns, updateHistoryMenu, updateFavoritesMenu,
} from './immerReducerMethods'
import { focus2Sidebar } from '../../focus_actions'

const { SpecialId, WebSocketActions } = Constants
const { CLOSE_PROGRAM, CLOSE_TAB } = Constants.MenuActions

const reducer = (state, action) => {

    //console.log("TREE");
    //console.log(tree);

    /*console.log("<REDUCER: ")
    console.log("ACTION", action)
    console.log("STATE", state)
    console.log("REDUCER/>")*/
    const { currentTabId, currentMaskID } = state.ws

    //castecne promazani dat pri dropContent == true
    if (action?.screenVariables?.dropContent && action?.screenVariables?.currentMaskID === 0) {
        let prevState = {
            ...state
        }

        state = deleteDesktopStore(state)
        state = {
            ...state,
            desktopMenu: {
                ...prevState.desktopMenu
            },
            app: {
                ...prevState.app
            },
            ws: {
                ...prevState.ws,
                loggedIn: false
            }
        }
    }

    // neexistuje root - vytoreni (muze nastat ze tabname je poslan zvlast, bez komponent, proto se to dela zde)
    if (action.screenVariables?.currentTabId
        && action.screenVariables?.currentMaskID
        && action.screenVariables?.screenLabel) {

        if (action.screenVariables?.screenLabel
            && (state.desktopSession[action.screenVariables?.currentTabId] === undefined || state.desktopSession[action.screenVariables?.currentTabId][action.screenVariables?.currentMaskID] === undefined)) {

            state = {
                ...state,
                desktopSession: {
                    ...state.desktopSession,
                    [action.screenVariables?.currentTabId]: {
                        ...state.desktopSession[action.screenVariables?.currentTabId],
                        [action.screenVariables?.currentMaskID]: {
                            name: action.screenVariables?.screenLabel,
                            0: {
                                id: 0,
                                componentName: "ROOT",
                                childIds: [],
                                data: {}
                            }
                        }
                    }
                }
            }
        }
    }

    switch (action.type) {
        case EMPTY_DESKTOP_STORE:
            return deleteDesktopStore(state);
        /*case REMOVE_TAB:
            //removeTab(state, action);
            const newDesktopSession = Object.fromEntries(
                Object.entries(state.desktopSession).filter(([key]) => key != action.tabId2Remove)
            )
     
            return {
                ...state,
                ws: {
                    ...state.ws,
                    currentTabId: action.newTabId,
                    currentMaskID: action.newMaskId
                },
                desktopSession: newDesktopSession
            };*/
        case REMOVE_MASK:
            const tabContent = Object.fromEntries(
                Object.entries(state.desktopSession[currentTabId]).filter(([key]) => key <= action.newMaskId)
            );

            const newState = {
                ...state,
                desktopSession: {
                    ...state.desktopSession,
                    [state.ws.currentTabId]: {
                        ...tabContent
                    }
                },
                ws: {
                    ...state.ws,
                    currentMaskID: action.newMaskId,
                    focusId: action.newFocusId
                }
            }

            /*console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
            console.log("action", action)
            console.log("state", state)
            console.log("newState", newState)
            console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")*/
            return newState;
        case TREE_ITEMS:
            return processTreeItems(state, action)

        case SET_RECONNECT_DATA:
            return ({
                ...state,
                ws: {
                    ...state.ws,
                    connected: action.payload.connected,
                    serverUUID: action.payload.serverUUID
                }
            })
        case SET_CONNECTION_DATA:
            let appData = action.payload.appData
            let menuItems = {}

            appData?.menuItems?.forEach(item => {
                menuItems = {
                    ...menuItems,
                    [item.action]: {
                        ...item
                    }
                }
            })

            return ({
                ...state,
                app: {
                    ...state.app,
                    ...appData,
                    messageContainer: action.payload.messageContainer,
                    menuItems: {
                        ...menuItems
                    }
                },
                ws: {
                    ...state.ws,
                    connected: action.payload.connected,
                    wsMessage: action.payload.wsMessage,
                    serverUUID: action.payload.serverUUID,
                    clientUUID: action.payload.clientUUID,
                    pid: appData.pid
                }
            })
        case SET_FOCUS_ID:
            /*console.log("SET FOCUS")
            console.log(action)
            console.log(state)*/

            let wsProps = {
                currentMaskID: action.screenVariables.currentMaskID,
                currentTabId: action.screenVariables.currentTabId,
            }

            if (action.screenVariables.focusId != undefined && action.screenVariables.focusId != null)
                wsProps = {
                    ...wsProps,
                    focusId: action.screenVariables.focusId
                }

            state = checkTab2Remove(state, wsProps.currentTabId, wsProps.currentMaskID)

            return ({
                ...state,
                ws: {
                    ...state.ws,
                    ...wsProps
                }
            });
        case LOGIN:
            return ({
                ...state,
                ws: {
                    ...state.ws,
                    loggedIn: true
                }
            });
        case SET_DISCONNECT:
            return ({
                ...state,
                ws: {
                    ...state.ws,
                    connected: action.payload.connected,
                    wsMessage: undefined,
                    //serverUUID: undefined
                }
            })
        case SET_BROWSE_DATA:
            return setBrowseData(state, action, action.type)
        case SET_BROWSE_DATA_AND_SELECT:
            return processTreeItems(setBrowseData(state, action.browseData, action.type), action)
        case ON_BROWSE_CELL_CHANGED:
            return updateBrowseInputFieldElementImmer(state, currentTabId, currentMaskID, action.id, action.newValue, action.rowId)
        case CHANGE_ALL_COLS_VISIBILITY:
            let updHeaders
            state.desktopSession[currentTabId][currentMaskID][action.browseHandle].data.browseColsSorted.split(',').forEach(function (columnHandle, index) {
                updHeaders = {
                    ...updHeaders,
                    [columnHandle]: {
                        ...state.desktopSession[currentTabId][currentMaskID][columnHandle],
                        data: {
                            ...state.desktopSession[currentTabId][currentMaskID][columnHandle].data,
                            browseColVisible: action.visibility
                        }

                    }
                }
            })

            return ({
                ...state,
                desktopSession: {
                    ...state.desktopSession,
                    [state.ws.currentTabId]: {
                        ...state.desktopSession[currentTabId],
                        [currentMaskID]: {
                            ...state.desktopSession[currentTabId][currentMaskID],
                            ...updHeaders
                        }
                    }
                }
            })
        case MOVE_BROWSE_COLUMNS:
            //uprav sloupce sloupcu v kazdem radku
            if (action.from == action.to) return state

            let updRows = state.desktopSession[currentTabId][currentMaskID][action.browseHandle].data.browseData.map(function (row, index) {
                let newColumns
                if (action.from < action.to) {
                    newColumns = row.columns.slice(0, action.from)
                    newColumns.push(...row.columns.slice(action.from + 1, action.to + 1), row.columns[action.from], ...row.columns.slice(action.to + 1))
                } else {
                    newColumns = row.columns.slice(0, action.to)
                    newColumns.push(row.columns[action.from], ...row.columns.slice(action.to, action.from), ...row.columns.slice(action.from + 1))
                }
                return ({
                    ...row,
                    columns: newColumns
                })
            })

            let browseColsTmp = state.desktopSession[state.ws.currentTabId][currentMaskID][action.browseHandle].data.browseColsSorted.split(",")
            let newBrowseCols
            if (action.from < action.to) {
                newBrowseCols = browseColsTmp.slice(0, action.from)
                newBrowseCols.push(...browseColsTmp.slice(action.from + 1, action.to + 1), browseColsTmp[action.from], ...browseColsTmp.slice(action.to + 1))
            } else {
                newBrowseCols = browseColsTmp.slice(0, action.to)
                newBrowseCols.push(browseColsTmp[action.from], ...browseColsTmp.slice(action.to, action.from), ...browseColsTmp.slice(action.from + 1))
            }

            return ({
                ...state,
                desktopSession: {
                    ...state.desktopSession,
                    [state.ws.currentTabId]: {
                        ...state.desktopSession[currentTabId],
                        [currentMaskID]: {
                            ...state.desktopSession[currentTabId][currentMaskID],
                            [action.browseHandle]: {
                                ...state.desktopSession[currentTabId][currentMaskID][action.browseHandle],
                                data: {
                                    ...state.desktopSession[currentTabId][currentMaskID][action.browseHandle].data,
                                    browseColsSorted: newBrowseCols.toString(),
                                    browseData: updRows
                                }
                            }
                        }
                    }
                }
            })

        case RESIZE_BROWSE_COLUMN:
            return resizeBrowseColumn(state, action)
        case RESIZE_BROWSE_COLUMNS:
            return resizeBrowseColumns(state, action)
        case EMPTY_BROWSE_DATA:
            return emptyBrowse(state, action);
        case SHOW_ABOUT_APP:
            return specialIdVisibility(state, SpecialId.ABOUT, action.visibility, action.layer)
        case SHOW_CONTACT:
            return specialIdVisibility(state, SpecialId.CONTACT, action.visibility, action.layer)
        case SHOW_SPINNER:
            return ({
                ...state,
                showSpinner: action.visibility
            });
        case ADD_NAVIGATOR_MENU_ITEMS:
            return {
                ...state,
                desktopNavigatorMenu: {
                    ...action.updatedMenu
                }
            }
        case FAVORITES_ITEMS:
            //console.log("items favourite", action.items)
            return {
                ...state,
                desktopFavoritesMenu: {
                    ...action.items
                }
            }
        case HISTORY_ITEMS:
            return {
                ...state,
                desktopHistoryMenu: {
                    ...Object.values(action.items).map(item => {
                        let rowItemId = item.programName + "_" + item.programParameter

                        let previousMenuItemState
                        if (state.desktopHistoryMenu)
                            Object.values(state.desktopHistoryMenu)?.forEach(item => {
                                if (item.rowItemId === rowItemId)
                                    previousMenuItemState = item
                            })

                        //historie nemá rowItemId, proto se zde vytvari, aby byla zachována stejná funkcionalita jako u favourties a menu items
                        let tempItem = {
                            ...item,
                            ...previousMenuItemState,
                            rowItemId: rowItemId
                        }

                        return tempItem
                    })
                }
            }
        case NAVIGATOR_POPUP:
            let updatedPupup
            if (state.popupNavigator !== undefined) {
                updatedPupup = state.popupNavigator.map(popup => {

                    let item = action.items.find(item => item.event === popup.event)
                    if (action.items.find(item => item.event === popup.event)) {
                        return {
                            ...popup,
                            ...item
                        }
                    } else {
                        return popup
                    }
                })
            } else
                updatedPupup = action.items

            return {
                ...state,
                popupNavigator: updatedPupup
            }
        case FAVORITE_POPUP:
            let updatedFavourites
            if (state.popupFavourites !== undefined) {
                updatedFavourites = state.popupFavourites.map(popup => {

                    let item = action.items.find(item => item.event === popup.event)
                    if (action.items.find(item => item.event === popup.event)) {
                        return {
                            ...popup,
                            ...item
                        }
                    } else {
                        return popup
                    }
                })
            } else
                updatedFavourites = action.items

            return {
                ...state,
                popupFavourites: updatedFavourites
            }

        case HISTORY_POPUP:
            let updatedHistory
            if (state.popupHistory !== undefined) {
                updatedHistory = state.popupHistory.map(popup => {

                    let item = action.items.find(item => item.event === popup.event)
                    if (action.items.find(item => item.event === popup.event)) {
                        return {
                            ...popup,
                            ...item
                        }
                    } else {
                        return popup
                    }
                })
            } else
                updatedHistory = action.items

            return {
                ...state,
                popupHistory: updatedHistory
            }
        case UPDATE_NAVIGATOR_MENU_ITEM:
            let currentlyFocusedNavMenuItemKey = undefined
            if (action.payload.isUserFocused) { //uzivatel vybral mysi polozku navigator menu -> musime smazat starou a oznacit novou
                for (var menuItemKey of Object.keys(state.desktopNavigatorMenu)) {
                    if (state.desktopNavigatorMenu[menuItemKey].isUserFocused) {
                        currentlyFocusedNavMenuItemKey = menuItemKey
                        break
                    }
                }
            }

            if (currentlyFocusedNavMenuItemKey == undefined) { //nemenime oznacenou polozku v navigator menu
                return ({
                    ...state,
                    desktopNavigatorMenu: {
                        ...state.desktopNavigatorMenu,
                        [action.payload.level]: {
                            ...state.desktopNavigatorMenu[action.payload.level],
                            ...action.payload

                        }
                    }

                });
            } else { //uzivatel vybral novou, menime
                return ({
                    ...state,
                    desktopNavigatorMenu: {
                        ...state.desktopNavigatorMenu,
                        [currentlyFocusedNavMenuItemKey]: {
                            ...state.desktopNavigatorMenu[currentlyFocusedNavMenuItemKey],
                            isUserFocused: false
                        },
                        [action.payload.level]: {
                            ...state.desktopNavigatorMenu[action.payload.level],
                            ...action.payload
                        }
                    }

                });
            }
        case UPDATE_HISTORY_MENU_ITEM:
            return updateHistoryMenu(state, action.payload)
        case UPDATE_FAVORITES_MENU_ITEM:
            return updateFavoritesMenu(state, action.payload)
        case ON_VALUE_CHANGE:
            /*console.log("on value change");
            console.log(action);
            console.log(state);*/

            if (action.id === undefined)
                return {
                    ...state,
                    [action.key]: action.value
                }

            return {
                ...state,
                desktopSession: {
                    ...state.desktopSession,
                    [state.ws.currentTabId]: {
                        ...state.desktopSession[currentTabId],
                        [currentMaskID]: {
                            ...state.desktopSession[currentTabId][currentMaskID],
                            [action.id]: {
                                ...state.desktopSession[currentTabId][currentMaskID][action.id],
                                data: {
                                    ...state.desktopSession[currentTabId][currentMaskID][action.id].data,
                                    [action.key]: action.value,
                                }
                            }
                        }
                    }
                }
            }
        case ON_VALUES_CHANGE_ON_MASK:
            /*console.log("on value change in mask")
            console.log(action)
            console.log(state)
            console.log(state.desktopSession[action.tabId][action.maskId][action.id])*/

            if (state.desktopSession[action.tabId] === undefined
                || state.desktopSession[action.tabId][action.maskId] === undefined
                || state.desktopSession[action.tabId][action.maskId][action.id] === undefined)
                return state

            return {
                ...state,
                desktopSession: {
                    ...state.desktopSession,
                    [action.tabId]: {
                        ...state.desktopSession[action.tabId],
                        [action.maskId]: {
                            ...state.desktopSession[action.tabId][action.maskId],
                            [action.id]: {
                                ...state.desktopSession[action.tabId][action.maskId][action.id],
                                data: {
                                    ...state.desktopSession[action.tabId][action.maskId][action.id].data,
                                    ...action.newValues
                                }
                            }
                        }
                    }
                }
            }
        case ON_VALUES_CHANGE:
            //console.log("ON_VALUES_CHANGE", action.newValues)
            return {
                ...state,
                desktopSession: {
                    ...state.desktopSession,
                    [state.ws.currentTabId]: {
                        ...state.desktopSession[currentTabId],
                        [currentMaskID]: {
                            ...state.desktopSession[currentTabId][currentMaskID],
                            [action.id]: {
                                ...state.desktopSession[currentTabId][currentMaskID][action.id],
                                data: {
                                    ...state.desktopSession[currentTabId][currentMaskID][action.id]?.data,
                                    ...action.newValues
                                }
                            }
                        }
                    }
                }
            }
        case ON_DATA_CHANGE:
            //pokud uz zaznam neexistuje
            return {
                ...state,
                desktopSession: {
                    ...state.desktopSession,
                    [state.ws.currentTabId]: {
                        ...state.desktopSession[currentTabId],
                        [currentMaskID]: {
                            ...state.desktopSession[currentTabId][currentMaskID],
                            [action.id]: {
                                ...state.desktopSession[currentTabId][currentMaskID][action.id],
                                data: {
                                    ...state.desktopSession[currentTabId][currentMaskID][action.id].data,
                                    ...action.data
                                }
                            }
                        }
                    }
                }
            }
        case ON_DESKTOP_MENU_VALUES_CHANGE:
            return {
                ...state,
                desktopMenu: {
                    ...state.desktopMenu,
                    [action.handle]: {
                        ...state.desktopMenu[action.handle],
                        ...action.values
                    }
                }
            }
        case ADD_MESSAGE_DIALOG:
            //kopirovani pole
            let newArray = state.messageDialog.slice()

            //vlozeni na zacatek pole, pokud uz neexistuje
            const exists = newArray.find(msg => {
                return (msg.errorMessage === action.payload.errorMessage)
            })

            if (exists == undefined) {
                newArray.splice(0, 0, action.payload)
                return {
                    ...state,
                    messageDialog: newArray
                }
            } else
                return state
        case REMOVE_MESSAGE_DIALOG:
            let rmArray = state.messageDialog.slice()
            rmArray.splice(action.index, 1)
            return {
                ...state,
                messageDialog: rmArray
            }
        case SET_INTERRUPT_AVAILABILITY:
            return {
                ...state,
                desktopMenu: {
                    ...state.desktopMenu,
                    [47]: {
                        ...state.desktopMenu[47],
                        sensitivity: action.sensitivity
                    }
                }
            }
        case CHANGE_FOCUS_AREA:
            return changeFocusArea(state, action.newFocusArea)
        case SET_DASHBOARDS:
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    ...action.payload
                }
            }
        case SET_WIDGETS:
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    dashboards: state.dashboards.dashboards.map((dashboard, index) => {
                        if (dashboard.id === action.payload.dashboardId) {
                            return {
                                ...dashboard,
                                dashboardId: action.payload.dashboardId,
                                widgets: action.payload.widgets.map(widget => {
                                    const widgetOrigin = dashboard.widgets?.find(widgetOrigin => widget.id === widgetOrigin.id)
                                    if (widget.id)
                                        return {
                                            ...widget,
                                            ...widgetOrigin
                                        }
                                })
                            }
                        }

                        return dashboard
                    })
                }
            }
        case SET_WIDGET_DATA:
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    dashboards: state.dashboards.dashboards.map((dashboard, dashboardIndex) => {
                        if (dashboardIndex === action.dashboardIndex) {
                            return {
                                ...dashboard,
                                widgets: state.dashboards.dashboards[dashboardIndex].widgets.map((widget, widgetIndex) => {
                                    if (widgetIndex === action.widgetIndex) {
                                        return {
                                            ...widget,
                                            data: {
                                                ...action.widgetData,
                                                timestamp: new Date().getTime()
                                            }
                                        }
                                    }
                                    return widget
                                })
                            }
                        }

                        return dashboard
                    })
                }
            }
        case SET_ADD_NOTIFICATIONS:
            return {
                ...state,
                ws: {
                    ...state.ws,
                    ...action.screenVariables
                },
                notifications: [...action.notifications.reverse().map(notification => {
                    return {
                        ...notification,
                        timestamp: Date.now(),
                        showed: false
                    }
                }), ...state.notifications]
            }
        case SET_NOTIFICATION_OPTIONS:
            return {
                ...state,
                notifications: state.notifications.map(notification => {
                    if (notification.requestId === action.requestId)
                        return {
                            ...notification,
                            ...action.options
                        }
                    return notification
                })
            }
        case REMOVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: []
            }
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter((notification) => {
                    return notification.requestId !== action.requestId
                })
            }
        case TRIGGER_BROWSE_GO:
            const removeParamFromJsonObject = (objekt, parametr) => {
                // Vytvoření nového objektu bez zvoleného parametru
                const novyObjekt = { ...objekt }
                delete novyObjekt[parametr]
                return novyObjekt
            }

            if (action.doDelete)
                return {
                    ...state,
                    desktopSession: {
                        ...state.desktopSession,
                        [state.ws.currentTabId]: {
                            ...state.desktopSession[state.ws.currentTabId],
                            [currentMaskID]: {
                                ...state.desktopSession[state.ws.currentTabId][state.ws.currentMaskID],
                                [action.browseId]: {
                                    ...removeParamFromJsonObject(state.desktopSession[state.ws.currentTabId][state.ws.currentMaskID][action.browseId], "triggerGoEvent")
                                }
                            }
                        }
                    }
                }
            else
                return {
                    ...state,
                    desktopSession: {
                        ...state.desktopSession,
                        [state.ws.currentTabId]: {
                            ...state.desktopSession[state.ws.currentTabId],
                            [currentMaskID]: {
                                ...state.desktopSession[state.ws.currentTabId][state.ws.currentMaskID],
                                [action.browseId]: {
                                    ...state.desktopSession[state.ws.currentTabId][state.ws.currentMaskID][action.browseId],
                                    triggerGoEvent: true
                                }
                            }
                        }
                    }
                }

        default: {
            if (!action.type.includes("@redux/INIT"))
                logDebug("reducer nezmama akce: " + action.type)

            return state
        }
    }
}

/**
 * Smazat store pri odhlaseni
 * @returns
 */
function deleteDesktopStore(state) {
    return ({
        ...preloadedState,
        ws: {
            ...preloadedState.ws
        },
        popupFavourites: state.popupFavourites,
        popupNavigator: state.popupNavigator,
        smallScreen: state.smallScreen
    })
}

function checkTab2Remove(state, currentTabId, currentMaskID) {
    //console.log(currentTabId, " < ", state.ws.currentTabId)
    if (currentTabId < state.ws.currentTabId) {
        //pokud neexistuje zadna zalozka, tak se provede focus na sidebar
        /*if (currentTabId === 0)
            focus2Sidebar()*/

        if (state.desktopSession[currentTabId] === undefined)
            return state
        if (state.desktopSession[currentTabId][currentMaskID] === undefined)
            return state

        return {
            ...state,
            mnemonic: false,
            desktopSession: {
                //pokud se aktualni cislo tabu snizi, je potreba ho odstranit + oznacit aktulani program
                ...Object.fromEntries(
                    Object.entries(state.desktopSession).filter(([key]) => key <= currentTabId)
                )
            },
            desktopNavigatorMenu: {
                ...markNavigatorMenuItemOpenImmer(state.desktopNavigatorMenu, state.desktopSession[currentTabId][currentMaskID].name)
            },
            ws: {
                ...state.ws,
                currentTabId: currentTabId,
                currentMaskID: currentMaskID
            }
        }
    }

    return state
}

function processTreeItems(state, action) {
    //const startTime = performance.now()
    let { currentMaskID, currentTabId, focusId, screenLabel, sleep, isBreakAllowed } = action.screenVariables

    //TAB    
    state = checkTab2Remove(state, currentTabId, currentMaskID)
    //console.log(`TAB took ${(performance.now() - startTime)}ms`)

    //MASKA - pokud se aktualni cislo masky snizi, je potreba ji odstranit
    //console.log(currentMaskID, " < ", state.ws.currentMaskID)
    if (currentMaskID < state.ws.currentMaskID) {
        state = {
            ...state,
            desktopSession: {
                ...state.desktopSession,
                [currentTabId]: {
                    ...Object.fromEntries(Object.entries(state.desktopSession[currentTabId]).filter(([key]) => {
                        if (Number.isInteger(parseInt(key))) {
                            return key <= currentMaskID
                        }
                        return false
                    }))
                }
            }
        }
    }

    //console.log(`MASKA took ${(performance.now() - startTime)}ms`)
    //oznaceni aktivni polozky menu vlevo v navigatoru pri otevreni noveho tabu
    if (currentTabId > state.ws.currentTabId) {
        state = {
            ...state,
            desktopNavigatorMenu: markNavigatorMenuItemOpenImmer(state.desktopNavigatorMenu, screenLabel)
        }
    }

    //MENU ITEMS
    state = {
        ...state,
        desktopMenu: {
            ...processMenuItemsImmer(state.desktopMenu, action.menuItems)
        }
    }
    //console.log(`MENU ITEMS took ${(performance.now() - startTime)}ms`)

    //COMPONENTS
    //console.log("DRAFT", state)
    state = processScreenComponentsImmer(state, action.payload, currentTabId, currentMaskID, screenLabel)
    //console.log(`COMPONENTS took ${(performance.now() - startTime)}ms`)

    //u techto akci nutne aktulizovat id radek v datech browsu
    state = updateBrowseDataImmer(state, action.payload, currentTabId, currentMaskID, action.request?.rowId)
    //console.log(`UPDATE BROWSE took ${(performance.now() - startTime)}ms`)

    //(de)aktivace plozku zavrit program v menu
    let activateCloseProgramMenuItem = false
    if (currentTabId > 0) {
        activateCloseProgramMenuItem = true
    }

    state = {
        ...state,
        desktopMenu: {
            ...state.desktopMenu,
            [CLOSE_PROGRAM]: {
                ...state.desktopMenu[CLOSE_PROGRAM],
                sensitivity: activateCloseProgramMenuItem
            },
            [CLOSE_TAB]: {
                ...state.desktopMenu[CLOSE_TAB],
                sensitivity: activateCloseProgramMenuItem
            }
        }
    }


    //zmena pomocne promene "loadData" pro scrolovani
    //console.log("REDUCER SCROLLING ACTION", action.action)
    state = produce(state, draft => {
        if (action.action === WebSocketActions.SELECT_BROWSE_ROW_REQUEST
            || action.action === WebSocketActions.SET_VALUE_REQUEST
            || action.action === WebSocketActions.CHOOSE_BROWSE_ROW
            || action.action === WebSocketActions.GET_SCREEN) {

            if (draft.desktopSession[currentTabId]
                && draft.desktopSession[currentTabId][currentMaskID]
                && draft.desktopSession[currentTabId][currentMaskID][focusId]
                && draft.desktopSession[currentTabId][currentMaskID][focusId].data.scrollOptions) {
                draft.desktopSession[currentTabId][currentMaskID][focusId].data.scrollOptions.loadData = true
            }
        }
    })

    //tabId, maskId, sleep, foucusId
    state = produce(state, draft => {
        draft.ws.currentMaskID = currentMaskID
        draft.ws.currentTabId = currentTabId
        draft.ws.sleep = sleep
        if (isBreakAllowed !== undefined && isBreakAllowed !== null)
            draft.ws.isBreakAllowed = isBreakAllowed
        if (focusId !== undefined /*&& focusId !== null*/)
            draft.ws.focusId = focusId

        return draft

    })

    //console.log(`processTreeItems took ${performance.now() - startTime}ms`)
    return state
}

function specialIdVisibility(state, specialId, visibility, layer) {
    let frameElementId
    Object.keys(state.desktopSession[0][0]).forEach((componentName) => {
        if (state.desktopSession[0][0][componentName].data.specialId === specialId)
            frameElementId = state.desktopSession[0][0][componentName].id
    })

    return ({
        ...state,
        desktopSession: {
            ...state.desktopSession,
            [0]: {
                ...state.desktopSession[0],
                [0]: {
                    ...state.desktopSession[0][0],
                    [frameElementId]: {
                        ...state.desktopSession[0][0][frameElementId],
                        data: {
                            ...state.desktopSession[0][0][frameElementId].data,
                            visible: visibility,
                            layer: layer
                        }
                    }
                }
            }
        }
    })
}

//const undoableReducer = undoable(reducer)
export default reducer