import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { WebSocketContext } from '../services/WebSocket'
import Constants from '../constants/constants';
import { wsUpdateNavigatorMenuItem, wsSaveNavigatorMenuItems, wsUpdateHistoryMenuItem, wsUpdateFavoritesMenuItem } from '../store/actions/ws_actions'
import { logRender, logRenderProps } from '../utils/logging'
import { addListener } from '../hooks/desktopListener'
import { MENU_MAIN, MENU_FAVORITE, MENU_HISTORY } from './SideMenu'
import { objectWithoutProperties } from '../utils/actions'
import { focus2Sidebar, focus2SessionMain } from '../focus_actions'

import { LISTENER_TYPE } from '../hooks/desktopListener'
import { getStore } from '..';
const { CLICK, CONTEXT_MENU, MOUSEDOWN } = LISTENER_TYPE

var { ACTION_GET_NAVIGATOR, EXECUTE_PROGRAM_NAVIGATOR_MAIN, EXECUTE_PROGRAM_NAVIGATOR_FAVORITES, EXECUTE_PROGRAM_NAVIGATOR_HISTORY } = Constants.WebSocketActions;
var { RUN } = Constants.MenuEvents


/**
 * Načte položky navigátoru do urovne @levelTo z rootu. Preskoci jiz otevrene submenu
 * @param {*} levelTo
 * @param {*} ws_context
 * @param {*} dispatch
 * @param {*} runMenuItem - zda se ma polozka menu i spustit
 */
export function loadNavigatorDataFromRoot(levelTo, ws_context, dispatch, runMenuItem) {
    let { desktopNavigatorMenu } = getStore().getState();

    loadNavigatorDataFromRootLocal(levelTo, ws_context, desktopNavigatorMenu, dispatch, runMenuItem)
}

function loadNavigatorDataFromRootLocal(levelTo, ws_context, loadedMenuData, dispatch, runMenuItem) {
    let levelToLoad = ""

    let split = levelTo.split(".")

    for (let i = 0; i < split.length; i++) {
        levelToLoad += (levelToLoad === "") ? split[i] : "." + split[i]

        if (loadedMenuData[levelToLoad] == undefined) return //snazim se otevrit neexistujici polozku

        if (i === (split.length - 1)) { //posledni polozka
            let isSubmenu = loadedMenuData[levelToLoad].isMenu

            if (isSubmenu) {
                ws_context.sendMessage(ws_context.createMessage(ACTION_GET_NAVIGATOR, ws_context.createRequest({
                    level: levelToLoad,
                    emptyRows: localStorage.getItem(Constants.LocalStorageKeys.MENU_EMPTY_ROWS) === 'true',
                    saveToRedux: false
                })), response => {
                    loadedMenuData = updateMenuFromNavigatorItems(response.data[0].NavigatorMainDataResponse.rows, levelToLoad, loadedMenuData)
                    return
                })
            }

            //odznac uzivatelky focusovanou polozku menuitem - pohybem sipek
            let menuItemUnfocus = ""

            for (var menuItemKey of Object.keys(loadedMenuData)) {
                if (loadedMenuData[menuItemKey].isUserFocused) {
                    menuItemUnfocus = menuItemKey
                    break
                }
            }

            loadedMenuData = {
                ...loadedMenuData,
                [levelToLoad]: {
                    ...loadedMenuData[levelToLoad],
                    scrollTo: true,
                    isUserFocused: true,
                    run: runMenuItem
                }
            }

            if (levelToLoad != menuItemUnfocus) { //muze se stat, ze se snazim znovu bliknout stejnou polozku. Pokud ano, tak ji neodznacuj
                loadedMenuData = {
                    ...loadedMenuData,
                    [menuItemUnfocus]: {
                        ...loadedMenuData[menuItemUnfocus],
                        isUserFocused: false
                    }
                }
            }

            dispatch(wsSaveNavigatorMenuItems(loadedMenuData))
        } else {
            if (!loadedMenuData[levelToLoad].isOpen) {
                ws_context.sendMessage(ws_context.createMessage(ACTION_GET_NAVIGATOR, ws_context.createRequest({
                    level: levelToLoad,
                    emptyRows: localStorage.getItem(Constants.LocalStorageKeys.MENU_EMPTY_ROWS) === 'true',
                    saveToRedux: false
                })), response => {
                    loadedMenuData = updateMenuFromNavigatorItems(response.data[0].NavigatorMainDataResponse.rows, levelToLoad, loadedMenuData)
                    loadNavigatorDataFromRootLocal(levelTo, ws_context, loadedMenuData, dispatch, runMenuItem)
                    return
                })

                return
            }
        }
    }
}

export function updateMenuFromNavigatorItems(menuItems, parentId, desktopNavigatorMenu) {
    let updatedParent = {
        childIds: [],
        isOpen: true
    }

    menuItems.forEach(value => {
        let newRowId = (parentId === undefined || parentId === "0") ? value.rowId : parentId + "." + value.rowId;

        updatedParent = {
            ...updatedParent,
            childIds: updatedParent.childIds.concat(newRowId)
        }

        desktopNavigatorMenu = ({
            ...desktopNavigatorMenu,
            [newRowId]: {
                ...desktopNavigatorMenu[newRowId],
                ...value,
                parentId: parentId,
                //childIds: [],
                level: newRowId
            }

        });
    })

    desktopNavigatorMenu = ({
        ...desktopNavigatorMenu,
        [parentId]: {
            ...desktopNavigatorMenu[parentId],
            ...updatedParent
        }
    })

    return desktopNavigatorMenu
}
function MenuItem(props) {
    const menuItemRef = React.useRef()
    const ws_context = React.useContext(WebSocketContext)
    const { id, childIds, isAllowed, scrollTo,
        rowItemId, menuType, dispatch, displayContextMenu, run } = props

    const [showHighlight, setShowHighlight] = React.useState(false)
    let lastRun = Date.now() - 300; //aby se program nespustil 2x, kdyz uzivatel udela double click a program se stihne od prvniho kliknuti nacist, tedy znovu posloucha click listener

    function renderChild(childId) {
        return (<ConnectedNode key={"ConnectedNode" + childId} id={childId} parentId={id} menuType={menuType} displayContextMenu={displayContextMenu} />)
    }

    React.useEffect(() => {
        if (scrollTo) {
            const mItem = document.getElementById('menu' + props.rowItemId);
            mItem.scrollIntoView({ behavior: 'smooth', block: "nearest" });

            //highlight efekt pri scrolovani k polozce
            setShowHighlight(true)
            const executerId = setTimeout(() => {
                setShowHighlight(false)
                clearTimeout(executerId)
            }, 1000)

            dispatch(wsUpdateNavigatorMenuItem({
                scrollTo: false,
                level: id
            }))
        }
    }, [scrollTo])

    React.useEffect(() => {
        if (props.isUserFocused) {
            const mItem = document.getElementById('menu' + props.rowItemId);
            mItem.scrollIntoView({ block: "nearest" });
        }
    }, [props.isUserFocused])

    React.useEffect(() => {
        if (run) {
            dispatch(wsUpdateNavigatorMenuItem({
                run: false,
                level: id
            }))
        } else {
            return
        }
        if (isAllowed && !props.isMenu) menuClickListener(true)
    }, [run]) //spusteni programove pomoci props "run" a zarazenim do queue bez ohledu na jeji stav

    //spust program
    function menuClickListener(ignoreQueue) {
        let run = ignoreQueue ? true : ws_context.isQueueEmpty() //obejiti queue
        if (isAllowed && run && (lastRun + 300) <= Date.now()) {
            lastRun = Date.now()
            //zavreni sidebar pri spusteni progarmu na mobilech
            if (window.innerWidth < 991.08) { //hodnota z $grid-breakpoints lg (992 - 0,02px). Tato hodnota se pouziva v media query a detekci mobile device
                document.getElementById("sidebar").classList.toggle('collapsed');
            }

            let action = ""
            switch (menuType) {
                case MENU_MAIN:
                    action = EXECUTE_PROGRAM_NAVIGATOR_MAIN
                    dispatch(wsUpdateNavigatorMenuItem({
                        isUserFocused: true,
                        level: props.level
                    }))
                    break
                case MENU_FAVORITE:
                    action = EXECUTE_PROGRAM_NAVIGATOR_FAVORITES
                    dispatch(wsUpdateFavoritesMenuItem({
                        isUserFocused: true,
                        rowItemId: props.rowItemId
                    }))
                    break
                case MENU_HISTORY:
                    action = EXECUTE_PROGRAM_NAVIGATOR_HISTORY
                    dispatch(wsUpdateHistoryMenuItem({
                        isUserFocused: true,
                        rowItemId: props.rowItemId
                    }))
                    break
                default: break
            }

            let newProps = {
                ...props,
                event: RUN
            }
            newProps = objectWithoutProperties(newProps, "dispatch")
            newProps = objectWithoutProperties(newProps, "displayContextMenu")

            ws_context.sendMessage(ws_context.createMessage(action, ws_context.createRequest({
                ...newProps
            })))
        }
    }

    //klikni na submenu
    function subMenuClickListener() {
        //console.log("subMenuClickListener", hasSidebarFocus())
        focus2Sidebar()

        if (isAllowed && ws_context.isQueueEmpty()) {
            let willOpen = !props.isOpen
            let newProps = {
                ...props,
                isOpen: willOpen,
                isUserFocused: true,
                childIds: willOpen ? props.childIds : []
            }

            newProps = objectWithoutProperties(newProps, "dispatch")
            newProps = objectWithoutProperties(newProps, "displayContextMenu")

            dispatch(wsUpdateNavigatorMenuItem(newProps))

            if (willOpen) {
                ws_context.sendMessage(ws_context.createMessage(ACTION_GET_NAVIGATOR, ws_context.createRequest({
                    level: props.level,
                    emptyRows: localStorage.getItem(Constants.LocalStorageKeys.MENU_EMPTY_ROWS) === 'true'
                })))
            }
        }
    }

    //program
    function menu() {
        //console.log("menu");
        let enabled = isAllowed ? "" : " isDisabled";
        let active = props.isOpen ? " active" : "";
        let isUserFocused = props.isUserFocused ? " sidebar-item-user-focused" : "";

        addListener(MOUSEDOWN, menuItemRef, e => {
            if (e.button === 1) {
                let action, event
                if (props.menuType === MENU_MAIN) {
                    action = EXECUTE_PROGRAM_NAVIGATOR_MAIN
                    event = 6
                } else if (props.menuType === MENU_FAVORITE) {
                    action = EXECUTE_PROGRAM_NAVIGATOR_FAVORITES
                    event = 5
                } else if (props.menuType === MENU_HISTORY) {
                    action = EXECUTE_PROGRAM_NAVIGATOR_HISTORY
                    event = 2
                }

                ws_context.sendMessage(ws_context.createMessage(action, ws_context.createRequest({
                    programName: props.programName,
                    programParameter: props.programParameter,
                    level: props.level,
                    event: event,
                    rowItemId: props.rowItemId
                })))
            }
        })

        addListener(CLICK, menuItemRef, () => menuClickListener(false))
        addListener(CONTEXT_MENU, menuItemRef, e => {
            e.preventDefault()

            let menuItemProps = {
                disabled: !isAllowed,
                programName: props.programName,
                programParameter: props.programParameter,
                rowItemId: props.rowItemId,
                level: props.level,
            }

            displayContextMenu(e, "menu", menuItemProps, menuType)
        })

        return (
            <li className={(showHighlight) ? "blink" : ""} key={"m_it" + props.rowItemId} ref={menuItemRef}>
                <a id={"menu" + props.rowItemId} className={"sidebar-link " + isUserFocused + active + enabled}>
                    {/*<i className="far fa-file-alt"/>*/}
                    <span>
                        {/*→*/} {" "} {props.rowName}
                    </span>
                </a>
            </li>
        )
    }

    //adresar
    function subMenu() {
        let enabled = isAllowed ? "" : " isDisabled";
        let collapsed = props.isOpen ? "" : " collapsed";
        let isUserFocused = props.isUserFocused ? " sidebar-item-user-focused" : "";

        addListener(CLICK, menuItemRef, subMenuClickListener)
        addListener(CONTEXT_MENU, menuItemRef, e => {
            if (isAllowed) {
                e.preventDefault()

                let menuItemProps = {
                    disabled: !isAllowed,
                    rowItemId: props.rowItemId,
                    level: props.level,
                }
                displayContextMenu(e, "submenu", menuItemProps, menuType)
            }
        })

        return (
            <li className="sidebar-item" aria-expanded="true" key={"m_ul" + props.rowItemId}>
                <a id={"menu" + props.rowItemId} className={(showHighlight) ? "sidebar-link blink" + enabled + isUserFocused + collapsed : "sidebar-link" + enabled + isUserFocused + collapsed} data-bs-toggle="collapse" ref={menuItemRef}>
                    {/*<i className="fas fa-folder"/>*/}
                    <span className="align-middle">{props.rowName}</span>
                </a>
                <ul className="sidebar-dropdown" data-bs-parent="#sidebar">
                    {childIds !== undefined ? childIds.map(renderChild) : ""}
                </ul>
            </li>
        )
    }

    logRender("MenuItem")
    logRenderProps(props)

    //root nezobrazovat
    if (props.id === 0)
        return childIds == undefined ? "" : childIds.map(renderChild);

    if (props.isMenu)
        return subMenu()
    else
        return menu()
}

function mapStateToProps(state, ownProps) {
    /*console.log("MenuItem -> mapStateToProps()", state.desktopNavigatorMenu[ownProps.id])
    console.log("MENU STATE", state)
    console.log("MENU OWN PROPS", ownProps)
    */

    switch (ownProps.menuType) {
        case MENU_MAIN: return {
            ...state.desktopNavigatorMenu[ownProps.id],
            displayContextMenu: ownProps.displayContextMenu,
        }
        case MENU_FAVORITE: return {
            ...state.desktopFavoritesMenu[ownProps.id],
            displayContextMenu: ownProps.displayContextMenu
        }
        case MENU_HISTORY: return {
            ...state.desktopHistoryMenu[ownProps.id],
            displayContextMenu: ownProps.displayContextMenu
        }
        default: return
    }
}

function areEqual(prevProps, nextProps) {
    /*console.log("MenuItem prevProps", prevProps)
    console.log("MenuItem nextProps", nextProps)
    console.log("====", (JSON.stringify(prevProps) === JSON.stringify(nextProps)))*/
    return (JSON.stringify(prevProps) === JSON.stringify(nextProps))
}

MenuItem.propTypes = {
    childIds: PropTypes.arrayOf(PropTypes.string),  //["1.1","1.2"]
    isAllowed: PropTypes.bool,
    isMenu: PropTypes.bool,
    level: PropTypes.string,                        //"1.23"
    parentId: PropTypes.string,
    rowId: PropTypes.string,                        //23
    rowItemId: PropTypes.string,
    scrollTo: PropTypes.bool,
    isOpen: PropTypes.bool,
    isUserFocused: PropTypes.bool,                   //kdyz uzivatel listuje skrz navigator pomoci sipek nahoru a dolu, tak se oznacuje polozka na zaklade teto hodnoty. Neni spojeno s APSO focus
    menuType: PropTypes.string,
    run: PropTypes.bool                             //spusteni daneho menu item pomoci programu
}

const ConnectedNode = connect(mapStateToProps)(React.memo(MenuItem, areEqual))
//const ConnectedNode = connect(mapStateToProps)(MenuItem)
export default ConnectedNode