import React from 'react'
import { addListener, LISTENER_TYPE } from './hooks/desktopListener'

/*
    let data = [{
        id: "1",
        label: "Dropdown 1 delsi text"
    },
    {
        id: "2",
        label: "Dropdown 2",
        childrens: [
            {
                id: "2_1",
                parent: "2",
                label: "Dropdown 2_1"
            },
            {
                id: "2_2",
                parent: "2",
                label: "Dropdown 2_2",
                childrens: [
                    {
                        id: "2_2_1",
                        parent: "2_2",
                        label: "Dropdown 2_2_1"
                    },
                ]
            }, {
                id: "2_3",
                parent: "2",
                label: "Dropdown 2_3"
            }
        ]
    },
    {
        id: "3",
        label: "Dropdown 3"
    }, {
        id: "4",
        label: "Dropdown 4"
    }]*/

const DropdownMenu = React.forwardRef(({ xPos, yPos, data, setShowMenu }, ref) => {
    let alignTo = "left"
    const width = window.innerWidth
    if (xPos > width / 3 * 2)
        alignTo = "right"

    if (width - xPos < 280)
        xPos = xPos - 280

    //console.log("render-> DropdownMenu", setShowMenu)
    if (data)
        return <ul ref={ref} className="dropdown-menu webtop-dropdown-items show" style={{ position: 'fixed', left: xPos, top: yPos }}>
            <DropdownItems key={"menu_" + 0} data={data} setShowMenu={setShowMenu} alignTo={alignTo} />
        </ul>
})

export default DropdownMenu

const DropdownItems = ({ data, setShowMenu, alignTo }) => {
    //console.log("render -> DropdownItems", setShowMenu)
    return data.map(({ id, label, icon, childrens, onClick }) => {
        return <DropdownItem id={id} key={id} label={label} childrens={childrens} onClick={onClick} icon={icon} setShowMenu={setShowMenu} alignTo={alignTo} />
    })
}

const DropdownItem = ({ id, label, icon, childrens, onClick, setShowMenu, alignTo }) => {
    const dropdownItemRef = React.useRef()
    const [submenuVisibility, setSubmenuVisibility] = React.useState(false)

    addListener(LISTENER_TYPE.CLICK, dropdownItemRef, e => {
        if (childrens)
            setSubmenuVisibility(!submenuVisibility)
        else {
            setShowMenu(false)
            onClick(e)
        }
    })

    const Submenu = () => {
        const submenuRef = React.useRef()

        React.useEffect(() => {
            if (submenuRef.current)
                submenuRef.current.style["display"] === "block"
        }, [])

        if (childrens)
            return <ul ref={submenuRef} id={"dropdown_submenu_" + id} className="dropdown-menu" style={{
                "--bs-dropdown-link-active-bg": "none",
                display: "block",
                [alignTo]: "100%"
            }}>
                <DropdownItems key={"menu_" + id} data={childrens} setShowMenu={setShowMenu} />
            </ul>
    }

    return <li>
        <a id={id} ref={dropdownItemRef} className="dropdown-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
                {icon ? <div style={{ width: '1.5rem' }}>{icon}</div> : null}
                <div>{label}</div>
            </div>
            <div style={{ paddingLeft: '1.5rem' }}>
                {childrens ? <>&#707;</> : null}
            </div>
        </a>
        {submenuVisibility ? <Submenu /> : null}
    </li>
}