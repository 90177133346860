import React from 'react'

import { ROW_HEIGHT } from './Browse'
import { logRender, logRenderProps } from '../../utils/logging'
import { getMaxLetterWidth, getPxWidthByLetters } from '../../utils/screen'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'
import { getThemeVariables } from '../../theme/getThemeVariables'

const { MOUSEDOWN, MOUSEUP, MOUSE_ENTER, MOUSEOUT, DOUBLE_CLICK, MOUSEMOVE } = LISTENER_TYPE

/*
TODO
- chybi viditelnost resizeru pri zmensovani neexistuje horizontal scrollbar
    - nefunguje nastaveni sirky tabulky v moveHandler() -> tableElementRef.current.style.width
- rozsirit pousouvani sloupce i na radky tabulky, zatim implementovano pouze na hlavicku
- testovat na 22.6 (bez horizontal scrollbaru),
*/
/*byl zvolen zpusob bez reactu, protoze vykreslovani browsu je pomale*/
export default function ColumnResizer(props) {
    const resizerRef = React.useRef()
    const { tableElementRef, colPosition, columnHandle } = props
    let newColWidth

    let pageX = 0, curCol, nxtCol, nxtColWidth, tableWidth, curColWidth, curColHeaderTextWidth, headerTextRef
    let activeResizing = false

    const controller = new AbortController()

    //stavalo se ze se pri onMouseUp nezavolala metoda removeEventListener
    React.useEffect(() => {
        return () => {
            //console.log("REMOVE move handler MOUS MOVE")
            controller.abort()
        }
    }, [])

    addListener(MOUSEDOWN, resizerRef, onResizeClick)
    addListener(MOUSE_ENTER, resizerRef, mouseEnterListener)
    addListener(MOUSEOUT, resizerRef, mouseOutListener)
    addListener(MOUSEUP, document, e => { releaseResizingClick(e, activeResizing) })
    addListener(DOUBLE_CLICK, resizerRef, e => { props.adjustColumnWidth() })

    function onResizeClick(e) {
        //console.log("RESIZE CLICK", e)
        //e.stopPropagation()

        tableWidth = tableElementRef.current.offsetWidth;
        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;

        let padding = paddingDiff(curCol)

        curColWidth = curCol.offsetWidth - padding

        headerTextRef = document.getElementById("header_options_column_" + columnHandle)
        if (headerTextRef)
            curColHeaderTextWidth = headerTextRef.offsetWidth

        if (nxtCol)
            nxtColWidth = nxtCol.offsetWidth - padding

        activeResizing = true
        //console.log("add MOUSEMOVE listener", activeResizing)
        document.addEventListener(MOUSEMOVE, moveHandler, {signal: controller.signal})
    }

    function moveHandler(e) {
        //console.log("MOVE", curCol)
        if (curCol) {
            var diffX = e.pageX - pageX;

            //nastaveni sirky sloupce hlavicky
            //curCol.style.width = (curColWidth + diffX) + 'px'
            newColWidth = curColWidth + diffX

            //nastaveni sirky sloupce(th+td)
            let bodyColumnsTableData = tableElementRef.current.querySelectorAll('[id^="b_col_' + colPosition + '."]')
            //console.log("bodyColumnsTableData", bodyColumnsTableData)
            bodyColumnsTableData.forEach(el => {
                el.style.width = newColWidth + 'px'
                //console.log("NASTAVUJU WIDTH sloupce", newColWidth + "px")
            })

            if (headerTextRef)
                headerTextRef.style.width = curColHeaderTextWidth + diffX + "px"

            //nastaveni sirky tabulky
            tableElementRef.current.style.width = tableWidth + diffX + "px"
            //console.log("NASTAVUJU WIDTH TABULKY", tableWidth + diffX + "px")
            //console.log("MOVE width", tableElementRef.current.style.width)
        }
    }

    function releaseResizingClick(e, resizingInProgress) {
        /*
        //nepouzivat, nefunguje pak odznaceni textu v inputFieldElement s vybranym textem po kliku
        e.preventDefault()
        e.stopPropagation()*/

        //console.log("releaseResizingClick", false)

        if (resizingInProgress) {
            controller.abort()

            curCol = undefined
            nxtCol = undefined
            pageX = 0
            nxtColWidth = undefined
            curColWidth = undefined         

            activeResizing = false

            if (newColWidth !== undefined) {
                let newColWidthInLetters = newColWidth / getMaxLetterWidth() - 1.1

                props.updateColWidth(newColWidthInLetters, props.childIds[colPosition], props, (newColWidthProp) => {
                    newColWidth = newColWidthProp
                })

                props.handleResize(props.sizePerPage)
            }
        }
    }

    function mouseEnterListener(e) {
        e.target.style.backgroundColor = 'var(--bs-light-text-emphasis)'
    }

    function mouseOutListener(e) {
        e.target.style.removeProperty('background-color')
    }

    function newSetWidths(currentWidth, newColWidth) {
        //console.log("setting", getPxWidthByLetters(newColWidth))
        let headerColumnElement = document.getElementById("b_col_" + props.colPosition + "_div")
        //console.log("headerColumnElement", headerColumnElement)

        //nastaveni sirky sloupce(th+td)
        let bodyColumnsTableData = tableElementRef.current.querySelectorAll('[id^="b_col_' + props.colPosition + '."]')
        bodyColumnsTableData.forEach(el => {
            el.style.width = getPxWidthByLetters(newColWidth)
        })

        if (headerColumnElement) {
            let diffX = headerColumnElement.offsetWidth - getPxWidthByLetters(currentWidth, true)

            //nastaveni sirky tabulky
            tableElementRef.current.style.width = tableWidth + diffX + "px"
        }
    }

    function paddingDiff(col) {
        if (getStyleVal(col, 'box-sizing') == 'border-box')
            return 0

        let padLeft = getStyleVal(col, 'padding-left')
        let padRight = getStyleVal(col, 'padding-right')
        return (parseInt(padLeft) + parseInt(padRight))
    }

    function getStyleVal(elm, css) {
        return (window.getComputedStyle(elm, null).getPropertyValue(css))
    }

    let resizerStyle = {
        top: '0',
        right: '0',
        width: '2px',
        position: 'absolute',
        cursor: 'col-resize',
        userSelect: 'none',
        height: ROW_HEIGHT + 'px'
    }

    logRender("ColumnResizer")
    logRenderProps(props)
    return (<div ref={resizerRef} style={resizerStyle} />)

}