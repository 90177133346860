import React from 'react'
import { WebSocketContext } from '../../services/WebSocket'
import constants from '../../constants/constants'

export default function WidgetTable(props) {
    const { data, widget } = props
    const dsTable = data?.dsDWDTable
    const ttTableColumn = dsTable?.ttDWDTableColumn
    const ttTableRow = dsTable?.ttDWDTableRow

    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context

    /*console.log("render table")
    console.log(ttTableColumn)
    console.log(ttTableRow)*/
    //console.log("RENDER WidgetTable", props)
    return <div className="table-responsive" draggable="true" style={{ userSelect: "text" }}>
        <table className='table table-borderless table_widget table-hover' style={{ "--bs-table-border-color": "none" }}>
            <thead >
                <tr>
                    {ttTableColumn?.map(column => {
                        return <th key={column.fieldName} style={{ textAlign: 'left' }}>{column.columnLabel}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {ttTableRow?.map((row, indexRow) => {
                    return <tr key={"widget_table_row_" + indexRow}>
                        {ttTableColumn?.map((column, indexColumn) => {
                            function Field(props) {
                                if (column.fieldType === "button")
                                    return <button type="button" className="btn btn-sm btn-light" onClick={e => {
                                        sendMessage(createMessage(constants.WebSocketActions.SET_WIDGET, createRequest({
                                            action: column.columnAction,
                                            widgetId: widget.id,
                                            data: row[column.keyFieldName]
                                        })))
                                    }} >{row[column.fieldName]}</button>

                                return row[column.fieldName]
                            }

                            function AdditionalField(props) {
                                //colorIncrement

                                function Badge(props) {
                                    const { text, color } = props
                                    return <span className={"badge ms-1 " + getBadgeColor(color)}>{text}</span>
                                }

                                if (column.joinFieldName) {
                                    if (column.joinType === "badge") {
                                        return <Badge
                                            key={"badge_row" + + indexRow + "_" + column.joinFieldName}
                                            text={row[column.joinFieldName]}
                                            color={column.joinColorFieldName === "colorIncrement" ? row[column.joinColorFieldName] : ""} />
                                    }
                                    return " " + row[column.joinFieldName]
                                }

                                return ""
                            }

                            let textAlign = "left"
                            if (typeof column.fieldName === 'number')
                                textAlign = "right"

                            return <td key={"widget_td_" + indexColumn} style={{ textAlign: textAlign }}>
                                <Field key={"field_" + indexRow + "_" + indexColumn} />
                                <AdditionalField key={"additional_field_" + indexRow + "_" + indexColumn} />
                            </td>
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    </div >
}

export function getBadgeColor(color) {
    let badgeColor = "badge-primary-light"
    if (color === "Red")
        badgeColor = "badge-danger-light"
    else if (color === "Green")
        badgeColor = "badge-success-light"
    else if (color === "Yellow")
        badgeColor = "badge-warning-light"
    else if (color === "Grey")
        badgeColor = "badge-secondary-light"

    return badgeColor
}
