import React from 'react'
import { useStore } from 'react-redux'

import constants from '../../constants/constants'
import { WebSocketContext } from '../../services/WebSocket'
import { getStore } from '../..'

import FavoritePrograms from '../FavoritePrograms'
import RecentOpen from '../RecentOpen'
import WidgetListWithLabels from './WidgetListWithLabels'
import WidgetTable from './WidgetTable'
import ShortInfo from './ShortInfo'
import Graph from './Graph'
import CustomCalendar from './CustomCalendar'


function Widget(props) {
    const { dashboard, dashboardIndex, widget, widgetIndex,
        dragItemIndex, dragWidget, targetWidget, isSmallScreen,
        switchingState, setSwitchingState } = props
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage, sendMessageWithoutUpdate } = ws_context
    const widgetData = widget.data
    const store = useStore()
    const app = store.getState().app
    const widgetRef = React.useRef()
    const widgetTileRef = React.useRef()
    const widgeHeadertRef = React.useRef()

    const ACTION_ADD_WIDGET = 2
    const ACTION_DELETE_WIDGET = 3
    const ACTION_MOVE_AFTER_WIDGET = 4
    const ACTION_MOVE_BEFORE_WIDGET = 5

    let dragSended = false

    const MINUTES = 1
    const SECONDS = 60
    const MILLISECONDS = 1000
    React.useEffect(() => {
        //console.log("@@@ USE EFFECT WIDGET", widget.id)
        const intervalId = setInterval(() => {
            refreshWidget()
        }, MINUTES * SECONDS * MILLISECONDS)

        refreshWidget(true)

        return () => {
            clearInterval(intervalId)
        }
    }, [widgetIndex, widget.id])

    function refreshWidget(forceUpdate) {
        if (widget.id > 3) {
            const widgetTimestamp = getStore().getState().dashboards.dashboards[dashboardIndex]?.widgets[widgetIndex]?.data?.timestamp || 0
            let updatedSecondsBefore = (new Date().getTime() - widgetTimestamp) / MILLISECONDS
            //console.log("updatedSecondsBefore", updatedSecondsBefore)
            if (forceUpdate || Math.ceil(updatedSecondsBefore) >= MINUTES * SECONDS)
                sendMessageWithoutUpdate(createMessage(constants.WebSocketActions.GET_WIDGET, createRequest({
                    dashboardIndex: dashboardIndex,
                    dashboardId: dashboard.id,
                    widgetIndex: widgetIndex,
                    widgetId: widget.id
                })))
        }
    }

    //vzor jak muze i vypadat drag and drop grid stack- https://gridstackjs.com/
    let dashboardProps = {
        id: "not_draggable_div_" + dashboard.widgets[widgetIndex].id
    }
    if (dashboard.userDashboard) {
        dashboardProps = {
            ...dashboardProps,
            draggable: "true",
            onDragStart: e => {
                /*console.log("e", e)
                console.log("widget current", widgetRef.current)
                console.log("target", e.target)*/
                if (widgetRef.current.contains(e.target)) {
                    //umozni oznaceni textu uvnitr widgetu
                    e.preventDefault()
                } else {
                    dragWidget.current = widget
                    dragItemIndex.current = widgetIndex
                }
                return true
            },
            onDragEnter: e => {
                dragSended = switchingState?.dragSended ? true : false

                //console.log("ON DRAG ENTER", dragSended, switchingState)
                targetWidget.current = dashboard.widgets[widgetIndex]
                const targetWidgetId = dashboard.widgets[widgetIndex].id
                const currentWidgetId = dragWidget.current.id
                if (currentWidgetId !== targetWidgetId && dragSended === false) {
                    const newSwitchState = { from: dragItemIndex.current, to: widgetIndex }
                    //console.log("switchingState", switchingState)                    

                    function shouldSwitch() {
                        if (switchingState === undefined)
                            return true

                        if (dragWidget.current.width === targetWidget.current.width && dragWidget.current.height === targetWidget.current.height)
                            return true

                        //nejedna se o stejne vylikosti widgetu - nelze dat tam a zpet, je to pozne az po uvolneni tlacitka
                        if (switchingState.from !== newSwitchState.to)
                            return true

                        return false
                    }

                    if (dragItemIndex.current < widgetIndex) {

                        if (shouldSwitch()) {
                            //console.log("-----------------------> davam za", targetWidgetId, dragSended)
                            setSwitchingState({ ...switchingState, dragSended: true })
                            sendMessage(createMessage(constants.WebSocketActions.SET_DASHBOARD, createRequest({
                                action: ACTION_MOVE_AFTER_WIDGET,
                                dashboardId: dashboard.id,
                                widgetId: currentWidgetId,
                                targetWidgetId: targetWidgetId
                            })), response => {
                                setSwitchingState({ ...newSwitchState, dragSended: false })
                                dragItemIndex.current = widgetIndex
                            })
                        }
                    } else {
                        if (shouldSwitch()) {
                            //console.log("-----------------------> davam pred", targetWidgetId, dragSended)
                            setSwitchingState({ ...switchingState, dragSended: true })
                            sendMessage(createMessage(constants.WebSocketActions.SET_DASHBOARD, createRequest({
                                action: ACTION_MOVE_BEFORE_WIDGET,
                                dashboardId: dashboard.id,
                                widgetId: currentWidgetId,
                                targetWidgetId: targetWidgetId
                            })), response => {
                                setSwitchingState({ ...newSwitchState, dragSended: false })
                                dragItemIndex.current = widgetIndex
                            })
                        }
                    }
                }
            },
            onDragOver: e => {
                //console.log("drag over")
                e.preventDefault()
            },
            onDragEnd: e => {
                //console.log("drag end")

                e.dataTransfer.effectAllowed = "move"
                document.body.style.cursor = 'normal'
                setSwitchingState()
            }
        }
    }

    let headerStyle = {
        display: "flex",
        justifyContent: "space-between",
    }
    if (dashboard.userDashboard)
        headerStyle = {
            ...headerStyle,
            cursor: "grab"
        }

    let bodyStyle = {
        userSelect: "text"
    }

    //console.log("render -> widget", widgetIndex, widget)
    return <div
        ref={widgetTileRef}
        className='card card-body'
        {...dashboardProps}
        index={widgetIndex}
        style={{
            gridRow: "span " + widget.height,
            gridColumn: isSmallScreen ? "span 1" : "span " + widget.width,
            padding: '0 var(--bs-card-spacer-x) var(--bs-card-spacer-y) var(--bs-card-spacer-x)'
        }}>
        {/*<div className='lead' style={{ fontWeight: 400 }}>
            {widget.title}
        </div>
    <hr style={{ margin: '.4rem' }} />*/}

        <div ref={widgeHeadertRef} style={headerStyle}>
            <p style={{
                color: 'var(--bs-body-color)',
                fontWeight: 700,
                paddingTop: "var(--bs-card-spacer-y)"
            }}>
                {widget.title}
            </p>
            <div className="dropdown" style={{ paddingTop: "var(--bs-card-spacer-y)" }}>
                <a className="dropdown-toggle dropdown-icon-button" href="#" data-bs-toggle="dropdown" tabIndex={-1}>
                    <i className="fas fa-ellipsis-v" />
                </a>
                <ul className="dropdown-menu">
                    {(() => {
                        let menuItems = []
                        menuItems.push(<li key={"dashboard_option_1"}>
                            <a className="dropdown-item" href="#" onClick={e => {
                                refreshWidget(true)
                            }}>{app.messageContainer[constants.MessageContainer.UPDATE_WIDGET]}</a>
                        </li>)
                        if (dashboard.userDashboard) {
                            menuItems.push(<li key={"dashboard_option_2"}><a className="dropdown-item" href="#" onClick={e => {
                                sendMessage(createMessage(constants.WebSocketActions.SET_DASHBOARD, createRequest({
                                    action: ACTION_DELETE_WIDGET,
                                    dashboardId: dashboard.id,
                                    widgetId: widget.id
                                })))
                            }}>{app.messageContainer[constants.MessageContainer.REMOVE_WIDGET_FROM_USER_DASHBOARD]}</a></li>)
                        } else {
                            menuItems.push(<li key={"dashboard_option_3"}><a className="dropdown-item" href="#" onClick={e => {
                                sendMessage(createMessage(constants.WebSocketActions.SET_DASHBOARD, createRequest({
                                    action: ACTION_ADD_WIDGET,
                                    dashboardId: dashboard.id,
                                    widgetId: widget.id
                                })))
                            }}>{app.messageContainer[constants.MessageContainer.ADD_WIDGET_TO_USER_DASHBOARD]}</a></li>)
                        }
                        return menuItems
                    })()}
                </ul>
            </div>
        </div>

        <div ref={widgetRef} style={bodyStyle} className='overflow-auto'>
            {(() => {
                if (widget.id === 1)
                    return <FavoritePrograms key={"widget_favorites_" + widget.id} />
                else if (widget.id === 2)
                    return <RecentOpen key={"widget_recent_open_" + widget.id} />
                else if (widget.id === 3)
                    return <CustomCalendar key={"widget_recent_open_" + widget.id} widgeHeadertRef={widgeHeadertRef} widgetTileRef={widgetTileRef} />
                else if (widget.id === 4)
                    return <WidgetTable key={"widget_table_" + widget.id} data={widgetData} widget={widget} />
                else if (widget.id === 5)
                    return <WidgetListWithLabels key={"widget_list_" + widget.id} data={widgetData} />
                else if (widget.id === 6)
                    return <WidgetListWithLabels key={"widget_list_" + widget.id} data={widgetData} />
                else if (widget.id === 8)
                    return <Graph key={"graph_" + widget.id} data={widgetData} widgeHeadertRef={widgeHeadertRef} widgetTileRef={widgetTileRef} />

                if (widgetData) {
                    const keysArray = Object.keys(widgetData)
                    return keysArray?.map(tt => {
                        if (tt === "ttDWDShortInfo") {
                            return <ShortInfo key={"widget_short_info" + widget.id} data={widgetData.ttDWDShortInfo[0]} />
                        }
                    })
                }

                return widget.id + ": " + widget.title
            })()}
        </div>
    </div >
}

export default Widget

/*function areEqual(prevProps, nextProps) {
    console.log("ARE EQUAL ", prevProps.widgetIndex === nextProps.widgetIndex)
    console.log(prevProps, nextProps)
    //console.log("ARE EQUAL", prevProps.widget.data === nextProps.widget.data, prevProps, nextProps)
    return prevProps.widgetIndex === nextProps.widgetIndex && prevProps.widget.data === nextProps.widget.data
}

export default React.memo(Widget, areEqual)*/
