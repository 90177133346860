import React from 'react'
import { connect } from 'react-redux'

import { getStore } from '../../index.js'
import { parentSensitivity } from './elementMethods'
import Constants from '../../constants/constants'
import useFocusObserver from '../../hooks/useFocusObserver.js'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import { logRender, logRenderProps } from '../../utils/logging'
import { getEmptyRowProp } from '../../utils/actions.js'

export function handleToggleBoxElementOnChange(event, ws_context) {
    const { createMessage, createRequest, sendMessage } = ws_context

    let store = getStore().getState()
    let ws = store.ws
    let desktopSessionMask = store.desktopSession[ws.currentTabId][ws.currentMaskID]
    let handle = event.target.id

    //nastaveni opacne hodnoty z formatu
    const toggleBoxData = desktopSessionMask[handle].data
    const formatArray = toggleBoxData.format.split("/")
    //console.log("formatArray", formatArray)
    let newValue = formatArray.indexOf(toggleBoxData.value) === 0 ? formatArray[1] : formatArray[0]

    let value
    if (ws.focusId?.toString() !== handle?.toString())
        value = { value: desktopSessionMask[ws.focusId].data.value }

    sendMessage(createMessage(Constants.WebSocketActions.SET_VALUE_REQUEST, createRequest({
        ...getEmptyRowProp(),
        handle: ws.focusId,
        newFocus: handle,
        newFocusValue: newValue,
        action: Constants.SetValueRequest.VALUE_CHANGED,
        ...value
    })))
}

function ToggleBoxElement(props) {
    const { data } = props
    const formatArray = data.format.split("/")
    const isChecked = formatArray.indexOf(data.value)

    let ws, desktopSessionMask
    useStoreSubscription(store => {
        ws = store.ws
        desktopSessionMask = store.desktopSession[ws.currentTabId][ws.currentMaskID]
    })

    useFocusObserver(props.id, "ToggleBoxElement")

    logRender("ToggleBoxElement")
    logRenderProps(props)
    return (
        <div className="form-check">
            <input className="form-check-input"
                id={data.handle}
                type="checkbox"
                //defaultChecked={isChecked === 0 ? true : false} //nefuguje nutne pouzit checked
                value={data.value}
                onChange={e => {

                }}
                checked={isChecked === 0 ? true : false}
                disabled={data.sensitivity === false || !props.parentSensitivity}
            />
            <label className="form-check-label" htmlFor={data.handle}>{data.label}</label>
        </div>
    )
}

function mapStateToProps(state, ownProps) {
    return {
        ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id],
        parentSensitivity: parentSensitivity(ownProps.id, ownProps.tabId, ownProps.maskId)
    }
}


export default connect(mapStateToProps)(ToggleBoxElement)