import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { onValuesChangeInMask } from '../store/actions/store_actions'

export default function useDialogDrag(modalRoot, props, refDialog, refHeaderDialog, refContentDialog, dialogX, dialogY) {
    let dialogPositionDiff = { x: 0, y: 0 }
    let isDragging = false
    const { id, maskId, tabId } = props
    const dispatch = useDispatch()

    let pos = { x: 0, y: 0 }
    if (dialogX !== undefined)
        pos.x = dialogX
    if (dialogY !== undefined)
        pos.y = dialogY

    function setPos(coorinates) {
        refDialog.current.style.top = coorinates.y + 'px'
        refDialog.current.style.left = coorinates.x + 'px'
    }

    function startDrag(x, y, event) {
        isDragging = true

        if (!isDragging) return

        pos.x = refDialog.current.getBoundingClientRect().x
        pos.y = refDialog.current.getBoundingClientRect().y

        dialogPositionDiff.x = x - pos.x
        dialogPositionDiff.y = y - pos.y

        setPos(pos)
    }

    function moveAction(x, y, event) {
        if (!isDragging)
            return

        pos.x = x - dialogPositionDiff.x
        pos.y = y - dialogPositionDiff.y
        setPos(pos)
    }

    const onMouseDown = event => startDrag(event.x, event.y, event)
    const onMouseMove = event => moveAction(event.x, event.y, event)
    const onMouseUp = event => isDragging = false

    const onTouchStart = event => startDrag(event.touches[0].clientX, event.touches[0].clientY, event)
    const onTouchMove = event => moveAction(event.touches[0].clientX, event.touches[0].clientY, event)
    const onTouchEnd = event => isDragging = false


    //console.log("props", props)
    /* pro dotykove obrazovky
        - obsahuje oblast pro skrolovani =>  listener pouze na hlavicku
        - neobsahuje oblast pro skrolovani =>  listener na cely dialog   */
    useEffect(() => {
        const controller = new AbortController()

        let isScrollEnabled = false
        if (refContentDialog && refContentDialog.current) {
            isScrollEnabled = refContentDialog.current.scrollWidth > refContentDialog.current.clientWidth ||
                refContentDialog.current.scrollHeight > refContentDialog.current.clientHeight
            //console.log(refContentDialog, refContentDialog.current)
        }


        //console.log("Is scrolling enabled: " + isScrollEnabled)

        if (refDialog.current) {
            if (pos.x === 0 && pos.y === 0)
                setPos({
                    x: (modalRoot.getBoundingClientRect().width - refDialog.current.getBoundingClientRect().width) / 2,
                    y: (modalRoot.getBoundingClientRect().height - refDialog.current.getBoundingClientRect().height) / 2
                })
            else
                setPos({ x: pos.x, y: pos.y })
        }

        document.addEventListener("mouseup", onMouseUp, { signal: controller.signal })
        document.addEventListener("mousemove", onMouseMove, { signal: controller.signal })

        if (refHeaderDialog.current)
            refHeaderDialog.current.addEventListener("mousedown", onMouseDown, { signal: controller.signal })



        if (isScrollEnabled) {
            if (refHeaderDialog.current) {
                refHeaderDialog.current.addEventListener("touchmove", onTouchMove, { signal: controller.signal, passive: true })
                refHeaderDialog.current.addEventListener("touchstart", onTouchStart, { signal: controller.signal, passive: true })
                refHeaderDialog.current.addEventListener("touchend", onTouchEnd, { signal: controller.signal })
            }
        } else {
            if (refDialog.current) {
                refDialog.current.addEventListener("touchmove", onTouchMove, { signal: controller.signal, passive: true })
                refDialog.current.addEventListener("touchstart", onTouchStart, { signal: controller.signal, passive: true })
                refDialog.current.addEventListener("touchend", onTouchEnd, { signal: controller.signal })
            }
        }

        return () => {
            //nastaveni poslednich souradnic, pokud se znovu bude vykreslovat(visibility=true), tak se pouziji tyto souradnice
            if (dialogX !== pos.x && dialogY !== pos.y && pos.x !== 0 && pos.y !== 0) {
                if (dispatch)
                    dispatch(onValuesChangeInMask(id, tabId, maskId, {
                        dialogX: pos.x,
                        dialogY: pos.y
                    }))
            }

            controller.abort()
        }
    })
}