import dark from '../resources/scss/1-variables/dark_vars.module.scss'
import light from '../resources/scss/1-variables/light_vars.module.scss'

import bootstrap from '../resources/scss/1-variables/bootstrap.module.scss'

import { isLightTheme } from '../app_actions'

/*vrací proměnné, které jsou odlišné dle použitého témetu. Jsou definovany v export souborech /resources/scss/1-variables/dark_vars.module.scss a /resources/scss/1-variables/light_vars.module.scss*/
export function getThemeVariables() {
    if (isLightTheme())
        return light

    return dark
}

/*vrací bootstrap proměnné, ktere jsou definovane pro export v souboru '../theme/getThemeVariables.js'*/
export function getBootstrapVariables() {
    return bootstrap
}