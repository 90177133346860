import React, { useContext } from 'react'
import { connect, useStore } from 'react-redux'

import { WebSocketContext } from '../../services/WebSocket'

import { parentSensitivity } from './elementMethods'
import { logRender, logRenderProps } from '../../utils/logging'
import Constants from '../../constants/constants'
import { onValuesChangeInMask, onValuesChangeByHandle } from '../../store/actions/store_actions'

import useFocusObserver from '../../hooks/useFocusObserver'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'
import BSTooltip from '../BSTooltip'
import ElementWithListener from '../ElementWithListener'
import { textWithMnemonic } from '../../utils/screen'
import { getEmptyRowProp } from '../../utils/actions'
import { getStore } from '../..'

const { KEYDOWN } = LISTENER_TYPE
const { DOWNLOAD_CHUNK } = Constants.WebSocketActions
const ROWS = "5"

/*
ukladni textu - provadi se v WebSocket.js-> sleep0()
    -> po stisknuti tlacitka ok (set_value_request) se vrati screen description
       s parametrem sleep=0 + EditorElement s parametrem save=true

server requests:
    focus na editor elementu
    -> SET_ATTRIBUTE:{editorHandle=1641,modified=true,cursorOffset=17}
    -> REQ_SETVAL:{id=1641,val=null,focs=1637,act=4}
    -> ResultUploadRequest*/
function EditorElement(props) {
    const ws_context = useContext(WebSocketContext)
    const ref = React.useRef(null)
    const { data, dispatch, tabId, maskId, sessionHeight } = props
    const { value, cursorOffset, dataFetched } = data
    const store = useStore()
    const [editorHeight, setEditorHeight] = React.useState()
    const [userEditorHeight, setUserEditorHeight] = React.useState(() => {
        if (dialogKey)
            return localStorage.getItem(dialogKey)
        else
            return getStore().getState().desktopSession[tabId][maskId][props.id]?.data?.editorHeight
    })

    const [editorData, setEditorData] = React.useState({
        value: value,
        cursorOffset: cursorOffset - 1
    })
    const { sendMessage, createMessage, createRequest } = ws_context

    let dialogKey = props.parentElement?.data?.dialogKey
    const tooltipId = "t_" + props.id
    const tooltipIdOffer = "o_" + props.id

    //resizing of editor element
    let modifiedEditorHeight = editorHeight
    const observer = React.useRef(
        new ResizeObserver(entries => {
            if (ref.current) {
                modifiedEditorHeight = Math.floor(ref.current.offsetHeight)
            }
        })
    )

    function getEditorValue() {
        return editorData
    }

    const handlerRef = React.useRef(getEditorValue)
    React.useEffect(() => {
        handlerRef.current = getEditorValue
    })

    //nastaveni hodnot z reduceru
    React.useEffect(() => {
        //console.log("nastavuju value", value)
        setEditorData({
            value: value,
            cursorOffset: cursorOffset
        })
    }, [value])


    React.useEffect(() => {
        let emptySpace = getScreenEmptySpace()
        let verticalScale = props.data.verticalScale

        function isInDialog(handle) {
            let component = props.desktopSessionMask[handle]
            if (component?.componentName === "DialogElement" || component?.componentName === "ModalDialogElement")
                return true

            if (component.data.parentHandle === 0)
                return false

            return isInDialog(component.data.parentHandle)
        }

        if (!isInDialog(props.data.parentHandle) && userEditorHeight === undefined && (emptySpace > 32 || emptySpace < 0))
            if (verticalScale > 0) {
                let newHeight = Math.floor(emptySpace / verticalScale + ref.current.offsetHeight)
                if (editorHeight !== newHeight) {
                    //muze se stat, ze framy jsou pres sebe a je spocitana vyska spatne, tak se prebere vyska editoru z element props
                    let parentHeight = document.getElementById("element_" + props.id)?.getBoundingClientRect()?.height
                    if (parentHeight > newHeight)
                        setEditorHeight(parentHeight)
                    else
                        setEditorHeight(newHeight)
                }
            }
    })

    function getScreenEmptySpace() {
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)

        let rootHeight = sessionHeight * rootFontSize
        let maskFrame = document.getElementById("fragment_0")
        let freeSpace = rootHeight - maskFrame.getBoundingClientRect().height

        const defaultTextAreaHeight = 48
        //console.log("getScreenEmptySpace", freeSpace - defaultTextAreaHeight)
        return freeSpace - defaultTextAreaHeight
    }

    React.useEffect(() => {
        const controller = new AbortController()

        if (ref.current) {
            observer.current.observe(ref.current)
            ref.current.addEventListener(LISTENER_TYPE.CLICK, e => {
                /*editorHeight !== undefined*/
                if (e.target.id.toString() === props.id.toString() && window.getSelection().toString() === "" && editorHeight !== modifiedEditorHeight) {
                    if (dialogKey)
                        localStorage.setItem(dialogKey, modifiedEditorHeight)
                    else {
                        dispatch(onValuesChangeInMask(data.handle, tabId, maskId, { editorHeight: modifiedEditorHeight }))
                        setUserEditorHeight(modifiedEditorHeight)
                    }
                }
            }, { signal: controller.signal })
        }

        return () => {
            if (ref.current)
                observer.current.unobserve(ref.current)

            controller.abort()
        }

    }, [ref])

    React.useEffect(() => {
        ref.current.setSelectionRange(editorData.cursorOffset, editorData.cursorOffset)
    })

    //nacte data editor elementu po kouscich
    React.useEffect(() => {
        if (editorData.value === undefined && dataFetched !== true) {
            var editorElementText = ""
            var downloadChunkRequest = {
                offset: 0,
                filePath: data.sourceFileName,
                isPlainText: true,
                key: "",
                isEditor: true
            }

            const downloadTextFile = () => {
                //console.log("downloadTextFile")
                sendMessage(createMessage(DOWNLOAD_CHUNK, createRequest({
                    DownloadChunkRequest: {
                        ...downloadChunkRequest
                    }
                })), callback)
            }

            const callback = response => {
                response.data.forEach(response => {
                    let responseEntity = Object.keys(response)[0]
                    if (responseEntity === "DownloadChunkResponse") {
                        let downloadChunkResponse = response["DownloadChunkResponse"]
                        //console.log("DOWNLOAD CHUNK RESPONSE", downloadChunkResponse)
                        editorElementText = editorElementText + downloadChunkResponse.fileData

                        if (downloadChunkResponse.lastChunk) {
                            //hotovo
                            setEditorData({
                                value: editorElementText,
                                cursorOffset: cursorOffset,
                            })

                            dispatch(onValuesChangeByHandle(props.id, {
                                value: editorElementText,
                                dataFetched: true,
                                modified: false
                            }))
                        }

                        //download next chunk / potvrzeni stazeni
                        downloadChunkRequest = {
                            ...downloadChunkRequest,
                            offset: downloadChunkRequest.offset + 1,
                            lastChunk: downloadChunkResponse.lastChunk,
                        }
                        downloadTextFile()
                    }
                })
            }

            if (dataFetched !== true)
                downloadTextFile()
        }

        //ulozeni hodnoty, pokud se napriklad otevre novy tab
        const updatedHandler = e => handlerRef.current()
        return () => {
            if (getStore().getState().desktopSession[tabId] !== undefined && getStore().getState().desktopSession[tabId][maskId] !== undefined) {
                let val = getStore().getState().desktopSession[tabId][maskId][props.id]?.data?.value
                if (!data.readOnly && data.sensitivity && updatedHandler().value !== undefined && updatedHandler().value !== val) {
                    dispatch(onValuesChangeInMask(data.handle, tabId, maskId, updatedHandler()))
                }
            }

            //aby se pri onchange zmenila hodnota
            if (props.data.modified)
                dispatch(onValuesChangeByHandle(props.id, {
                    modified: false
                }))
        }

    }, [])

    addListener(KEYDOWN, ref, (e) => {
        if (e.key === "Home") {
            e.stopPropagation() //aby se v masce s Editorem nespoustela tlacitka pod browsem (skocit na prvni zaznam), ale fungovala defaultni chovani textu v Editoru
        } else if (e.key === "End") {
            e.stopPropagation() //aby se v masce s Editorem nespoustela tlacitka pod browsem (skocit na posledni zaznam), ale fungovala defaultni chovani textu v Editoru
        }
    })

    useFocusObserver(props.id, "EditorElement")

    function showOfferTriangle() {
        let offerTooltip = textWithMnemonic(store.getState().desktopMenu[Constants.MenuActions.OFFER]?.tooltip, false)
        let offerShortcut = store.getState().desktopMenu[Constants.MenuActions.OFFER]?.accelerator

        if ((data.hasValuesOffer && data.sensitivity) || (data.hasValuesOffer & data.readOnly === false))
            return <BSTooltip id={tooltipIdOffer} tooltiptext={offerTooltip} shortcuts={[offerShortcut]}>
                <ElementWithListener
                    tooltipIdOffer={tooltipIdOffer}
                    doAction={() => {
                        //console.log("DO ACTION", MENU_ACTION_OFFER)
                        if (props.id !== currentFocusId) {
                            doEntry(props.id, (response) => {
                                sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                                    ...getEmptyRowProp(),
                                    handle: props.id,
                                    newFocus: MENU_ACTION_OFFER,
                                    value: getElementData(props.id).value,
                                    action: MENU_ACTION
                                })))
                            })
                        } else
                            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                                ...getEmptyRowProp(),
                                handle: props.id,
                                newFocus: MENU_ACTION_OFFER,
                                value: getElementData(props.id).value,
                                action: MENU_ACTION
                            })))
                    }}
                    outerHtmlClassName="red-triangle"
                    mouseListener={true} />
            </BSTooltip>
    }

    logRender("EditorElement")
    logRenderProps(props)
    //console.log("------> render editor", props)
    //console.log(editorData)
    //console.log("FRAGMENT NULA: ", getStore().getState().desktopSession[tabId][maskId][0])
    //console.log("------> REDNER EDITOR", props.id, editorHeight, modifiedEditorHeight)

    let height = editorHeight
    if (userEditorHeight)
        height = userEditorHeight

    return (
        <BSTooltip id={tooltipId}>
            <div className="input-group input-group-sm">
                <textarea
                    id={data.handle}
                    ref={ref}
                    style={{
                        height: (height ? height + "px" : "100%"),
                        whiteSpace: 'pre',
                    }}
                    tabIndex="0"
                    title={props.data.help}
                    className={"form-control form-control-sm" + ((data.proportionalFont === false) ? "font-monospace" : "")}
                    readOnly={!data.sensitivity || data.readOnly || !props.parentSensitivity}
                    rows={props.data.height !== undefined ? props.data.height : ROWS}
                    value={editorData.value}
                    onBlur={e => {
                        //console.log("ON BLUR")
                        if (getStore().getState().desktopSession[tabId] !== undefined && getStore().getState().desktopSession[tabId][maskId] !== undefined) {
                            let val = getStore().getState().desktopSession[tabId][maskId][props.id]?.data?.value
                            if (!data.readOnly && data.sensitivity && editorData !== undefined && editorData !== val) {
                                setEditorData({
                                    value: e.target.value,
                                    cursorOffset: e.target.selectionStart,
                                })

                                dispatch(onValuesChangeInMask(data.handle, tabId, maskId, editorData))
                            }
                        }
                    }}
                    onChange={(e) => {
                        //console.log("onChange", e.target.value)
                        setEditorData({
                            value: e.target.value,
                            cursorOffset: e.target.selectionStart
                        })

                        if (getStore().getState().desktopSession[tabId][maskId][props.id]?.data?.modified !== true) {
                            dispatch(onValuesChangeByHandle(props.id, {
                                modified: true
                            }))

                            sendMessage(createMessage(Constants.WebSocketActions.SET_ATTRIBUTE, createRequest({
                                editorHandle: data.handle,
                                modified: true,
                                cursorOffset: e.target.selectionStart + 1
                            })))
                        }

                    }}
                />

                {showOfferTriangle()}
            </div>
        </BSTooltip>
    )
}

function mapStateToProps(state, ownProps) {
    //console.log("MAP STATE PROPS", state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id])
    let editor = state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id]
    return {
        ...editor,
        parentSensitivity: parentSensitivity(ownProps.id, ownProps.tabId, ownProps.maskId),
        parentElement: state.desktopSession[ownProps.tabId][ownProps.maskId][editor.data.parentHandle],
        [Constants.InternalElementState.SESSION_HEIGHT]: state[Constants.InternalElementState.SESSION_HEIGHT]
    }
}

function areEqual(prevProps, nextProps) {
    /*console.log("prevProps", prevProps.data.value, prevProps)
    console.log("nextProps", nextProps.data.value, nextProps)
    console.log("ARE EQUAL", (prevProps.data.value === nextProps.data.value), prevProps.data.cursorOffset === nextProps.data.cursorOffset)*/
    return (prevProps.data.value === nextProps.data.value
        && prevProps.data.cursorOffset === nextProps.data.cursorOffset
        && prevProps.data.sensitivity === nextProps.data.sensitivity
        && prevProps.parentSensitivity === nextProps.parentSensitivity
        && prevProps.data.readOnly === nextProps.data.readOnly
        && prevProps[Constants.InternalElementState.SESSION_HEIGHT] === nextProps[Constants.InternalElementState.SESSION_HEIGHT]
        && prevProps.data.editorHeight === nextProps.data.editorHeight)
}

export default connect(mapStateToProps)(React.memo(EditorElement, areEqual))