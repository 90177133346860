import React from 'react'
import { useDispatch } from 'react-redux'

import Constants from '../../constants/constants'
import { WebSocketContext } from '../../services/WebSocket'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import { logRender, logRenderProps } from '../../utils/logging'
import { addListener } from '../../hooks/desktopListener'
import { textWithMnemonic } from '../../utils/screen'
import { getEmptyRowProp } from '../../utils/actions'

const { REFRESH_SCREEN, EXPORT_2_XML, SEARCH, PRINT, DEFAULT_TABLE_COLUMNS_ORDER,
    SUM_OF_TABLE, DEFAULT_TABLE_ROW_ACTION, OPTIONS, FILTER_OPTIONAL, ATTACHED_DOCUMENTS } = Constants.MenuActions
const { MENU_ACTION } = Constants.SetValueRequest
const { SET_VALUE_REQUEST } = Constants.WebSocketActions

import { LISTENER_TYPE } from '../../hooks/desktopListener'
import BSTooltip from '../BSTooltip'
import { COLUMNS_SETTING_PROP } from './BrowseColumnsSettingsDialog'
import { onValuesChangeInMask } from '../../store/actions/store_actions'
import styles from '../../constants/styles'
const { CLICK } = LISTENER_TYPE

/*tlacitka pro spusteni funkci nad browsem:
    -   export do pdf, tisk, filtr, refresh*/
export default function TableActionButtons(props) {
    const dispatch = useDispatch()

    const refreshBtn = React.useRef()
    const searchBtn = React.useRef()
    const filterBtn = React.useRef()
    const printBtn = React.useRef()
    const exportXmlBtn = React.useRef()
    const optionsBtn = React.useRef()
    const defaultTableRowActionBtn = React.useRef()
    const sumOfTableBtn = React.useRef()
    const defaultTableColumnsOrderBtn = React.useRef()
    const attachedDocumentsBtn = React.useRef()

    const { desktopMenu, desktopSessionMask, browseId, tabId, maskId } = props
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessage } = ws_context
    let btnStyle = "btn btn-primary"

    let currentFocusId, messageContainer
    useStoreSubscription(store => {
        currentFocusId = store.ws.focusId
        messageContainer = store.app.messageContainer
    })

    function doMenuAction(action) {
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: currentFocusId,
            newFocus: action,
            action: MENU_ACTION
        })))
    }

    addListener(CLICK, refreshBtn, () => doMenuAction(REFRESH_SCREEN))
    addListener(CLICK, searchBtn, () => doMenuAction(SEARCH))
    addListener(CLICK, filterBtn, () => doMenuAction(FILTER_OPTIONAL))
    addListener(CLICK, printBtn, () => doMenuAction(PRINT))
    addListener(CLICK, exportXmlBtn, () => doMenuAction(EXPORT_2_XML))
    addListener(CLICK, optionsBtn, () => doMenuAction(OPTIONS))
    addListener(CLICK, defaultTableRowActionBtn, () => doMenuAction(DEFAULT_TABLE_ROW_ACTION))
    addListener(CLICK, sumOfTableBtn, () => doMenuAction(SUM_OF_TABLE))
    addListener(CLICK, defaultTableColumnsOrderBtn, () => doMenuAction(DEFAULT_TABLE_COLUMNS_ORDER))
    addListener(CLICK, attachedDocumentsBtn, () => doMenuAction(ATTACHED_DOCUMENTS))

    logRender("TableActionButtons")
    logRenderProps(props)
    //console.log("TableActionButtons", props)
    if (desktopSessionMask[browseId].data.sensitivity === false)
        btnStyle = btnStyle + " disabled"

    let refresSreen = desktopMenu[REFRESH_SCREEN]
    let search = desktopMenu[SEARCH]
    let filterOptional = desktopMenu[FILTER_OPTIONAL]
    let print = desktopMenu[PRINT]
    let export2Xml = desktopMenu[EXPORT_2_XML]
    let attachedDocuments = desktopMenu[ATTACHED_DOCUMENTS]

    let tableColumnsOrder = desktopMenu[DEFAULT_TABLE_COLUMNS_ORDER]
    let sumOfTable = desktopMenu[SUM_OF_TABLE]
    let tableRowAction = desktopMenu[DEFAULT_TABLE_ROW_ACTION]
    let options = desktopMenu[OPTIONS]

    /*console.log("tableColumnsOrder", tableColumnsOrder)
    console.log("sumOfTable", sumOfTable)
    console.log("tableRowAction", tableRowAction)
    console.log("options", options)*/

    return <div className='d-flex gap-1'>
        <BSTooltip tooltiptext={refresSreen.tooltip} shortcuts={[refresSreen.accelerator, refresSreen.accelerator2]}>
            <button type="button" ref={refreshBtn} className={btnStyle} style={styles.buttonStyle} disabled={!refresSreen.sensitivity}><i className="fas fa-sync" /></button>
        </BSTooltip>
        <BSTooltip tooltiptext={search.tooltip} shortcuts={[search.accelerator, search.accelerator2]}>
            <button type="button" ref={searchBtn} className={btnStyle} style={styles.buttonStyle} disabled={!search.sensitivity}><i className="fas fa-search" /></button>
        </BSTooltip>
        <BSTooltip tooltiptext={filterOptional.tooltip} shortcuts={[filterOptional.accelerator, filterOptional.accelerator2]}>
            <button type="button" ref={filterBtn} className={btnStyle} style={styles.buttonStyle} disabled={!filterOptional.sensitivity}><i className="fas fa-filter" /></button>
        </BSTooltip>
        <BSTooltip tooltiptext={print.tooltip} shortcuts={[print.accelerator, print.accelerator2]}>
            <button type="button" ref={printBtn} className={btnStyle} style={styles.buttonStyle} disabled={!print.sensitivity}><i className="fas fa-print" /></button>
        </BSTooltip>
        <BSTooltip tooltiptext={export2Xml.tooltip} shortcuts={[export2Xml.accelerator, export2Xml.accelerator2]}>
            <button type="button" ref={exportXmlBtn} className={btnStyle} style={styles.buttonStyle} disabled={!export2Xml.sensitivity}><i className="fas fa-download" /></button>
        </BSTooltip>
        <BSTooltip tooltiptext={attachedDocuments.tooltip} shortcuts={[attachedDocuments.accelerator, attachedDocuments.accelerator2]}>
            <button type="button" ref={attachedDocumentsBtn} className={btnStyle} style={styles.buttonStyle} disabled={!attachedDocuments.sensitivity}><i className="fas fa-folder" /></button>
        </BSTooltip>
        <BSTooltip tooltiptext={messageContainer[Constants.MessageContainer.MANAGE_COLUMNS]} >
            <button className={btnStyle} style={styles.buttonStyle} tabIndex={-1} id="settings" disabled={!desktopSessionMask[browseId].data.sensitivity} onClick={e => {
                dispatch(onValuesChangeInMask(browseId, tabId, maskId, { [COLUMNS_SETTING_PROP]: true }))
            }}>
                <i className="far fa-table" />
            </button>
        </BSTooltip>

        {/*<BrowseColumnsSettings messageContainer={messageContainer} btnStyle={btnStyle} browseColsSorted={browseColsSorted} desktopSessionMask={desktopSessionMask} browseHandle={browseId} disabled={!desktopSessionMask[browseId].data.sensitivity} />*/}

        <div>
            <button type="button" className="btn btn-primary dropdown-toggle" style={{
                ...styles.buttonStyle,
                '--bs-btn-padding-x': '0.6rem'
            }} disabled={!desktopSessionMask[browseId].data.sensitivity} data-bs-toggle="dropdown">
                {/*<i className="fas fa-ellipsis-v" />*/}
            </button>
            <ul className="dropdown-menu">
                <BSTooltip tooltiptext={tableColumnsOrder.tooltip} shortcuts={[tableColumnsOrder.accelerator, tableColumnsOrder.accelerator2]}>
                    <li ref={defaultTableColumnsOrderBtn} key={"f_" + tableColumnsOrder.handle}>
                        <a className={"dropdown-item" + (tableColumnsOrder.sensitivity ? "" : " disabled")} href="#">{textWithMnemonic(tableColumnsOrder.label, false)}</a>
                    </li>
                </BSTooltip>
                <BSTooltip tooltiptext={sumOfTable.tooltip} shortcuts={[sumOfTable.accelerator, sumOfTable.accelerator2]}>
                    <li ref={sumOfTableBtn} key={"f_" + sumOfTable.handle}>
                        <a className={"dropdown-item" + (sumOfTable.sensitivity ? "" : " disabled")} href="#">{textWithMnemonic(sumOfTable.label, false)}</a>
                    </li>
                </BSTooltip>
                <BSTooltip tooltiptext={tableRowAction.tooltip} shortcuts={[tableRowAction.accelerator, tableRowAction.accelerator2]}>
                    <li ref={defaultTableRowActionBtn} key={"f_" + tableRowAction.handle}>
                        <a className={"dropdown-item" + (tableRowAction.sensitivity ? "" : " disabled")} href="#">{textWithMnemonic(tableRowAction.label, false)}</a>
                    </li>
                </BSTooltip>
                <BSTooltip tooltiptext={options.tooltip} shortcuts={[options.accelerator, options.accelerator2]}>
                    <li ref={optionsBtn} key={"f_" + options.handle}>
                        <a className={"dropdown-item" + (options.sensitivity ? "" : " disabled")} href="#">{textWithMnemonic(options.label, false)}</a>
                    </li>
                </BSTooltip>
            </ul>
        </div>
    </div>

}