import React, { useContext } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'

import { resetSession } from '../../index'
import { WebSocketContext } from '../../services/WebSocket'
import { logRender, logRenderProps } from '../../utils/logging'
import { removeMessageDialog } from '../../store/actions/ws_actions'
import Constants from '../../constants/constants'
import addKeyMouseClickListener from '../../hooks/desktopListener'
import { focusElement } from '../../focus_actions'
import Styles from '../../constants/styles'
import styles from '../../constants/styles'


const { PRESS_LOCKED_ROW_OK, SYSTEM_ERROR_OK, GET_ROW_LOCKED_MESSAGE } = Constants.WebSocketActions
const { ERROR_DEFAULT, ERROR_LOCKED_ROW, ERROR_TERMINATE, ERROR_WEB_SOCKET, ERROR_SYSTEM } = Constants.ERRORS
const { CLOSE_DIALOG_SYSTEM_ERROR_BTN } = Constants.CloseButtons

const modalRoot = document.getElementById("root")

/*backdrop - de definován v _app.scss */
export default function MessageDialog(props) {
    const messageDialogButtonRef = React.useRef()
    const dispatch = useDispatch()
    const ws_context = useContext(WebSocketContext)
    const { sendMessage, createMessage } = ws_context
    const showCloseButton = (props.errorType === ERROR_DEFAULT)
    const { labelPositiveButton } = props

    // element to which the modal will be rendered
    const el = document.createElement("div")
    el.id = Constants.portal_dialog

    /*zamceny zaznam, ktery neni zamcen pomoci m_setlck, ma zvlastni dialog,
    ktery se cyklicky dotazuje pomoci metody GET_ROW_LOCKED_MESSAGE jestli uz je zaznam uvolnen */
    let isSended = false // useEffect je volan 2x z nejakeho neznameho duvodu
    React.useEffect(() => {
        let action
        if (isSended === false && props.errorType === ERROR_LOCKED_ROW) {
            isSended = true
            action = setInterval(() => {
                sendMessage(createMessage(GET_ROW_LOCKED_MESSAGE), response => {
                    response.data.forEach(resp => {
                        let responseEntity = Object.keys(resp)[0]
                        if (responseEntity === "WSResponse") {
                            if (resp[responseEntity].status === "INTERRUPT") {
                                clearInterval(action)

                                if (messageDialogButtonRef.current !== null)
                                    messageDialogButtonRef.current.click()
                            }
                        }
                    })
                })
            }, 1000)
        }

        return () => {
            if (action !== undefined)
                clearInterval(action)
        }
    }, [])


    React.useEffect(() => {
        // append to root when the children of Modal are mounted
        modalRoot.append(el)
        focusElement(CLOSE_DIALOG_SYSTEM_ERROR_BTN, "useEffect MessageDialog")

        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el])

    addKeyMouseClickListener(messageDialogButtonRef, (event) => {
        //escape se provadi v App.doEscape()

        if (!(event instanceof MouseEvent) &&
            !(event instanceof KeyboardEvent &&
                event.key === "Enter")) {
            return
        }

        dispatch(removeMessageDialog(props.index))
        //console.log("ERROR TYPE", props.errorType)

        if (props.errorType === ERROR_LOCKED_ROW)
            sendMessage(createMessage(PRESS_LOCKED_ROW_OK))
        else if (props.errorType === ERROR_TERMINATE || props.errorType === ERROR_WEB_SOCKET) {
            resetSession("MessageDialog -> ", props.errorType)
            ws_context.initWS([], status => { }, "MESSAGE DIALOG TERMINATE")
        } else if (props.errorType === ERROR_SYSTEM) {
            sendMessage(createMessage(SYSTEM_ERROR_OK))
        }
    })

    /*pokud ano, nelze klikat mimo dialog */
    function isModal() {
        if (props.errorType === ERROR_LOCKED_ROW || props.errorType === ERROR_SYSTEM)
            return true

        return false
        /*return {
            className: "modal"
        }*/
    }

    logRender("MessageDialog")
    logRenderProps(props)
    return (
        createPortal(<div id="dialog-container">
            <div
                id={props.id}
                key={props.id}
                aria-modal="true"
                style={Styles.dialogStyle}>
                <div className="modal-content blue_box" style={{ ...Styles.dialogContent, maxWidth: '50%' }} data-bs-backdrop={isModal() ? "static" : "false"}>
                    <div className="modal-header p-3">
                        <h4 className="modal-title">{props.dialogTitle}</h4>
                    </div>
                    <div className="modal-body p-3">
                        <p style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
                            {props.errorMessage}
                        </p>
                    </div>
                    <div className="modal-footer p-3 pb-3">
                        <button id={showCloseButton ? "" : CLOSE_DIALOG_SYSTEM_ERROR_BTN} type="button" className="btn btn-primary" style={styles.buttonStyle} ref={messageDialogButtonRef}>{(labelPositiveButton === undefined) ? "OK" : labelPositiveButton}</button>
                    </div>
                </div>
            </div >
        </div>, el))
}
