import React from 'react'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'

import Constants from '../../constants/constants'
import { renderChildrens2Screen } from '../renderChildrens2Screen'
import { logRender, logRenderProps } from '../../utils/logging'

import addKeyMouseClickListener from '../../hooks/desktopListener'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'

import useStoreSubscription from '../../hooks/useStoreSubscription'
import { focus2Sidebar, focusElement, getRelatedTargetFocusArea } from '../../focus_actions'
import constants from '../../constants/constants'
import { changeFocusAreaWithBrowseFocusLoss, showAboutApp, showContact } from '../../store/actions/store_actions'
import Styles from '../../constants/styles'
import useDialogDrag from '../../hooks/useDialogDrag'
import DecideDialog from '../dialogs/DecideDialog'
import { getWidthInChars } from '../../utils/screen'

export const MODAL_DIALOG_CONTACT = "modal_dialog_contact"
export const MODAL_DIALOG_ABOUT = "modal_dialog_contact"
export const KEYBOARD_SHORTCUTS_DIALOG = "modal_dialog_about"
export const BREAK_BUTTON = "break_button"

const modalRoot = document.getElementById("root")
const { CLOSE_DIALOG_BTN } = Constants.CloseButtons

function ModalDialogElement(props) {
    const { data, dispatch, isBreakAllowed, id } = props
    const { SpecialId } = Constants
    const el = document.createElement("div")
    el.id = Constants.portal_dialog
    const refDialog = React.useRef(null)
    const refHeaderDialog = React.useRef(null)
    const refBodytDialog = React.useRef(null)
    const closeBtnRef = React.useRef()

    let ws, currentFocusId, currentTabId, currentMaskID, app, desktopSession, smallScreen
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
        currentTabId = ws.currentTabId
        currentMaskID = ws.currentMaskID
        app = store.app
        desktopSession = store.desktopSession
        smallScreen = store.smallScreen
    })

    React.useEffect(() => {
        // append to root when the children of Modal are mounted
        modalRoot.append(el)
        return () => {
            modalRoot.removeChild(el)
        }
    }, [el])

    //SOUBOR=/work1/vyvoj/desktop/apl.ele/p-476.log
    /*
    Nastaveni focusu - pri prvnim vykresleni
    Portal se vykresluje mimo DOM - komponenty uvnitr volaji metodu render drive nez portal*/
    React.useEffect(() => {
        if (currentFocusId === null)
            currentFocusId = data.defaultButtonHandle

        focusElement(currentFocusId, "ModalDialog.useEffect()")

        const controller = new AbortController()

        function focusout(e) {
            const leavingParent = !refDialog.current?.contains(e.relatedTarget)
            if (leavingParent && e.relatedTarget !== null)
                dispatch(changeFocusAreaWithBrowseFocusLoss(getRelatedTargetFocusArea(e.relatedTarget)))
        }

        if (refDialog.current && refDialog.current !== null)
            refDialog.current.addEventListener("focusout", focusout, { signal: controller.signal })

        return () => {
            controller.abort()
        }
    })

    useDialogDrag(modalRoot, props, refDialog, refHeaderDialog, refBodytDialog, data.dialogX, data.dialogY)

    React.useEffect(() => {
        return () => {
            //FOCUS 2 SIDEBAR - stejny kod se provadi v App.js useEffect()
            function existModalDialogInRoot() {
                for (const [key, value] of Object.entries(desktopSession[0][0])) {
                    if (value.componentName === "ModalDialogElement")
                        return true
                }
                return false
            }

            if (Object.keys(desktopSession).length === 1 && !existModalDialogInRoot())
                focus2Sidebar()
        }
    }, [])

    addKeyMouseClickListener(refDialog, (event) => {
        if (!refDialog.current?.contains(document.activeElement)) {
            if (currentFocusId === null)
                currentFocusId = data.defaultButtonHandle

            //neprovadet focus pokud je oznaceny text
            if (window.getSelection()?.toString() === "")
                focusElement(currentFocusId, "ModalDialog.useEffect()")
        }
    })

    addListener(LISTENER_TYPE.CLICK, closeBtnRef, () => {
        if (data.specialId === SpecialId.ABOUT)
            dispatch(showAboutApp(false))
        if (data.specialId === SpecialId.CONTACT)
            dispatch(showContact(false))
    })

    //pro specialni dialogy se fixni sirka nenastavuje 
    let iconName = "", minModalDialogWidth = 0
    switch (data.specialId) {
        case constants.SpecialId.DIALOG_MESSAGE:
            iconName = "fa-info-square"
            break
        case constants.SpecialId.DIALOG_LOCKED_ROW:
            iconName = "fa-info-square text-danger"
            break
        case constants.SpecialId.DIALOG_ERROR:
            iconName = "fa-exclamation-square text-danger"
            break
        case constants.SpecialId.DIALOG_QUESTION_2:
            iconName = "fa-question-square"
            break
        case constants.SpecialId.DIALOG_QUESTION_3:
            iconName = "fa-question-square"
            break
        case constants.SpecialId.DIALOG_FILTER:
            //minModalDialogWidth = 0
            break
        case constants.SpecialId.LOADING_DIALOG:
            //minModalDialogWidth = 0
            break
        default:
            iconName = "";
            minModalDialogWidth = props.data.width + "rem"
            //minModalDialogWidth = "100%"
            break
    }

    function renderContent() {
        let { childIds, desktopSessionMask } = props

        let gridChilds = []
        childIds.forEach((val) => {
            let element = desktopSessionMask[val]
            let y = desktopSessionMask[val].data.y
            if (y == undefined)
                y = 0

            if (gridChilds[y] == undefined)
                gridChilds[y] = []

            gridChilds[y].push(element)
        })

        //setridit podle atributu x
        gridChilds.map((val) => {
            return val.sort((a, b) => {
                return parseFloat(a.data.x) - parseFloat(b.data.x)
            })
        })

        let frameContent = []
        gridChilds.map((yRow, index_r) => {
            let frameRow = []

            yRow.map((el, index_c) => {
                let key = "m_row_" + index_r + "col_" + index_c
                if (el.componentName === "LabelElement") {
                    frameRow.push(<div key={key} className="col">
                        {el.data.caption}
                    </div>)
                } else if (el.componentName === "InputFieldElement") {
                    if (frameRow.length === 0)
                        frameRow.push(<div key={key + "_empty"} className="col" />)

                    if (el.data.specialId === SpecialId.APP_VERSION)
                        frameRow.push(<div key={key} className="col">{app.version}</div>)
                    else
                        frameRow.push(<div key={key} className="col">{el.data.value}</div>)
                } else if (el.componentName === "EditorElement") {
                    frameRow.push(<div key={key} className="col" style={{ padding: '1rem' }}>{el.data.value}</div>)
                }
            })
            frameContent.push(<div className="row" key={"m_row_" + index_r}>{frameRow}</div>)
        })

        return <>{frameContent}</>
    }


    logRender("ModalDialogElement")
    logRenderProps(props)
    //console.log("render modal dialog", props)


    let style = {
        ...Styles.dialogStyle,
        zIndex: Constants.modalDialogZIndex + data.layerOrder,
    }
    let width = getWidthInChars(data.width)
    if (!smallScreen) {
        style = {
            ...style,
            width: width,
        }
    }

    let justifyContent = { justifyContent: "space-between" }

    if (data.stornoButtonHandle === undefined
        && data.specialId !== constants.SpecialId.ABOUT
        && data.specialId !== constants.SpecialId.CONTACT)
        justifyContent = { justifyContent: "start" }

    let headerProps = {
        style: {
            ...justifyContent,
            cursor: 'grab'
        },
        className: "modal-header p-3",
        ref: refHeaderDialog
    }

    //console.log(modalRoot.getBoundingClientRect().width, props.data.width, convertRemToPixels(props.data.width), (modalRoot.getBoundingClientRect().width / 2 - convertRemToPixels(props.data.width)))

    //Specialni dialogy
    let htmlDialog
    switch (data.specialId) {
        case constants.SpecialId.ABOUT:
            if (data.specialId, document.getElementById(MODAL_DIALOG_ABOUT) === null)
                htmlDialog = <div
                    key={id}
                    id={MODAL_DIALOG_ABOUT}
                    ref={refDialog}
                    aria-modal="true"
                    //data-bs-backdrop="false"
                    style={{
                        ...style,
                        zIndex: Constants.modalDialogZIndex + data.layerOrder + data.layer
                    }}>
                    <div className="modal-content blue_box" style={Styles.dialogContent}>
                        <div {...headerProps}>
                            <div className="btn-group">
                                <h4 className="modal-title">{data.header}</h4>
                            </div>
                            <div className="btn-group">
                                <button ref={closeBtnRef} type="button" className="btn-sm btn-close" />
                            </div>
                        </div>
                        <div className="modal-body p-3" ref={refBodytDialog} style={{ overflowY: 'auto' }}>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            break
        case constants.SpecialId.CONTACT:
            if (data.specialId, document.getElementById(MODAL_DIALOG_CONTACT) === null)
                htmlDialog = <div
                    key={id}
                    id={MODAL_DIALOG_CONTACT}
                    ref={refDialog}
                    aria-modal="true"
                    data-bs-backdrop="false"
                    style={{
                        ...style,
                        zIndex: Constants.modalDialogZIndex + data.layerOrder + data.layer
                    }}>
                    <div className="modal-content blue_box" style={Styles.dialogContent}>
                        <div {...headerProps}>
                            <div className="btn-group">
                                <h4 className="modal-title">{data.header}</h4>
                            </div>
                            <div className="btn-group">
                                <button ref={closeBtnRef} type="button" className="btn-sm btn-close" />
                            </div>
                        </div>
                        <div className="modal-body p-3" ref={refBodytDialog} style={{ overflowY: 'auto' }}>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            break
        case constants.SpecialId.DIALOG_MESSAGE:
            htmlDialog = <DecideDialog {...props} style={style} refDialog={refDialog} headerProps={headerProps} iconName={iconName} />
            break
        case constants.SpecialId.DIALOG_QUESTION_3:
            htmlDialog = <DecideDialog {...props} style={style} refDialog={refDialog} headerProps={headerProps} iconName={iconName} />
            break
        case constants.SpecialId.DIALOG_QUESTION_2:
            htmlDialog = <DecideDialog {...props} style={style} refDialog={refDialog} headerProps={headerProps} iconName={iconName} />
            break
        case constants.SpecialId.DIALOG_ERROR:
            htmlDialog = <DecideDialog {...props} style={style} refDialog={refDialog} headerProps={headerProps} iconName={iconName} />
            break
        default:
            htmlDialog = <div
                key={id}
                ref={refDialog}
                style={style}
                className='modal-dialog'
                id="modal_dialog"
                aria-modal="true" >
                <div className="modal-content blue_box" style={Styles.dialogContent}>
                    <div {...headerProps}>
                        <div className='d-inline-flex'>
                            {(iconName !== "") ? (<i className={iconName + " fal fa-2x me-2"}></i>) : null}
                            <h4 className="modal-title">{data.header}</h4>
                        </div>
                        {(data.stornoButtonHandle !== undefined ? <button id={CLOSE_DIALOG_BTN} type="button" className="btn-close" aria-label="Close" /> : "")}

                    </div>
                    <div className="modal-body p-3" ref={refBodytDialog} style={{ overflowY: 'auto' }}>
                        {renderChildrens2Screen(props)}
                    </div>
                </div>
            </div >
            break
    }

    //console.log("ModalDialogElement(" + props.id + ") -> render() props", props)
    //console.log("IDNEX", 9999 + props.data.layerOrder)
    return createPortal(htmlDialog, el)
}

function mapStateToProps(state, ownProps) {
    //console.log("mapStateToProps", state, ownProps)
    let desktopSessionMask = state.desktopSession[ownProps.tabId][ownProps.maskId]
    //console.log("mapStateToProps desktopSessionMask", desktopSessionMask)
    return {
        ...desktopSessionMask[ownProps.id],
        desktopSessionMask: desktopSessionMask,
        isBreakAllowed: state.ws.isBreakAllowed,
        focusId: state.ws.focusId
    }
}

function areEqual(prevProps, nextProps) {
    //console.log("prevProps", prevProps)
    //console.log("nextProps", nextProps)
    return (JSON.stringify(prevProps.desktopSessionMask[prevProps.id]) === JSON.stringify(nextProps.desktopSessionMask[nextProps.id])
        && prevProps.desktopMenu === nextProps.desktopMenu
        && prevProps.focusId === nextProps.focusId)

    //pri pouziti, nefunguje vyber souboru -> ((JSON.stringify(prevProps.desktopSessionMask) === JSON.stringify(nextProps.desktopSessionMask))
}

export default connect(mapStateToProps)(React.memo(ModalDialogElement, areEqual))
