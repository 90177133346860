
import React from 'react'

import constants from '../../constants/constants'
import { WebSocketContext } from '../../services/WebSocket'
import useStoreSubscription from '../../hooks/useStoreSubscription'

export default function DataList(props) {
    const HINT_OPTION_KEY = "hint_option_key_"
    const ws_context = React.useContext(WebSocketContext)
    const { createMessage, createRequest, sendMessageNoBlock } = ws_context

    const [hints, setHints] = React.useState([])

    let currentFocusId, ws
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
    })

    React.useEffect(() => {
        //console.log("use effect ->", currentFocusId, props.id)
        if (currentFocusId !== props.id)
            return

        //console.log("handleHintSearch", props.inputValue)
        if (props.inputValue !== undefined && props.inputValue !== "") {
            //pred odeslanim se smaze puvodni data
            setHints([])

            sendMessageNoBlock(createMessage(constants.WebSocketActions.GET_WIDGET_DATALIST, createRequest({
                value: props.inputValue,
                handle: props.id
            })), response => {
                //console.log("RESPONSE", response)
                let hintValues = response.data[0]?.WidgetDataListResponse?.hintValues
                let tooltipsArray = hintValues?.split("\u0001")
                //let tooltipsArray = [hintValues]

                if (tooltipsArray?.length > 0) {
                    //console.log("set hint", hints, tooltipsArray)
                    setHints(tooltipsArray)
                }
            })
        }
    }, [props.inputValue])

    //console.log("RENDER DATALIST", hints)
    return <datalist id={props.datalist_id}>
        {hints.map((hint, index) => <option value={hint} key={HINT_OPTION_KEY + index} />)}
    </datalist>
}

