import constants from "../constants/constants"

export function uuidv4() {
    const uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
    return uuid
}

export function objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

export function getEmptyRowProp() {
    return { emptyRows: localStorage.getItem(constants.LocalStorageKeys.MENU_EMPTY_ROWS) === 'true' }
}
