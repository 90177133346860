import React from "react";
import Fullcalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import csLocale from '@fullcalendar/core/locales/cs'
import { remToPx } from "../../utils/screen"
import bootstrap5Plugin from '@fullcalendar/bootstrap5'

function CustomCalendar(props) {
    const { widgetTileRef, widgeHeadertRef } = props

    if (widgetTileRef.current === undefined || widgeHeadertRef.current === undefined)
        return

    let tileClientRect = widgetTileRef.current?.getBoundingClientRect()
    let tileHeight = tileClientRect.height

    let headerClientRect = widgeHeadertRef.current.getBoundingClientRect()
    let calendarHeight = tileHeight - headerClientRect.height - remToPx(1.25)

    bootstrap5Plugin.themeClasses.bootstrap5.prototype.baseIconClass = 'fa'
    bootstrap5Plugin.themeClasses.bootstrap5.prototype.iconOverridePrefix = 'fa-'
    bootstrap5Plugin.themeClasses.bootstrap5.prototype.iconClasses = {
        close: 'fa-times',
        prev: 'fa-chevron-left',
        next: 'fa-chevron-right',
        prevYear: 'fa-angle-double-left',
        nextYear: 'fa-angle-double-right',
    }
    bootstrap5Plugin.themeClasses.bootstrap5.prototype.rtlIconClasses = {
        prev: 'fa-chevron-right',
        next: 'fa-chevron-left',
        prevYear: 'fa-angle-double-right',
        nextYear: 'fa-angle-double-left',
    }

    return (
        <div>
            <Fullcalendar
                themeSystem={"bootstrap5"}
                height={calendarHeight}
                plugins={[dayGridPlugin, bootstrap5Plugin]}
                initialView={"dayGridMonth"}
                locale={csLocale}
            />
        </div>
    )
}

export default CustomCalendar