import React from 'react';
import { connect } from 'react-redux'

import { parentSensitivity } from './elementMethods'
import { getStore } from '../../index.js'
import Constants from '../../constants/constants'
import useFocusObserver from '../../hooks/useFocusObserver'
import { addListenerWithRefHandler, LISTENER_TYPE, addListener } from '../../hooks/desktopListener'

import { onValueChangeByHandle } from '../../store/actions/store_actions'
import { logRender, logRenderProps } from '../../utils/logging'
import { getEmptyRowProp } from '../../utils/actions'

const { SET_VALUE_REQUEST } = Constants.WebSocketActions
const { VALUE_CHANGED } = Constants.SetValueRequest

//substituce onChange - posila se pouze pokud je hodnota odlisna nez je ve storu
export function handleSelectionElementOnClick(event, ws_context) {
    //console.log("handleSelectionElementOnClick", event)

    const { createMessage, createRequest, sendMessage } = ws_context

    let store = getStore().getState()
    let ws = store.ws

    let key = event.target.id.split(";")
    let elementId = key[0]
    let elementValue = key[1]
    let itemValue = store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId].data.values[elementValue]

    //console.log("ITEM VALUE", itemValue)
    //console.log("ELEMENT VALUE", store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId])
    //console.log("===", store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId].data.value, itemValue, store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId].data.value !== itemValue)
    if (itemValue !== undefined && itemValue !== null && store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId].data.value !== itemValue) {
        sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
            ...getEmptyRowProp(),
            handle: ws.focusId,
            newFocus: elementId,
            action: VALUE_CHANGED,
            newFocusValue: itemValue
        })), response => {
            //console.log("DISPATCHUJU", elementId, itemValue)
            getStore().dispatch(onValueChangeByHandle(elementId, "value", itemValue))
        })
    }
}

export function handleSelectionElementOnChange(event, ws_context) {
    //console.log("handleSelectionElementOnChange", event)

    const { createMessage, createRequest, sendMessage } = ws_context

    let store = getStore().getState()
    let ws = store.ws

    let itemValue = event.target.value
    /*let itemValue = ""
    //input field element je taky select
    if (store.desktopSession[ws.currentTabId][ws.currentMaskID][event.srcElement.id].componentName === "InputFieldElement")
        itemValue = event.target.value
    else
        itemValue = store.desktopSession[ws.currentTabId][ws.currentMaskID][event.srcElement.id].data.values[event.target.value]*/

    sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
        ...getEmptyRowProp(),
        handle: ws.focusId,
        newFocus: event.srcElement.id,
        action: VALUE_CHANGED,
        newFocusValue: itemValue
    })), response => {
        getStore().dispatch(onValueChangeByHandle(event.srcElement.id, "value", itemValue))
    })

    //getStore().dispatch(onValueChangeByHandle(event.srcElement.id, "value", itemValue))
}

function SelectionElement(props) {
    const ref = React.useRef(null)

    const { data } = props
    let values = props.data.values
    let className = "form-select"
    let classNameAdd = ""

    useFocusObserver(props.id, "SelectionElement")
    addListenerWithRefHandler(LISTENER_TYPE.KEYDOWN, ref, e => {
        if (e.key === "Enter") {
            //prepsani defaultniho chovani, pri zmacknuti enter se zavola akce GO
            let parent = props.desktopSessionMask[data.parentHandle]
            if (parent.data.defaultButtonHandle)
                e.preventDefault()
        }
    })

    if (data.height < 2)
        classNameAdd = " form-select-sm"

    logRender("SelectionElement")
    logRenderProps(props)

    //pri pohybovani sipkami => onChange se provede
    //pri kliku mouseDown => onChange se nevola a nemeni hodnota
    //proto se vyuziva select
    return (
        <select
            id={props.id}
            ref={ref}
            className={className + classNameAdd}
            size={data.height}
            //defaultValue={props.data.value}
            disabled={data.sensitivity === false || !props.parentSensitivity} > {
                values?.map((val, index) => {
                    return <option
                        id={props.id + ";" + index}
                        key={"option" + index}
                        selected={val === props.data.value}
                        value={val}>{val}</option>
                })
            }
        </select >)
}

function mapStateToProps(state, ownProps) {
    return {
        ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id],
        currentFocusId: state.ws.focusId,
        parentSensitivity: parentSensitivity(ownProps.id, ownProps.tabId, ownProps.maskId)
    }
}

function areEqual(prevProps, nextProps) {
    /*console.log("prevProps", prevProps)
    console.log("nextProps", nextProps)
    console.log("ARE EQUAL", prevProps.data.value, nextProps.data.value, (prevProps.parentSensitivity === nextProps.parentSensitivity))*/
    return (prevProps.data.value === nextProps.data.value
        && prevProps.data.sensitivity === nextProps.data.sensitivity
        && prevProps.parentSensitivity === nextProps.parentSensitivity
    )
}

export default connect(mapStateToProps)(React.memo(SelectionElement, areEqual))