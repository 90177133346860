import React from 'react'
import { useDispatch } from 'react-redux'

import ColumnResizer from './ColumnResizer'
import Constants from '../../constants/constants'

import { disableOtherListeners } from '../../app_actions'
import { onValueChangeByHandle, onValuesChangeInMask, resizeBrowseColumns } from '../../store/actions/store_actions'
import { WebSocketContext } from '../../services/WebSocket'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import { LISTENER_TYPE, addListener } from '../../hooks/desktopListener'
import { ROW_HEIGHT } from './Browse'
import { fitPxToChars, textWithMnemonic } from '../../utils/screen'
import { getEmptyRowProp } from '../../utils/actions'
import constants from '../../constants/constants'
import { resizeBrowseColumn } from '../../store/actions/store_actions'
import { COLUMNS_SETTING_PROP } from './BrowseColumnsSettingsDialog'
import { getStore } from '../..'
import DropdownMenu from '../../DropdownMenu'
import BSTooltip from '../BSTooltip'
import { sensitivityRecursively } from '../desktop_server/elementMethods'

const { CLICK, KEYDOWN } = LISTENER_TYPE
const { SpecialId, SetValueRequest, WebSocketActions, MenuActions, InternalElementState } = Constants
const ICONS_WIDTH = 20

function BrowseHeaderColumn(props) {
    const dispatch = useDispatch()

    const checkboxRef = React.useRef(null)
    const headerRef = React.useRef(null)
    const iconRef = React.useRef(null)
    const thRef = React.useRef(null)
    const dropdownMenuRef = React.useRef()
    const ws_context = React.useContext(WebSocketContext)
    const [showCM, setShowCM] = React.useState(false)
    const [positionCM, setPositionCM] = React.useState({ x: 0, y: 0 })

    const { createMessage, createRequest, sendMessage } = ws_context
    const { hColumn, hKey, tableElementRef, browseId, desktopSessionMask,
        childIds, colPosition, desktopMenu, columsWidth, tabId, maskId } = props
    const hasCheckbox = (hColumn.specialId === SpecialId.CHECKBOX)
    const { DEFAULT_TABLE_COLUMNS_ORDER } = MenuActions
    const tableColumnsOrder = desktopMenu[DEFAULT_TABLE_COLUMNS_ORDER]
    const { SET_VALUE_REQUEST, SET_ATTRIBUTE } = WebSocketActions
    const { MENU_ACTION } = SetValueRequest

    let newColWidth
    let ws, currentFocusId, messageContainer
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
        messageContainer = store.app.messageContainer
    })

    let lbl = hColumn.label
    if (lbl === "&Null;")
        lbl = ""

    const headerColumnSettingsButton = "settings_icon_" + hColumn.id
    const headerColumnSettingsIcon = "header_options_icon_" + hColumn.handle
    const headerColumnSettingsText = "header_options_text_" + hColumn.handle
    const headerColumnSettingsColumn = "header_options_column_" + hColumn.handle

    addListener(CLICK, headerRef, (e) => {
        if (hColumn.sortType != undefined) {
            //SORT
            sendMessage(createMessage(WebSocketActions.SET_VALUE_REQUEST, createRequest({
                ...getEmptyRowProp(),
                handle: currentFocusId,
                newFocus: hColumn.handle,
                action: SetValueRequest.SORT
            })))
        }
    })

    addListener(CLICK, checkboxRef, (e) => {
        if (hasCheckbox) {
            disableOtherListeners(e, "BrowseHeaderColumn.addListener()")

            let doAction = MenuActions.SELECT_ALL   //69
            if (isChecked())
                doAction = MenuActions.DESELECT_ALL //70

            sendMessage(createMessage(WebSocketActions.SET_VALUE_REQUEST, createRequest({
                ...getEmptyRowProp(),
                handle: currentFocusId,
                newFocus: doAction,
                action: SetValueRequest.MENU_ACTION
            })), response => {
                let elementState = InternalElementState.CHECKED
                if (doAction === MenuActions.DESELECT_ALL)
                    elementState = InternalElementState.UNCHECKED
                dispatch(onValueChangeByHandle(hColumn.handle, "internalState", elementState))
            })
        }
    })

    React.useEffect(() => {
        const controller = new AbortController()

        const handleClickOutside = (event) => {
            if (showCM) {
                if (dropdownMenuRef.current
                    && !dropdownMenuRef.current.contains(event.target)
                    && event.target.id !== headerColumnSettingsIcon
                    && showCM) {
                    setShowCM(false)
                }
            }
        }

        //zabrani doEscape() v App.js
        function doKeydown(e) {
            if (e.key === "Escape") {
                if (showCM) {
                    e.stopPropagation()
                    setShowCM(false)
                }
            }
        }

        document.addEventListener(KEYDOWN, doKeydown, { capture: true, signal: controller.signal })
        document.addEventListener(CLICK, handleClickOutside, { signal: controller.signal })
        return () => {
            controller.abort()
        }
    })

    const handleContextMenu = (e) => {
        if (sensitivityRecursively(props.browseId, props.tabId, props.maskId)) {
            e.preventDefault()
            setPositionCM({ x: e.pageX, y: e.pageY })
            setShowCM(true)
        }
    }

    function isChecked() {
        if (hColumn.internalState === InternalElementState.CHECKED || hColumn.internalState === InternalElementState.PARTLY_CHECKED)
            return true
        else
            return false
    }

    function renderCheckbox() {
        if (!hasCheckbox)
            return null

        let selectAllFce = desktopMenu[MenuActions.SELECT_ALL]
        return <BSTooltip key={"select_all_checkbox"} tooltiptext={selectAllFce.tooltip} shortcuts={[selectAllFce.accelerator]}>
            <input
                className="form-check-input"
                ref={el => {
                    //indeterminate = true => není ani zaškrtnutý ani nezaškrtnutý (je na něm vykreslen symbol pomlčky -).
                    checkboxRef.current = el
                    if (el !== null) {
                        if (hColumn.internalState === InternalElementState.CHECKED) {
                            el.checked = true
                            el.indeterminate = false
                        } else if (hColumn.internalState === InternalElementState.UNCHECKED) {
                            el.checked = false
                            el.indeterminate = false
                        } else if (hColumn.internalState === InternalElementState.PARTLY_CHECKED) {
                            el.checked = false
                            el.indeterminate = true
                        }
                    }
                }}
                type="checkbox"
                disabled={!selectAllFce.sensitivity}
                style={{ float: 'right' }}
                defaultChecked={isChecked()} />
        </BSTooltip>
    }

    function adjustColumnWidthByOffsetWidth(newColWidth) {
        let headerColumn = desktopSessionMask[props.childIds[props.colPosition]]
        updateColWidth(newColWidth, headerColumn.id, props)
    }

    function adjustColumnWidth() {
        let newColWidth = getAdjustedColumnWidth(props.colPosition)
        let headerColumn = desktopSessionMask[props.childIds[props.colPosition]]
        updateColWidth(newColWidth, headerColumn.id, props)
    }

    function getAdjustedColumnWidth(columnPosition) {
        let bdata = getStore().getState().desktopSession[tabId][maskId][browseId].data.browseData
        let newLength = 2
        bdata.forEach(data => {
            if (data.columns[columnPosition].value.length > newLength)
                newLength = data.columns[columnPosition].value.length
        })

        if (newLength > 4)
            return Math.floor(newLength / 1.75)

        return newLength
    }

    function updateColWidth(newWidth, columnHandle, props, callback) {
        //console.log("updateColWidth")
        sendMessage(createMessage(SET_ATTRIBUTE, createRequest({
            handle: columnHandle,
            value: newWidth
        })), (response) => {
            if (response.data && response.data.length > 0) {
                response.data.forEach(resp => {
                    if (resp.OkResponse) {
                        dispatch(resizeBrowseColumn({
                            browseHandle: props.id,
                            columnHandle: columnHandle,
                            newColWidth: newWidth,
                        }))
                        newColWidth = undefined

                        let sizePerPage = getStore().getState().desktopSession[tabId][maskId][browseId].data.sizePerPage
                        props.handleResize(sizePerPage)

                        if (callback)
                            callback(newColWidth)
                    }
                })
            }
        })
    }

    function getNumOfLettersFromOffsetWidth(columnId, headerIncluded) {
        let key = "browse_cell_text_" + columnId
        let dataColumns = document.querySelectorAll('[id^="' + key + '"]')
        let maxWidth = 0

        dataColumns?.forEach((el, i) => {
            if (el.offsetWidth > maxWidth)
                maxWidth = el.offsetWidth
        })

        if (headerIncluded) {
            let checkboxWidth = 0
            if (desktopSessionMask[columnId]?.data?.specialId === SpecialId.CHECKBOX && desktopMenu[MenuActions.SELECT_ALL].sensitivity === true)
                checkboxWidth = ICONS_WIDTH

            let hederId = "header_options_text_" + columnId
            let headerTextWidth = document.getElementById(hederId)?.scrollWidth + ICONS_WIDTH + checkboxWidth
            if (headerTextWidth > maxWidth)
                maxWidth = headerTextWidth
        }

        let numOfLetters = Math.ceil(maxWidth / fitPxToChars())
        if (numOfLetters <= 2)
            return 2

        return numOfLetters
    }

    const options = [
        {
            //SCHOVAT SLOUPEC
            id: "1",
            label: messageContainer[constants.MessageContainer.HIDE_COLUMN],
            icon: <i className="fas fa-eye-slash"></i>,
            onClick: e => {
                let headerColumn = desktopSessionMask[childIds[colPosition]]

                let columnsVisibleArray = []
                desktopSessionMask[browseId].childIds.forEach((childId, index) => {
                    if (childId === headerColumn.id)
                        columnsVisibleArray.push(0)
                    else
                        columnsVisibleArray.push(1)
                })

                sendMessage(createMessage(SET_ATTRIBUTE, createRequest({
                    browseHandle: browseId,
                    columnsOrder: desktopSessionMask[browseId].data.browseColsSorted,
                    columnsVisible: columnsVisibleArray?.toString()
                })), response => {
                    dispatch(onValueChangeByHandle(
                        hColumn.handle,
                        'browseColVisible',
                        false,
                    ))
                })
            }
        }, {
            id: "2",
            label: messageContainer[constants.MessageContainer.ADJUST_WIDTH],
            icon: <i className="fas fa-text-width"></i>,
            childrens: [
                {
                    //PRIZPUSOBIT SLOUPEC
                    id: "2_1",
                    parent: "2",
                    label: messageContainer[constants.MessageContainer.ADJUST_COLUMN_WIDTH],
                    icon: <i className="fas fa-bars" />,
                    onClick: e => {
                        let newColWidth = getNumOfLettersFromOffsetWidth(hColumn.handle, false)
                        adjustColumnWidthByOffsetWidth(newColWidth)
                    }
                }, {
                    //PRIZPUSOBIT SLOUPEC VCETNE HLAVICKY
                    id: "2_2",
                    parent: "2",
                    label: messageContainer[constants.MessageContainer.ADJUST_COLUMN_WIDTH_WITH_HEADER],
                    icon: <i className="fas fa-bars" />,
                    onClick: e => {
                        let newColWidth = getNumOfLettersFromOffsetWidth(hColumn.handle, true)
                        adjustColumnWidthByOffsetWidth(newColWidth)
                    }
                },
                {
                    //PRIZPUSOBIT VSECHNY SLOUPCE
                    id: "2_3",
                    label: messageContainer[constants.MessageContainer.ADJUST_ALL_COLUMNS_WIDTH],
                    icon: <i className="fas fa-th-list" />,
                    onClick: e => {
                        let browseColumnsWidth = []
                        props.columnOrder?.forEach((columnId) => {
                            browseColumnsWidth.push(getNumOfLettersFromOffsetWidth(columnId, false))
                        })

                        sendMessage(createMessage(SET_ATTRIBUTE, createRequest({
                            browseHandle: browseId,
                            columnsOrder: props.columnOrder?.toString(),
                            browseColumnsWidth: browseColumnsWidth.toString()
                        })), (response) => {
                            if (response.data && response.data.length > 0) {
                                response.data.forEach(resp => {
                                    if (resp.OkResponse) {
                                        dispatch(resizeBrowseColumns({
                                            tabId: tabId,
                                            maskId: maskId,
                                            columnsOrder: props.columnOrder,
                                            browseColumnsWidth: browseColumnsWidth
                                        }))
                                    }
                                })
                            }
                        })
                    }
                }, {
                    //PRIZPUSOBIT VSECHNY SLOUPCE VCETNE HLAVICKY
                    id: "2_4",
                    label: messageContainer[constants.MessageContainer.ADJUST_ALL_COLUMNS_WIDTH_WITH_HEADER],
                    icon: <i className="fas fa-th-list" />,
                    onClick: e => {
                        let browseColumnsWidth = []
                        props.columnOrder?.forEach((columnId) => {
                            browseColumnsWidth.push(getNumOfLettersFromOffsetWidth(columnId, true))
                        })

                        sendMessage(createMessage(SET_ATTRIBUTE, createRequest({
                            browseHandle: browseId,
                            columnsOrder: props.columnOrder?.toString(),
                            browseColumnsWidth: browseColumnsWidth.toString()
                        })), (response) => {
                            if (response.data && response.data.length > 0) {
                                response.data.forEach(resp => {
                                    if (resp.OkResponse) {
                                        dispatch(resizeBrowseColumns({
                                            tabId: tabId,
                                            maskId: maskId,
                                            columnsOrder: props.columnOrder,
                                            browseColumnsWidth: browseColumnsWidth
                                        }))
                                    }
                                })
                            }
                        })
                    }
                }
            ],
        },
        {
            //DIALOG - NASTAVENI SLOUPCU
            id: "3",
            label: messageContainer[constants.MessageContainer.MANAGE_COLUMNS],
            icon: <i className="fas fa-columns"></i>,
            onClick: e => { dispatch(onValuesChangeInMask(browseId, tabId, maskId, { [COLUMNS_SETTING_PROP]: true })) }
        },
        {
            //VYCHOZI NASTAVENI SLOUPCU
            id: "4",
            label: textWithMnemonic(tableColumnsOrder.label, false, true),
            icon: <i className="fas fa-undo"></i>,
            onClick: e => {
                sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                    ...getEmptyRowProp(),
                    handle: currentFocusId,
                    newFocus: DEFAULT_TABLE_COLUMNS_ORDER,
                    action: MENU_ACTION
                })))
            }
        },
    ]


    let textAlign = "left"
    //zarovnani decimal a integer do prava
    /*if (hColumn.dataType === "DECIMAL" || hColumn.dataType === "INTEGER")
        textAlign = "right"*/


    /*let pinCol = "", boxShadow
    if (colPosition === 0) {
        pinCol = "fixed-td fixed-hd left-top-td "
        boxShadow = 'rgba(0, 0, 0, 0.2) -4px 0px 8px -6px inset'
    }*/

    function renderSortIcon(sortType) {
        let sortIcon
        if (props.sortedByHandle === hColumn.handle) {
            if (sortType === -1)
                sortIcon = "fas fa-sort-up text-danger"
            else if (sortType === 1)
                sortIcon = "fas fa-sort-up text-success"
            else if (sortType === -2)
                sortIcon = "fas fa-sort-down text-danger"
            else if (sortType === 2)
                sortIcon = "fas fa-sort-down text-success"
        } else {
            if (sortType == undefined)
                return ""
            else
                sortIcon = "fas fa-sort text-secondary"
        }

        return <div>
            <i className={sortIcon} />
        </div>
    }

    function SettingsIcon() {
        return <div id={headerColumnSettingsButton} className='hover-browse-header-icon' ref={iconRef} onClick={e => {
            handleContextMenu(e)
        }}>
            <i id={headerColumnSettingsIcon} className="fas fa-ellipsis-v icon-container" />
        </div>
    }

    function TextOfHeader() {
        return <div
            id={headerColumnSettingsText}
            style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }}>
            {hasCheckbox ? "" : ' ' + lbl}
        </div>
    }

    return (<th
        ref={thRef}
        className={"table_header col-3"/*+ pinCol */}
        id={"b_col_" + colPosition + "."}
        onContextMenu={handleContextMenu}
        style={{
            height: ROW_HEIGHT + 'px',
            width: columsWidth[colPosition].colWidthPx,
            position: 'relative'
        }}
        scope="col"
        key={hKey}>
        <div
            className='hover-browse-cell'
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                cursor: (hColumn.sortType !== undefined ? "pointer" : "")
            }}>
            <div
                id={headerColumnSettingsColumn}
                title={hColumn.tooltip}
                ref={headerRef}
                style={{
                    display: 'flex',
                    width: columsWidth[colPosition].colWidth - ICONS_WIDTH,
                    alignItems: 'center',
                    justifyContent: "flex-start",
                    gap: '5px'
                }}>

                {renderSortIcon(hColumn.sortType, hColumn.handle)}

                {renderCheckbox()}

                <TextOfHeader />

            </div>

            <SettingsIcon />
        </div>
        <ColumnResizer
            tableElementRef={tableElementRef}
            childIds={childIds}
            columnHandle={hColumn.handle}
            handleResize={props.handleResize}
            sizePerPage={props.sizePerPage}
            browseId={props.browseId}
            colPosition={colPosition}
            newColWidth={newColWidth}
            adjustColumnWidth={adjustColumnWidth}
            updateColWidth={updateColWidth} />

        {showCM && <DropdownMenu
            ref={dropdownMenuRef}
            data={options}
            xPos={positionCM.x}
            yPos={positionCM.y}
            setShowMenu={setShowCM} />}
    </th >)
}

function areEqual(prevProps, nextProps) {
    //console.log("ARE EQUAL", prevProps.desktopSessionMask[prevProps.browseId].data.browseColsSorted === nextProps.desktopSessionMask[nextProps.browseId].data.browseColsSorted, prevProps.desktopSessionMask[prevProps.browseId].data.browseColsSorted, nextProps.desktopSessionMask[nextProps.browseId].data.browseColsSorted)
    //console.log("PREV PREV_PROPS", prevProps)
    //console.log("PREV NEXT_PROPS", nextProps)
    //console.log("ARE EQUAL", prevProps.hColumn.internalState === nextProps.hColumn.internalState)
    if (prevProps.hColumn.width === nextProps.hColumn.width
        && prevProps.hColumn.visible === nextProps.hColumn.visible
        && prevProps.hColumn.label === nextProps.hColumn.label
        && prevProps.hColumn.tooltip === nextProps.hColumn.tooltip
        && prevProps.hColumn.sortType === nextProps.hColumn.sortType
        && prevProps.sortedByHandle === nextProps.sortedByHandle
        && prevProps.hColumn.internalState === nextProps.hColumn.internalState
        && prevProps.hColumn.inTableWidth === nextProps.hColumn.inTableWidth
        && prevProps.hColumn.isModalOpen === nextProps.hColumn.isModalOpen
        && prevProps.desktopSessionMask[prevProps.browseId].data.browseColsSorted === nextProps.desktopSessionMask[nextProps.browseId].data.browseColsSorted
        && prevProps.desktopMenu[MenuActions.SELECT_ALL] == nextProps.desktopMenu[MenuActions.SELECT_ALL]
        && prevProps.sizePerPage === nextProps.sizePerPage
    )
        return true

    return false
}

export default React.memo(BrowseHeaderColumn, areEqual)
