import React from 'react'
import PropTypes from 'prop-types'
import { Toast } from 'bootstrap/dist/js/bootstrap.bundle.min.js'

import { logRender, logRenderProps } from '../utils/logging'

/**
 * default values: autohide = true, delay = 5000
 * @param {{iconClassName: string, textHeader: string, textBody: string, autohide: boolean, delay: number}} props 
 */
export default function BSToast(props) {
    const toastRef = React.useRef()
    let { iconClassName, textHeader, textBody, autohide, delay } = props

    React.useEffect(() => {
        const bsToast = new Toast(toastRef.current)
        bsToast.show()

        return () => {
            bsToast.dispose()
        }
    }, [])

    logRender("BSToast")
    logRenderProps(props)
    return (
        <div className="toast-container top-0 end-0 p-3">
            <div
                className="toast"
                ref={toastRef}
                data-bs-autohide={autohide}
                data-bs-delay={delay}
                role="alert"
                aria-live="assertive"
                aria-atomic="true">
                <div className="toast-header">
                    {iconClassName ? <i className={iconClassName + " me-2"} /> : ""}

                    <strong className="me-auto">{textHeader}</strong>
                    {/*<small>11 mins ago</small>*/}
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div className="toast-body" style={{whiteSpace: 'pre-wrap' }}>
                    {textBody}
                </div>
            </div>
        </div>
    )
}

BSToast.defaultProps = {
    /*textHeader: "header",
    textBody: "body"*/
}

BSToast.protoTypes = {
    iconClassName: PropTypes.string,
    textHeader: PropTypes.string,
    textBody: PropTypes.string,
    autohide: PropTypes.bool,
    delay: PropTypes.number,
}