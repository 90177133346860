import Constants from './constants/constants'
import { getStore } from './index'
import { getElementData, saveValue2Store } from './components/desktop_server/elementMethods'
import constants from './constants/constants'
import { getEmptyRowProp } from './utils/actions'
import { logDebug } from './utils/logging'

const { Keyboard, SpecialId, WebSocketActions, SetValueRequest, LocalStorageKeys } = Constants
const { INPUT_FILE_NAME } = SpecialId
const { SET_VALUE_REQUEST } = WebSocketActions
const { TAB_ACTION, SHIFT_TAB_ACTION } = SetValueRequest

export function isLightTheme() {
    return localStorage.getItem(LocalStorageKeys.DARK_THEME) === 'false' || localStorage.getItem(LocalStorageKeys.DARK_THEME) === null
}

export function isEntryable(event) {
    let element = getEventElement(event)
    //console.log("ELEMENT", element)

    if (element === undefined) return false

    //pokud je InputFieldElement v Browsu, tak ma sensitivutu undefined ale readonly = false
    if ((element.data.sensitivity || element.data.readOnly === false) && (element.componentName === "InputFieldElement"
        || (element.componentName === "EditorElement" && element.data.readOnly === false)
        || element.componentName === "ComboBoxElement"
        || element.componentName === "SelectionElement"))
        return true

    return false
}

export function getElement(elementId) {
    let store = getStore().getState()
    let ws = store.ws
    return store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId]
}

export function existSystemErrorDialog() {
    let existErrorDialog = false
    getStore().getState().messageDialog?.forEach(dialog => {
        if (dialog.errorType === constants.ERRORS.ERROR_SYSTEM)
            return existErrorDialog = true
    })

    return existErrorDialog
}

export function getEventElement(e) {
    let store = getStore().getState()
    let ws = store.ws
    let elementId

    //underline text - napr v button
    if (e.target.tagName?.toLowerCase()?.toLowerCase() === "u")
        elementId = e.target.parentElement.id

    if (e.target.id) {
        elementId = e.target.id.split(";")[0]

        //browse cell handle
        if (e.target.id.indexOf("b_col_") > -1)
            elementId = e.target.id.split("_")[3]

        //browse header
        if (e.target.id.indexOf("header_options_column") > -1)
            elementId = e.target.id.split("_")[3]
    }

    logDebug("getEventElement", e.type, e)


    if (elementId)
        return store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId]
}

export function disableOtherListeners(event, type, fromElement) {
    //console.log("DISABLE disableOtherListeners", fromElement, type)
    if (fromElement?.data.specialId === INPUT_FILE_NAME)
        return

    disableListeners(event)
}

function disableListeners(event) {
    //console.log("disableListeners", event)
    event.stopImmediatePropagation()
    event.preventDefault()
}

export function doTabAction(ws_context, event, shift) {
    let store = getStore().getState()
    let ws = store.ws

    let elementId = event.target.id.split(";")[0]
    let element = store.desktopSession[ws.currentTabId][ws.currentMaskID][elementId]

    const { createMessage, createRequest, sendMessage } = ws_context

    if (event.keyCode === Keyboard.TAB && element.componentName === "EditorElement")
        return

    if ((event.keyCode === Keyboard.UP || event.keyCode === Keyboard.DOWN)
        && (element.componentName === "ComboBoxElement"
            || element.componentName === "SelectionElement"
            || element.componentName === "RadioSetElement"))
        return

    disableOtherListeners(event, "keydown tab", element)

    let wsAction = shift ? SHIFT_TAB_ACTION : TAB_ACTION
    let elData2Save = getElementData(ws.focusId)

    let requestData = {
        handle: ws.focusId,
        action: wsAction
    }

    if (elData2Save.shouldSaveValue) {
        saveValue2Store(ws.focusId, elData2Save)
        requestData = {
            ...getEmptyRowProp(),
            ...requestData,
            value: elData2Save.value,
        }
    }

    sendMessage(createMessage(SET_VALUE_REQUEST, createRequest(requestData)))
}