import React from 'react'

import { WebSocketContext } from '../../services/WebSocket'
import Constants from '../../constants/constants'
import { addListener } from '../../hooks/desktopListener'
import useStoreSubscription from '../../hooks/useStoreSubscription'
import { textWithMnemonic } from '../../utils/screen'
import { getEmptyRowProp } from '../../utils/actions'
import { renderMenuItemChildrens } from '../Navbar'
import { focusElement, ACTION_ESCAPE_NAVBAR } from '../../focus_actions'

import { LISTENER_TYPE } from '../../hooks/desktopListener'
import BSTooltip from '../BSTooltip'
import { hideAllSubmenus, hideAllDropdowns } from '../NavbarItem'
import { getThemeVariables } from '../../theme/getThemeVariables'
import styles from '../../constants/styles'

const { CLICK, KEYDOWN } = LISTENER_TYPE
const { NEW_RECORD, CHANGE_RECORD, DUPLICATE_RECORD, DELETE_RECORD, SHOW_DETAIL_RECORD,
    FUNCTIONS } = Constants.MenuActions
const { SET_VALUE_REQUEST } = Constants.WebSocketActions
const { MENU_ACTION } = Constants.SetValueRequest

/**
 * Spousteni akci na zaznamem tabulky pomoci tlacitek:
 *  - novy, edit, duplikovat, smazat a maska/normalni zobrazeni
 * @param {*} props
 * @returns
 */
function RecordActions(props) {
    const ws_context = React.useContext(WebSocketContext)

    const [hasFocus, setFocus] = React.useState(false)

    const btnNew = React.useRef()
    const btnEdit = React.useRef()
    const btnDuplicate = React.useRef()
    const btnDelete = React.useRef()
    const btnDetail = React.useRef()
    const btnFunctions = React.useRef()

    const { createMessage, createRequest, sendMessage } = ws_context
    const { desktopMenu, tabId, maskId } = props
    const BTN_FUNCTIONS = "record_actions_functions_btn"

    let ws, currentFocusId, focusedElement
    useStoreSubscription(store => {
        ws = store.ws
        currentFocusId = ws.focusId
    })

    addListener(CLICK, btnDetail, (e) => { doMenuAction(e, SHOW_DETAIL_RECORD) })
    addListener(CLICK, btnNew, (e) => doMenuAction(e, NEW_RECORD))
    addListener(CLICK, btnEdit, (e) => doMenuAction(e, CHANGE_RECORD))
    addListener(CLICK, btnDelete, (e) => doMenuAction(e, DELETE_RECORD))
    addListener(CLICK, btnDuplicate, (e) => doMenuAction(e, DUPLICATE_RECORD))

    //zabrani zavreni tabu
    addListener(KEYDOWN, btnFunctions, e => {
        if (e.key === "Escape") {
            focusElement(currentFocusId, "RecordActions(Escape)", ACTION_ESCAPE_NAVBAR)

            e.preventDefault()
            e.stopPropagation()
        } else if (e.key === "0" && e.target.id === BTN_FUNCTIONS) {
            //opetovne zmacnuti nuly provede focus na puvodni element
            focusElement(currentFocusId, "RecordActions(0)")
        }
    })

    addListener("hidden.bs.dropdown", btnFunctions, e => {
        hideAllSubmenus()
    })
    //schovani submenu pri kliku mimo - nefunguje hidden.bs.dropdown
    addListener(CLICK, document, (event) => {
        //console.log(event.target)
        if (btnFunctions.current && !btnFunctions.current.contains(event.target))
            hideAllSubmenus()
    })

    function doMenuAction(event, action) {
        if (!event instanceof MouseEvent || action == null || action == "") {
            return
        }

        if (desktopMenu[action].sensitivity)
            sendMessage(createMessage(SET_VALUE_REQUEST, createRequest({
                ...getEmptyRowProp(),
                handle: currentFocusId,
                newFocus: action,
                action: MENU_ACTION
            })))
    }

    const showDetailRecord = desktopMenu[SHOW_DETAIL_RECORD]
    const newRecord = desktopMenu[NEW_RECORD]
    const changeRecord = desktopMenu[CHANGE_RECORD]
    const duplicateRecord = desktopMenu[DUPLICATE_RECORD]
    const deleteRecord = desktopMenu[DELETE_RECORD]
    const functiosRecord = desktopMenu[FUNCTIONS]
    //console.log("RENDER record action", hasFocus, props)
    return (<div className="btn-group" style={{ position: "unset" }}>
        <div className='d-flex gap-1'>
            <BSTooltip tooltiptext={showDetailRecord.tooltip} shortcuts={[showDetailRecord.accelerator, showDetailRecord.accelerator2, showDetailRecord.accelerator3]}>
                <button type="button" className="btn btn-primary" style={styles.buttonStyle} disabled={!showDetailRecord.sensitivity} ref={btnDetail}>
                    <i className="far fa-eye"></i> {/*textWithMnemonic(desktopMenu[SHOW_DETAIL_RECORD].label, false)*/}
                </button>
            </BSTooltip>
            <BSTooltip tooltiptext={newRecord.tooltip} shortcuts={[newRecord.accelerator, newRecord.accelerator2, newRecord.accelerator3]}>
                <button type="button" className="btn btn-success" style={styles.buttonStyle} disabled={!newRecord.sensitivity} ref={btnNew}>
                    <i className="fas fa-plus"></i> {/*textWithMnemonic(desktopMenu[NEW_RECORD].label, false)*/}
                </button>
            </BSTooltip>
            <BSTooltip tooltiptext={changeRecord.tooltip} shortcuts={[changeRecord.accelerator, changeRecord.accelerator2, changeRecord.accelerator3]}>
                <button type="button" className="btn btn-warning" style={styles.buttonStyle} disabled={!changeRecord.sensitivity} ref={btnEdit}>
                    <i className="fas fa-edit"></i> {/*textWithMnemonic(desktopMenu[CHANGE_RECORD].label, false)*/}
                </button>
            </BSTooltip>
            <BSTooltip tooltiptext={duplicateRecord.tooltip} shortcuts={[duplicateRecord.accelerator, duplicateRecord.accelerator2, duplicateRecord.accelerator3]}>
                <button type="button" className="btn btn-info" style={styles.buttonStyle} disabled={!duplicateRecord.sensitivity} ref={btnDuplicate}>
                    <i className="fas fa-clone"></i> {/*textWithMnemonic(desktopMenu[DUPLICATE_RECORD].label, false)*/}
                </button>
            </BSTooltip>
            <BSTooltip tooltiptext={deleteRecord.tooltip} shortcuts={[deleteRecord.accelerator, deleteRecord.accelerator2, deleteRecord.accelerator3]}>
                <button type="button" className="btn btn-danger" style={styles.buttonStyle} disabled={!deleteRecord.sensitivity} ref={btnDelete}>
                    <i className="fas fa-trash"></i> {/*textWithMnemonic(desktopMenu[DELETE_RECORD].label, false)*/}
                </button>
            </BSTooltip>
            <BSTooltip tooltiptext={textWithMnemonic(functiosRecord.label)} shortcuts={[functiosRecord.accelerator, functiosRecord.accelerator2, functiosRecord.accelerator3]}>
                {/*pouzity classname navbar, kvuli preskakovani submenu*/}
                <div className={"dropdown navbar" + (props.zoomFrameVisibility ? "" : " dropup")} ref={btnFunctions} style={{
                    padding: 0,
                    boxShadow: '0px 0px 0px'
                }}>
                    <button
                        id={BTN_FUNCTIONS} className="btn btn-primary me-1 webtop-dropdown-items" style={styles.buttonStyle} disabled={!functiosRecord.sensitivity} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {textWithMnemonic(functiosRecord.label + " ", false)}
                        <i className="far fa-angle-down" /> {/*pri pouziti classnem="dropdown-toggle" nefungoval listener "hide.bs.dropdown"*/}
                    </button>
                    <ul
                        id="dropdown_menu_record_actions_functions_ul_bottom"
                        className="dropdown-menu">
                        {renderMenuItemChildrens(functiosRecord, desktopMenu, false, "record_actions")}
                    </ul>
                </div>
            </BSTooltip>
        </div>
    </div >)
}

function arePropsEqual(prevProps, nextProps) {
    return (JSON.stringify(prevProps.desktopMenu) === JSON.stringify(nextProps.desktopMenu)
        && prevProps.zoomFrameVisibility === nextProps.zoomFrameVisibility)
}

export default React.memo(RecordActions, arePropsEqual)
