import React from 'react'
import { connect } from 'react-redux'

import { renderChildrens2Screen } from '../renderChildrens2Screen'
import { logRender, logRenderProps } from '../../utils/logging'
import { getThemeVariables } from '../../theme/getThemeVariables'
import constants from '../../constants/constants'
import { onValueChange } from '../../store/actions/store_actions'
import BSTooltip from '../BSTooltip'

function FrameElementXY(props) {
    let { data, messageContainer } = props
    let { x, y, width, height } = data

    logRender("FrameElementXY")
    logRenderProps(props)

    function boxFrame() {
        if (props.data.box)
            return <div style={{
                borderTop: "1px inset",
                paddingBottom: "10px",
                marginTop: "2px",
                marginBottom: "2px"
            }} />
    }

    //pokud obsahuje frame browse, tak se nastavi max zIndex  kvuli prekrivani kontextovemu menu funkci
    function getLayerOrder() {
        let zIndex = props.data.layerOrder
        function getMaxLayerOrder() {
            let layerOrder = props.data.layerOrder
            props.childIds.forEach(elementId => {
                if (props.desktopSessionMask[elementId].data.layerOrder > layerOrder)
                    layerOrder = props.desktopSessionMask[elementId].data.layerOrder
            })
        }

        props.childIds.forEach(elementId => {
            if (props.desktopSessionMask[elementId].componentName === "BrowseElement") {
                zIndex = getMaxLayerOrder()
            }
        })

        return zIndex
    }

    //Pokud rodic je DialogElement musi se odecist jeho x pozice    
    let parentElement = props.desktopSessionMask[props.data.parentHandle]
    //console.log("parentElement X<Y", parentElement, parentElement.componentName)
    if (parentElement?.componentName === "DialogElement")
        x = x - parentElement.data.x

    //let minHeight = props.data.height * 2 + 'rem'
    function ZommFrame() {
        //zoom frame je napevno prirazen k radku browsu, pokud browse ma sensitivity === false, tak zoomframe musi byt taky sensitivity === false a nemuze fungovat schovani/zobrazeni zoom
        let browseFrameSensitivity
        props.desktopSessionMask[0]?.childIds.forEach(id => {
            let rootElementChildren = props.desktopSessionMask[id]
            if (rootElementChildren.data.specialId === constants.SpecialId.BROWSE_FRAME) {
                let frameOfBrowseId = rootElementChildren.childIds[0]
                let browseFrame = props.desktopSessionMask[frameOfBrowseId]
                browseFrameSensitivity = browseFrame.data.sensitivity
            }
        })

        function Accordion() {
            let tootltipText = props.zoomFrameVisibility ? messageContainer[constants.MessageContainer.HIDE_ZOOM_FRAME] : messageContainer[constants.MessageContainer.SHOW_ZOOM_FRAME]
            return <div className="accordion" id="accordionZoom">
                <BSTooltip tooltiptext={tootltipText}>
                    <div className="hover-icon parent-hr" data-bs-toggle="collapse" data-bs-target="collapseOne" aria-expanded="true" aria-controls="collapseOne"
                        style={{ cursor: "pointer", display: 'flex', alignItems: 'center', gap: "10px" }}
                        onClick={e => {
                            if (browseFrameSensitivity) {
                                props.dispatch(onValueChange("zoomFrameVisibility", !props.zoomFrameVisibility))
                                localStorage.setItem(constants.LocalStorageKeys.ZOOM_FRAME_VISIBILITY, !props.zoomFrameVisibility)
                            }
                        }}>
                        <i className={(props.zoomFrameVisibility ? "far fa-chevron-down" : "far fa-chevron-up") + " font-icon"} />
                        <hr className="w-100 font-icon hr-hover" style={{ marginTop: ".7rem", marginBottom: ".7rem" }} />
                        <i className={props.zoomFrameVisibility ? "far fa-chevron-down" : "far fa-chevron-up" + " font-icon"} />
                    </div>
                </BSTooltip>
                <div id="collapseOne" className={"accordion-collapse collapse" + (props.zoomFrameVisibility ? " show" : "")} data-bs-parent="#accordionZoom">
                    {renderChildrens2Screen(props)}
                </div>
            </div>
        }

        return <Accordion />
    }

    function contentOfFrame() {
        if (props.data.visible
            && props.data.specialId === constants.SpecialId.ZOOM_FRAME
            && props.childIds?.length > 0) {
            return <ZommFrame />
        }

        return renderChildrens2Screen(props)
    }

    let frame_key = "frame_" + props.id
    if (props.data.specialId === constants.SpecialId.ZOOM_FRAME)
        frame_key = constants.ElementsIds.ZOOM_FRAME
    else if (props.data.specialId === constants.SpecialId.RIGHT_FRAME)
        frame_key = constants.ElementsIds.RIGHT_FRAME
    else if (props.data.specialId === constants.SpecialId.HEADER_FRAME)
        frame_key = constants.ElementsIds.HEADER_FRAME
    else if (props.data.specialId === constants.SpecialId.BROWSE_FRAME)
        frame_key = constants.ElementsIds.BROWSE_FRAME

    //console.log("RENDER FRAME", props.id, props.data.specialId, props)
    return (<div
        id={frame_key}
        style={{
            zIndex: getLayerOrder(),
            gridArea: y + " / " + x + " / " + (y + height) + " / " + (x + width),
            display: 'inherit',
            background: getThemeVariables().cardBodyColor,
            //border: '1px solid green'
            //minHeight: minHeight
        }}>
        {
            (props.data.header === "&Null;" || props.data.header === undefined) ? "" : (
                <h4 className="card-header mb-2">
                    {props.data.header /*+ " " + props.id*/}
                </h4>
            )
        }

        {/*boxFrame()*/}

        {contentOfFrame()}
        {/*renderChildrens2Screen(props)*/}
    </div >)
}

function mapStateToProps(state, ownProps) {
    return {
        ...state.desktopSession[ownProps.tabId][ownProps.maskId][ownProps.id],
        messageContainer: state.app.messageContainer,
        zoomFrameVisibility: state.zoomFrameVisibility
    }
}

export default connect(mapStateToProps)(FrameElementXY)
