import React from 'react';
import { useContext } from 'react';
import { WebSocketContext } from '../services/WebSocket'
import { useDispatch } from 'react-redux'
import { loadNavigatorDataFromRoot } from '../components/MenuItem'
import ElementWithListener from './ElementWithListener'

/**
 * Klikaci cesta k programu v ramci vysledku vyhledavani z horniho vyhlevace
 * @param {*} props
 * @returns
 */
export default function createBreadCrumb(props) {
    let { menuPath, result } = props
    const ws_context = useContext(WebSocketContext)
    const dispatch = useDispatch()

    if (menuPath == "") return ""

    let isValidMenuPath = false
    if (menuPath.substring(0, 1).match(/^\d/)) {
        isValidMenuPath = true //cesta (breadcrumb) zacina cislem
    }

    if (isValidMenuPath) {
        let level = ""

        return (
            menuPath.split(" > ").map((item, index) => {
                if (level !== "") level += "."
                level += item.split(".")[0]

                let localLevel = level
                return <ElementWithListener key={"breadcrumb_" + level + "."}
                    doAction={(event) => {
                        if (!event instanceof MouseEvent) return

                        event.stopPropagation() //aby se neaktivovalo spusteni radku
                        loadNavigatorDataFromRoot(localLevel, ws_context, dispatch, false)
                    }
                    }
                    keyListener={false}
                    mouseListener={true}>
                    <>{(index > 0) ? <span className="text-muted small mt-1">&gt;&nbsp;</span> : ""}<span className="text-muted small mt-1 hover-underline">{item}</span></>
                </ElementWithListener>
            })
        )
    } else {
        return <span className="text-muted small mt-1">{menuPath}</span>
    }

}
