import Constants from '../constants/constants'

const { LocalStorageKeys } = Constants

const DEFAULT_TEXT_COLOR = "#34eb65"

export function log(...msg) {
    msg.forEach(obj => {
        if (Array.isArray(obj))
            obj.forEach(obj2 => {
                console.log(obj2)
            })
        else
            console.log(obj)
    })
}

export function logColored(color, msg) {
    console.log('%c ' + msg, 'color: ' + color)
}

export function logDebug(...msg) {
    if (localStorage.getItem(LocalStorageKeys.LOGGING_DEBUG) === "true")
        log(msg)
}

export function logRender(msg) {
    if (localStorage.getItem(LocalStorageKeys.LOGGING_RENDER) === "true") {
        let date = new Date()
        logColored(DEFAULT_TEXT_COLOR, date.getMinutes() + ":" + date.getMilliseconds() + " render() -> " + msg)
    }
}

export function logDoFocus(id, calledFrom) {
    if (localStorage.getItem(LocalStorageKeys.LOGGING_FOCUS) === "true")
        logColored(DEFAULT_TEXT_COLOR, "focus() -> " + id + "(" + calledFrom + ")")
}

export function logRenderProps(obj) {
    if (localStorage.getItem(LocalStorageKeys.LOGGING_PROPS) === "true")
        log(obj)
}

export function logWS(tag, jsonObjectMsg) {
    if (localStorage.getItem(LocalStorageKeys.LOGGING_WEB_SOCKETS) === "true")
        logColored(DEFAULT_TEXT_COLOR, tag + ": " + JSON.stringify(jsonObjectMsg))
}

function asyncTemplate() {
    const asyncTask = () => {
        return new Promise((resolve, reject) => {
            const executerId = setTimeout(() => {
                reject('Something not working!');
                clearTimeout(executerId)
            }, 1000);
        });
    }

    const foo = async () => {
        try {
            const res = await asyncTask();
            console.log(res);
        } catch (err) {
            console.log(err);
        }
        console.log('After calling AsyncTask');
    }

    foo();
}