import React from 'react'
import { useDispatch, useStore } from 'react-redux'

import Constants from '../constants/constants'
import {
    setWSConnectionData, wsProcessScreenData, wsSaveNavigatorMenuItems, setWSFocusId, setWSDisconnect,
    setBrowseDataAction, addMessageDialog, emptyDesktopStore, setBrowseDataAndSelect, wsSaveFavoritesItems,
    wsSaveHistoryItems, wsSetFavouritePopup, wsSetNavigatorPopup, setWSReconnectData, setDashboards, setWidgets,
    setWidgetData, addNotifications, wsSetHistoryPopup, triggerBrowseGo
} from '../store/actions/ws_actions'
import { showSpinner, setInterruptAvailability, onValuesChangeByHandle, onValuesChangeInMask } from '../store/actions/store_actions'
import { logWS, logDebug } from '../utils/logging'
import { focusElement } from '../focus_actions'
import { appendFileData, getFile, deleteRecord, findAndPrint, TABLE_FILES } from '../store/indexedDB'
import { uuidv4 } from '../utils/actions'
import { clientUUID, getStore, resetSession } from '../index'
import conf from '../resources/config.json'

import { WS_MESSAGE_VERSION } from '../constants/config'
import { getBrowser, getBrowserVersion, getOS, getOsVersion, isMobileVersion, areCookiesEnabled } from '../utils/clientInfo'
import { tranform2TableData } from '../components/browse/data'
import { findAndOpen, findAndDownload } from '../store/indexedDB'
import { updateMenuFromNavigatorItems } from '../components/MenuItem'
import { DOWN, getNumberOfRows, UP } from '../components/browse/Browse'
import { BREAK_BUTTON } from '../components/desktop_server/ModalDialogElement'
//import { error } from 'console'

const { CONNECT, GET_SCREEN, GET_BROWSE_DATA_REQUEST, GET_BROWSE_SCREEN_DATA_REQUEST, SET_VALUE_REQUEST,
    CLOSE_SESSION, TERMINATE, GET_BROWSE_DATA_AND_SELECT_REQUEST, ACTION_GET_NAVIGATOR,
    ACTION_GET_FAVORITES, ACTION_GET_HISTORY, DOWNLOAD_CHUNK, UPLOAD_FILE_CHUNK, ACTION_ERROR, INTERRUPT, PING } = Constants.WebSocketActions
const { BRW_HOME, BRW_END, SEARCH } = Constants.SetValueRequest
const { CURRENT_LANGUAGE } = Constants.LocalStorageKeys
const { chunk_size, LocalStorageKeys } = Constants

export const KEY_REQUEST = "key_request"
export const STATUS_OPEN = "open"
export const STATUS_CLOSE = "close"
export const STATUS_ERROR = "error"

const showSpinnerTimeLimit = 400 //delay, nez se zobrazi spinner

export const WebSocketContext = React.createContext()
const WebSocketProvider = ({ children }) => {
    /*logDebug("@@@@@@@@@@@@@@@@@@@@@@@@@")
    logDebug("@@@ WebSocketProvider @@@")
    logDebug("@@@@@@@@@@@@@@@@@@@@@@@@@")*/

    let sendingInProgress = false
    let sendingInProgressNoBlock = false
    let interrutionInProgress
    let wsClient
    let wsMessagesDialogs = []
    let configFileData
    //let clientUUID
    let lastRequest = new Date().getTime() //timestamp, kdy byl predan posledni pozadavek ke zpracovani
    let wsStatus = STATUS_CLOSE

    const getRequestsQueue = () => {
        return requests
    }

    const getRequestsNoBlock = () => {
        return requestsNoBlock
    }

    const setInterruptionInProgress = val => {
        //console.log("---------------------------> SET INTERRUTION IN PROGRESS", val)
        interrutionInProgress = val
    }

    //fronta do ktere spadnou vsechny pozadavky a vyuziva se aby bylo mozne zavolat callback
    const requestsImmediately = (() => {
        let queue = []

        return {
            get: () => { return queue },
            getElement: (messageUUID) => { return queue.find(item => item.id === messageUUID) },
            enqueue: (element) => { queue.push(element) },
            dequeue: (messageUUID) => {
                for (var i = 0; i < queue.length; i++) {
                    if (queue[i].id === messageUUID) {
                        queue.splice(i, 1)
                        i--
                    }
                }
            },
            size: () => { return queue.length },
            erase: from => { queue = [] }
        }
    })()

    //fronta do ktere lze vkladat pozadavky bez blokace
    const requestsNoBlock = (() => {
        let queue = []

        return {
            get: () => { return queue },
            getElement: messageUUID => { return queue.find(item => item.id === messageUUID) },
            getElementMessageData: (messageUUID) => {
                //console.log(queue, messageUUID)
                let element = queue.find(item => {
                    //console.log(item)
                    return item.id === messageUUID
                })

                if (element != undefined) {
                    if (element.message.data.length === 0)
                        return []

                    return element.message.data[0].request
                }
            },
            enqueue: element => { queue.push(element) },
            enqueueOrUpdate: element => {
                const index = queue.findIndex(object => { return object.inProgress === false && object.message.action === element.message.action })
                //console.log("INDEX", index)
                if (index === -1)
                    queue.push(element) //new
                else {
                    //console.log("UPDATE", queue[index], element)
                    queue[index] = element //update
                }

                //queue.forEach(item => console.log(item))
            },
            dequeue: messageUUID => {
                for (var i = 0; i < queue.length; i++) {
                    if (queue[i].id === messageUUID) {
                        queue.splice(i, 1)
                        i--
                    }
                }
                //console.log("--------------- DEQUEUED: " + queue.length, queue)
                //queue.forEach(item => console.log(item))
            },
            size: () => { return queue.length },
            erase: from => {
                queue = []
                sendingInProgressNoBlock = false
            },
            eraseNotRunningRequests: () => {
                queue = queue.filter(item => item.inProgress)
            }
        }
    })()

    //fronta - blokuji se udalosti - nevytvari se dalsi request pokud neni fronta prazdna
    const requests = (() => {
        let queue = []

        return {
            get: () => { return queue },
            getElement: (messageUUID) => { return queue.find(item => item.id === messageUUID) },
            getElementMessageData: (messageUUID) => {
                let element = queue.find(item => item.id === messageUUID)

                if (element != undefined) {
                    if (element.message.data.length === 0)
                        return []

                    return element.message.data[0].request
                }
            },
            enqueue: (element) => {
                //console.log("--------------- ENQUE", queue.length, queue)
                //queue.forEach(item => console.log(item))
                //console.log("element", element)

                /*konrola zda uz neexistuje s klicem ve fronte
                -> keyrequest se zatim pouziva pro odfiltrovani duplicitncich ws pozdadavku např. v browsu při držení tlačítka dolu*/
                let notInQueue = true
                if (element.message.keys != undefined) {
                    let key = element.message.keys[KEY_REQUEST]
                    //console.log("KEY", key)
                    if (key != undefined)
                        if (queue.find(item => {
                            if (item.message.keys == undefined)
                                return false

                            return item.message.keys[KEY_REQUEST] === key
                        }) != undefined)
                            notInQueue = false
                }

                if (notInQueue) {
                    queue.push(element)
                }
            },

            dequeue: (messageUUID) => {
                for (var i = 0; i < queue.length; i++) {
                    if (queue[i].id === messageUUID) {
                        queue.splice(i, 1)
                        i--
                    }
                }
                //console.log("--------------- DEQUEUED: " + queue.length, queue)
                //queue.forEach(item => console.log(item))
            },
            size: () => { return queue.length },
            erase: (from) => { queue = [] }
        }
    })()

    const dispatch = useDispatch()

    let store = useStore()
    let { ws, desktopSession, desktopNavigatorMenu } = store.getState()
    store.subscribe(() => {
        ws = store.getState().ws
        desktopSession = store.getState().desktopSession
        desktopNavigatorMenu = store.getState().desktopNavigatorMenu
    })

    const handleResponse = (msg) => {
        if (msg.data.length === 0) {
            if (msg.action !== Constants.WebSocketActions.CLOSE_SESSION_OK)
                dispatch(setWSFocusId(msg.screenVariables))
        } else
            msg.data.forEach(response => {
                let responseEntity = Object.keys(response)[0]
                //console.log("handleResponse", responseEntity)
                switch (responseEntity) {
                    case "appData":
                        dispatch(setWSConnectionData({
                            connected: true,
                            appData: response["appData"],
                            messageContainer: response["messageContainer"].messages,
                            serverUUID: msg.server_uuid,
                            clientUUID: clientUUID
                        }))

                        break
                    case "ScreenDescriptionEntity":
                        //pridavani komponent do stromu
                        let screenDataResponse = getScreenDataFromResponse(msg, response)
                        dispatch(wsProcessScreenData(screenDataResponse.msgScreen, screenDataResponse.menuItems, msg.action, requests.getElementMessageData(msg.message_uuid), screenDataResponse.screenVariables))
                        break
                    case "BrowseDataResponse":
                        const startTime = performance.now()

                        var browseDataResponse = response["BrowseDataResponse"]
                        if (browseDataResponse != undefined) {
                            let bdata = desktopSession[ws.currentTabId][ws.currentMaskID][requests.getElementMessageData(msg.message_uuid).handle].data
                            let columnsOrder = bdata.browseColsSorted.split(",")
                            let newData = tranform2TableData(browseDataResponse, columnsOrder)

                            let borders = {
                                reachTop: browseDataResponse.reachTop,
                                reachBottom: browseDataResponse.reachBottom
                            }

                            if (msg.action === GET_BROWSE_SCREEN_DATA_REQUEST) {
                                let request = requests.getElementMessageData(msg.message_uuid)
                                let scrollOptions = {
                                    ...request.scrollOptions,
                                    action: GET_BROWSE_SCREEN_DATA_REQUEST
                                }

                                dispatch(setBrowseDataAction(browseDataResponse.browseHandle, newData, borders, scrollOptions))
                            } else if (msg.action === GET_BROWSE_DATA_REQUEST) {
                                let bData = addNewBrowseData(browseDataResponse, newData, msg.message_uuid, borders, msg.action)
                                dispatch(setBrowseDataAction(bData.browseHandle, bData.browseData, bData.borders, bData.scrollOptions))
                            }
                        }

                        //console.log(`process browse data response took ${performance.now() - startTime}ms`)

                        break
                    case "DashboardsResponse":
                        dispatch(setDashboards(response[responseEntity]))
                        break
                    case "WidgetsResponse":
                        let dashboardId = requests.getElementMessageData(msg.message_uuid)?.dashboardId
                        dispatch(setWidgets({
                            dashboardId: dashboardId,
                            widgets: response[responseEntity]?.widgets
                        }))
                        break
                    case "WidgetResponse":
                        if (response[responseEntity]?.data) {
                            const widgetData = JSON.parse(response[responseEntity]?.data)
                            let request = requests.getElementMessageData(msg.message_uuid)
                            if (request === undefined)
                                request = requestsNoBlock.getElementMessageData(msg.message_uuid)
                            dispatch(setWidgetData(request?.dashboardIndex, request?.dashboardId, request?.widgetIndex, request?.widgetId, widgetData))
                        }
                        break
                    case "NavigatorMainDataResponse":
                        if (response["NavigatorMainDataResponse"]?.refresh === true) {//udelej refresh menu - neco se zmenilo na serveru
                            dispatch(addMessageDialog({ errorMessage: response["NavigatorMainDataResponse"].refreshMessage }));

                            sendMessage(createMessage(ACTION_GET_NAVIGATOR, createRequest({
                                level: response["NavigatorMainDataResponse"].level,
                                emptyRows: localStorage.getItem(LocalStorageKeys.MENU_EMPTY_ROWS) === 'true'
                            })))
                        } else {
                            //console.log("PROCESS", response["NavigatorMainDataResponse"])
                            processMenuItems(response["NavigatorMainDataResponse"], msg.message_uuid)
                        }
                        break
                    case "NavigatorFavoritesDataResponse":
                        if (response["NavigatorFavoritesDataResponse"]?.refresh === true) {//udelej refresh favorites - neco se zmenilo na serveru
                            dispatch(addMessageDialog({ errorMessage: response["NavigatorFavoritesDataResponse"].refreshMessage }));

                            sendMessage(createMessage(ACTION_GET_FAVORITES))
                        } else {
                            dispatch(wsSaveFavoritesItems(response["NavigatorFavoritesDataResponse"].rows))
                        }
                        break
                    case "NavigatorHistoryDataResponse":
                        if (response["NavigatorHistoryDataResponse"]?.refresh === true) {//udelej refresh history - neco se zmenilo na serveru
                            dispatch(addMessageDialog({ errorMessage: response["NavigatorHistoryDataResponse"].refreshMessage }));

                            sendMessage(createMessage(ACTION_GET_HISTORY))
                        } else {
                            dispatch(wsSaveHistoryItems(response["NavigatorHistoryDataResponse"].rows))
                        }
                        break
                    case "ResultDownloadResponse":
                        response["ResultDownloadResponse"].map((file, index) => { newOrReplace(TABLE_FILES, file, () => { }) })
                        break
                    case "DownloadChunkResponse":
                        let downloadChunkResponse = response["DownloadChunkResponse"]

                        //text editoru se neuklada do databaze
                        if (downloadChunkResponse.isEditor)
                            break

                        let downloadChunkRequest = {
                            offset: downloadChunkResponse.offset + 1,
                            filePath: downloadChunkResponse.filePath,
                            key: downloadChunkResponse.key,
                            lastChunk: downloadChunkResponse.lastChunk,
                            isPlainText: downloadChunkResponse.isPlainText,
                            targetFilePath: downloadChunkResponse.targetFilePath,
                            canceled: interrutionInProgress,
                        }

                        //DOWNLOAD_CHUNK
                        appendFileData(downloadChunkResponse.isEditor, TABLE_FILES, downloadChunkResponse, status => {
                            logDebug("@debug will send DownloadChunkRequest")
                            sendMessage(createMessage(DOWNLOAD_CHUNK, createRequest({
                                DownloadChunkRequest: {
                                    ...downloadChunkRequest,
                                    status: status
                                }
                            })), res => {
                                if (interrutionInProgress) {
                                    setInterruptionInProgress(false)

                                } else if (!downloadChunkResponse.isEditor) {
                                    let key = downloadChunkResponse.key
                                    if (downloadChunkResponse.lastChunk && key === "2_OPEN") {
                                        findAndDownload(key, () => {
                                            //smazani z databaze
                                            deleteRecord(TABLE_FILES, key)
                                        })
                                    }
                                }
                            })
                        })
                        break
                    case "OpenFileCommand":
                        findAndOpen(response["OpenFileCommand"].file, () => { })
                        break
                    case "JasperReportPrintResponse":
                        findAndPrint(response["JasperReportPrintResponse"].fileName)
                        break
                    case "SearchQueryResponse":
                        //reseno v callbacku v SearchResult.jsx
                        break
                    case "OkResponse":
                        //console.log("OkResponse...", response["OkResponse"])
                        break
                    case "ErrorMessageResponse":
                        //console.log('%c TODO SHOW ERROR TO USER: ' + msg.data.errorType + ': ' + msg.data.errorMessage + ' - ' + msg.data.errorData, 'color: #f54242');

                        //pokud je vracen websocket error, nastal problem se serializaci objektu message, neni znam message_uuid
                        if (response["ErrorMessageResponse"].errorType === "ERROR_WEB_SOCKET")
                            requests.get().pop()

                        dispatch(addMessageDialog(response["ErrorMessageResponse"]));
                        break
                    case "WSResponse":
                        if (msg.action === "INTERRUPT") {
                            setInterruptionInProgress(false)

                            var btn = document.getElementById(BREAK_BUTTON)
                            if (btn)
                                btn.disabled = false
                        }

                        break
                    case "NavigatorPopup":
                        //console.log("NAVIGATOR PUPUP")
                        dispatch(wsSetNavigatorPopup(response["NavigatorPopup"]))
                        break
                    case "FavouritesPopup":
                        dispatch(wsSetFavouritePopup(response["FavouritesPopup"]))
                        break
                    case "HistoryPopup":
                        dispatch(wsSetHistoryPopup(response["HistoryPopup"]))
                        break
                    case "WidgetDataListResponse":
                        //console.log(response["WidgetDataListResponse"])
                        break
                    case "NotificationResponse":
                        dispatch(addNotifications(response["NotificationResponse"], getScreenVariables(msg)))
                        break
                    case "TriggerGoEvent":
                        dispatch(triggerBrowseGo(msg.screenVariables.focusId, false))
                        break
                    default:
                        logDebug("NEZNAMA ODPOVED: " + msg.action, response)
                        //console.log("NEZNMA ODPOVED", msg.action, response[responseEntity])
                        break
                }
            })
    }

    const processMenuItems = (navigatorDataResponse, message_uuid) => {
        let rqst = requests.getElementMessageData(message_uuid)

        if (rqst?.saveToRedux === false)
            return //resi si to nekdo dal v onReponse - SearchResults

        let level = "0"
        if (rqst?.level != undefined && rqst?.level != "0")
            level = rqst.level.toString()

        if (navigatorDataResponse.level !== undefined && navigatorDataResponse.level !== "")
            level = navigatorDataResponse.level

        let updatedMenu = updateMenuFromNavigatorItems(navigatorDataResponse.rows, level, JSON.parse(JSON.stringify(desktopNavigatorMenu)))

        dispatch(wsSaveNavigatorMenuItems(updatedMenu))
    }

    const createWSConnection = (data, clientParams, callback) => {
        //console.log("createWSConnection", wsStatus)
        if (wsStatus !== STATUS_OPEN) {
            let url = data.ws_base_url + clientUUID
            logDebug("URL", url)

            wsClient = new WebSocket(url)
            requests.erase("createWSConnection")
            requestsImmediately.erase("createWSConnection")
            requestsNoBlock.erase("createWSConnection")

            let pingInterval = null

            wsClient.onopen = function onopen(message) {
                getOS().then(function (os) {
                    wsStatus = STATUS_OPEN
                    callback(wsStatus)

                    logWS("OPEN", message)
                    connectDesktopServer(os, clientParams);
                    pingInterval = setInterval(() => {
                        if (requests.size() == 0 && (lastRequest + 58000) <= new Date().getTime()) {
                            sendMessage(createMessage(PING))
                        }
                    }, 60000);
                })
            };
            wsClient.onclose = function (message) {
                logWS("CLOSE", message)
                wsStatus = STATUS_CLOSE
                callback(wsStatus)

                if (pingInterval != null) {
                    clearInterval(pingInterval)
                    pingInterval = null
                }

                dispatch(setWSDisconnect({
                    connected: ws?.connected ? false : undefined,
                    wsMessage: "",
                    serverUUID: ""
                }))
            }
            wsClient.onerror = function (message) {
                logWS("ERROR", message)
                wsStatus = STATUS_ERROR
                callback(wsStatus)

                if (pingInterval != null) {
                    clearInterval(pingInterval)
                    pingInterval = null
                }

                dispatch(setWSDisconnect({
                    connected: ws?.connected ? false : undefined,
                    wsMessage: "",
                    serverUUID: ""
                }))
            }

            wsClient.onmessage = function (message) {
                var msg = JSON.parse(message.data);

                //let request = requests.getElement(msg.message_uuid)
                let time = 0
                if (requests.getElement(msg.message_uuid)) {
                    time = new Date().getTime() - requests.getElement(msg.message_uuid).timeStamp
                } else if (requestsImmediately.getElement(msg.message_uuid)) {
                    time = new Date().getTime() - requestsImmediately.getElement(msg.message_uuid).timeStamp
                } else if (requestsNoBlock.getElement(msg.message_uuid)) {
                    time = new Date().getTime() - requestsNoBlock.getElement(msg.message_uuid).timeStamp
                }

                logWS("(" + time + "ms) -> receive(" + requests.size() + ")-> ON_MESSAGE (" + msg.message_uuid + ")", msg)
                //PROVEDENI FOCUSU
                /*if (msg.screenVariables != null) {
                    let screenVariables = getScreenVariables(msg)
                    if (screenVariables.focusId !== undefined)
                        focusElement(screenVariables.focusId, "Websocket.onmessage()")
                }*/

                let shouldParseResponse = true
                switch (msg.action) {
                    case CLOSE_SESSION:
                        shouldParseResponse = false
                        disconnectDesktopServer()
                        break
                    case TERMINATE:
                        shouldParseResponse = false
                        resetSession("TERMINATE")
                        initWS([], status => { }, "TERMINATE")
                        break
                    case GET_BROWSE_DATA_AND_SELECT_REQUEST:
                        //browse + screen
                        shouldParseResponse = false

                        //pokud uz radek v browsu existuje nevklada se do storu
                        //let isInBrowse = false
                        //let bdata = desktopSession[ws.currentTabId][ws.currentMaskID][requests.getElementMessageData(msg.message_uuid).handle].data.browseData
                        let browseDataResponse, screenDescriptionEntity
                        msg.data.forEach(response => {
                            let responseEntity = Object.keys(response)[0]
                            if (responseEntity === "BrowseDataResponse")
                                browseDataResponse = response["BrowseDataResponse"]
                            if (responseEntity === "ScreenDescriptionEntity")
                                screenDescriptionEntity = response["ScreenDescriptionEntity"]
                        })

                        /*let brows = browseDataResponse.rows
                        brows.map(row => {
                            bdata.map(browseRow => {
                                //console.log(browseRow.rowId === row.rowId, browseRow.rowId, row.rowId)
                                if (browseRow.rowId === row.rowId)
                                    isInBrowse = true
                            })
                        })*/

                        //if (!isInBrowse) {
                        let screenVariables = getScreenVariables(msg)

                        let scrData = {
                            payload: screenDescriptionEntity?.screenElement,
                            action: msg.action,
                            request: requests.getElementMessageData(msg.message_uuid),
                            screenVariables
                        }

                        let borders = {
                            reachTop: browseDataResponse.reachTop,
                            reachBottom: browseDataResponse.reachBottom
                        }

                        let bdata = desktopSession[ws.currentTabId][ws.currentMaskID][requests.getElementMessageData(msg.message_uuid).handle].data
                        let columnsOrder = bdata.browseColsSorted.split(",")
                        let newData = tranform2TableData(browseDataResponse, columnsOrder)
                        let bbbddaattaa = addNewBrowseData(browseDataResponse, newData, msg.message_uuid, borders, msg.action)
                        dispatch(setBrowseDataAndSelect(bbbddaattaa, scrData, screenDescriptionEntity?.menuItems))
                        //}
                        break
                    default:
                        break;
                }

                if (shouldParseResponse)
                    handleResponse(msg)

                finishTask(msg)

                doSleep0(msg)
            }
        }
    }

    const initWS = (clientParams, callback, from) => {
        //console.log("INIT WS", from, configFileData)
        if (configFileData)
            createWSConnection(configFileData, clientParams, callback)
        else
            fetch('./config.json')
                .then(response => response.json())
                .then(data => {
                    configFileData = data
                    createWSConnection(configFileData, clientParams, callback)
                }).catch(error => {

                })
        //console.log("-------------> END OF ONMESSAGE")
    }

    function doSleep0(msg) {
        //console.log("***** DO SLEEP ZERO: ", msg.screenVariables)
        //hodnota sleep, zatím může být poze "0" nebo "null". Pokud je 0 tak se volá opět metoda getScreen
        //console.log("SLEEP 0", msg)
        if (msg.screenVariables != undefined && msg.screenVariables.sleep !== null) {
            //pokud je vracen EditorElement s paramentrem save, tak se musi nejdříve odeslat obsah editorElementu
            let doGetScreen = true;

            msg.data.forEach(component => {
                if (component.ScreenDescriptionEntity != undefined && component.ScreenDescriptionEntity.screenElement != undefined) {
                    component.ScreenDescriptionEntity.screenElement.forEach(element => {
                        let elementName = Object.keys(element)[0];
                        if (elementName === "EditorElement") {
                            if (element[elementName].save === true) {
                                let edtEl = desktopSession[ws.currentTabId][ws.currentMaskID][element[elementName].handle]
                                let editorText = document.getElementById(edtEl.data.handle).value
                                function chunkString(str, len) {

                                    const size = Math.ceil(str.length / len)
                                    //logDebug("STR", str, size)
                                    //logDebug(str.length, len)
                                    const r = Array(size)
                                    let offset = 0

                                    for (let i = 0; i < size; i++) {
                                        r[i] = str.substr(offset, len)
                                        offset += len
                                    }

                                    return r
                                }

                                var editorTextArray = chunkString(editorText, chunk_size)
                                var currentPart = 0
                                var numParts = editorTextArray.length - 1
                                var uploadChunkRequest = {
                                    uploadFilePath: edtEl.data.sourceFileName
                                }

                                /*rekuzivní volání uploadu casti souboru*/
                                function uploadChunk(part) {
                                    logDebug("numParts", numParts, "currentPart", currentPart, numParts === currentPart)
                                    uploadChunkRequest = {
                                        ...uploadChunkRequest,
                                        chunk: editorTextArray[part],
                                        part: part,
                                        numParts: numParts,
                                        plainText: true,
                                    }

                                    sendMessage(createMessage(UPLOAD_FILE_CHUNK, createRequest({
                                        UploadChunkRequest: {
                                            ...uploadChunkRequest
                                        }
                                    })), response => {
                                        //po uploadu nastaveni na modifikovano na false(pokud neni promenna nastavena, nastal problem pri modifikaci editoru v maskovem zobrazeni + editace)
                                        if (uploadChunkRequest.lastPart) {
                                            dispatch(onValuesChangeInMask(element?.EditorElement?.handle, msg.screenVariables.currentTabId, msg.screenVariables.currentMaskID, {
                                                modified: false
                                            }))
                                        }

                                        response.data.forEach(response => {
                                            let responseEntity = Object.keys(response)[0]
                                            if (responseEntity === "UploadChunkRequest") {
                                                if (currentPart < numParts) {
                                                    currentPart++

                                                } else {
                                                    //konec uploadu
                                                    uploadChunkRequest = {
                                                        ...uploadChunkRequest,
                                                        lastPart: true
                                                    }
                                                }
                                                uploadChunk(currentPart)
                                            }
                                        })
                                    })
                                }

                                //upload
                                uploadChunk(currentPart)

                                doGetScreen = false
                            }
                        }
                    })
                }
            })

            //console.log("DO GET SCREEN" + msg.sleep, msg)
            if (doGetScreen)
                sendMessage(createMessage(GET_SCREEN, createRequest({ emptyRows: localStorage.getItem(LocalStorageKeys.MENU_EMPTY_ROWS) === 'true' })))
        }
    }

    function connectDesktopServer(os, clientParams) {
        //console.log("connectDesktopServer", ws)
        let request = createMessage(CONNECT,
            createRequest({
                wsMessageVersion: WS_MESSAGE_VERSION,
                browser: getBrowser(),
                browserVersion: getBrowserVersion(),
                os: os,
                mobileVersion: isMobileVersion(),
                cookiesEnabled: areCookiesEnabled(),
                lang: (localStorage.getItem(CURRENT_LANGUAGE) === null) ? "" : localStorage.getItem(CURRENT_LANGUAGE),
                websiteUrl: window.location.href,
                clientParams: clientParams
            })
        )

        //po incicailazaci se vola prvni getscreen
        sendMessage(request, (response) => {
            //console.log("RESPONSE", response)
            response.data.forEach(resp => {
                if (resp.OkResponse)
                    dispatch(setWSReconnectData({
                        connected: true,
                        serverUUID: resp.server_uuid,
                        clientUUID: clientUUID
                    }))
            })

            if (response.action !== ACTION_ERROR)
                sendMessage(createMessage(GET_SCREEN))
        })
    }

    function disconnectDesktopServer() {
        sendMessage(createMessage(CLOSE_SESSION), response => {
            resetSession("CLOSE_SESSION")
            initWS([], status => { }, "DISCONNECT")
        })
        //zavre se zalozka neceka se 10s
        window.close()
    }

    function closeWS() {
        wsClient.close()
    }

    function openWS() {
        wsClient.open()
    }

    //----------------------QUEUE PROCESSING-------------------------
    function finishTask(msg) {
        //console.log("finishTask", requests.size(), requestsNoBlock.size(), msg.message_uuid)
        let request = requests.getElement(msg.message_uuid)
        let responseFocusId
        if (request) {
            //REMOVE REQUEST
            requests.dequeue(msg.message_uuid)
            sendingInProgress = false

            //!!! CALLBACK - musi obsahovat sendMessage / nebo musi zavolat callNextRequest()
            if (typeof request.callback === 'function') {
                //console.log("CALL CALLBACK", request)
                request.callback(msg)
            }

            responseFocusId = msg?.screenVariables?.focusId

            if (requests.size() == 0 && !sendingInProgress) {
                //vypnuti loading spinner
                if (store.getState().showSpinner)
                    dispatch(showSpinner(false))
            }
        }

        //REQUESTS NO BLOCK
        let requestNoBlock = requestsNoBlock.getElement(msg.message_uuid)
        if (requestNoBlock) {
            requestsNoBlock.dequeue(msg.message_uuid)
            sendingInProgressNoBlock = false

            if (typeof requestNoBlock.callback === 'function')
                requestNoBlock.callback(msg)
        }

        //REQUESTS IMMEDIATELY
        let requestImmediately = requestsImmediately.getElement(msg.message_uuid)
        if (requestImmediately) {
            requestsImmediately.dequeue(msg.message_uuid)

            if (typeof requestImmediately.callback === 'function')
                requestImmediately.callback(msg)
            return
        }

        //fronta requests ma prednost pred requestsNoBlock a nemuzou bezet soucasne
        if (requests.size() > 0) {
            callNextRequest(responseFocusId)
            return
        }

        callNextRequestNoBlock()
    }

    function callNextRequest(responseFocusId) {
        //console.log("callNextRequest -> queue size: " + requests.size(), requests.get()[0], "sendingInProgress", sendingInProgress)

        //cfavy00s
        //
        //console.log("sendingInProgress", sendingInProgress)
        //if (!sendingInProgress) {
        if (requests.size() > 0 && !sendingInProgress) {
            let nextRequest = requests.get()[0]

            //kontrola rozdilneho focusId - pokud je vraceno jine focusid musi se v nasledujicim pozadavku upravit
            let requestFocusId = nextRequest.message.data[0]?.request?.handle
            //console.log("REQUEST focusId: ", requestFocusId)
            if (responseFocusId && requestFocusId && responseFocusId !== requestFocusId)
                nextRequest.message.data[0].request.handle = responseFocusId

            nextRequest.timeStamp = new Date().getTime()

            //zobrazeni loading spinner
            lastRequest = nextRequest.timeStamp
            const executerId = setTimeout(() => {
                if (requests.getElement(nextRequest.id) !== undefined && !existProgressDialog())
                    dispatch(showSpinner(true))

                clearTimeout(executerId)
            }, showSpinnerTimeLimit)

            logWS("send queue(" + requests.size() + ")-> ON_MESSAGE (" + nextRequest.id + "): " + JSON.stringify(nextRequest.message))
            wsClient.send(JSON.stringify(nextRequest.message))
            sendingInProgress = true
        }
    }

    function callNextRequestNoBlock() {
        //console.log("callNextRequestNoBlock", requests.size() === 0, requestsNoBlock.size() > 0, !sendingInProgressNoBlock)
        if (requests.size() === 0 && requestsNoBlock.size() > 0 && !sendingInProgressNoBlock) {
            let nextRequest = requestsNoBlock.get()[0]

            //kontrola rozdilneho focusId - pokud bylo focusId zmeneno, tak se pozadavek zahodi
            let requestFocusId = nextRequest.message.data[0]?.request?.handle
            let currentFosucId = getStore().getState().ws.focusId
            //console.log("requestFocusId", requestFocusId, "currentFosucId", currentFosucId, (requestFocusId !== undefined && requestFocusId !== currentFosucId))
            if (requestFocusId !== undefined && requestFocusId !== currentFosucId)
                finishTask(nextRequest.message)
            else {
                nextRequest.timeStamp = new Date().getTime()
                logWS("send queue no-block(" + requestsNoBlock.size() + ")-> ON_MESSAGE (" + nextRequest.id + "): " + JSON.stringify(nextRequest.message))
                wsClient.send(JSON.stringify(nextRequest.message))
                nextRequest.inProgress = true
                sendingInProgressNoBlock = true
            }
        }
    }

    //----------------------REQUEST----------------------------------
    function createRequest(requestData) {
        return {
            request: requestData
        }
    }

    /*
    @keys - konstanta KEY_REQUEST se zatim pouziva pro odfiltrovani duplicitncich ws pozdadavku např. v browsu při držení tlačítka dolu
    */
    function createMessage(action, iData, keys) {
        let data = []
        if (iData !== undefined)
            data = [iData]

        return {
            action,
            data,
            keys
        }
    }

    function createQueueRequest(messageValues, callback) {
        const messageUUID = uuidv4()
        const request = {
            id: messageUUID,
            //timeStamp: new Date().getTime(),
            inProgress: false,
            message: {
                ...messageValues,
                message_uuid: messageUUID,
            },
            callback: callback
        }

        return request
    }
    /**
     *
     * @param {createMessage} messageValues
     * @param {callback} callback
     */
    function sendMessage(messageValues, callback) {
        //console.trace("SEND MESSAGE", messageValues)
        //let wasQueueEmpty = requests.size() === 0
        let request = createQueueRequest(messageValues, callback)
        requests.enqueue(request)

        callNextRequest()
    }

    function existProgressDialog() {
        let currentMask = desktopSession[ws.currentTabId][ws.currentMaskID]
        for (const property in currentMask) {
            let data = currentMask[property].data
            if (data?.specialId === Constants.SpecialId.LOADING_DIALOG)
                return true
        }
        return false
    }

    function isQueueEmpty() {
        return (requests.size() === 0)
    }

    function sendMessageImmediately(messageValues, action, callback) {
        if (action === INTERRUPT)
            setInterruptionInProgress(true)

        let request = createQueueRequest(messageValues, callback)

        requestsImmediately.enqueue(request)

        request.timeStamp = new Date().getTime()
        logWS("send Immediately -> ON_MESSAGE: " + JSON.stringify(request.message))

        wsClient.send(JSON.stringify(request.message))
    }

    /*pokud se posila se stejnou akci, dalsi pozadavky se spoji do jednoho noveho*/
    function sendMessageNoBlock(messageValues, callback) {
        let request = createQueueRequest(messageValues, callback)
        requestsNoBlock.enqueueOrUpdate(request)
        callNextRequestNoBlock()
    }

    /*pokud se posila se stejnou akci, dalsi pozadavky se nespoji do jednoho noveho*/
    function sendMessageWithoutUpdate(messageValues, callback) {
        let request = createQueueRequest(messageValues, callback)
        requestsNoBlock.enqueue(request)
        callNextRequestNoBlock()
    }

    //----------------------RESPONSE---------------------------------
    function getScreenVariables(msg) {
        let currentMaskID = msg.screenVariables.currentMaskID;
        if (currentMaskID === null)
            currentMaskID = 0;

        let currentTabId = msg.screenVariables.currentTabId;
        if (currentTabId === null)
            currentTabId = 0;

        return {
            currentMaskID,
            currentTabId,
            focusId: msg.screenVariables.focusId,
            sleep: msg.screenVariables.sleep,
            screenLabel: msg.screenVariables.screenLabel,
            dropContent: msg.screenVariables.dropContent,
            isBreakAllowed: msg.screenVariables.isBreakAllowed
        }
    }

    function getScreenDataFromResponse(msg, response) {
        let msgScreen = response["ScreenDescriptionEntity"]["screenElement"]
        let menuItems = response["ScreenDescriptionEntity"]["menuItems"]
        let screenVariables = getScreenVariables(msg)

        if (msgScreen !== null && msgScreen !== undefined && msgScreen.length > 0) {
            if (msg.action === SET_VALUE_REQUEST) {
                let setValueRequestData = msgScreen.map(item => {
                    if (item.BrowseElement != undefined) {
                        if (item.BrowseElement.handle === screenVariables.focusId) {
                            //pokud je vraceno rowid v browsu, ktere neni v datech, je nutne nacist data(nastaveni refresh pro browse)
                            //muze nastat pri akcich napr home/end/search/refresh
                            let rowId = item.BrowseElement.rowId
                            let browseState
                            if (desktopSession[ws.currentTabId][ws.currentMaskID][item.BrowseElement.handle] !== undefined)
                                browseState = { ...desktopSession[ws.currentTabId][ws.currentMaskID][item.BrowseElement.handle] }

                            if (browseState !== undefined && browseState.data.browseData !== undefined) {
                                let browseData = [...browseState.data.browseData]
                                let existRowId = false

                                if (browseData != undefined)
                                    browseData.map(row => {
                                        if (row.rowId === rowId) {
                                            existRowId = true
                                            return existRowId
                                        }
                                    })

                                if (existRowId === false) {

                                    let request = requests.getElementMessageData(msg.message_uuid)
                                    let ukladam = {
                                        ...item.BrowseElement,
                                        refresh: true
                                    }

                                    //console.log("BBBRRROOWWSSE", ukladam)
                                    //console.log("RRREEQQUUEESST", request)
                                    if (request.action === BRW_HOME) {
                                        ukladam = {
                                            ...ukladam,
                                            reachTop: true,
                                            reachBottom: false
                                        }
                                    } else if (request.action === BRW_END) {
                                        ukladam = {
                                            ...ukladam,
                                            reachTop: false,
                                            reachBottom: true
                                        }
                                    }
                                    return {
                                        BrowseElement: {
                                            ...ukladam
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return item
                })

                msgScreen = setValueRequestData
            }
        }

        return { msgScreen, menuItems, screenVariables }
    }

    /*function getScreenData(msg) {
        let msgScreen = msg.data;
        if (msgScreen !== null && msgScreen !== undefined) {
            if (msg.action === SET_VALUE_REQUEST) {
                let setValueRequestData = msg.data.map(item => {
                    if (item.BrowseElement != undefined) {
                        if (item.BrowseElement.handle === msg.screenVariables.focusId) {
                            //pokud je vraceno rowid v browsu, ktere neni v datech, je nutne nacist data(nastaveni refresh pro browse)
                            //muze nastat pri akcich napr home/end/search/refresh
                            let rowId = item.BrowseElement.rowId
                            let state = desktopSession[ws.currentTabId][ws.currentMaskID][item.BrowseElement.handle]
     
     
                            if (state != undefined) {
                                let browseData = state.data.browseData
                                let existRowId = false
     
                                if (browseData != undefined)
                                    browseData.map(row => {
                                        if (row.rowId === rowId) {
                                            existRowId = true
                                            return existRowId
                                        }
                                    })
     
                                if (existRowId === false)
                                    return {
                                        BrowseElement: {
                                            ...item.BrowseElement, refresh: true
                                        }
                                    }
                            }
                        }
                    }
     
                    return item
                })
     
                msgScreen = setValueRequestData
            }
        }
     
        return msgScreen
    }*/

    function addNewBrowseData(addNewBrowseData, newData, message_uuid, borders, action) {
        let request = requests.getElementMessageData(message_uuid)
        let browseData = [...desktopSession[ws.currentTabId][ws.currentMaskID][request.handle].data.browseData]
        let sizePerPage = desktopSession[ws.currentTabId][ws.currentMaskID][request.handle].data.sizePerPage

        //console.log("++++++++++++++ REDUCER ADD DATA")
        //console.log(addNewBrowseData)
        const numRowsInBuffer = 3 * sizePerPage //getNumberOfRows(request.handle)
        let numBuffers = 3
        //console.log("reach top: ", addNewBrowseData.reachTop, "reach bottom: ", addNewBrowseData.reachBottom)

        // pridat na zacatek/konec
        //console.log("numRows: ", request.numRows)
        if (request.numRows > 0) {
            //DOLU
            //console.log("numBuffers", numBuffers)
            //console.log("stare", browseData.length)
            //console.log("nove", newData.length)

            //pridani na konec
            newData.map(column => {
                browseData.push(column)
            })

            let remove = browseData.length - numBuffers * sizePerPage //getNumberOfRows(request.handle)
            //console.log("DOLU", remove)
            //console.log(browseData.length + " > " + numRowsInBuffer)
            if (browseData.length > numRowsInBuffer) {
                browseData.splice(0, remove) //splice(start, deleteCount)
            }
        } else {
            //NAHORU
            //console.log("stare", browseData.length)
            //console.log("nove", newData.length)

            // pridani na zacatek
            newData.map(column => {
                browseData.unshift(column)
            })

            let remove = browseData.length - numBuffers * sizePerPage //getNumberOfRows(request.handle)
            //console.log("NAHORU", remove)
            //console.log(browseData.length + " > " + numRowsInBuffer)
            if (browseData.length > numRowsInBuffer) {
                browseData.splice(browseData.length - remove, remove) //splice(start, deleteCount)
            }
        }


        let direction = ""
        if (action === GET_BROWSE_DATA_REQUEST || GET_BROWSE_DATA_AND_SELECT_REQUEST) {
            if (request.numRows > 0)
                direction = DOWN
            else {
                direction = UP
            }
        }

        let scrollOptions = {
            direction,
            action,
            rowId: request.rowId,
            loadData: request.scrollOptions?.loadData,
            horizontalScrollPosition: request?.scrollOptions?.horizontalScrollPosition,
            verticalScrollPosition: request?.scrollOptions?.verticalScrollPosition,
        }

        //console.log("SCROLL OPTIONS", scrollOptions)
        //let action = requests.getElement(msg.message_uuid).action
        //console.log("SCROLL INTO VIEW", scrollIntoView)
        return {
            browseHandle: addNewBrowseData.browseHandle,
            browseData: browseData,
            borders,
            scrollOptions
        }
    }

    function getTimeOfLastRequest() {
        return lastRequest
    }

    return (
        <WebSocketContext.Provider value={{
            ws: wsClient,
            wsMessagesDialogs: wsMessagesDialogs,
            getRequestsQueue,
            getRequestsNoBlock,
            initWS,
            closeWS,
            openWS,
            connectDesktopServer,
            disconnectDesktopServer,
            sendMessage,
            sendMessageImmediately,
            sendMessageNoBlock,
            sendMessageWithoutUpdate,
            callNextRequest,
            createRequest,
            createMessage,
            isQueueEmpty,
            getTimeOfLastRequest
        }}>
            {children}
        </WebSocketContext.Provider>
    )


}

export default WebSocketProvider;
