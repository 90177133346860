import React from 'react'
import { connect } from 'react-redux'

import { logRender, logRenderProps } from '../utils/logging.js'


function Spinner(props) {
    let { showSpinner } = props

    logRender("Spinner")
    logRenderProps(props)

    if (showSpinner === false)
        return null

    return (
        <div className="spinner-border text-warning me-2 spinner-center" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>)
}

function mapStateToProps(state) {
    return {
        showSpinner: state.showSpinner,
    }
}

export default connect(mapStateToProps)(Spinner)
