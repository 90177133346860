import React from 'react'
import { connect } from 'react-redux'
import { logRender, logRenderProps } from '../utils/logging'
import { addListenerWithRefHandler, LISTENER_TYPE, addListener } from '../hooks/desktopListener'
import { WebSocketContext } from '../services/WebSocket'
import { focus2SessionMain } from '../focus_actions'
import Constants from '../constants/constants'
import ContextMenu from './ContextMenu'
import { MENU_FAVORITE } from './SideMenu'

function DesktopFavoriteMenuRow({ rowName, menuItem, refContextMenu }) {
    const menuItemRef = React.useRef()
    const ws_context = React.useContext(WebSocketContext)

    const style = { border: 'none', padding: '.1rem' }

    addListenerWithRefHandler(LISTENER_TYPE.CLICK, menuItemRef, e => {
        if (!window.getSelection()?.toString())
            ws_context.sendMessage(ws_context.createMessage(Constants.WebSocketActions.EXECUTE_PROGRAM_NAVIGATOR_FAVORITES, ws_context.createRequest({
                ...menuItem,
                event: Constants.MenuEvents.RUN
            })), response => focus2SessionMain())
    })

    addListener(LISTENER_TYPE.CONTEXT_MENU, menuItemRef, e => {
        e.preventDefault()

        let menuItemProps = {
            disabled: !menuItem.isAllowed,
            programName: menuItem.programName,
            programParameter: menuItem.programParameter,
            rowItemId: menuItem.rowItemId,
            level: menuItem.level,
        }

        refContextMenu.current.displayContextMenu(e, "menu", menuItemProps, MENU_FAVORITE)
    })

    let enabled = menuItem.isAllowed ? "" : " isDisabled"

    return (
        <a ref={menuItemRef} href="#" className={"list-group-item list-group-item-action" + enabled} style={style}>
            {rowName}
        </a>
    )
}

function FavoritePrograms({ desktopFavoritesMenu, messageContainer, popupFavourites }) {
    const refContextMenu = React.useRef({})

    function renderRows() {
        return desktopFavoritesMenu
            ? Object.values(desktopFavoritesMenu).map((value, i) => {
                return <DesktopFavoriteMenuRow key={value.rowName} rowName={value.rowName} menuItem={value} refContextMenu={refContextMenu} />
            })
            : []
    }

    logRender('FavoritePrograms ')
    return (
        <React.Fragment>
            {<ContextMenu ctxRef={refContextMenu} type={MENU_FAVORITE} popupFavourites={popupFavourites} />}
            <div className="list-group card-text grid-card h-100" >{renderRows()}</div>
        </React.Fragment>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        desktopFavoritesMenu: state.desktopFavoritesMenu,
        messageContainer: state.app.messageContainer,
        popupFavourites: state.popupFavourites
    }
}

export default connect(mapStateToProps)(FavoritePrograms)