import { useEffect, useRef } from 'react'

export const LISTENER_TYPE = {
    KEYDOWN: "keydown",
    KEYUP: "keyup",
    KEYPRESS: "keypress ",
    CLICK: "click",
    DOUBLE_CLICK: "dblclick",
    CONTEXT_MENU: "contextmenu",
    ON_BLUR: "blur",
    ON_FOCUS: "focus",
    SCROLL: "scroll",
    MOUSEDOWN: "mousedown",
    MOUSEUP: "mouseup",
    MOUSEMOVE: "mousemove",
    MOUSE_ENTER: "mouseenter",
    MOUSE_LEAVE: "mouseleave",
    MOUSEOUT: "mouseout",
    RESIZE: "resize",
    DRAG_START: "dragstart",
    DRAG_END: "dragend",
    ON_CHANGE: "change" //nefunguje... nevim proc
}

/**
 * Zaregistruje udalost pro keydown a click
 * @param {import('react').MutableRefObject} attachTo
 * @param {Function} handler
 */
export default function addKeyMouseClickListener(attachTo, handler) {
    //KeyListener(attachTo, handler)
    //ClickListener(attachTo, handler)
    addListener(LISTENER_TYPE.KEYDOWN, attachTo, handler)
    addListener(LISTENER_TYPE.CLICK, attachTo, handler)
}

/**
 *
 * @param {LISTENER_TYPE} type
 * @param {import('react').MutableRefObject} attachTo
 * @param {Function} handler
 */
export function addListener(type, attachTo, handler) {
    useEffect(() => {
        const controller = new AbortController()
        //console.log("USE EFFECT LISTENER", attachTo, isElement(attachTo))
        if (attachTo === undefined || attachTo === null) return

        if (attachTo instanceof Document) {
            attachTo.addEventListener(type, handler, { signal: controller.signal })
        } else {
            if (attachTo.current && attachTo.current !== null)
                attachTo.current.addEventListener(type, handler, { signal: controller.signal })
        }

        return () => { controller.abort() }
    })
}

/**
 * Listener se registruje se pouze po prvním vykreslení, reference na hadler zajišťuje aktuální hodnoty uvnitř i po dalším překreslení
 * @param {LISTENER_TYPE} type
 * @param {import('react').MutableRefObject} attachTo
 * @param {Function} handler
 */
export function addListenerWithRefHandler(type, attachTo, handler) {
    const handlerRef = useRef(handler)
    useEffect(() => { handlerRef.current = handler })
    useEffect(() => {
        const controller = new AbortController()
        const updatedHandler = e => handlerRef.current(e)

        if (attachTo.current !== null && attachTo.current !== undefined)
            attachTo.current.addEventListener(type, updatedHandler, { signal: controller.signal })
        return () => { controller.abort() }
    }, [])
}

/**
 * Zaregistruje udalost pro click
 * @param {import('react').MutableRefObject} attachTo
 * @param {Function} handler
 */
export function ClickListener(attachTo, handler) {
    useEffect(() => {
        const controller = new AbortController()

        if (attachTo == null) return

        if (attachTo.current != null) {
            attachTo.current.addEventListener('click', handler, { signal: controller.signal })
        } else {
            //console.log("attach: ", attachTo)
            attachTo.addEventListener('click', handler, { signal: controller.signal })
        }

        return () => {
            controller.abort()
        }
    })
}
