import React from 'react'

import { connect } from 'react-redux'

import Browse from '../browse/Browse'
import ButtonElement from '../desktop_server/ButtonElement'
import { logRender, logRenderProps } from '../../utils/logging'
import SettingsDropdown from '../../components/SettingsDropdown'
import BSTooltip from '../BSTooltip'

function AccountUnitSelection(props, renderChild) {
    const { data, childIds, ucjeScreen } = props
    const { defaultButtonHandle, stornoButtonHandle } = data

    const [calculatorVisibility, setCalculatorVisibility] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const precision = 3; //pocet desetinnych mist

    const refInput = React.useRef(null);

    let browseProps, btnOkProps, btnStornoProps


    //nelezeni browse
    childIds.forEach(item => {
        let element = ucjeScreen[item];
        if (element.componentName === "BrowseElement") {
            browseProps = element
        } else {
            //OK
            if (element.id === defaultButtonHandle)
                btnOkProps = element

            //STORNO
            if (element.id === stornoButtonHandle)
                btnStornoProps = element
        }
    })

    logRender("AccountUnitSelection")
    logRenderProps(props)
    return (

        <div>

            <div className='position-absolute top-0 end-0'>
                <SettingsDropdown />
            </div>
            
            <main className="d-flex w-90">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                <div className="text-center mb-4">
                                    <h1 className="h2">{data.header}</h1>
                                    <p className="lead">{props.title}</p>
                                </div>
                                <div className="card" style={{ boxShadow: "0px 0px 20px 5px rgba(195,192,192,.5)" }}>
                                    <div className="card-body">
                                        <div className="m-sm-4">
                                            <div className='form-group mb-4 row gx-0'>
                                                <Browse key={"brw" + props.id}{...browseProps} renderChild={renderChild} hidePagination={true} />
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <ButtonElement key={"btn" + btnOkProps.id} {...btnOkProps} />
                                                </div>
                                                <div className="col">
                                                    <ButtonElement key={"btn" + btnStornoProps.id} {...btnStornoProps} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div >
    )
}

function mapStateToProps(state) {
    return {
        ucjeScreen: state.desktopSession[state.ws.currentTabId][state.ws.currentMaskID],
        title: state.app.title,
    }
}

export default connect(mapStateToProps)(AccountUnitSelection)
