import React from 'react';
import { addListener } from '../hooks/desktopListener'
import useFocusObserver from '../hooks/useFocusObserver'
import PropTypes from 'prop-types'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.bundle.min.js'


import { LISTENER_TYPE } from '../hooks/desktopListener'
const { KEYDOWN, CLICK } = LISTENER_TYPE

/**
 * Univerzalni html item, ktera ma standardne reagovat na kliknuti nebo klavesnici a zaroven neposlouchat, kdyz uz probiha jine zpracovani (resi se v App.js)
 * @param {*} props
 * @returns
 */
export default function ElementWithListener(props) {
    const thisRef = React.useRef()
    let { id, doAction, outerHtmlClassName, mouseListener, keyListener, children, disabled, style, title } = props

    useFocusObserver(id, "ElementWithListener")

    addListener(CLICK, thisRef, (e) => {
        if (doAction !== undefined && mouseListener)
            doAction(e)
    })

    addListener(KEYDOWN, thisRef, (e) => {
        if (doAction !== undefined && keyListener)
            doAction(e)
    })

    //nefungje disabled pro div
    if (outerHtmlClassName !== undefined && outerHtmlClassName.includes("btn"))
        return <button id={id} className={outerHtmlClassName} ref={thisRef} disabled={disabled} style={style}>{children}</button>

    let additionalProps = {}
    if (props.tooltipIdOffer !== undefined) {
        additionalProps = {
            onMouseOver: e => {
                //vstup do redTriangle - pro nabidku zneplatni tooltip z InputFieldElement
                let inputFieldElementId = props.tooltipIdOffer.replace("o_", "t_")
                let inputFieldElement = document.getElementById(inputFieldElementId)
                let tooltip = Tooltip.getInstance(inputFieldElement)
                if (tooltip !== null)
                    tooltip.disable()
            },
            onMouseOut: e => {
                //vystup do redTriangle - zplatni tooltip pro InputFieldElement
                let inputFieldElementId = props.tooltipIdOffer.replace("o_", "t_")
                let inputFieldElement = document.getElementById(inputFieldElementId)
                let tooltip = Tooltip.getInstance(inputFieldElement)
                if (tooltip !== null)
                    tooltip.enable()
            }
        }
    }

    return <div {...additionalProps} className={outerHtmlClassName} ref={thisRef} style={style} title={title}>{children}</div>
}

ElementWithListener.propTypes = {
    doAction: PropTypes.func,
    outerHtmlClassName: PropTypes.string,
    mouseListener: PropTypes.bool,
    keyListener: PropTypes.bool,
    disabled: PropTypes.bool
}