import { createPortal } from 'react-dom'
import React from "react"
import { useStore, useDispatch } from 'react-redux'


import ElementWithListener from "../ElementWithListener"
import ThemeSwither from '../../theme/ThemeSwither'
import { logRender, logRenderProps } from '../../utils/logging'
import Constants from '../../constants/constants'
import { onValueChange } from '../../store/actions/store_actions'
import { addListener, LISTENER_TYPE } from '../../hooks/desktopListener'
import Styles from '../../constants/styles'
import { WebSocketContext } from '../../services/WebSocket'
import styles from '../../constants/styles'


const { LocalStorageKeys } = Constants

export default function SettingsDialog(props) {
    const dispatch = useDispatch()
    const store = useStore()
    const app = store.getState().app
    const supportedLanguages = app.supportedLanguages
    const { CURRENT_LANGUAGE } = Constants.LocalStorageKeys
    const [loggingWebSockets, setLoggingWebSockets] = React.useState(localStorage.getItem(LocalStorageKeys.LOGGING_WEB_SOCKETS) === 'true')
    const [menuEmptyRows, setMenuEmptyRows] = React.useState(localStorage.getItem(LocalStorageKeys.MENU_EMPTY_ROWS) === 'true')
    const [browseRowColoredBackground, setBrowseRowColoredBackground] = React.useState(localStorage.getItem(LocalStorageKeys.BROWSE_ROW_COLORED_BACKGROUND) === 'true')
    const [tooltipEnabled, setTooltipEnabled] = React.useState(localStorage.getItem(LocalStorageKeys.TOOLTIP) === 'true')
    const [loggingDebug, setLoggingDebug] = React.useState(localStorage.getItem(LocalStorageKeys.LOGGING_DEBUG) === 'true')
    const [loggingFocus, setLoggingFocus] = React.useState(localStorage.getItem(LocalStorageKeys.LOGGING_FOCUS) === 'true')
    const [loggingRender, setLoggingRender] = React.useState(localStorage.getItem(LocalStorageKeys.LOGGING_RENDER) === 'true')
    const [loggingProps, setLoggingProps] = React.useState(localStorage.getItem(LocalStorageKeys.LOGGING_PROPS) === 'true')

    const { isLoggingDialogVisible, setLoggingDialogVisibility } = props

    var usedLang = (localStorage.getItem(CURRENT_LANGUAGE) === null) ? app.usedLanguage : localStorage.getItem(CURRENT_LANGUAGE)

    if (!isLoggingDialogVisible)
        return

    addListener(LISTENER_TYPE.KEYDOWN, document, e => {
        if (e.key === 'Escape')
            setLoggingDialogVisibility(false)
    })

    logRender("SettingsDropdown")
    logRenderProps(props)

    return (createPortal(<div id="dialog-container">
        <div
            id="settings_dialog"
            aria-modal="true"
            style={{
                ...Styles.dialogStyle
            }}>

            <div className="modal-content blue_box" style={{ ...Styles.dialogContent, maxWidth: '50%' }} data-bs-backdrop="static">
                <div className="modal-header p-3">
                    <h4 className="modal-title">{app.messageContainer[Constants.MessageContainer.SETTINGS]}</h4>
                </div>
                <div className="modal-body p-3 ps-3">

                    <div className="form-check form-switch">
                        <input
                            id={LocalStorageKeys.MENU_EMPTY_ROWS}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={menuEmptyRows}
                            onChange={e => {
                                if (confirm(app.messageContainer["gui.configuration.restart"])) {
                                    setMenuEmptyRows(e.target.checked)
                                    localStorage.setItem(LocalStorageKeys.MENU_EMPTY_ROWS, e.target.checked)
                                    window.location.reload()
                                }
                            }} />
                        <label className="form-check-label" htmlFor={LocalStorageKeys.MENU_EMPTY_ROWS}>{app.messageContainer[Constants.MessageContainer.MENU_EMPTY_ROWS]}</label>
                    </div>

                    <ThemeSwither askRestartMessage={app.messageContainer["gui.configuration.restart"]} label={app.messageContainer["menu.main.view.theme.nightMode"]} />

                    <div className="form-check form-switch">
                        <input
                            id={LocalStorageKeys.BROWSE_ROW_COLORED_BACKGROUND}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={browseRowColoredBackground}
                            onChange={e => {
                                setBrowseRowColoredBackground(e.target.checked)
                            }} />
                        <label className="form-check-label" htmlFor={LocalStorageKeys.BROWSE_ROW_COLORED_BACKGROUND}>{app.messageContainer[Constants.MessageContainer.BROWSE_ROW_COLORED]}</label>
                    </div>

                    <div className="form-check form-switch">
                        <input
                            id={LocalStorageKeys.TOOLTIP}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={tooltipEnabled}
                            onChange={e => {
                                setTooltipEnabled(e.target.checked)
                            }} />
                        <label className="form-check-label" htmlFor={LocalStorageKeys.TOOLTIP}>{app.messageContainer[Constants.MessageContainer.TOOLTIP]}</label>
                    </div>

                    {(() => {
                        if (supportedLanguages == undefined) return "" //neni vyber jazyku na serveru
                        let supportedLanguagesArray = supportedLanguages.split(",")
                        if (supportedLanguagesArray.length > 1) {
                            return <li className="dropdown-item">
                                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" style={{ paddingLeft: 0 }}>
                                    <img src={require("../../resources/images/flags/" + usedLang + '.png').default} className="align-middle me-1" width="20"></img>
                                </button>
                                <div className="dropdown-menu">
                                    {supportedLanguages.split(",").map((lang, index) => (
                                        <ElementWithListener key={index + lang}
                                            outerHtmlClassName={"dropdown-item"}
                                            doAction={(event) => {
                                                if (!(event instanceof MouseEvent) && !(event instanceof KeyboardEvent && event.key === "Enter")) return

                                                localStorage.setItem(CURRENT_LANGUAGE, lang) //save current language
                                                window.location.reload()
                                            }}
                                            mouseListener={true}>
                                            <><img src={require("../../resources/images/flags/" + lang + ".png").default} className="align-middle me-1" width="20"></img><span className="align-middle">{lang}</span></>
                                        </ElementWithListener>
                                    ))}
                                </div>
                            </li>
                        }
                    })()}

                    <label className="col-sm-2 col-form-label col-form-label-lg">{app.messageContainer[Constants.MessageContainer.LOGGING]}</label>

                    <div className="form-check form-switch">
                        <input
                            id={LocalStorageKeys.LOGGING_DEBUG}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={loggingDebug}
                            onChange={e => {
                                setLoggingDebug(e.target.checked)
                            }} />
                        <label className="form-check-label" htmlFor={LocalStorageKeys.LOGGING_DEBUG}>Debug log</label>
                    </div>
                    <div className="form-check form-switch">
                        <input
                            id={LocalStorageKeys.LOGGING_WEB_SOCKETS}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={loggingWebSockets}
                            onChange={e => {
                                setLoggingWebSockets(e.target.checked)
                            }} />
                        <label className="form-check-label" htmlFor={LocalStorageKeys.LOGGING_WEB_SOCKETS}>WebSocket log</label>
                    </div>
                    <div className="form-check form-switch">
                        <input
                            id={LocalStorageKeys.LOGGING_FOCUS}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={loggingFocus}
                            onChange={e => {
                                setLoggingFocus(e.target.checked)
                            }} />
                        <label className="form-check-label" htmlFor={LocalStorageKeys.LOGGING_FOCUS}>Focus log</label>
                    </div>
                    <div className="form-check form-switch">
                        <input
                            id={LocalStorageKeys.LOGGING_RENDER}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={loggingRender}
                            onChange={e => {
                                setLoggingRender(e.target.checked)
                            }} />
                        <label className="form-check-label" htmlFor={LocalStorageKeys.LOGGING_RENDER}>Render log</label>
                    </div>
                    <div className="form-check form-switch">
                        <input
                            id={LocalStorageKeys.LOGGING_PROPS}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={loggingProps}
                            onChange={e => {
                                setLoggingProps(e.target.checked)
                            }} />
                        <label className="form-check-label" htmlFor={LocalStorageKeys.LOGGING_PROPS}>Props log</label>
                    </div>

                </div>
                <div className="modal-footer p-3 pb-3">
                    <button
                        type="button"
                        className="btn btn-secondary me-2"
                        style={styles.buttonStyle}
                        onClick={e => {
                            setLoggingDialogVisibility(false)
                        }}>{app.messageContainer["button.storno"]}</button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        style={styles.buttonStyle}
                        onClick={e => {
                            setLoggingDialogVisibility(false)

                            localStorage.setItem(LocalStorageKeys.BROWSE_ROW_COLORED_BACKGROUND, browseRowColoredBackground)
                            localStorage.setItem(LocalStorageKeys.TOOLTIP, tooltipEnabled)
                            localStorage.setItem(LocalStorageKeys.LOGGING_WEB_SOCKETS, loggingWebSockets)
                            localStorage.setItem(LocalStorageKeys.LOGGING_DEBUG, loggingDebug)
                            localStorage.setItem(LocalStorageKeys.LOGGING_FOCUS, loggingFocus)
                            localStorage.setItem(LocalStorageKeys.LOGGING_RENDER, loggingRender)
                            localStorage.setItem(LocalStorageKeys.LOGGING_PROPS, loggingProps)

                            dispatch(onValueChange("browseRowColoredBackground", localStorage.getItem(LocalStorageKeys.BROWSE_ROW_COLORED_BACKGROUND) === "true"))
                            dispatch(onValueChange("tooltipEnabled", localStorage.getItem(LocalStorageKeys.TOOLTIP) === "true"))

                        }}>{app.messageContainer["button.ok"]}</button>
                </div>
            </div>
        </div >
    </div>, document.body))
}